import React, { useContext, useEffect, useState } from 'react';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import { Article } from '../../models/article';
import { StoreContext } from '../../../../../StoreContext';
import ImageContainer from '../../../../common/components/ImageContainer';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import { ProductInformation } from './ProductInformation';
import AvailabilityCheck from '../availabilityMessage/AvailabilityCheck';
import CatalogService from '../../../../catalog/services/catalogService';
import ArticleDetailAddCart from './ArticleDetailAddCart';
import Price from '../tiles/Price';
import Divider from '@mui/material/Divider';
import ArticleActions from '../ArticleActions';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ArticleSellUnit from './ArticleSellUnit';
import ArticleInfoIcons from '../ArticleInfoIcons';
import ArticleIcons from '../ArticleIcons';
import useTheme from '@mui/material/styles/useTheme';
import ComparisonPrice from './ComparisonPrice';
import SimilarArticlesCarousel from './SimilarArticlesCarousel';
import PriceTag from '../PriceTag';
import { useTranslation } from 'react-i18next';
import CatalogItemSupplierInfo from '../factinfo/CatalogItemSupplierInfo';
import { makeStyles } from 'tss-react/mui';
import { getLanguageId } from '../../../../../util/i18n';
import { sendEventToGoogleAnalytics } from '../../../../../tracking/googleAnalytics';
import { formatDate } from '../../../../../util/dateUtils';

const useStyles = makeStyles()(({ spacing, palette }: Theme) => {
  return {
    loader: {
      height: 'calc(90vh - 75px)'
    },
    articleActionText: {
      color: palette.primary.main
    },
    firstColumn: {
      width: 300,
      minWidth: 300,
      maxWidth: 300,
      marginLeft: spacing(2),
      marginRight: spacing(2)
    },
    secondColumn: {
      flexGrow: 1,
      marginLeft: spacing(2),
      marginRight: spacing(2),
      maxWidth: 'auto',
      minWidth: 'auto'
    },
    additionalIconsFrame: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2)
    },
    sticky: {
      zIndex: 999,
      backgroundColor: 'white',
      top: 0
    },
    dialogTitle: {
      margin: 0,
      padding: 0
    },
    horizontalDivider: {
      marginBottom: spacing(1),
      marginTop: spacing(1)
    },
    price: {
      minWidth: 200,
      marginBottom: spacing(1)
    },
    similarProducts: {
      marginTop: spacing(1)
    }
  };
});

interface IArticleDetailViewProps {
  articleNumber: string;
}

const ArticleDetailView = observer(({ articleNumber }: IArticleDetailViewProps) => {
  const { userStore, catalogStore, cartStore, masterDataStore } = useContext(StoreContext);
  const { classes } = useStyles();
  const [article, setArticle] = useState<Article>();
  const [recentOrderInfoText, setRecentOrderInfoText] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('sm'));

  useEffect(() => {
    const fetchArticle = async (artNo: string, isLoggedIn: boolean) => {
      try {
        setIsLoading(true);
        const loadedArticle = await CatalogService.fetchCatalogItem(artNo, isLoggedIn);

        const art = Article.createFromDto(loadedArticle.article, 1); // createFromDto only copies the data from IArticleDTO
        art.update(loadedArticle, 1); // update also copies the data from IArticleDetailDTO
        art.fetchArticleDeepLoaded(userStore.isLoggedIn, false);
        const zzIndexCurrentArticle = art.zzArticles.findIndex((a) => a.articleNumber === articleNumber);
        art.zzIndex = zzIndexCurrentArticle > 0 ? zzIndexCurrentArticle : 0;
        setArticle(art);

        if (loadedArticle.recentOrderDate) {
          const sellUnitValue = masterDataStore.getSellUnit(loadedArticle.recentOrderSellUnitType);
          const articleUnitValue = masterDataStore.getSellUnit(loadedArticle.recentOrderArticleUnitType);
          const textSellUnitText = loadedArticle.recentOrderSellAmount > 1 ? sellUnitValue + ` ${t('sellUnitSeparator')} ` : '';
          const textPart2 = loadedArticle.recentOrderWeight ? loadedArticle.recentOrderWeight.toFixed(3) + ' ' + articleUnitValue : loadedArticle.recentOrderSellAmount + ' ' + articleUnitValue;

          setRecentOrderInfoText(`${t('Zuletzt bestellt mit Lieferdatum am')} ${formatDate(new Date(loadedArticle.recentOrderDate))}: ${loadedArticle.recentOrderQuantity} x ${textSellUnitText}${textPart2}`);
        }

        const pageName = loadedArticle.article.articleText[getLanguageId()] ?? 'Transgourmet Web Shop';
        sendEventToGoogleAnalytics('detail', { page_pageInfo_pageName: pageName });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchArticle(articleNumber, userStore.isLoggedIn);
  }, [articleNumber, masterDataStore, t, userStore.isLoggedIn]);

  const handleIsInCart = (checkArticle: Article) => {
    const articleIdCartInfo = cartStore.getAmountInCartByArticleId(checkArticle.articleId);
    const articleCartInfo = cartStore.getAmountInCartByArticleNr(checkArticle.articleNumber);

    if (articleCartInfo) {
      return articleCartInfo > 0;
    } else if (articleIdCartInfo) {
      return articleIdCartInfo > 0;
    } else {
      return false;
    }
  };

  const handleAddToCart = (addArticle: Article) => {
    catalogStore.addArticleToCart(addArticle);
  };

  const handleIsLoading = (article: Article) => {
    return catalogStore.rootStore.cartStore.hasPendingIntents && catalogStore.isAddPositionIntentPending(article);
  };

  const handleOnChangeSellUnit = (changeArticle: Article, value: string) => {
    changeArticle.zzIndex = parseInt(value, 10);
  };

  if (isLoading) {
    return (
      <Grid container direction={'column'} justifyContent={'center'} alignItems={'center'} className={classes.loader}>
        <Grid item>
          <CircularProgress variant={'indeterminate'} />
        </Grid>
      </Grid>
    );
  }

  if (!article) {
    return <ArticleDetailNotAvailableMessage articleNumber={articleNumber} isLoggedIn={userStore.isLoggedIn} />;
  }

  if (isMobile) {
    return (
      <Grid container spacing={1} direction={'column'}>
        <Grid item className={classes.price} xs={12}>
          <Price item={article.articleOrZzArticle} id={'article-detail-mobile-price'} actionExclude={userStore.actionExclude} />
          <Divider sx={{ opacity: 0.6 }} className={classes.horizontalDivider} />
          {(article.hasComparisonPrice || article.hasPriceSteps) && (
            <>
              <PriceTag alignRight={false} article={article.articleOrZzArticle} ignoreGraduatedPriceSteps={false} />
              <ComparisonPrice article={article.articleOrZzArticle} />
              <Divider sx={{ opacity: 0.6 }} className={classes.horizontalDivider} />
            </>
          )}
        </Grid>
        {article.brandLabel && article.brandLabelImageUrl && (
          <Grid item xs={12}>
            <ImageContainer src={article.brandLabelImageUrl} width={100} disabledLoadingIndicator disableAlternativeImage />
          </Grid>
        )}
        {article.exclusiveness && article.exclusivenessImageUrl && (
          <Grid item xs={12}>
            <ImageContainer src={article.exclusivenessImageUrl} disableAlternativeImage width={100} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Box display={'flex'} justifyContent={'center'}>
            <ImageContainer src={article.imageUrl} width={300} height={300} disableAlternativeImage />
          </Box>
          <ArticleIcons article={article} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h1" color={article.displayAsAction ? 'primary' : 'textPrimary'}>
            {article.articleText}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ opacity: 0.6 }} className={classes.horizontalDivider} />
          <ArticleSellUnit article={article} justify={'center'} onChangeSellUnit={(unit: string) => handleOnChangeSellUnit(article, unit)} id={'article-detail-mobile-sellunit'} />
          <CatalogItemSupplierInfo article={article} />
          <Divider sx={{ opacity: 0.6 }} className={classes.horizontalDivider} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent={'center'} alignItems={'flex-end'} spacing={2}>
            <Grid item>
              <Grid container>
                <Grid item>
                  <ArticleInfoIcons article={article.articleOrZzArticle} isMedium={true} id={'article-detail-mobile-infoicons'} />
                </Grid>
                <Grid item>
                  <ArticleActions article={article.articleOrZzArticle} id={'article-detail-mobile-actions'} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <ArticleDetailAddCart isLoading={handleIsLoading} article={article.articleOrZzArticle} isLoggedIn={userStore.isLoggedIn} addToCart={handleAddToCart} isInCart={handleIsInCart} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <AvailabilityCheck article={article.articleOrZzArticle} variant="body2" showCompactMessage={false} />
          <Divider sx={{ opacity: 0.6 }} className={classes.horizontalDivider} />
        </Grid>
        {recentOrderInfoText && (
          <Grid item xs={12}>
            <Typography color={'textSecondary'}>{recentOrderInfoText}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <ProductInformation article={article} />
        </Grid>
        <Grid item>
          <Typography variant={'subtitle1'}>{t('Ähnliche Produkte')}</Typography>
          <Grid container justifyContent={'center'}>
            <Grid item>
              <SimilarArticlesCarousel article={article} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      {article && (
        <Grid container wrap={'nowrap'}>
          <Grid item className={classes.firstColumn}>
            <div className={classes.sticky}>
              <Grid container spacing={1}>
                {article.brandLabel && article.brandLabelImageUrl && (
                  <Grid item>
                    <ImageContainer disableAlternativeImage src={article.brandLabelImageUrl} width={80} disabledLoadingIndicator />
                  </Grid>
                )}
                {article.exclusiveness && article.exclusivenessImageUrl && (
                  <Grid item>
                    <ImageContainer disableAlternativeImage src={article.exclusivenessImageUrl} width={100} />
                  </Grid>
                )}
                <Grid item>
                  <ImageContainer disableAlternativeImage src={article.imageUrl} width={300} height={300} />
                  <Divider sx={{ opacity: 0.6 }} className={classes.horizontalDivider} />
                </Grid>
                <Grid item className={classes.similarProducts}>
                  <Typography variant={'subtitle1'}>{t('Ähnliche Produkte')}</Typography>
                  <Grid container justifyContent={'center'}>
                    <Grid item>
                      <SimilarArticlesCarousel article={article} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item>
            <Divider
              orientation={'vertical'}
              sx={{
                minHeight: 'calc(90vh - 65px)',
                opacity: 0.6
              }}
            />
          </Grid>
          <Grid item className={classes.secondColumn}>
            <div className={classes.sticky}>
              <Grid container wrap={'nowrap'} justifyContent={'space-between'}>
                <Grid item>
                  <Typography variant="h1" gutterBottom={false} color={article.displayAsAction && !userStore.actionExclude ? 'primary' : 'textPrimary'}>
                    {article.articleOrZzArticle.articleText}
                  </Typography>
                  <ArticleIcons article={article.articleOrZzArticle} actionExclude={userStore.actionExclude} />
                </Grid>
                <Grid item className={classes.price}>
                  <Price item={article.articleOrZzArticle} id={'article-detail-price'} actionExclude={userStore.actionExclude} />
                  {(article.hasComparisonPrice || article.hasPriceSteps) && (
                    <>
                      <Divider sx={{ opacity: 0.6 }} className={classes.horizontalDivider} />
                      <PriceTag alignRight={false} article={article.articleOrZzArticle} ignoreGraduatedPriceSteps={false} />
                      <ComparisonPrice article={article.articleOrZzArticle} />
                    </>
                  )}
                </Grid>
              </Grid>

              <Divider sx={{ opacity: 0.6 }} className={classes.horizontalDivider} />

              <Grid container justifyContent={'space-between'} alignItems={'flex-end'}>
                <Grid item>
                  <ArticleSellUnit article={article} onChangeSellUnit={(unit: string) => handleOnChangeSellUnit(article, unit)} id={'article-detail-sellunit'} />
                  <Box marginLeft={1}>
                    <CatalogItemSupplierInfo article={article} />
                  </Box>
                </Grid>
                <Grid item>
                  <Grid container justifyContent={'center'} alignItems={'flex-end'} spacing={2}>
                    <Grid item>
                      <Grid container>
                        <Grid item>
                          <ArticleInfoIcons article={article.articleOrZzArticle} isMedium={true} id={'article-detail-infoicons'} />
                        </Grid>
                        <Grid item>
                          <ArticleActions article={article.articleOrZzArticle} id={'article-detail-actions'} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <ArticleDetailAddCart isLoading={handleIsLoading} article={article.articleOrZzArticle} isLoggedIn={userStore.isLoggedIn} addToCart={handleAddToCart} isInCart={handleIsInCart} actionExclude={userStore.actionExclude} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <AvailabilityCheck article={article.articleOrZzArticle} variant="body2" showCompactMessage={false} />

              <Divider sx={{ opacity: 0.6 }} className={classes.horizontalDivider} />
              {recentOrderInfoText && (
                <Grid item container justifyContent={'end'}>
                  <Typography color={'textSecondary'}>{recentOrderInfoText}</Typography>
                </Grid>
              )}
            </div>
            <ProductInformation article={article} />
          </Grid>
        </Grid>
      )}
    </div>
  );
});

export default ArticleDetailView;

type ArticleDetailNotAvailableMessageProps = {
  articleNumber: string;
  isLoggedIn: boolean;
};
function ArticleDetailNotAvailableMessage({ articleNumber, isLoggedIn }: ArticleDetailNotAvailableMessageProps) {
  const { t } = useTranslation();

  const notAvailableMessage = isLoggedIn
    ? t('Der Artikel mit Artikel Nummer "{{articleNumber}}" ist leider an Ihrem Standort nicht verfügbar.', { articleNumber })
    : t('Bitte loggen Sie sich ein, um die Verfügbarkeit des Artikels mit der Artikelnummer "{{articleNumber}}" an Ihrem Standort zu prüfen.', { articleNumber });

  return (
    <Grid container justifyContent={'center'}>
      <Grid item mx={3} my={5}>
        <Typography variant={'subtitle1'}>{notAvailableMessage}</Typography>
      </Grid>
    </Grid>
  );
}
