import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DeliveryCalendar } from './DeliveryCalendar';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme, Typography } from '@mui/material';
import { StoreContext } from '../../../StoreContext';

const DeliveryDateSelectionDialog = observer(() => {
  const { cartStore } = useContext(StoreContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const dateSelected = (date?: Date) => {
    if (cartStore.deliveryDateSelectionRequest) {
      cartStore.deliveryDateSelectionRequest!.handler(date);
    }
  };

  const closeDialog = () => {
    dateSelected();
  };

  return (
    <Dialog fullScreen={fullScreen} open={!!cartStore!.deliveryDateSelectionRequest} maxWidth={'xl'}>
      <DialogTitle>{t('Neuer Auftrag erstellen')}</DialogTitle>
      <DialogContent>
        <Typography variant={'h6'}>{t('Wählen Sie das Wunschlieferdatum:')}</Typography>
        <DeliveryCalendar onDateSelected={dateSelected} isReadonly={false} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} variant={'contained'} color="primary" id={'calendar-cancel-button'}>
          {t('Abbrechen')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default DeliveryDateSelectionDialog;
