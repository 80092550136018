import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Article } from '../models/article';
import { Button, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import DotsIcon from 'mdi-material-ui/DotsVertical';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../../../StoreContext';
import { FEATURES } from '../../../common/stores/featureStore';

export interface IArticleActionsProps {
  article: Article;
  showButtons?: boolean;
  id?: string;
}

const ArticleActions = observer(({ article, showButtons, id }: IArticleActionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { uiStore, userStore, inventoryStore, templateStore, featureStore } = useContext(StoreContext);
  const { t } = useTranslation();

  const showInventoryLink = featureStore.isFeatureEnabled(FEATURES.inventory) && article.showAddToInventoryLink && inventoryStore!.areOpenInventoriesAvailable;
  const showAddToTemplateLink = templateStore.templates && templateStore.templates.length > 0 && article.showAddToTemplateLink;

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = (event: any) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const addItemToTemplate = (event: any) => {
    if (article.isZzArticle && article.templateItems) {
      const items = [];
      for (const art of article.templateItems) {
        const articleCopy = {
          amount: art.amount,
          articleId: art.articleId,
          externalArticle: art.externalArticle,
          mainArticleId: art.mainArticleId
        };
        articleCopy.amount = article.amount;
        items.push(articleCopy);
      }
      templateStore.addTemplateArticleToAdd(items);
    } else {
      const articleCopy = {
        amount: article.amount,
        articleId: article.articleId,
        externalArticle: article.externalArticle,
        mainArticleId: article.mainArticleId
      };
      articleCopy.amount = article.amount;
      templateStore.addTemplateArticleToAdd(articleCopy);
    }

    closeMenu(event);
  };

  const addItemToInventory = (event: any) => {
    if (userStore.isLoggedIn) {
      inventoryStore.fetchInventories();

      const articleCopy = {
        amount: article.amount,
        articleId: article.articleId,
        externalArticle: article.externalArticle,
        mainArticleId: article.mainArticleId
      };
      articleCopy.amount = article.amount;

      inventoryStore.addPositionToInventory(articleCopy);
    }

    closeMenu(event);
  };

  if (!userStore.isLoggedIn || (!showAddToTemplateLink && !showInventoryLink)) {
    return null;
  }

  if (showButtons) {
    return (
      <Grid container spacing={1} wrap={uiStore!.isSmallScreenDown ? 'wrap' : 'nowrap'}>
        {showAddToTemplateLink && (
          <Grid item>
            <Button variant="contained" key="template" onClick={addItemToTemplate}>
              <Typography variant="inherit" noWrap>
                {t('Zur Vorlage hinzufügen')}
              </Typography>
            </Button>
          </Grid>
        )}
        {showInventoryLink && (
          <Grid item>
            <Button variant="contained" key="inventory" onClick={addItemToInventory}>
              <Typography variant="inherit" noWrap>
                {t('Zum Inventar hinzufügen')}
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <div>
      <IconButton style={{ alignSelf: 'flex-end' }} onClick={toggleMenu} id={id} size="large">
        <DotsIcon />
      </IconButton>
      <Menu id="options" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
        {showAddToTemplateLink && (
          <MenuItem key="template" onClick={addItemToTemplate} id={id + '-addtemplate-button'}>
            {t('Zur Vorlage hinzufügen')}
          </MenuItem>
        )}
        {showInventoryLink && (
          <MenuItem key="inventory" onClick={addItemToInventory} id={id + '-addinventory-button'}>
            {t('Zum Inventar hinzufügen')}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
});

export default ArticleActions;
