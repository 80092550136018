import type { ICustomerOrderPositionData, IExternalArticlePositionData, ICartResponseData } from '../../../../api';
import CartService from '../../services/cartService';
import CartStore from '../../stores/cartStore';
import { IntentServerState } from '../../types/IntentServerState';
import AbstractOrderIntent from './AbstractOrderIntent';
import { format } from 'date-fns';

export default class ModifyPositionIntent extends AbstractOrderIntent {
  private readonly itemId: number;
  private readonly quantity: number;
  private readonly deliveryDate?: string;
  private readonly articleNumber: string;
  private readonly externalArticle: boolean;
  private articleId!: number;

  constructor(cartStore: CartStore, itemId: number, quantity: number, deliveryDate: Date | null, articleNumber: string, externalArticle = false) {
    super(cartStore);
    this.itemId = itemId;
    this.quantity = quantity;
    const localFormat = 'yyyy-MM-dd';
    this.deliveryDate = deliveryDate ? format(deliveryDate, localFormat) : undefined;
    this.articleNumber = articleNumber;
    this.externalArticle = externalArticle;
  }

  public isPending(intentSpec: any) {
    return super.isPending(intentSpec) && this.articleId === intentSpec.articleId && this.externalArticle === intentSpec.isExternal;
  }

  public async internalOrderIntentProcess(resolve: () => Promise<void>, reject: (reason?: any) => Promise<void>) {
    switch (this.internalState.state) {
      case IntentServerState.INITIAL: {
        await this.handleInit();
        break;
      }
      case IntentServerState.SERVER_RESPONSE_MODIFY_POSITION: {
        await this.handleModifyPosition(resolve, reject);
        break;
      }
      case IntentServerState.SERVER_REQUEST_SEND_OPTION: {
        await this.handleSendOption();
        break;
      }
    }
  }

  private async handleInit(): Promise<any> {
    await this.handleServiceCallResponse(CartService.updatePosition(this.itemId, this.articleNumber, this.quantity, this.deliveryDate, this.externalArticle), IntentServerState.SERVER_RESPONSE_MODIFY_POSITION);
  }

  private async handleModifyPosition(resolve: () => Promise<void>, reject: (reason?: any) => Promise<void>): Promise<any> {
    const response = this.internalState.serverResponse!;

    // NOTE: response can come from different API calls:
    // - PUT positions/:positionId -> returns a single ICustomerOrderPosition2DTOData
    // - POST positions/commands -> returns several ICustomerOrderPosition2DTOData
    // TODO on the server -> make PUT positions wich receives a batch of modifications
    // TODO: (jba, 2019-02-26) remove exceptions after testing ...
    if (response.data.length > 1) throw new Error('Inconsistent state: server responded with several results ... '); // tslint:disable-line:curly
    const data: ICartResponseData = response.data.length > 0 ? response.data[0] : response.data;

    if (data.options && data.options.length > 0) {
      const promptInfo = {
        message: data.result.mostImportantMessage.message,
        options: data.options
      };
      const option = await this.userPrompter.userPromptRequest([promptInfo]);

      if (!option) {
        reject('Cancel');
      } else {
        this.internalState = {
          state: IntentServerState.SERVER_REQUEST_SEND_OPTION,
          option
        };
      }
    } else if (data.result.mostImportantMessage) {
      await this.userPrompter.userAlertRequest(data.result.mostImportantMessage);
      reject('Alert');
    } else {
      if (data.customerOrder) {
        const order = data.customerOrder;
        const cartDiscountInfo = data.cartDiscountInfo;
        this.cartStore.orderReceived(order, cartDiscountInfo);
      }

      const dataWithExternalPositions = data as any;
      if (dataWithExternalPositions.extlArticlePositions && dataWithExternalPositions.extlArticlePositions.length > 0) {
        const externalPositions = dataWithExternalPositions.extlArticlePositions as IExternalArticlePositionData[];
        for (const externalPosition of externalPositions) {
          this.cartStore.updateExternalPosition(externalPosition);
        }
      }
      resolve();
    }
  }

  private async handleSendOption(): Promise<any> {
    if (!this.internalState.option) throw new Error('Inconsistent state: handleSendOption -> option array is undefined'); // tslint:disable-line:curly
    if (this.internalState.option.length > 1) throw new Error('Inconsistent state: handleSendOption -> option does contain several options'); // tslint:disable-line:curly
    if (!this.internalState.option[0]) throw new Error('Inconsistent state: handleSendOption -> option is undefined'); // tslint:disable-line:curly
    const commands = this.internalState.option.map((option) => option.command);
    const response = await CartService.sendCommands(commands);
    this.internalState = {
      state: IntentServerState.SERVER_RESPONSE_MODIFY_POSITION,
      serverResponse: response
    };
  }
}
