import { IAvailabilityOptionData } from '../../../../api';
import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ppConstants } from '../../../../util/ppConstants';
import CartDeliveryDateExpiredDialog from './CartDeliveryDateExpiredDialog';
import CartExpiredDialog from './CartExpiredDialog';
import DefaultErrorDialog from './DefaultErrorDialogFragment';
import AddPositionProblemDialogFragment, { AddPositionProblemDialogFragmentHandleType } from './AddPositionProblemDialogFragment';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { StoreContext } from '../../../../StoreContext';
import { observer } from 'mobx-react-lite';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { FEATURES } from '../../../common/stores/featureStore';

const CartItemProblemDialog = observer(() => {
  const { featureStore, cartStore } = useContext(StoreContext);
  const addPositionOptionsRef = useRef<any>();

  const { t } = useTranslation();
  const theme = useTheme();
  const isFullscreen = useMediaQuery(theme.breakpoints.down('md'));

  const closeDialog = () => {
    cartStore.cancelCartItem();
  };

  let header;
  let body;
  let footer;

  if (cartStore.currentError) {
    if (cartStore.currentError.options) {
      header = cartStore.currentError.message;
      body = <DefaultErrorDialog options={cartStore.currentError.options} />;
    } else {
      header = <span>{t('Fehler aufgetreten')}</span>;
      body = cartStore.currentError.message;
    }

    if (cartStore.currentError.default) {
      const closeAction = cartStore.currentError.default;
      const cancel =
        cartStore.intentProcessor.pendingIntents.length > 1 ? (
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              cartStore.cancelIntents();
              closeAction();
            }}
          >
            {t('Alle Aktionen abbrechen')}
          </Button>
        ) : null;

      footer = (
        <Box
          sx={{
            '& button:not(:last-child)': {
              marginRight: '5px'
            }
          }}
        >
          {cancel}
          <Button variant={'contained'} onClick={closeAction}>
            {t('Abbrechen')}
          </Button>
        </Box>
      );
    }
  }

  if (cartStore.prompt) {
    header = (
      <div>
        <div>{t('Gewünschte Menge für den Liefertermin nicht verfügbar')}!</div>
        <Typography variant={'body1'} gutterBottom>{`${t('Sie haben folgende Möglichkeiten')}:`}</Typography>
        <Divider />
      </div>
    );
    if (cartStore.prompt.info.length > 0) {
      body = <AddPositionProblemDialogFragment ref={addPositionOptionsRef} problems={cartStore.prompt.info} />;
      const skipAllPositions = () => {
        // Send all the SkipCustomerOrderPositionCommands to the server
        const skipCommands = cartStore.prompt!.info.map((prompt) => prompt.options.find((option) => option.command.class.includes('SkipCustomerOrderPosition'))).filter((command): command is IAvailabilityOptionData => command !== undefined);
        if (skipCommands.length > 0) {
          cartStore.prompt!.handler(skipCommands);
        }
        closeDialog();
      };

      const okAction = (ref: AddPositionProblemDialogFragmentHandleType) => {
        const selectedCommands = ref.getSelectedCommands();
        if (selectedCommands && selectedCommands.length > 0) {
          cartStore.prompt!.handler(selectedCommands);
        }
      };

      footer = (
        <Box
          sx={{
            '& button:not(:last-child)': {
              marginRight: '5px'
            }
          }}
        >
          {featureStore.isFeatureEnabled(FEATURES.batchPositionAdd) && (
            <Button variant={'contained'} onClick={skipAllPositions}>
              {t('Alle Aktionen abbrechen')}
            </Button>
          )}
          <Button variant={'contained'} color={'primary'} onClick={() => okAction(addPositionOptionsRef.current)}>
            {t('Bestätigen')}
          </Button>
        </Box>
      );
    } else {
      footer = (
        <Box
          sx={{
            '& button:not(:last-child)': {
              marginRight: '5px'
            }
          }}
        >
          <Button variant={'contained'} color={'primary'} onClick={closeDialog}>
            {t('OK')}
          </Button>
        </Box>
      );
    }
  }

  if (cartStore.cartExpiration) {
    if (cartStore.cartExpiration.reason === ppConstants.INVALID_DELIVERY_DATE) {
      header = <span>{t('Das gewünschte Lieferdatum ist verfallen!')}</span>;
      body = (
        <div>
          <CartDeliveryDateExpiredDialog handler={cartStore.cartExpiration.handler} />
        </div>
      );
    } else {
      header = <span>{t('Der Warenkorb ist verfallen!')}</span>;
      body = (
        <div>
          <CartExpiredDialog handler={cartStore.cartExpiration.handler} />
        </div>
      );
    }
  }

  if (cartStore.alertInfo) {
    if (cartStore.alertInfo.alert.message === ppConstants.DEFAULT_FAILED_ORDER_SENT_MESSAGE) {
      header = <span>{t('Der Warenkorb konnte nicht gesendet werden')}</span>;
      body = <span>{t('Versuchen Sie die blockierte Positionen zu korrigieren')}</span>;
    } else {
      header = cartStore.alertInfo.alert.message ? cartStore.alertInfo.alert.message : cartStore.alertInfo.alert;
      body = cartStore.alertInfo.alert.additionalInfo ? cartStore.alertInfo.alert.additionalInfo : null;
      if (body !== null && body.match(/\n/)) {
        const lines = body.split(/\n/);
        body = lines.map((line: string, index: number) => {
          return (
            <span key={index}>
              {line}
              <br />
            </span>
          );
        });
      }
    }
    footer = (
      <div>
        <Button variant={'contained'} color={'primary'} onClick={cartStore.alertInfo!.handler}>
          {t('OK')}
        </Button>
      </div>
    );
  }

  return (
    <Dialog open={!!cartStore.currentError || !!cartStore.prompt || !!cartStore.cartExpiration || !!cartStore.alertInfo} fullScreen={isFullscreen} maxWidth="xl">
      <DialogTitle>{header ?? ''}</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <DialogActions>{footer}</DialogActions>
    </Dialog>
  );
});

export default CartItemProblemDialog;
