import { IArticleDTOData } from '../../../api';
import axios from '../../../util/axios';
import { ppConstants } from '../../../util/ppConstants';

const SalesOrderSetService = {
  async fetchSalesOrderSetArticles(): Promise<IArticleDTOData[]> {
    const response = await axios.get(ppConstants.API_HOST_SEC + 'salesorderset');
    return response.data;
  }
};

export default SalesOrderSetService;
