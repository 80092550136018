const generator = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000) //NOSONAR
      .toString(16)
      .substring(1);
  };
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export default generator;
