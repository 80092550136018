import { useQuery } from '@tanstack/react-query';
import { qkArticleQuickSearch } from './searchQueryKeys';
import { fetchArticleSearch } from './articleFetch';
import { getDefaultSearchRequest } from '../model/searchModel';
import { SESSION_STORAGE_KEY_SALESORDERSET_FILTER } from '../../../storageKey';
import { executeAsyncActionNew } from '../../../util/exceptionHandler';

export function useArticleQuickSearch(debouncedSearchTerm: string, minSearchTermLength: number) {
  const storedSalesOrderSetValue = sessionStorage.getItem(SESSION_STORAGE_KEY_SALESORDERSET_FILTER);
  const salesOrderSetValue = storedSalesOrderSetValue ? JSON.parse(storedSalesOrderSetValue) : false;

  const searchQuery = useQuery({
    queryKey: [qkArticleQuickSearch, { ...getDefaultSearchRequest(), searchTerm: debouncedSearchTerm, pageSize: 3 }],
    queryFn: () => executeAsyncActionNew(async () => await fetchArticleSearch({ ...getDefaultSearchRequest(), searchInSalesOrderSet: salesOrderSetValue, searchTerm: debouncedSearchTerm, pageSize: 3 })),
    enabled: debouncedSearchTerm?.length >= minSearchTermLength
  });

  const hasSearchResults = !!searchQuery.data && searchQuery.data?.itemCount > 0;
  const articles = searchQuery.data?.articles ?? [];

  return { hasSearchResults, articles };
}
