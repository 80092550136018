import React, { useState } from 'react';
import { Box, Grid, IconButton, Popover, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import ErrorIcon from 'mdi-material-ui/AlertCircleOutline';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import AvailabilityMessageSubstitute from './AvailabilityMessageSubstitute';

export interface IAvailabilityMessageCompactProps {
  articleNumber: string;
  messageType: 'notAvailable' | 'temporaryNotAvailable';
  message: string;
  variant?: Variant | 'inherit';
}

export default function AvailabilityMessageCompact({ articleNumber, messageType, message, variant }: Readonly<IAvailabilityMessageCompactProps>) {
  const [anchorEl, setAnchorEl] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toogleMenu = (event: any) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <Box sx={{ mt: 1 }}>
      <Grid container justifyContent={'center'}>
        <Grid item>
          <IconButton onClick={toogleMenu} size={'medium'} aria-label="info" color={messageType === 'notAvailable' ? 'error' : 'warning'}>
            {messageType === 'notAvailable' ? <ErrorIcon /> : <WarningIcon />}
          </IconButton>
        </Grid>
        <Popover
          open={isMenuOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <Grid container direction={'column'} spacing={1} sx={{ m: 1, overflowY: 'hidden' }}>
            <Typography sx={{ mb: 0.2 }} variant={variant ?? 'subtitle2'}>
              {message}
            </Typography>
            <AvailabilityMessageSubstitute articleNumber={articleNumber} variant={variant ?? 'body2'} />
          </Grid>
        </Popover>
      </Grid>
    </Box>
  );
}
