// tslint:disable:no-console
const TEST_ENVIRONMENTS = ['TEST', 'STAGE', 'PREVIEW'];
const DEPLOYMENT_ENVIRONMENTS = ['DEVELOPMENT', ...TEST_ENVIRONMENTS, 'PROD'];

export let PPCONTEXT: {
  DEPLOYMENT_ENVIRONMENT: string;
  STATIC_IMAGES_BASE_URL: string;
  KEYCLOAK_HOST: string;
  KEYCLOAK_REALM: string;
  BACKEND_URL: string;
  CONTAINER_STARTUP_TIME: string;
  BUILD_TIME: string;
  GITHASH: string;
  RELEASE_VERSION: string;
  ENABLE_USER_TRACKING: boolean;
  ENABLE_ERROR_TRACKING: boolean;
  PORTAL_URL: string;

  LOAD_TIMESTAMP: Date;
};

export function getDeploymentEnvironment() {
  if (DEPLOYMENT_ENVIRONMENTS.indexOf(window.PPCONTEXT.DEPLOYMENT_ENVIRONMENT) !== -1) {
    return window.PPCONTEXT.DEPLOYMENT_ENVIRONMENT;
  } else {
    console.warn('Invalid DEPLOYMENT_ENVIRONMENT: ' + window.PPCONTEXT.DEPLOYMENT_ENVIRONMENT);
    return 'DEVELOPMENT';
  }
}

export function isTestEnvironment() {
  if (TEST_ENVIRONMENTS.indexOf(window.PPCONTEXT.DEPLOYMENT_ENVIRONMENT) !== -1) {
    return true;
  } else {
    return false;
  }
}

export function getStaticImagesBaseUrl() {
  if (!window.PPCONTEXT.STATIC_IMAGES_BASE_URL.startsWith('http')) {
    console.warn('Invalid STATIC_IMAGES_BASE_URL', PPCONTEXT.STATIC_IMAGES_BASE_URL);
    return 'http://smo10236.tgs.intern/images';
  }
  return window.PPCONTEXT.STATIC_IMAGES_BASE_URL;
}

export async function initPPContext() {
  // ** NOTE:
  // ** window.PPCONTEXT is initialized in index.html. Values are set according to deployment environment by Apache SSI
  let waitCounter = 0;
  while (!window.PPCONTEXT) {
    console.log('Waiting for PPCONTEXT to be set on window ...', waitCounter++);
    await new Promise((resolve) => setTimeout(resolve, 100));
    if (waitCounter > 50) {
      throw new Error('PPCONTEXT not set on window!');
    }
  }

  PPCONTEXT = window.PPCONTEXT;

  ensureContextProperty('DEPLOYMENT_ENVIRONMENT');
  ensureContextProperty('STATIC_IMAGES_BASE_URL');
  ensureContextProperty('KEYCLOAK_HOST');
  ensureContextProperty('KEYCLOAK_REALM');
  ensureContextProperty('BACKEND_URL');
  ensureContextProperty('CONTAINER_STARTUP_TIME');
  ensureContextProperty('BUILD_TIME');
  ensureContextProperty('GITHASH');
  ensureContextProperty('RELEASE_VERSION');
  ensureContextProperty('ENABLE_USER_TRACKING');
  ensureContextProperty('ENABLE_ERROR_TRACKING');
  ensureContextProperty('PORTAL_URL');

  modifySetupForLocalDevelopment();

  PPCONTEXT.LOAD_TIMESTAMP = new Date();
  return PPCONTEXT;
}

function ensureContextProperty(propertyName: keyof typeof PPCONTEXT) {
  if (PPCONTEXT[propertyName] === undefined) {
    const message = `Global variable '${propertyName}' is not defined!`;
    console.error(message);
    throw message;
  }
}

function modifySetupForLocalDevelopment() {
  PPCONTEXT.DEPLOYMENT_ENVIRONMENT = getDeploymentEnvironment();
  PPCONTEXT.STATIC_IMAGES_BASE_URL = getStaticImagesBaseUrl();
}
