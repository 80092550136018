import { runInAction, makeAutoObservable, toJS } from 'mobx';
import UserService from '../../../modules/user/services/userService';
import FeatureService from '../services/featureService';
import { executeAsyncAction } from '../../../util/exceptionHandler';
import { ICustomerWebshopSettingData, IDefaultSettingDTOData } from '../../../api';

export const FEATURES = {
  tileView: 'tileView',
  batchPositionAdd: 'batchPositionAdd',
  externalArticle: 'externalArticle',
  inventory: 'inventory',
  scanPool: 'scanPool',
  hubSearch: 'hubSearch',
  enableTealium: 'enableTealium',
  enableSentry: 'enableSentry',
  enableSentryPerformance: 'enableSentryPerformance',
  enableSentrySession: 'enableSentrySession',
  sentryConfigExtension: 'sentryConfigExtension',
  enableDebugLog: 'enableDebugLog'
} as const;

export type FeatureName = (typeof FEATURES)[keyof typeof FEATURES];
type Features = { [f in FeatureName]: string };

const defaultFeatures: Features = {
  tileView: 'true',
  batchPositionAdd: 'false',
  externalArticle: 'false',
  inventory: 'false',
  scanPool: 'false',
  hubSearch: 'legacy',
  enableTealium: 'false',
  enableSentry: 'true',
  enableSentryPerformance: 'false',
  enableSentrySession: 'false',
  sentryConfigExtension: '',
  enableDebugLog: 'false'
};

let devEnvOverrides: Partial<Features> = {};
if (process.env.NODE_ENV === 'development') {
  /* block is eliminated by tree-shaking in prod build */
  devEnvOverrides = {
    enableTealium: 'false',
    enableSentry: 'false',
    enableSentryPerformance: 'false',
    enableSentrySession: 'false',
    enableDebugLog: 'true'
  };
}

export const tileViewFeatureTranslation = {
  nameTranslationKey: 'Suchresultate als Kachelansicht darstellen',
  descriptionTranslationKey: 'Die Einstellung zeigt die Suchresultate innerhalb des Katalogs als Kachelansicht an. Default ist die Listenansicht.'
};

export class FeatureStore {
  private features = defaultFeatures;

  constructor() {
    makeAutoObservable(this);
  }

  isFeatureEnabled(featureName: FeatureName) {
    const feature = this.getStringSettingValue(featureName);
    return feature === 'true';
  }

  get enableHubSearchToggle() {
    const hubSearch = this.getStringSettingValue(FEATURES.hubSearch);
    const enableHubSearchToggle = hubSearch === 'new' || hubSearch === 'legacy';
    return enableHubSearchToggle;
  }

  get enableHubSearchNew() {
    const hubSearch = this.getStringSettingValue(FEATURES.hubSearch);
    const enableHubSearchNew = hubSearch === 'new';
    return enableHubSearchNew;
  }

  get sentryConfigExtension() {
    let sentryConfigExtension = {};

    const sentyConfigExtensionString = this.getStringSettingValue(FEATURES.sentryConfigExtension);
    if (sentyConfigExtensionString) {
      try {
        sentryConfigExtension = JSON.parse(sentyConfigExtensionString);
      } catch (e) {
        console.error('Error parsing sentryConfigExtension:', sentyConfigExtensionString, e);
      }
    }

    return sentryConfigExtension;
  }

  toggleSearchView() {
    const hubSearch = this.getStringSettingValue(FEATURES.hubSearch);
    if (hubSearch === 'new') {
      this.setStringSettingsValue(FEATURES.hubSearch, 'legacy');
    } else if (hubSearch === 'legacy') {
      this.setStringSettingsValue(FEATURES.hubSearch, 'new');
    }
  }

  async setStringSettingsValue(name: FeatureName, value: string) {
    const settings = await UserService.saveWebshopSetting(name, value);
    this.setFeatureConfigFromBackend(settings);
  }

  async activateSetting(name: FeatureName) {
    const settings = await UserService.saveWebshopSetting(name, 'true');
    this.setFeatureConfigFromBackend(settings);
  }

  async deactivateSetting(name: FeatureName) {
    const settings = await UserService.saveWebshopSetting(name, 'false');
    this.setFeatureConfigFromBackend(settings);
  }

  async fetchDefaultSettings() {
    return executeAsyncAction(async () => {
      const settings = await FeatureService.fetchDefaultSettings();

      runInAction(() => {
        this.setFeatureConfigFromBackend(settings);
      });
    });
  }

  setFeatureConfigFromBackend(settings: ICustomerWebshopSettingData[] | IDefaultSettingDTOData[]) {
    this.features = defaultFeatures;

    // lowest priority: settings from the backend
    for (const s of settings) {
      this.features[s.setting as FeatureName] = s.value;
    }

    // some settings are hard-coded in dev mode and can only be configured via sessionStorage
    if (process.env.NODE_ENV === 'development') {
      /* block is eliminated by tree-shaking in prod-build */
      this.features = { ...this.features, ...devEnvOverrides };
    }

    // highest priority: control all settings locally via sessionStorage
    for (const featureName in FEATURES) {
      const localOverride = sessionStorage.getItem(featureName);
      if (localOverride) {
        this.features[featureName as FeatureName] = localOverride;
      }
    }

    if (this.features.enableDebugLog === 'true') {
      console.log('Feature Config:', toJS(this.features));
    }
  }

  private getStringSettingValue(featureName: FeatureName) {
    const feature = this.features[featureName];
    return feature ?? '';
  }
}
