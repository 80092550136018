import { deMessages } from './lang/de';
import { frMessages } from './lang/fr';
import { itMessages } from './lang/it';
import Messages from './messages';

// var require_method = require;

var container = {
  messages: {},

  /**
   * Set messages for language
   *
   * @param {string} lang
   * @param {object} rawMessages
   * @return {void}
   */
  _set: function (lang, rawMessages) {
    this.messages[lang] = rawMessages;
  },

  /**
   * Set message for given language's rule.
   *
   * @param {string} lang
   * @param {string} attribute
   * @param {string|object} message
   * @return {void}
   */
  _setRuleMessage: function (lang, attribute, message) {
    this._load(lang);
    if (message === undefined) {
      message = this.messages[lang].def;
    }

    this.messages[lang][attribute] = message;
  },

  /**
   * Load messages (if not already loaded)
   *
   * @param  {string} lang
   * @return {void}
   */
  _load: function (lang) {
    if (!this.messages[lang]) {
      try {
        // var rawMessages = require_method('./lang/' + lang); //jonas, 2024-02-11: Diese Zeile funktioniert nicht mit Vite!!!
        var rawMessages;

        switch (lang) {
          case 'de':
            rawMessages = deMessages;
            break;
          case 'fr':
            rawMessages = frMessages;
            break;
          case 'it':
            rawMessages = itMessages;
            break;
          default:
            rawMessages = deMessages;
        }

        this._set(lang, rawMessages);
      } catch (e) {}
    }
  },

  /**
   * Get raw messages for language
   *
   * @param  {string} lang
   * @return {object}
   */
  _get: function (lang) {
    this._load(lang);
    return this.messages[lang];
  },

  /**
   * Make messages for given language
   *
   * @param  {string} lang
   * @return {Messages}
   */
  _make: function (lang) {
    this._load(lang);
    return new Messages(lang, this.messages[lang]);
  }
};

export default container;
