import type { IImportedResultDTOData, IWebInventoryData, IWebInventoryDetailData, IWebInventoryPositionData } from '../../../api';
import axios from '../../../util/axios';
import { ppConstants } from '../../../util/ppConstants';

const InventoryService = {
  async fetchInventories(withClosed: boolean): Promise<IWebInventoryData[]> {
    const response = await axios.get(ppConstants.API_HOST_SEC + 'webinventories', {
      params: {
        withClosed
      }
    });
    return response.data;
  },

  async fetchInventory(id: number): Promise<IWebInventoryDetailData> {
    const response = await axios.get(ppConstants.API_HOST_SEC + 'webinventories/' + id, {});
    return response.data;
  },

  async fetchInventoryForPolling(id: number): Promise<IWebInventoryDetailData> {
    const response = await axios.get(ppConstants.API_HOST_SEC + 'webinventories/' + id, {});
    return response.data;
  },

  async saveInventory(name: string): Promise<IWebInventoryData> {
    const response = await axios.post(ppConstants.API_HOST_SEC + 'webinventories', [], {
      params: {
        name
      }
    });
    return response.data;
  },

  async importInventoryPositions(webInvId: number, positions: any): Promise<IWebInventoryData> {
    const response = await axios.post(ppConstants.API_HOST_SEC + 'webinventories/import', positions, {
      params: {
        webInvId
      }
    });

    return response.data;
  },

  async addPosition(invId: number, artId: number, quantity: number, extArt: boolean): Promise<IWebInventoryData> {
    const response = await axios.put(
      ppConstants.API_HOST_SEC + 'webinventories/positions',
      {},
      {
        params: {
          webInventoryId: invId,
          artId,
          quantity,
          extArt
        }
      }
    );

    return response.data;
  },

  async createInventoryFromSalesOrderSet(name: string): Promise<IWebInventoryData> {
    const response = await axios.post(
      ppConstants.API_HOST_SEC + 'webinventories/import/salesorderset',
      {},
      {
        params: {
          name
        }
      }
    );

    return response.data;
  },

  async changeNameOfInventory(id: number, name: string): Promise<IWebInventoryData> {
    const response = await axios.put(
      ppConstants.API_HOST_SEC + 'webinventories/' + id,
      {},
      {
        params: {
          name
        }
      }
    );

    return response.data;
  },

  async removeInventory(id: number): Promise<void> {
    await axios({
      method: 'DELETE',
      url: ppConstants.API_HOST_SEC + 'webinventories/' + id,
      data: {}
    });
  },

  async updatePositionQuantity(positionUpdate: { id: number; quantity: number; rating: number; isManualPrice: boolean; price: number }): Promise<IWebInventoryPositionData> {
    const response = await axios.put(
      ppConstants.API_HOST_SEC + 'webinventories/positions/' + positionUpdate.id,
      {},
      {
        params: {
          quantity: positionUpdate.quantity,
          rating: positionUpdate.rating,
          price: positionUpdate.isManualPrice ? positionUpdate.price : null
        }
      }
    );

    return response.data;
  },

  async removePosition(positionId: number): Promise<number> {
    await axios({
      method: 'DELETE',
      url: ppConstants.API_HOST_SEC + 'webinventories/positions/' + positionId,
      data: {}
    });

    return positionId;
  },

  async updatePositions(positionIds: any[], rating: any): Promise<IWebInventoryDetailData> {
    const response = await axios.put(ppConstants.API_HOST_SEC + 'webinventories/positions/ratings', positionIds, {
      params: {
        rating
      }
    });
    return response.data;
  },

  async calculatePrices(inventoryId: any): Promise<IWebInventoryDetailData> {
    const response = await axios.put(ppConstants.API_HOST_SEC + 'webinventories/' + inventoryId + '/calculateprices', {}, {});

    return response.data;
  },

  async closeInventory(inventoryId: any): Promise<IWebInventoryData> {
    const response = await axios.put(ppConstants.API_HOST_SEC + 'webinventories/' + inventoryId + '/close', {}, {});
    return response.data;
  },

  async importCSVIntoInventory(inventoryId: number, data: any): Promise<IImportedResultDTOData> {
    const response = await axios.post(ppConstants.API_HOST_SEC + 'webinventories/' + inventoryId + '/csv', data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    return response.data;
  }
};

export default InventoryService;
