import React from 'react';
import { Typography } from '@mui/material';
import classNames from 'classnames';

export interface IScreenTitleProps {
  title: string;
  indent?: boolean;
  cssClass?: string;
}

const ScreenTitle = ({ title, indent = false, cssClass }: IScreenTitleProps) => {
  return (
    <Typography variant="h1" gutterBottom className={classNames(indent ? cssClass : '')}>
      {title}
    </Typography>
  );
};

export default ScreenTitle;
