import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NavigationDropDown from '../NavigationDropDown';
import { observer } from 'mobx-react-lite';
import CatalogContent from '../../../../../features/overview/CatalogContent';
import { useMediaQuery } from '@mui/material';
import CatalogDialog from './CatalogDialog';
import { StoreContext } from '../../../../../StoreContext';
import CatalogContentLegacy from './CatalogContentLegacy';

const CatalogNav = observer(() => {
  const { t } = useTranslation();
  const isSmallHeight = useMediaQuery('(max-height:650px)');
  const [catalogDialogOpen, setCatalogDialogOpen] = useState<boolean>(false);
  const { featureStore } = useContext(StoreContext);

  const getComponents = (close: any) => {
    return featureStore.enableHubSearchNew ? <CatalogContent onClose={close} /> : <CatalogContentLegacy onClose={close} />;
  };

  return (
    <>
      <NavigationDropDown id={'nav-catalog-button'} title={t('Katalog')} menu={(close) => getComponents(close)} onClick={isSmallHeight ? () => setCatalogDialogOpen(true) : undefined}></NavigationDropDown>
      {catalogDialogOpen && <CatalogDialog open={catalogDialogOpen} onClose={() => setCatalogDialogOpen(false)} />}
    </>
  );
});

export default CatalogNav;
