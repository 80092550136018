import React, { ReactNode, SyntheticEvent } from 'react';
import { Link } from '@mui/material';
import { SxProps } from '@mui/system';

interface IOutboundLinkProps {
  href: any;
  target?: any;
  className?: string;
  id?: string;
  children: ReactNode;
  sx?: SxProps;
}

const OutboundLink: React.FC<IOutboundLinkProps> = (props) => {
  // the href attribute on the <a> should be set, so that the browser shows a preview at the bottom
  // but the browser should not navigate to the href when clicking the link,
  // because the javascript functionality (sending the event to google) should finish first
  return (
    <Link className={props.className} sx={props.sx} onClick={trackThenNavigate} id={props.id}>
      {props.children}
    </Link>
  );

  async function trackThenNavigate(e: SyntheticEvent) {
    e.stopPropagation(); // clicking the link should not trigger click handlers on parent elements (i.e. selecting an elment in a table)
    let navigationPending: number | undefined = undefined;
    if (props.target) {
      // if we are opening a new tab, sending the tracking ifo is "fire-and-forget"
      window.open(props.href, props.target);
    } else {
      // if we are navigating the current page, the browser should not perform the navigation immediately
      // we navigate programmatically later (see below) when we the analytics data was sent
      e.preventDefault();
      navigationPending = window.setTimeout(() => navigateToLinkTarget(), 1000); // force navigation after 1 sec if analytics does not work

      navigateToLinkTarget();
    }

    function navigateToLinkTarget() {
      if (navigationPending) {
        clearTimeout(navigationPending);
        navigationPending = undefined;
        window.document.location = props.href;
      }
    }
  }
};

export default OutboundLink;
