import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary, Button, Theme, Typography } from '@mui/material';
import { isTestEnvironment } from '../util/ppContext';
import ExpandIcon from 'mdi-material-ui/ArrowExpandDown';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      position: 'relative',
      color: theme.palette.secondary.main
    },
    title: {
      color: theme.palette.primary.main,
      fontSize: 'xxx-large'
    }
  };
});

type TestEnvironmentWarningWrapperProps = {
  children: JSX.Element;
};

const PP_TEST_ENV_ACCEPTED = 'PP_TEST_ENV_ACCEPTED';

export function TestEnvironmentWarningWrapper({ children }: TestEnvironmentWarningWrapperProps) {
  const { classes } = useStyles();
  const { search } = useLocation();
  const [testEnvAccepted, setTestEnvAccepted] = useState(false);
  const speceKeyCountRef = useRef(0);

  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  function acceptTestEnv() {
    setTestEnvAccepted(true);
    sessionStorage.setItem(PP_TEST_ENV_ACCEPTED, 'true');
  }

  useEffect(() => {
    if (searchParams.has('pp-test')) {
      acceptTestEnv();
    }
  }, [searchParams, setTestEnvAccepted]);

  useEffect(() => {
    function handleSpaceKey(e: KeyboardEvent) {
      if (e.code === 'Space') {
        speceKeyCountRef.current += 1;
        if (speceKeyCountRef.current >= 3) {
          acceptTestEnv();
        }
      }
    }

    document.addEventListener('keydown', handleSpaceKey);
    return () => document.removeEventListener('keydown', handleSpaceKey);
  }, []);

  const isTestEnv = isTestEnvironment();
  const showApp =
    !isTestEnv ||
    testEnvAccepted || // performance: avoid reading webstorage
    sessionStorage.getItem(PP_TEST_ENV_ACCEPTED) ||
    localStorage.getItem(PP_TEST_ENV_ACCEPTED); // trick for developers/testers

  return showApp ? (
    children
  ) : (
    <div className={classes.container}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minHeight: '70vh'
        }}
      >
        <div>
          <Typography align="center" className={classes.title}>
            Test System
          </Typography>
        </div>
        <div>
          <Typography variant="h5" align="center">
            Bitte verwenden Sie den Transgourmet Webshop hier:
          </Typography>
          <Typography variant="h5" align="center">
            <a href="https://webshop.transgourmet.ch/">https://webshop.transgourmet.ch/</a>
          </Typography>
        </div>

        <div style={{ marginTop: 20, minWidth: 300 }}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandIcon />}>
              <Typography>Weiter zum Testsystem</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Button variant="outlined" onClick={acceptTestEnv}>
                  Ja, ich will zum TestSystem ...
                </Button>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
