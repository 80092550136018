import { useCurrentPathWithModalArticleDetailsDialog } from '../../util/useModalArticleDetailDialog';
import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Variant } from '@mui/material/styles/createTypography';
import NormalLink from '../../../components/NormalLink';
import { IArticleDTOData } from '../../../../../api';
import { StoreContext } from '../../../../../StoreContext';
import CatalogService from '../../../../catalog/services/catalogService';

export interface IAvailabilityMessageSubstituteProps {
  articleNumber: string;
  variant?: Variant | 'subtitle2' | 'inherit';
}

export default function AvailabilityMessageSubstitute({ articleNumber, variant }: Readonly<IAvailabilityMessageSubstituteProps>) {
  const { t } = useTranslation();
  const { uiStore, userStore } = useContext(StoreContext);

  const [substituteArticle, setSubstituteArticle] = useState<IArticleDTOData | undefined | null>(undefined);
  const pathWithModalArticleDetailDialog = useCurrentPathWithModalArticleDetailsDialog(substituteArticle?.articleNumber);

  useEffect(() => {
    async function loadSubstituteArticleAsync() {
      const loadedArticle = await CatalogService.fetchCatalogItem(articleNumber, userStore.isLoggedIn);
      const loadedSubstituteArticle = loadedArticle.substituteArticle || null;
      setSubstituteArticle(loadedSubstituteArticle);
    }

    loadSubstituteArticleAsync();
  }, [articleNumber, userStore.isLoggedIn]);

  if (substituteArticle === null) {
    return null;
  }

  let languageInt = 0;
  if (uiStore.language === 'fr') {
    languageInt = 1;
  } else if (uiStore.language === 'it') {
    languageInt = 2;
  }

  if (substituteArticle) {
    return (
      <Box mb={0.2}>
        <Typography variant={variant ?? 'body2'}>{t('Ersatzartikel')}:</Typography>
        <Typography variant={variant ?? 'body2'} onClick={(e) => e.stopPropagation()}>
          <NormalLink link={pathWithModalArticleDetailDialog} linkName={(substituteArticle.articleNumber ? substituteArticle.articleNumber : '') + ' ' + (substituteArticle.articleText ? substituteArticle.articleText[languageInt] : '')} />
        </Typography>
      </Box>
    );
  } else {
    return (
      <div style={{ display: 'flex' }}>
        <CircularProgress variant={'indeterminate'} />
      </div>
    );
  }
}
