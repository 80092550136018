import { Article } from '../../../../shared/articles/models/article';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import ImageContainer from '../../ImageContainer';
import NoImage from '../../../../../img/noimage.png';

interface ISearchSuggestionItemProps {
  article: Article;
  showPictures?: boolean;
  onArticleClicked: (article: Article) => void;
  id: string;
}

const SearchArticleItem = observer(({ article, showPictures = true, onArticleClicked, id }: ISearchSuggestionItemProps) => {
  const { t } = useTranslation();

  const handleNavigate = (selectedArticle: Article) => {
    onArticleClicked(selectedArticle);
  };

  return (
    <ListItem key={article.articleNumber} onClick={() => handleNavigate(article)} sx={{ cursor: 'pointer' }} id={id}>
      <ListItemAvatar style={{ marginRight: 8 }}>
        <>{showPictures ? <ImageContainer src={article.thumbImageUrl} width={80} height={80} /> : <img src={NoImage} alt={t('Kein Bild')} />}</>
      </ListItemAvatar>
      <ListItemText secondary={article.articleText} primary={article.formattedPriceArticleUnit} sx={{ color: article.displayAsAction ? 'primary' : 'text.primary' }} />
    </ListItem>
  );
});

export default SearchArticleItem;
