import React, { ChangeEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import Validator from '../../../util/validatorjs/validator';
import { validate, validateField } from '../../../util/validationHelper';
import TextField from '@mui/material/TextField';
import { StoreContext } from '../../../StoreContext';
import notifications from '../../../notifications';

export const TemplateNameForm = () => {
  const { templateStore } = useContext(StoreContext);
  const [templateName, setTemplateName] = useState('');
  const [validationErrors, setValidationErrors] = useState<{
    [index: string]: any;
  }>({});
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const rules = {
    templateName: 'required|min:1|max:50'
  };

  const getValidator = (data: any) => {
    const validator = new Validator(data, rules);
    validator.setAttributeNames({
      templateName: t('Bezeichnung der Vorlage')
    });

    return validator;
  };

  const validateAll = () => {
    const validator = getValidator({ templateName: templateName });
    const result = validate(validator);
    setValidationErrors(result);
    return Object.keys(result).length === 0;
  };

  const onChangeTemplateName = (value: string) => {
    setTemplateName(value);
    const validator = getValidator({ templateName: value });
    setValidationErrors(validateField(validator, 'templateName', validationErrors));
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      // Check for Enter
      event.preventDefault();
      confirm();
    }
  };

  const confirm = () => {
    const isValid = validateAll();
    if (isValid) {
      let message = templateStore.templateArticleToAdd ? t('Artikel wurde erfolgreich zur Vorlage hinzugefügt') : t('Vorlage wurde erfolgreich erstellt');
      templateStore.createNewTemplate(templateName);
      notifications.emit('success', message);
      templateStore.closeTemplateArticleDialog();
      templateStore.closeTemplateNameDialog();
    }
  };

  const cancelDialog = () => {
    templateStore.clearTemplateArticleToAdd();
    templateStore.closeTemplateArticleDialog();
    templateStore.closeTemplateNameDialog();
  };

  return (
    <form noValidate autoComplete="off">
      <Grid container direction="column" spacing={1} sx={{ pt: 1 }}>
        <Grid item>
          <TextField
            variant={'outlined'}
            label={t('Bezeichnung der Vorlage')}
            value={templateName}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeTemplateName(event.target.value)}
            error={!!validationErrors.templateName}
            helperText={validationErrors.templateName}
            autoFocus
            fullWidth
            onKeyDown={handleKeyDown}
          />
        </Grid>
        <Grid item></Grid>
        <Grid container item spacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <Button size={'small'} onClick={cancelDialog} variant={'contained'} color="secondary">
              {t('Schliessen')}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={confirm} variant={'contained'} color="primary">
              {templateStore.templateArticleToAdd ? t('Erstellen & Hinzufügen') : t('Erstellen')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
