import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const LoginFirstBadge = () => {
  const { t } = useTranslation();

  return (
    <Typography variant={'body2'} align={'center'}>
      {t('Bitte loggen Sie sich ein, um diesen Artikel zu bestellen')}
    </Typography>
  );
};

export default LoginFirstBadge;
