import { makeAutoObservable } from 'mobx';
import { getTime } from 'date-fns';

export class CustomerOrderFilter {
  from: Date;
  till: Date;

  constructor() {
    makeAutoObservable(this);
    this.till = new Date();
    this.till.setHours(0);
    this.till.setMinutes(0);
    this.till.setSeconds(0);
    this.till.setMilliseconds(0);

    this.from = new Date(this.till.getTime() - 2190 * 60 * 60 * 1000);
  }

  public get fromValue(): number {
    return getTime(new Date(this.from));
  }

  public get tillValue(): number {
    return getTime(new Date(this.till));
  }
}
