import createTypography from '@mui/material/styles/createTypography';
import createPalette, { Palette } from '@mui/material/styles/createPalette';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';
import { alpha, createTheme } from '@mui/material';

const palette: Palette = createPalette({
  primary: {
    main: '#e20026',
    dark: '#c90022'
  },
  secondary: {
    light: '#efefef',
    main: '#666',
    dark: '#595959'
  },
  error: {
    main: '#e20026'
  }
});

const typography = createTypography(palette, {
  fontFamily: ['Transgourmet'].join(','),
  h1: {
    fontSize: '2em'
  },
  h2: {
    fontSize: '1.5em'
  },
  h3: {
    fontSize: '1.0em'
  },
  subtitle2: {
    fontWeight: 600
  },
  subtitle1: {
    fontWeight: 600
  }
});

const transgourmetMuiTheme = createTheme({
  palette: palette,
  typography: typography,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1920
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          backgroundColor: 'white'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 100
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        multiline: {
          height: 'auto'
        },
        notchedOutline: {
          borderRadius: 0
        },
        root: {
          '&.Mui-focused MuiOutlinedInput-notchedOutline': {
            borderColor: palette.grey.A400,
            borderWidth: 1
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        color: 'secondary'
      },
      styleOverrides: {
        sizeLarge: {
          height: '45px'
        },
        root: {
          minWidth: 0,
          height: 40
        },
        text: {
          textTransform: 'none'
        },
        outlined: {
          borderRadius: 0,
          textTransform: 'none',
          backgroundColor: 'white'
        },
        contained: {
          borderRadius: 0,
          boxShadow: 'none',
          textTransform: 'none'
        },
        outlinedSecondary: {
          ':disabled': {
            borderColor: alpha(palette.secondary.main, 0.6),
            color: alpha(palette.secondary.main, 0.6)
          }
        },
        containedPrimary: {
          backgroundColor: palette.primary.main,
          color: palette.common.white,
          '&:hover': {
            backgroundColor: palette.primary.dark,
            color: palette.common.white
          }
        },
        containedSecondary: {
          backgroundColor: palette.secondary.main,
          color: palette.common.white,
          '&:hover': {
            backgroundColor: palette.secondary.dark,
            color: palette.common.white
          }
        },
        textPrimary: {
          borderRadius: 0,
          '&:hover': {
            backgroundColor: palette.primary.main,
            color: palette.common.white
          },
          textTransform: 'none'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          // color: palette.text.primary,
          // fontWeight: 600,
          '&.Mui-focused': {
            color: palette.text.primary
          }
        },
        focused: {}
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: typography.body1.fontSize
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          marginLeft: 24,
          marginRight: 24
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%'
        }
      }
    },

    MuiToolbar: {
      defaultProps: {
        disableGutters: true
      }
    }
  }
});

const transgourmetTheme = responsiveFontSizes(transgourmetMuiTheme);

export default transgourmetTheme;
