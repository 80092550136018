import type { IShipmentWebshopPresentationData } from '../../../api';
import axios from '../../../util/axios';
import { ppConstants } from '../../../util/ppConstants';

const ShipmentService = {
  async fetchShipments(from: any, to: any, sortBy: any, sortByDesc: boolean, start: number = 0, rows: number = 20): Promise<IShipmentWebshopPresentationData[]> {
    const response = await axios.get(ppConstants.API_HOST_SEC + 'shipments', {
      params: {
        from,
        to,
        sortBy,
        sortByDesc,
        start,
        rows
      }
    });
    return response.data;
  }
};

export default ShipmentService;
