import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { QUERY_SEARCH_PARAM_SEARCHTERM } from '../useSearchQueryParams';

export function useQuickSearchParams() {
  const [searchParams] = useSearchParams();
  const [quickSearchTerm, setQuickSearchTerm] = useState<string>('');
  const navigate = useNavigate();

  useEffect(() => {
    const term = searchParams.has(QUERY_SEARCH_PARAM_SEARCHTERM) ? searchParams.get(QUERY_SEARCH_PARAM_SEARCHTERM)! : '';
    setQuickSearchTerm(term);
  }, [searchParams]);

  const setQuickSearchTermQueryParam = useCallback(
    (term: string) => {
      const encodedTerm = encodeURIComponent(term);
      navigate(term?.length > 0 ? `/catalog?searchTerm=${encodedTerm}` : '/catalog');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    [navigate]
  );

  return { quickSearchTerm, setQuickSearchTermQueryParam };
}
