import type { IWebshopInvoicePresentationData } from '../../../api';
import axios from '../../../util/axios';
import { ppConstants } from '../../../util/ppConstants';

const InvoiceService = {
  async fetchInvoices(from: any, to: any, sortBy: any, sortByDesc: boolean, start: number = 0, rows: number = 20): Promise<IWebshopInvoicePresentationData[]> {
    const response = await axios.get(ppConstants.API_HOST_SEC + 'invoices', {
      params: {
        from,
        to,
        sortBy,
        sortByDesc,
        start,
        rows
      }
    });
    return response.data;
  }
};

export default InvoiceService;
