import React, { useContext, useEffect } from 'react';
import { Badge, CircularProgress, Divider, Fade, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Theme, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import CartIcon from 'mdi-material-ui/Cart';
import Price from './Price';
import { Article } from '../../models/article';
import { ppConstants } from '../../../../../util/ppConstants';
import ArticleActions from '../ArticleActions';
import classNames from 'classnames';
import PlusMinusAmount from '../../../components/PlusMinusAmount';
import ArticleInfoIcons from '../ArticleInfoIcons';
import SignOkIcon from 'mdi-material-ui/CheckboxMarkedCircle';
import LoginFirstBadge from '../LoginFirstBadge';
import ImageContainer from '../../../../common/components/ImageContainer';
import { StoreContext } from '../../../../../StoreContext';
import { crop } from '../../../../../util/stringUtil';
import NotInWebshopMessage from '../article/NotInWebshopMessage';
import CatalogItemSupplierInfo from '../factinfo/CatalogItemSupplierInfo';
import { useConfirmation } from '../../../../common/confirmation/ConfirmContext';
import AvailabilityCheck from '../availabilityMessage/AvailabilityCheck';
import { makeStyles } from 'tss-react/mui';
import ArticleInfoOrderEndTimes from '../ArticleInfoOrderEndTimes';

interface ICatalogTilesItemProps {
  article: Article;
  loadArticleDeep: boolean;
  loadLedger: boolean;
  isLoggedIn: boolean;
  addToCart: (article: Article) => void;
  isLoading: (article: Article) => boolean;
  navigateToDetail: (article: Article) => void;
  isInCart: (article: Article) => boolean;
  flexWidth?: boolean;
  focusOnAction?: boolean;
  id?: string;
  idx?: number;
}

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      width: TILES_WIDTH,
      height: 440,
      textAlign: 'center',
      padding: theme.spacing(0.1),
      border: '1px solid rgba(0,0,0,0.12)',
      boxShadow: 'none'
    },
    title: {
      marginBottom: theme.spacing(1),
      height: 40
    },
    textContent: {
      height: '100%',
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      paddingTop: 0
    },
    noZzArticle: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      margin: theme.spacing(1),
      minWidth: 100,
      textAlign: 'center'
    },

    zzArticle: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      margin: theme.spacing(1),
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: theme.palette.secondary.main,
      cursor: 'pointer',
      minWidth: 100,
      textAlign: 'center'
    },
    selected: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      margin: theme.spacing(1),
      minWidth: 100,
      textAlign: 'center'
    },
    imageContainer: {
      marginTop: theme.spacing(1),
      alignContent: 'center',
      alignSelf: 'center'
    },
    sellUnit: {
      alignSelf: 'center'
    },
    link: {
      cursor: 'pointer'
    },
    actions: {
      width: 48
    },
    actionsContainer: {
      width: 'auto'
    },
    icon: {
      height: 12,
      width: 12
    },
    formControl: {
      minWidth: 120
    },
    notAvailable: {
      opacity: 0.5
    }
  };
});

export const TILES_WIDTH = 300;
export const TILES_SPACING_WIDTH = 16;

function CatalogTilesItem({ article, isLoggedIn, isLoading, isInCart, flexWidth, loadLedger, navigateToDetail, loadArticleDeep, addToCart, focusOnAction = true, idx = 0 }: Readonly<ICatalogTilesItemProps>) {
  const { userStore } = useContext(StoreContext);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const confirm = useConfirmation();

  useEffect(() => {
    if (loadArticleDeep && !article.isDeepLoaded && !article.isDeepLoading) {
      article.fetchArticleDeepLoaded(userStore.isLoggedIn, loadLedger);
    }
  }, [article, userStore.isLoggedIn, loadLedger, loadArticleDeep]);

  useEffect(() => {
    if (article.isZzArticle && focusOnAction) {
      const firstActionIndex = article.zzArticles.findIndex((a) => a.isAction && a.showAction);

      if (firstActionIndex !== -1) {
        article.zzIndex = firstActionIndex;
      }
    }
  }, [article, focusOnAction]);

  const handleOnChange = (value: number) => {
    article.updateAmountProperty(value > 0 ? value : 1);
  };

  const handleZZArticleChange = (event: any) => {
    article.zzIndex = parseInt(event.target.value, 10);
  };

  const getSellUnit = () => {
    if (article.isZzArticle) {
      if (article.hasMoreThanTwoZzArticles) {
        return (
          <FormControl variant={'outlined'} className={classes.formControl}>
            <InputLabel id={'zz'}>Varianten</InputLabel>
            <Select labelId={'zz'} value={article.zzIndex} onChange={handleZZArticleChange}>
              {article.zzArticles.map((zzArt: Article, i: number) => {
                return (
                  <MenuItem key={i} value={i} id={'tile-sellunit-' + i}>
                    <em>
                      {zzArt.sellAmount > 1 ? zzArt.sellUnit + ` ${t('sellUnitSeparator')} ` : ''}
                      {zzArt.priceCode === ppConstants.PRICECODE_WEIGHTCONTROLLED ? zzArt.approxWeight.toFixed(3) : zzArt.sellAmount} {zzArt.articleUnit}
                    </em>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      } else {
        return (
          <Grid container item justifyContent="center">
            {article.zzArticles.map((zzArt: Article, i: number) => {
              return (
                <div
                  key={i}
                  className={article.zzIndex === i ? classes.selected : classes.zzArticle}
                  onClick={(event: any) => {
                    event.stopPropagation();
                    handleOnChangeSellUnit(i);
                  }}
                  id={'tile-sellunit-' + i}
                >
                  <Typography variant="body2">
                    {zzArt.sellAmount > 1 ? zzArt.sellUnit + ` ${t('sellUnitSeparator')} ` : ''}
                    {zzArt.priceCode === ppConstants.PRICECODE_WEIGHTCONTROLLED ? zzArt.approxWeight.toFixed(3) : zzArt.sellAmount} {zzArt.articleUnit}
                  </Typography>
                </div>
              );
            })}
          </Grid>
        );
      }
    } else {
      return (
        <div className={classNames(classes.noZzArticle)}>
          <Typography variant="body2" id={'tile-sellunit-' + idx}>
            {article.sellAmount > 1 ? article.sellUnit + ` ${t('sellUnitSeparator')} ` : ''}
            {article.priceCode === ppConstants.PRICECODE_WEIGHTCONTROLLED ? article.approxWeight.toFixed(3) : article.sellAmount} {article.articleUnit}
          </Typography>
        </div>
      );
    }
  };

  const handleAddToCart = async (event: any) => {
    event.stopPropagation();

    if (article.isProfitPlus) {
      await confirm({
        variant: 'warning',
        catchOnCancel: true,
        title: t('Es gelten spezielle Preis- und Lieferkonditionen!'),
        content: <CatalogItemSupplierInfo article={article} />,
        submitLabel: t('Bestätigen')
      });
      addToCart(article.articleOrZzArticle);
    } else {
      addToCart(article.articleOrZzArticle);
    }
  };

  const handleOnChangeSellUnit = (index: number) => {
    article.zzIndex = index;
  };

  const handleNavigate = () => {
    navigateToDetail(article.isZzArticle ? article.zzArticles[article.zzIndex] : article);
  };

  const isArticleInCart = isInCart(article.articleOrZzArticle);
  const isDisabled = article?.hideCart;

  return (
    <Fade in={true} timeout={600}>
      <Paper className={classes.root} style={{ width: flexWidth ? 'auto' : TILES_WIDTH }} id={'tile-' + idx}>
        <Grid container direction="column">
          <Grid item className={isDisabled ? `${classes.link} ${classes.notAvailable}` : classes.link} onClick={handleNavigate}>
            <Price item={article.articleOrZzArticle} id={'article-tile-' + idx} />
            <Divider />
          </Grid>
          <Grid item className={isDisabled ? `${classNames(classes.imageContainer, classes.link)} ${classes.notAvailable}` : classNames(classes.imageContainer, classes.link)} onClick={handleNavigate}>
            <ImageContainer src={article.thumbImageUrl} width={120} height={120} />
          </Grid>
          <Grid container item className={classes.textContent} direction="column" justifyContent="space-between">
            <Grid item className={isDisabled ? classes.notAvailable : ''}>
              <Typography variant={'caption'} id={'articlenumber-tile-' + idx}>{`${t('Art. Nr.')} ${article.articleOrZzArticle.articleNumber}`}</Typography>
              <Typography variant="subtitle2" className={classNames(classes.title, classes.link)} id={'article-tile-' + idx} onClick={handleNavigate}>
                {crop(article.articleOrZzArticle.articleText, 65)}
              </Typography>
            </Grid>
            <Grid item className={isDisabled ? `${classes.sellUnit} ${classes.notAvailable}` : classes.sellUnit}>
              {getSellUnit()}
            </Grid>
            <Grid item className={isDisabled ? classes.notAvailable : ''}>
              <Grid container className={classes.actionsContainer} justifyContent={'space-between'} alignItems="center">
                <Grid item className={classes.actions}>
                  <ArticleInfoIcons article={article.articleOrZzArticle} isMedium={false} id={'tile-infoicon-' + idx} />
                </Grid>
                {!article.hideCart && (
                  <Grid item>
                    <Typography variant="subtitle1" align="center" id={'tile-calculatedPrice-' + idx}>
                      {article.calculatedTotalPrice(userStore.actionExclude)}
                    </Typography>
                  </Grid>
                )}
                <Grid item className={classes.actions}>
                  <ArticleInfoOrderEndTimes article={article.articleOrZzArticle} id={'tile-orderendtimes-' + idx} />
                </Grid>
              </Grid>
            </Grid>
            {article.articleOrZzArticle.notInWebShop && <NotInWebshopMessage />}
            {isLoggedIn && !article.articleOrZzArticle.hideCart && (
              <Grid item container justifyContent={isLoggedIn ? 'space-between' : 'center'} alignItems="center">
                <Grid item container style={{ width: 'auto' }}>
                  <Grid item className={classes.actions}>
                    <ArticleActions article={article.articleOrZzArticle} id={'tile-actionicon-' + idx} />
                  </Grid>
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <PlusMinusAmount amount={article.amount} onAmountChanged={handleOnChange} onDecreaseIncreaseAmount={handleOnChange} idx={idx} />
                </Grid>
                <Grid item className={classes.actions}>
                  {isLoading(article.articleOrZzArticle) && <CircularProgress size={32} variant={'indeterminate'} />}
                  {!isLoading(article.articleOrZzArticle) && (
                    <IconButton color="primary" onClick={handleAddToCart} aria-label={t('Hinzufügen')} id={'tile-item-add-to-cart-button-' + idx} size="large">
                      <Badge badgeContent={<SignOkIcon className={classes.icon} fontSize={'small'} />} invisible={!isArticleInCart}>
                        <CartIcon color="primary" />
                      </Badge>
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            )}
            {isLoggedIn && article.articleOrZzArticle.hideCart && (
              <Grid item container justifyContent={'center'} alignItems="center">
                <Grid item container style={{ width: 'auto' }}>
                  <AvailabilityCheck article={article.articleOrZzArticle} showCompactMessage={true} />
                </Grid>
              </Grid>
            )}
            {!isLoggedIn && (
              <Grid item>
                <LoginFirstBadge />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Fade>
  );
}

export default observer(CatalogTilesItem);
