import { IAvailabilityOptionData } from '../../../../api';
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useForceUpdate } from '../../../../util/forceUpdateHook';
import { IPromptInfo } from '../../types/IPrompt';
import { Grid, Typography, Divider, FormControlLabel } from '@mui/material';
import Radio from '@mui/material/Radio';

type SelectedOptionsMap = Map<IPromptInfo, IAvailabilityOptionData | undefined>;

export interface AddPositionProblemDialogFragmentHandleType {
  getSelectedCommands: () => IAvailabilityOptionData[];
}

interface AddPositionProblemOptionsProps {
  problem: IPromptInfo;
  selectedOption?: IAvailabilityOptionData;
  onOptionChange: (selectedOption: IAvailabilityOptionData) => void;
}

const AddPositionProblemOptions = ({ onOptionChange, selectedOption, problem }: AddPositionProblemOptionsProps) => {
  const choose = (option: IAvailabilityOptionData) => {
    onOptionChange(option);
  };

  return (
    <Grid container direction={'column'}>
      {problem.options.map((option) => {
        return (
          <Grid item key={option.command.guid}>
            <FormControlLabel control={<Radio value={option} onChange={() => choose(option)} checked={option === selectedOption} />} label={option.description} />
          </Grid>
        );
      })}
    </Grid>
  );
};

const AddPositionProblemMessage = ({ problem }: { problem: IPromptInfo }) => {
  const messageParts = problem.message.split('\n').filter((v) => v.length > 0);
  const articleDescription = [messageParts[1], messageParts[2], messageParts[3]].filter((part) => part).join(' / ');
  return (
    <Typography variant="subtitle2" gutterBottom>
      {articleDescription}
    </Typography>
  );
};

export interface IAddPositionProblemDialogFragmentProps {
  problems: IPromptInfo[];
  handler?: (option: any) => void;
}

const AddPositionProblemDialogFragment = forwardRef(({ problems }: IAddPositionProblemDialogFragmentProps, ref: any) => {
  const forceUpdate = useForceUpdate();
  const selectedOptionsMapRef = useRef<SelectedOptionsMap>(new Map(problems.map((problem): [IPromptInfo, IAvailabilityOptionData | undefined] => [problem, problem.options[0]])));

  useEffect(() => {
    selectedOptionsMapRef.current = new Map(problems.map((problem): [IPromptInfo, IAvailabilityOptionData | undefined] => [problem, problem.options[0]]));
  }, [problems]);

  useImperativeHandle(
    ref,
    (): AddPositionProblemDialogFragmentHandleType => ({
      // This returns the object that is exposed to a client who gets a ref of this component
      getSelectedCommands() {
        const values = [...selectedOptionsMapRef.current!.values()].filter((value): value is IAvailabilityOptionData => value !== undefined);
        return values;
      }
    })
  );

  function changeOption(problem: IPromptInfo, selectedOption: IAvailabilityOptionData) {
    selectedOptionsMapRef.current!.set(problem, selectedOption);
    forceUpdate(); // since the selectedOptionsMap is not managed as state of the component we have to explicitly re-render the component
  }

  return (
    <div>
      {problems.map((problem, i) => {
        const selectedOption = selectedOptionsMapRef.current ? selectedOptionsMapRef.current.get(problem) : undefined;
        return (
          <Grid container direction={'column'} spacing={2} key={i}>
            <Grid item>
              <AddPositionProblemMessage problem={problem} />
            </Grid>
            <Grid item>
              <AddPositionProblemOptions problem={problem} selectedOption={selectedOption} onOptionChange={(option) => changeOption(problem, option)} />
            </Grid>
            {i !== problems.length - 1 && (
              <Grid item>
                <Divider />
              </Grid>
            )}
          </Grid>
        );
      })}
    </div>
  );
});

export default AddPositionProblemDialogFragment;
