import i18n from 'i18next';
import RootStore from '../rootStore';

export function getMLSelector(): any {
  return (languageStrings: any[]): string | string[] => {
    if (!languageStrings) {
      console.info('Language strings are not passed to the selector!');
      return 'undefined';
    }

    const locale = getShortLocale();

    switch (locale) {
      case 'de':
        return languageStrings[0] ? languageStrings[0] : '';
      case 'fr':
        return languageStrings[1] ? languageStrings[1] : '';
      case 'it':
        return languageStrings[2] ? languageStrings[2] : '';
      default:
        throw new Error('i18n language selection: unsupported language: ' + locale);
    }
  };
}

export function getShortLocale() {
  const locale = i18n.language;
  return locale ? locale.substring(0, 2) : 'de';
}

export function getLanguageId() {
  const locale = i18n.language;
  switch (locale.substring(0, 2)) {
    case 'de':
      return 0;
    case 'fr':
      return 1;
    case 'it':
      return 2;
    default:
      throw new Error('Getting language id: unsupported language: ' + locale);
  }
}

export const LOCALE_LOADER = {
  getLocale() {
    return i18n.language;
  },

  async switchLanguage(locale: string, rootStore: RootStore) {
    try {
      await rootStore.masterDataStore.changeLocale();
      i18n.changeLanguage(locale);
    } catch (error) {
      console.error('Could not load translations from server: ', error);
    }
  }
};
