import type { ICustomerOrderPresentationData, IWebshopCustomerOrderDTOData } from '../../../api';
import axios from '../../../util/axios';
import { ppConstants } from '../../../util/ppConstants';

const OrderService = {
  async fetchOrders(from: number, to: number, sortBy: string, sortByDesc: boolean, start: number = 0, rows: number = 20, language: string): Promise<ICustomerOrderPresentationData[]> {
    const response = await axios.get(ppConstants.API_HOST_SEC + 'customerorders', {
      params: {
        from,
        to,
        sortBy,
        sortByDesc,
        start,
        rows,
        language
      }
    });
    return response.data;
  },

  async fetchOrder(orderId: number) {
    const response = await axios.get<IWebshopCustomerOrderDTOData>(ppConstants.API_HOST_SEC + 'customerorders/details/' + orderId);
    return response.data;
  }
};

export default OrderService;
