import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICartExpirationHandler } from '../../types/ICartExpirationHandler';
import { Button, Divider, Grid, Stack, Typography } from '@mui/material';

export interface ICartDeliveryDateExpiredDialogProps {
  handler: (data: ICartExpirationHandler) => void;
}

const CartExpiredDialog = ({ handler }: ICartDeliveryDateExpiredDialogProps) => {
  const { t } = useTranslation();

  const doRefreshCart = () => {
    handler({ type: 'REFRESH' });
  };

  const doDiscardCart = () => {
    handler({ type: 'DISCARD' });
  };

  return (
    <Stack spacing={2}>
      <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={2}>
        <Grid item>
          <Typography variant="body1">{t('Sie können den Warenkorb beibehalten, dabei wird der Warenkorb aktualisiert.')}</Typography>
        </Grid>
        <Grid item>
          <Button sx={{ minWidth: 80 }} variant={'contained'} color={'primary'} onClick={doRefreshCart}>
            {t('Behalten')}
          </Button>
        </Grid>
      </Grid>
      <Divider />
      <Grid container justifyContent={'space-between'} alignItems={'center'} spacing={2}>
        <Grid item>
          <Typography variant="body1">{t('Sie können den Warenkorb verwerfen.')}</Typography>
        </Grid>
        <Grid item>
          <Button sx={{ minWidth: 80 }} variant={'contained'} onClick={doDiscardCart}>
            {t('Verwerfen')}
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CartExpiredDialog;
