import React, { forwardRef } from 'react';
import { Img } from 'react-image';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';

interface IImageContainerProps {
  src: string;
  alternativeSrc?: string;
  disableAlternativeImage?: boolean;
  disabledLoadingIndicator?: boolean;
  width?: any;
  height?: any;
  id?: string;
  sx?: SxProps;
}

const ImageContainer = forwardRef((props: IImageContainerProps, ref) => {
  const imageUrls = [props.src];
  const id = props.id;

  if (!props.disableAlternativeImage) {
    imageUrls.push(props.alternativeSrc ? props.alternativeSrc : 'assets/img/noimage.png');
  }

  return (
    <Box
      ref={ref}
      sx={{
        color: (theme) => theme.palette.grey.A100,
        maxWidth: props.width ? props.width : 'none',
        maxHeight: props.height ? props.height : 'none',
        ...props.sx
      }}
    >
      <Grid container justifyContent={'center'} alignItems={'center'}>
        <Grid
          item
          sx={{
            '& img': {
              maxWidth: props.width ? props.width : 'none',
              maxHeight: props.height ? props.height : 'none'
            }
          }}
        >
          <Img src={imageUrls} id={id} loader={props.disabledLoadingIndicator ? null : <CircularProgress variant={'indeterminate'} color={'inherit'} />} />
        </Grid>
      </Grid>
    </Box>
  );
});

export default ImageContainer;
