import type { ICartDiscountInfoData, ICustomerOrderData, ICustomerOrderPositionData, IExternalArticlePositionData } from '../../../api';
import i18n from 'i18next';
import { reaction, runInAction, makeAutoObservable } from 'mobx';
import RootStore from '../../../rootStore';
import { Article } from '../../shared/articles/models/article';
import AddPositionIntent from '../models/intent/AddPositionIntent';
import AddPositionsIntent, { AddPositionParameterType } from '../models/intent/AddPositionsIntent';
import BaseIntent from '../models/intent/BaseIntent';
import DiscardOrderIntent from '../models/intent/DiscardOrderIntent';
import GetOrderIntent from '../models/intent/GetOrderIntent';
import IntentProcessor from '../models/intent/IntentProcessor';
import SendOrderIntent from '../models/intent/SendOrderIntent';
import type { ICartAlertAction } from '../types/ICartAlertAction';
import type { ICartExpirationAction } from '../types/ICartExpirationAction';
import type { IDeliveryDateSelectionAction } from '../types/IDeliveryDateSelectionAction';
import type { IPrompt } from '../types/IPrompt';
import type { ITempCashAction } from '../types/ITempCashAction';
import CartExternalArticlePosition from './cartExternalArticlePosition';
import CustomerOrderPosition from './customerOrderPosition';
import { Order } from './order';
import ChangeOrderDateWithCheck from '../models/intent/ChangeOrderDateWithCheck';
import { format } from 'date-fns';
import { FEATURES } from '../../common/stores/featureStore';

export default class CartStore {
  rootStore: RootStore;
  intentProcessor: IntentProcessor;
  pendingRequestsCount = 0;
  order?: Order;
  cartDiscountInfo?: ICartDiscountInfoData;
  tempCashInfo?: ITempCashAction;
  currentError: any; // TODO: Compare with old implementation (Symbol)
  prompt?: IPrompt;
  cartExpiration?: ICartExpirationAction;
  alertInfo?: ICartAlertAction;
  deliveryDateSelectionRequest?: IDeliveryDateSelectionAction;
  ociOrderSentUrl?: string;
  ociOrderData?: string;
  initialOrderLoaded: boolean = false;
  isTempCashAccepted = false;
  selectedDeliveryDate?: string;
  hasPendingIntents = false;

  isIntentPending = (intentSpec: any) => this.intentProcessor.isPending(intentSpec);

  getAmountInCartByArticleId = (articleId: number): number | null => {
    let result = null;
    const positions = this.order ? this.order.externalArticlePositions : [];
    for (const position of positions) {
      if (position.articleId === articleId) {
        if (result === null) {
          result = position.quantity;
        } else {
          result += position.quantity;
        }
      }
    }
    return result;
  };

  getAmountInCartByArticleNr = (articleNr: string): number | null => {
    let result = null;
    const positions = this.order ? this.order.positions : [];
    for (const position of positions) {
      if (position.articleBaseNo === articleNr) {
        if (result === null) {
          result = position.orderQuantity;
        } else {
          result += position.orderQuantity;
        }
      }
    }
    return result;
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.intentProcessor = new IntentProcessor(this.rootStore.cartStore);

    reaction(
      () => this.rootStore.userStore.currentCustomer,
      () => {
        if (!this.ociOrderSentUrl && this.rootStore.userStore.loginCustomer) {
          this.resetCartState();
          this.addIntent(new GetOrderIntent(this));
        }
      }
    );

    reaction(
      () => this.tempCashInfo,
      () => {
        if (this.tempCashInfo) {
          this.tempCashInfo.handler(true);
        }
      }
    );
  }

  isProcessorIdle = () => this.intentProcessor.isIdle;

  addIntent(intent: BaseIntent) {
    const processor = this.intentProcessor.scheduleIntent(intent);

    processor.getCompletedPromise().then(() => {
      runInAction(() => {
        this.hasPendingIntents = this.intentProcessor.pendingIntents.length > 0;
      });
    });

    this.hasPendingIntents = this.intentProcessor.pendingIntents.length > 0;
  }

  get isMobileView() {
    return this.rootStore.uiStore.width <= 768;
  }

  get overview() {
    return this.cartOverview;
  }

  get cartOverview() {
    if (this.order) {
      return {
        numberOfPositions: this.order.positions.filter((p: any) => !p.container).length,
        numberOfExternalPositions: this.order.externalArticlePositions ? this.order.externalArticlePositions.length : 0,
        totalPrice: this.order.positions.reduce((sum: any, p: any) => sum + p.total, 0).toFixed(2),
        deliveryDate: new Date(this.order.desiredDeliveryDate),
        orderId: this.order.id,
        isOci: this.order.creationSystem === 'OCI'
      };
    } else {
      return null;
    }
  }

  changeOrderDate(deliveryDate: Date) {
    this.addIntent(new ChangeOrderDateWithCheck(this, deliveryDate));
  }

  discardCart() {
    this.addIntent(new DiscardOrderIntent(this));
  }

  sendOrder(reference: string, remark: string) {
    this.addIntent(new SendOrderIntent(this, reference, remark));
  }

  cancelIntents() {
    this.intentProcessor.reset();
  }

  addArticlesToCart(articles: Article[]) {
    if (this.rootStore.featureStore.isFeatureEnabled(FEATURES.batchPositionAdd)) {
      const addPositionParameters: AddPositionParameterType[] = [];

      for (const article of articles) {
        let articleNumber = article.articleNumber;
        let amount = article.amount;
        if (article.isZzArticle) {
          articleNumber = article.zzArticles[article.zzIndex].articleNumber;
          amount = article.zzArticles[article.zzIndex].amount;
        }

        addPositionParameters.push({
          articleNumber,
          quantity: amount,
          externalArticle: article.externalArticle,
          articleIsEan: false
        });
      }

      const addPositionsIntent = new AddPositionsIntent(this, addPositionParameters);
      this.addIntent(addPositionsIntent);
    } else {
      for (const selectedArticle of articles) {
        let articleNumber = selectedArticle.articleNumber;
        let amount = selectedArticle.amount;
        if (selectedArticle.isZzArticle) {
          articleNumber = selectedArticle.zzArticles[selectedArticle.zzIndex].articleNumber;
          amount = selectedArticle.zzArticles[selectedArticle.zzIndex].amount;
        }

        const addPositionIntent = new AddPositionIntent(this, selectedArticle.externalArticle ? selectedArticle.articleId.toString() : articleNumber, amount, selectedArticle.externalArticle);
        this.addIntent(addPositionIntent);
      }
    }
  }

  promptAnswered() {
    this.prompt = undefined;
  }

  cancelCartItem() {
    this.alertInfo = undefined;
    this.prompt = undefined;
    this.currentError = undefined;
  }

  expirationActionAnswered() {
    this.cartExpiration = undefined;
  }

  orderReceived(order: ICustomerOrderData, cartDiscountInfo: ICartDiscountInfoData) {
    this.order = order ? new Order(order) : undefined;
    this.cartDiscountInfo = cartDiscountInfo;

    this.initialOrderLoaded = true;
    this.alertInfo = undefined;
    this.prompt = undefined;
    this.deliveryDateSelectionRequest = undefined;
    this.ociOrderSentUrl = undefined;

    if (!order) {
      this.isTempCashAccepted = false;
    }
  }

  resetCartState() {
    this.intentProcessor.reset();
    this.order = undefined;
    this.alertInfo = undefined;
    this.prompt = undefined;
    this.isTempCashAccepted = false;
    this.selectedDeliveryDate = undefined;
  }

  tempCashAccepted(accepted: boolean) {
    this.isTempCashAccepted = accepted;
    this.tempCashInfo = undefined;
  }

  showTempCash(tempCashInfo: ITempCashAction) {
    this.tempCashInfo = tempCashInfo;
  }

  updatePosition(position: ICustomerOrderPositionData) {
    if (this.order) {
      const index = this.order.positions.findIndex((p) => p.id === position.id);
      if (index >= 0) {
        this.order.positions[index].update(position);
      } else {
        this.order.positions.push(new CustomerOrderPosition(position));
      }
    }
  }

  updateExternalPosition(position: IExternalArticlePositionData) {
    if (this.order) {
      const index = this.order.externalArticlePositions.findIndex((p) => p.id === position.id);
      if (index >= 0) {
        this.order.externalArticlePositions[index].update(position);
      } else {
        this.order.externalArticlePositions.push(new CartExternalArticlePosition(position));
      }
    }
  }

  removeExternalPosition(positionId: number) {
    if (this.order) {
      // if the cart is not loaded, we don't need to update it
      this.order.externalArticlePositions = this.order.externalArticlePositions.filter((p: any) => p.id !== positionId);
    }
  }

  removePosition(positionId: number) {
    if (this.order) {
      // if the cart is not loaded, we don't need to update it
      this.order.positions = this.order.positions.filter((p: any) => p.id !== positionId && p.mainCustomerOrderPositionId !== positionId);
    }
  }

  ociOrderSent(ociUrl: string, data: string) {
    this.ociOrderSentUrl = ociUrl;
    this.ociOrderData = data;
    this.resetCartState();
    this.rootStore.userStore.ociFinish();
  }

  orderSent() {
    const orderId = this.order ? this.order.id : undefined;
    this.resetCartState();

    if (orderId) {
      this.rootStore.router.navigate(`/cart/${orderId}`, { replace: true });
    } else {
      this.rootStore.router.navigate('/cart');
    }
  }

  showExpirationAction(expirationAction: ICartExpirationAction) {
    this.cartExpiration = expirationAction;
  }

  deliveryDateSelectionAnswered(selectedDeliveryDate?: Date) {
    if (selectedDeliveryDate) {
      const localFormat = 'yyyy-MM-dd';
      this.selectedDeliveryDate = format(selectedDeliveryDate, localFormat);
    }
    this.deliveryDateSelectionRequest = undefined;
  }

  showDeliveryDateSelection(deliveryDateSelectionRequest: IDeliveryDateSelectionAction) {
    this.deliveryDateSelectionRequest = deliveryDateSelectionRequest;
  }

  showPrompt(prompt: IPrompt) {
    this.prompt = prompt;
  }

  showAlert(alertInfos: ICartAlertAction) {
    this.alertInfo = alertInfos;
  }

  alertAnswered() {
    this.alertInfo = undefined;
  }

  get externalArticlePositions() {
    return this.order && this.order.externalArticlePositions ? this.order.externalArticlePositions : [];
  }

  get customerOrderPositions() {
    return this.order && this.order.positions ? this.order.positions : [];
  }

  private get anyPositionEmpty() {
    const anyPositionEmpty = this.customerOrderPositions.findIndex((pos) => pos.orderQuantity === 0) > -1;
    const anyExternalPositionEmpty = this.externalArticlePositions.findIndex((pos) => pos.quantity === 0) > -1;
    return anyPositionEmpty || anyExternalPositionEmpty;
  }

  private get allPositionEmpty() {
    const positionsEmpty = this.customerOrderPositions.findIndex((pos) => pos.orderQuantity > 0) === -1;
    const externalPositionsEmpty = this.externalArticlePositions.findIndex((pos) => pos.quantity > 0) === -1;
    return positionsEmpty && externalPositionsEmpty;
  }

  private get cartIsEmpty() {
    return (this.customerOrderPositions.length === 0 || this.allPositionEmpty) && this.externalArticlePositions.length === 0;
  }

  private get missingAgbAccepted() {
    return this.rootStore.userStore?.loginCustomer?.agbAccepted === false;
  }

  get isOrderDisabled() {
    return this.anyPositionEmpty || this.allPositionEmpty || this.cartIsEmpty || this.missingAgbAccepted || this.rootStore.userStore.isTempCash;
  }

  get orderDisabledReason() {
    let disabledReason = '';
    if (this.cartIsEmpty) {
      disabledReason = 'Warenkorb ist leer!';
    }

    if (this.anyPositionEmpty) {
      disabledReason = 'Position mit Menge 0!';
    }

    if (this.missingAgbAccepted) {
      disabledReason = 'AGB nicht akzeptiert!';
    }

    if (this.rootStore.userStore.isTempCash) {
      disabledReason = 'Ihr Konto steht leider für Bestellungen momentan nicht zur Verfügung';
    }

    return disabledReason;
  }

  get cartWarnings() {
    const result = [];
    let i = 0;
    if (this.order && this.order!.total < 700) {
      result.push({
        id: 'cart' + i++,
        message: i18n.t(
          'Sie haben den Mindestbestellwert von CHF 700.- nicht erreicht. Bitte beachten Sie, dass wir aktuell, Bestellungen mit einem Bestellwert unter CHF 350.- mit einem Kleinmengenzuschlag von CHF 50.- belasten. Die Belastung erfolgt im Folgemonat.'
        )
      });
    }

    if (this.anyPositionEmpty) {
      result.push({
        id: 'cart' + i++,
        message: i18n.t('Sie haben Positionen mit der Menge 0 im Warenkorb. Bitte entfernen Sie diese.')
      });
    }

    if (this.cartIsEmpty) {
      result.push({
        id: 'cart' + i++,
        message: i18n.t('Ihr Warenkorb ist zur Zeit leer!')
      });
    }

    if (this.missingAgbAccepted) {
      result.push({
        id: 'cart' + i++,
        message: i18n.t('Sie haben die AGB noch nicht akzeptiert')
      });
    }

    return result;
  }

  get cartOrderId() {
    if (this.cartOverview) {
      return this.cartOverview.orderId;
    }
    return null;
  }

  get isLoading() {
    return this.pendingRequestsCount > 0;
  }

  get deliveryDate() {
    return this.order && this.order.desiredDeliveryDate ? new Date(this.order.desiredDeliveryDate) : new Date();
  }
}
