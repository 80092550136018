import * as React from 'react';
import BellIcon from 'mdi-material-ui/BellRing';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

interface INotificationInfoProps {
  tooltipText: string;
  onOpen: () => void;
  count: number;
}

type Props = INotificationInfoProps;

export const NotificationInfo = (props: Props): JSX.Element => {
  const { onOpen, tooltipText, count } = props;

  return (
    <Tooltip title={tooltipText} data-testid={'notification-info-tooltip'}>
      <IconButton onClick={onOpen} aria-label={tooltipText} data-testid={'notification-info-button'} size="large">
        <Badge badgeContent={count} color="primary" data-testid={'notification-info-badge'}>
          <BellIcon fontSize={'small'} />
        </Badge>
      </IconButton>
    </Tooltip>
  );
};
