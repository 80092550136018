import React from 'react';
import { Dialog, Theme, useMediaQuery } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from 'mdi-material-ui/Close';
import useTheme from '@mui/material/styles/useTheme';
import ArticleDetailView from './ArticleDetailView';
import Divider from '@mui/material/Divider';
import { makeStyles } from 'tss-react/mui';
import { useModalArticleDetailDialogControl } from '../../util/useModalArticleDetailDialog';

const useStyles = makeStyles()(({ spacing }: Theme) => {
  return {
    dialogPaper: {
      minHeight: '90vh',
      maxHeight: '90vh'
    },
    mobileDialogPaper: {
      padding: spacing(1)
    },
    content: {
      padding: 0,
      paddingBottom: spacing(2),
      height: '100%',
      overflowX: 'hidden'
    },
    dialogTitle: {
      margin: 0,
      padding: 0
    }
  };
});

type ArticleDetailDialogComponentProps = {
  articleNumber: string;
};

function ArticleDetailDialogComponent({ articleNumber }: ArticleDetailDialogComponentProps) {
  const { classes } = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { closeArticleDetailsDialog } = useModalArticleDetailDialogControl();

  function handleClose() {
    closeArticleDetailsDialog();
  }

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      maxWidth={'lg'}
      scroll={'paper'}
      fullWidth={true}
      fullScreen={isMobile}
      classes={{
        paper: isMobile ? classes.mobileDialogPaper : classes.dialogPaper
      }}
    >
      <>
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          <Grid container wrap={'nowrap'} justifyContent={'flex-end'} alignItems={'center'}>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          {isMobile && <Divider variant={'fullWidth'} />}
        </DialogTitle>

        <DialogContent className={classes.content}>
          <ArticleDetailView articleNumber={articleNumber} />
        </DialogContent>
      </>
    </Dialog>
  );
}

export const ArticleDetailDialog = observer(ArticleDetailDialogComponent);
