import type { IWebshopCustomerOrderExternalPositionDTOData } from '../../../../api';
import { getMLSelector } from '../../../../util/i18n';
import i18n from 'i18next';

export class CustomerOrderExternalArticlePosition {
  id: number;
  orderQuantity: number;
  deliveryUnit: string = '';
  contentUnit: string = '';
  units: string = '';
  contentAmount: number;
  articleNumber: string = '';
  articleText: string = '';
  price: number;
  supplierId: number;
  supplierName: string = '';
  articleId: number;

  constructor(dto: IWebshopCustomerOrderExternalPositionDTOData) {
    const mls = getMLSelector();
    this.id = dto.id;
    this.orderQuantity = dto.orderQuantity;
    this.deliveryUnit = dto.deliveryUnit ? mls(dto.deliveryUnit) : '';
    this.contentUnit = dto.contentUnit ? mls(dto.contentUnit) : '';
    this.units = dto.contentAmount > 1 && this.deliveryUnit ? this.deliveryUnit + ` ${i18n.t('sellUnitSeparator')} ` : '';
    this.contentAmount = dto.contentAmount ? dto.contentAmount : 0;
    this.articleNumber = dto.articleNumber;
    this.articleText = dto.articleText ? mls(dto.articleText) : '';
    this.price = dto.price ? dto.price : 0;
    this.supplierId = dto.supplierId;
    this.supplierName = dto.supplierName;
    this.articleId = dto.articleId;
  }

  get contentAmountDescription() {
    return `${this.units}${this.contentAmount} ${this.contentUnit}`;
  }

  get total() {
    return this.price * (this.contentAmount ? this.contentAmount : 1) * this.orderQuantity;
  }
}
