import React from 'react';
import { Breadcrumbs } from '@mui/material';
import DefaultBreadcrumbItem from './DefaultBreadcrumbItem';

export interface IDefaultBreadcrumbProps {
  breadCrumbName: string;
}

export default (props: IDefaultBreadcrumbProps) => {
  return (
    <Breadcrumbs aria-label="Breadcrumb">
      <DefaultBreadcrumbItem breadCrumbName={props.breadCrumbName} />
    </Breadcrumbs>
  );
};
