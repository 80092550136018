import type { ICustomerOrderPresentationData } from '../../../api';
import { runInAction, makeAutoObservable } from 'mobx';
import RootStore from '../../../rootStore';
import { orderSortBy } from '../models/OrderConstants';
import OrderService from '../services/orderService';
import { CustomerDetailOrder } from './models/customerDetailOrder';
import { CustomerOrder } from './models/customerOrder';
import { CustomerOrderArticlePosition } from './models/customerOrderArticlePosition';
import { CustomerOrderExternalArticlePosition } from './models/customerOrderExternalArticlePosition';
import { CustomerOrderFilter } from './models/customerOrderFilter';
import { CustomerOrderTotalTableRow } from './models/customerOrderTotalTableRow';
import { executeAsyncAction } from '../../../util/exceptionHandler';

export type ArticlePosition = CustomerOrderArticlePosition | CustomerOrderTotalTableRow;
export type ExternalArticlePosition = CustomerOrderExternalArticlePosition | CustomerOrderTotalTableRow;

export default class CustomerOrderStore {
  rootStore: RootStore;
  orders: CustomerOrder[] = [];
  order: CustomerDetailOrder | null | undefined; // undefined: no API call, null: API call returned no data
  pendingRequestsCount = 0;
  filter: CustomerOrderFilter;
  start: number = 0;
  rows: number = 20;
  sortBy: string = orderSortBy.ORDERID;
  sortByDesc: boolean = true;
  nextPageLoading: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.filter = new CustomerOrderFilter();
  }

  fetchOrders() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const orders = await OrderService.fetchOrders(this.filter.fromValue, this.filter.tillValue, this.sortBy, this.sortByDesc, 0, this.rows, this.rootStore.uiStore.language);
        runInAction(() => {
          this.start = 0;

          this.orders = orders.map((o: ICustomerOrderPresentationData) => new CustomerOrder(o));
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  fetchMore() {
    executeAsyncAction(
      async () => {
        this.nextPageLoading = true;
        this.start = this.start + 20;
        const orders = await OrderService.fetchOrders(this.filter.fromValue, this.filter.tillValue, this.sortBy, this.sortByDesc, this.start, this.rows, this.rootStore.uiStore.language);
        runInAction(() => {
          orders.forEach((o) => {
            this.orders.push(new CustomerOrder(o));
          });
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.nextPageLoading = false;
          });
        }
      }
    );
  }

  fetchOrder(orderId: number) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const order = await OrderService.fetchOrder(orderId);
        runInAction(() => {
          if (!order) {
            this.order = null;
          } else {
            this.order = new CustomerDetailOrder(this, order);
          }
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  isRowLoaded(index: any) {
    return this.orders.length > index;
  }

  clearOrder() {
    this.order = undefined;
  }

  updateFilter(data: { from?: Date; till?: Date }) {
    if (data.from) {
      this.filter.from = data.from;
    }

    if (data.till) {
      this.filter.till = data.till;
    }
  }

  updateSortCriteria(sortBy: string, sortDesc: boolean) {
    this.sortBy = sortBy;
    this.sortByDesc = sortDesc;

    this.fetchOrders();
  }

  get isLoading() {
    return this.pendingRequestsCount > 0;
  }

  get orderNotAvailable() {
    return this.order === null;
  }
}
