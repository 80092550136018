import React, { useContext } from 'react';
import { StoreContext } from '../StoreContext';
import { observer } from 'mobx-react-lite';
import Footer from './common/components/footer/Footer';
import Portal from '@mui/material/Portal';
import Box from '@mui/material/Box';

export default function withContentLayout(Breadcrumb: any, Toolbar: any, Content: any, withDefaultPadding: boolean = true) {
  return observer((props: any): any => {
    const { uiStore } = useContext(StoreContext);

    const isMediumScreenDown = uiStore.isMediumScreenDown;

    return (
      <>
        <Portal container={isMediumScreenDown ? uiStore.mobileBreadcrumbRef : uiStore.breadcrumbRef}>{Breadcrumb ? <Breadcrumb /> : <div />}</Portal>
        <Portal container={isMediumScreenDown ? uiStore.mobileToolbarRef : uiStore.toolbarRef}>{Toolbar ? <Toolbar /> : <div />}</Portal>
        <Box
          sx={{
            flexGrow: 1,
            marginLeft: 0,
            marginRight: 0,
            width: '100%',
            transition: 'all 0.5s ease',
            marginTop: isMediumScreenDown ? '120px' : '210px'
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              padding: withDefaultPadding ? 2 : 0,
              paddingTop: 0
            }}
          >
            <Content {...props} />
          </Box>
        </Box>
        <Footer />
      </>
    );
  });
}
