import { makeAutoObservable, runInAction } from 'mobx';
import i18n from 'i18next';
import RootStore from '../../../rootStore';
import { groupBy, sortBy } from '../../../util/filterConstants';
import { rootStore } from '../../../StoreContext';
import { createCategories } from '../helper/InventoryCategoryBuilder';
import InventoryPosition from '../models/inventoryPosition';
import ProductCategory from '../models/productCategory';
import InventoryService from '../services/inventoryService';
import { InventoryStore } from './inventoryStore';
import { formatServerMessage } from '../../../util/stringUtil';
import InventoryDetail from '../models/inventoryDetail';
import { IWebInventoryDetailData } from '../../../api';
import { executeAsyncAction } from '../../../util/exceptionHandler';
import notifications from '../../../notifications';

type InventoryItem = ProductCategory | InventoryPosition;

export class InventoryDetailStore {
  rootStore: RootStore;
  collapsedCategoryKeys: string[] = [];
  pendingRequestsCount = 0;
  inventory?: InventoryDetail;
  inventorySortSpec = { sortBy: sortBy.ARTTXT, groupBy: groupBy.HWG };
  itemToRemoveId?: number;
  filterTerm: string = '';
  showImportFileDialogOpen = false;
  inventoryNameDialogType = InventoryStore.INVENTORYNAMEDIALOGTYPE_NEW;
  isInventoryNameDialogShown: boolean = false;
  showImportFileFeedbackDialog: boolean = false;
  importFileFeedbackTitle: string = '';
  importFileFeedbackMessage: string[] = [];
  showSureToCloseDialog: boolean = false;
  showSureToCalcPriceDialog: boolean = false;
  isLoading: boolean = false;
  inventoryPollingIds: number[] = [];
  isPositionMassMutationLoading: boolean = false;
  isCalculatingPrices: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  toggleCategory(key: string) {
    if (this.isCollapsedCategory(key)) {
      this.collapsedCategoryKeys = this.collapsedCategoryKeys.filter((x) => x !== key);
    } else {
      this.collapsedCategoryKeys.push(key);
    }
  }

  showChangeNameInventoryNameDialog() {
    this.inventoryNameDialogType = InventoryStore.INVENTORYNAMEDIALOGTYPE_CHANGENAME;
    this.isInventoryNameDialogShown = true;
  }

  showImportFileDialog(show: boolean) {
    this.showImportFileDialogOpen = show;
  }

  get filteredCategories() {
    if (!this.inventory) {
      return [];
    }

    return createCategories(this.inventory.positions, this.inventorySortSpec.groupBy, this.inventorySortSpec.sortBy);
  }

  get items(): InventoryItem[] {
    const items: InventoryItem[] = [];

    const hasFilterTerm = !!this.filterTerm && this.filterTerm.length > 0;
    for (const category of this.filteredCategories) {
      items.push(category);

      if (!this.isCollapsedCategory(category.name)) {
        for (const item of category.items) {
          if (hasFilterTerm) {
            if (item.articleText && (item.articleText.toLowerCase().indexOf(this.filterTerm.toLowerCase()) !== -1 || (item.articleNumber && item.articleNumber.toLowerCase().indexOf(this.filterTerm.toLowerCase()) !== -1))) {
              items.push(item);
            }
          } else {
            items.push(item);
          }
        }
      }
    }

    return items;
  }

  isCollapsedCategory(key: string) {
    return this.collapsedCategoryKeys.indexOf(key) > -1;
  }

  fetchInventory(id: number) {
    executeAsyncAction(
      async () => {
        this.isLoading = true;
        const dto = await InventoryService.fetchInventory(id);

        const inventory = InventoryDetail.createFromInventoryDetailDto(this, dto);

        runInAction(() => {
          this.inventory = inventory;
        });

        if (this.inventory?.isCalculationInProgress) {
          this.startInventoryPollingIfPriceCalculationIsRunning(this.inventory.id);
        }
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.isLoading = false;
          });
        }
      }
    );
  }

  private stopPolling(inventoryId: number) {
    this.inventoryPollingIds = [...this.inventoryPollingIds.filter((x) => x !== inventoryId)];
  }

  private get isPolling(): boolean {
    return this.inventoryPollingIds.length > 0;
  }

  private startInventoryPollingIfPriceCalculationIsRunning(inventoryId: number) {
    if (this.inventoryPollingIds.findIndex((x) => inventoryId === x) === -1) {
      this.inventoryPollingIds.push(inventoryId);

      this.poll();
    }
  }

  poll() {
    executeAsyncAction(
      async () => {
        if (this.isPolling) {
          const inventoryLoaders: Promise<IWebInventoryDetailData>[] = [];

          this.inventoryPollingIds.forEach((id) => {
            inventoryLoaders.push(InventoryService.fetchInventoryForPolling(id));
          });

          const results = await Promise.all(inventoryLoaders);
          results.forEach((dto) => {
            const inventory = InventoryDetail.createFromInventoryDetailDto(this, dto);
            if (this.inventory && this.inventory.id === inventory.id) {
              this.inventory.updateFromInventoryDetailDto(dto);
            }

            if (!inventory.isCalculationInProgress) {
              this.stopPolling(inventory.id);
              notifications.emit('success', i18n.t("Die Preisberechnung für das Inventar '{{inventoryName}}' ist abgeschlossen", { inventoryName: inventory.name }));
            }
          });
        }
      },
      false,
      {
        finallyAction: () => {
          if (this.isPolling) {
            setTimeout(() => this.poll(), 10000);
          }
        }
      }
    );
  }

  removePosition() {
    if (!this.itemToRemoveId) {
      return;
    }

    const positionId = this.itemToRemoveId;
    executeAsyncAction(async () => {
      await InventoryService.removePosition(positionId);

      runInAction(() => {
        this.inventory!.removePosition(positionId);
      });
    });
  }

  updatePosition(positionUpdate: { id: number; quantity: number; rating: number; isManualPrice: boolean; price: number }) {
    const index = this.inventory!.positions.findIndex((pos: InventoryPosition) => pos.id === positionUpdate.id);
    if (index >= 0) {
      this.inventory!.positions[index].positionUpdate(positionUpdate);
    }

    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        await InventoryService.updatePositionQuantity(positionUpdate);
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  createInventory(name: string) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const dto = await InventoryService.saveInventory(name);
        const inventory = InventoryDetail.createFromInventoryDto(this, dto);

        runInAction(() => {
          this.inventory = inventory;
        });

        if (this.inventory) {
          rootStore.router.navigate('/inventory/' + this.inventory.id);
        }
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  changeNameOfInventory(id: any, name: string) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const inventoryDto = await InventoryService.changeNameOfInventory(id, name);

        runInAction(() => {
          this.inventory!.updateFromInventoryDto(inventoryDto);
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  createInventoryFromSalesOrderSet(name: string) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const dto = await InventoryService.createInventoryFromSalesOrderSet(name);
        const inventory = InventoryDetail.createFromInventoryDto(this, dto);

        runInAction(() => {
          this.inventory = inventory;
        });

        if (this.inventory) {
          rootStore.router.navigate('/inventory/' + this.inventory.id);
        }
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  importCSVIntoInventory(inventoryId: any, data: any) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;

        const importResult = await InventoryService.importCSVIntoInventory(inventoryId, data);

        runInAction(() => {
          this.importFileFeedbackTitle = importResult.message;
          this.importFileFeedbackMessage = formatServerMessage(importResult.additionalInfo);
          this.showImportFileFeedbackDialog = true;
          this.inventory!.updateFromInventoryDetailDto(importResult.result);
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  closeInventory(id: any) {
    executeAsyncAction(async () => {
      const dto = await InventoryService.closeInventory(id);
      this.inventory!.updateFromInventoryDto(dto);
    });
  }

  calculatePrices(id: number) {
    executeAsyncAction(
      async () => {
        this.isCalculatingPrices = true;
        const dto = await InventoryService.calculatePrices(id);
        this.inventory!.updateFromInventoryDetailDto(dto);
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.isCalculatingPrices = false;
          });
        }
      }
    );
  }

  updatePositionsRating(positionIds: any[], rating: any) {
    executeAsyncAction(
      async () => {
        this.isPositionMassMutationLoading = true;

        const dto = await InventoryService.updatePositions(positionIds, rating);
        this.inventory!.updateFromInventoryDetailDto(dto);
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.isPositionMassMutationLoading = false;
          });
        }
      }
    );
  }

  clearCurrentInventory() {
    this.inventory = undefined;
  }

  setItemToRemoveId(id: number) {
    this.itemToRemoveId = id;
  }

  resetPositionToRemove() {
    this.itemToRemoveId = undefined;
  }

  setInventorySortSpec(sortSpec: any) {
    this.inventorySortSpec = sortSpec;
  }

  setFilterTerm(term: string) {
    this.filterTerm = term;
  }

  closeInventoryNameDialog() {
    this.isInventoryNameDialogShown = false;
  }

  closeImportFileFeedbackDialog() {
    this.showImportFileFeedbackDialog = false;
    this.importFileFeedbackTitle = '';
    this.importFileFeedbackMessage = [];
  }

  setShowSureToCloseDialog(show: boolean) {
    this.showSureToCloseDialog = show;
  }

  setShowSureToCalcPricesDialog(show: boolean) {
    this.showSureToCalcPriceDialog = show;
  }

  get removePositionMessage(): string {
    if (!this.inventory || !this.inventory.positions) {
      return '';
    }

    const position = this.inventory.positions.find((x) => x.id === this.itemToRemoveId);

    if (!position) {
      return '';
    } else {
      return i18n.t("Sind Sie sicher, dass Sie die Inventarposition '{{0}}' wirklich löschen möchten?", { 0: position!.articleText });
    }
  }

  get inProgress() {
    return this.pendingRequestsCount > 0;
  }
}
