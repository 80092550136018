import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import { OverridableStringUnion } from '@mui/types';
import { ButtonPropsColorOverrides } from '@mui/material/Button/Button';
import { SxProps } from '@mui/system';

interface IProgressButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  inProgress?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?: 'text' | 'outlined' | 'contained';
  color?: OverridableStringUnion<'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning', ButtonPropsColorOverrides>;
  text: string;
  progressSize?: number;
  progressColor?: 'primary' | 'secondary' | 'inherit';
  className?: string;
  id?: string;
  sx?: SxProps;
}

export const ProgressButton = ({ onClick, disabled, inProgress, size, variant, color, text, progressColor, className, progressSize, id, sx }: IProgressButtonProps) => {
  return (
    <Button sx={sx} className={className} size={size ? size : 'medium'} variant={variant ? variant : 'contained'} color={color ? color : 'primary'} onClick={onClick} disabled={disabled || inProgress} id={id}>
      {inProgress && <CircularProgress size={progressSize ? progressSize : 32} color={progressColor ? progressColor : 'primary'} sx={{ mr: 1 }} />}
      {text}
    </Button>
  );
};
