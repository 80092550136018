import { useSearchParams, useResolvedPath, useHref } from 'react-router-dom';

export const MODAL_ARTICLE_DETAILS_PARAM = 'modal-article-details';

export function useModalArticleDetailDialogControl() {
  const [_, setSearchParams] = useSearchParams();

  function showArticleDetailsDialog(articleNumber: string) {
    setSearchParams((searchParams) => {
      searchParams.set(MODAL_ARTICLE_DETAILS_PARAM, articleNumber);
      return searchParams;
    });
  }

  function closeArticleDetailsDialog() {
    setSearchParams((params) => {
      params.delete(MODAL_ARTICLE_DETAILS_PARAM);
      return params;
    });
  }

  return { showArticleDetailsDialog, closeArticleDetailsDialog };
}

export function useModalArticleDetailDialogArticle() {
  const [searchParams] = useSearchParams();
  const articleNumberFromParams = searchParams.get(MODAL_ARTICLE_DETAILS_PARAM);
  const modalDialogArticleNumber = articleNumberFromParams ?? undefined;
  return { modalDialogArticleNumber };
}

export function useCurrentPathWithModalArticleDetailsDialog(articleNumber: string | undefined) {
  const path = useResolvedPath('.');
  const pathWithModalArticleDetails = usePathWithModalArticleDetailsDialog(path.pathname, articleNumber);
  return pathWithModalArticleDetails;
}

export function usePathWithModalArticleDetailsDialog(path: string, articleNumber: string | undefined) {
  const [searchParams] = useSearchParams();
  if (articleNumber) {
    searchParams.set(MODAL_ARTICLE_DETAILS_PARAM, articleNumber);
  }

  const pathWithModalArticleDetails = `${path}?${searchParams.toString()}`;
  return pathWithModalArticleDetails;
}
