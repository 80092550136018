import { Article } from '../models/article';
import { useTranslation } from 'react-i18next';

export function useOrderEndTimesShortText(article: Article) {
  const { t } = useTranslation();
  let orderEndTimesText = '';
  if (article.articleOrZzArticle.orderEndTimes) {
    if (article.articleOrZzArticle.orderEndTimes[0] !== '0') {
      orderEndTimesText += `${t('Mo-Fr')} ${article.articleOrZzArticle.orderEndTimes[0]}:00`;
    }

    if (article.articleOrZzArticle.orderEndTimes[5] !== '0') {
      orderEndTimesText += ', ' + t('Sa') + ' ' + article.articleOrZzArticle.orderEndTimes[5] + ':00';
    }
  }
  return orderEndTimesText;
}

export function useOrderEndTimesLongText(article: Article) {
  const { t } = useTranslation();
  const orderEndTimesShortText = useOrderEndTimesShortText(article);
  let orderEndTimesText = '';
  if (article.articleOrZzArticle.orderEndTimes) {
    orderEndTimesText = `${t('Bestellschlusszeit')}: ${orderEndTimesShortText}`;
  }
  return orderEndTimesText;
}
