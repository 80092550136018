import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import { StoreContext } from '../../../StoreContext';
import { TemplateNameForm } from './TemplateNameForm';

const TemplateNameDialog = observer(() => {
  const { templateStore } = useContext(StoreContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog open={templateStore.isTemplateNameDialogShown} fullScreen={fullScreen} fullWidth={true}>
      <DialogTitle>{t('Neue Vorlage erstellen')}</DialogTitle>
      <DialogContent>
        <TemplateNameForm />
      </DialogContent>
    </Dialog>
  );
});

export default TemplateNameDialog;
