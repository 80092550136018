import { makeAutoObservable, runInAction } from 'mobx';
import RootStore from '../../../rootStore';
import { shipmentSortBy } from '../models/ShipmentConstants';
import ShipmentService from '../services/shipmentService';
import { Shipment } from './models/shipment';
import { ShipmentFilter } from './models/shipmentFilter';
import { executeAsyncAction } from '../../../util/exceptionHandler';

export default class ShipmentStore {
  rootStore: RootStore;
  shipments: Shipment[] = [];
  pendingRequestsCount = 0;
  filter: ShipmentFilter;
  start: number = 0;
  rows: number = 20;
  sortBy: string = shipmentSortBy.SHIPMENTNR;
  sortByDesc: boolean = true;
  nextPageLoading: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.filter = new ShipmentFilter();
  }

  async fetchShipments() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        this.start = 0;

        const shipments = await ShipmentService.fetchShipments(this.filter.fromValue, this.filter.tillValue, this.sortBy, this.sortByDesc, this.start, this.rows);
        if (shipments) {
          runInAction(() => {
            this.shipments = shipments.map((s) => new Shipment(s));
          });
        }
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  fetchMore() {
    executeAsyncAction(
      async () => {
        this.nextPageLoading = true;
        this.start = this.start + 20;
        const invoices = await ShipmentService.fetchShipments(this.filter.fromValue, this.filter.tillValue, this.sortBy, this.sortByDesc, this.start, this.rows);
        runInAction(() => {
          invoices.forEach((s) => {
            this.shipments.push(new Shipment(s));
          });
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.nextPageLoading = false;
          });
        }
      }
    );
  }

  updateFilter(data: { from?: Date; till?: Date }) {
    if (data.from) {
      this.filter.from = data.from;
    }

    if (data.till) {
      this.filter.till = data.till;
    }
  }

  toggleDatePickerFrom() {
    this.filter.togglePickerFrom();
  }

  toggleDatePickerTill() {
    this.filter.togglePickerTill();
  }

  updateSortCriteria(sortBy: string, sortDesc: boolean) {
    this.sortBy = sortBy;
    this.sortByDesc = sortDesc;

    this.fetchShipments();
  }

  isRowLoaded(index: any) {
    return this.shipments.length > index;
  }

  get isLoading() {
    return this.pendingRequestsCount > 0;
  }
}
