import { observer } from 'mobx-react-lite';
import { Box, Dialog, DialogContent, DialogTitle, Tab, useMediaQuery, useTheme } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../StoreContext';
import { TabList, TabPanel } from '@mui/lab';
import { TabContext } from '@mui/lab';
import { AddToTemplateForm } from './AddToTemplateForm';
import { TemplateNameForm } from './TemplateNameForm';

export const TemplateArticleActionsDialog = observer(() => {
  const { t } = useTranslation();
  const { templateStore } = useContext(StoreContext);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [tabIdx, setTabIdx] = useState('add');

  const handleOnEnter = () => {
    templateStore.setDefaultTemplate();
    if (templateStore.templates.length < 1 || !templateStore.templateArticleToAdd) {
      setTabIdx('new');
    }
  };

  const handleOnExit = () => {
    templateStore.closeTemplateArticleDialog();
    setTabIdx('add');
  };

  const handleTabChange = (event: React.SyntheticEvent, newVal: string) => {
    setTabIdx(newVal);
  };

  return (
    <Dialog
      open={templateStore.isTemplateArticleDialogShown}
      fullScreen={fullScreen}
      fullWidth={true}
      TransitionProps={{
        onEnter: handleOnEnter,
        onExit: handleOnExit
      }}
    >
      <DialogTitle>{t('Vorlagen')}</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tabIdx}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleTabChange}>
                {templateStore.templates.length > 0 && templateStore.templateArticleToAdd && <Tab label={t('Zu Vorlage hinzufügen')} value="add" />}
                <Tab label={t('Neue Vorlage erstellen')} value="new" />
              </TabList>
            </Box>
            <TabPanel value="add">
              <AddToTemplateForm />
            </TabPanel>
            <TabPanel value="new">
              <TemplateNameForm />
            </TabPanel>
          </TabContext>
        </Box>
      </DialogContent>
    </Dialog>
  );
});
