import type { ICustomerWebshopSettingData, IWebshopAuthenticationDTOData } from '../../../api';
import axios from '../../../util/axios';
import env from '../../../util/environment';
import { ppConstants } from '../../../util/ppConstants';

const UserService = {
  async initWebshopConversation(backendParams: { isOciEntry: boolean }) {
    // this is the initial request from the webshop client in the SSO Login scenario
    const response = await axios.get<IWebshopAuthenticationDTOData>(ppConstants.API_HOST_SEC + 'auth/init', { params: backendParams });
    return response.data;
  },

  async performServerLogout(): Promise<any> {
    const axiosInstance = axios.create(); // we don't want to show an error if there is a problem
    return axiosInstance
      .post(ppConstants.API_HOST_PUB + 'logout', {}) // note: empty payload needed for IE ...
      .catch((error: any) => {
        console.error('Server log-out failed!', error);
      });
  },

  async saveWebshopSetting(setting: string, value: any): Promise<ICustomerWebshopSettingData[]> {
    const config = {
      params: {
        setting,
        value
      }
    };

    const result = await axios.put(ppConstants.API_HOST_SEC + 'customers/current/settings', {}, config);
    return result.data;
  },

  async fetchDeliveryCalendar(weekOffset: any): Promise<any> {
    const response = await axios.get(ppConstants.API_HOST_SEC + 'customers/deliverycalendar', {
      params: { weekOffset }
    });
    return { weekOffset, deliveryDates: response.data.deliveryDates };
  },

  async fetchLedgerAccounts(): Promise<any> {
    const response = await axios.get(ppConstants.API_HOST_SEC + 'customers/current/ledgeraccounts');
    return response.data;
  },

  async requestForgotPassword(username: string): Promise<any> {
    const instance = axios.create();
    const response = await instance.post(ppConstants.API_HOST_PUB + 'login/requestpasswordreset', username, { headers: { 'Content-Type': 'text/plain' } });

    return response.data;
  },

  invalidateSessionToken() {
    if (typeof window !== 'undefined') {
      env.cookie = 'JSESSIONID=invalid;Path=/pp;'; // just in case the logout-request did not succeed, and the session is still active on the server ...
    }
  }
};

export default UserService;
