import { Theme } from '@mui/material';
import transgourmetConfig from './transgourmet/transgourmetConfig';

export interface IFooterLink {
  labelKey: string;
  href: string | (() => string);
}

export interface IWebshopConfiguration {
  name: string;
  theme: Theme;
  logo: any;
  footerLinks: IFooterLink[];
}

function loadConfig(): IWebshopConfiguration {
  return transgourmetConfig;
}

const webshopConfig = loadConfig();

export default webshopConfig;
