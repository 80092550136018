export const orderSortBy = {
  CREATIONDATE: 'CREATIONDATE',
  CUSTOMERNUMBER: 'CUSTOMERNUMBER',
  ORDERID: 'ORDERID',
  DELIVERYDATE: 'DELIVERYDATE',
  ORDERSTATE: 'ORDERSTATE',
  CREATIONSYSTEM: 'CREATIONSYSTEM',
  ORDERTYPE: 'ORDERTYPE',
  USERNAME: 'USERNAME',
  NUMBEROFPOSITIONS: 'NUMBEROFPOSITIONS',
  ORDERVALUE: 'ORDERVALUE'
};
