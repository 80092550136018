import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemIcon, ListItemText, Theme } from '@mui/material';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { StoreContext } from '../../../../../StoreContext';
import { observer } from 'mobx-react-lite';
import PubliIcon from 'mdi-material-ui/Bullhorn';
import OpenInNewIcon from 'mdi-material-ui/OpenInNew';
import Divider from '@mui/material/Divider';
import find from 'lodash/find';
import { getLanguageId } from '../../../../../util/i18n';
import { WineSociety } from '../../../models/wineSociety';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { toBase64 } from '../../../../catalog/util/catalogUtils';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles()(({ spacing }: Theme) => {
  return {
    area: {
      margin: spacing(2),
      minWidth: '200px'
    },
    externalLinkItem: {
      '&:hover': {
        textDecoration: 'none'
      }
    },
    filterItemText: {
      paddingLeft: 0
    },
    menuItem: {},
    icon: {
      marginRight: spacing(2)
    }
  };
});

interface ICatalogContent {
  onClose: (event: any) => void;
}

type Props = ICatalogContent;
type Facet = { name: string; values: string[] };

export default observer(({ onClose }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userStore, catalogStore, masterDataStore } = useContext(StoreContext);
  const { classes } = useStyles();

  const loadFacets = (facets: Facet[], sortBy?: any) => {
    if (sortBy) {
      catalogStore.updateSortBy(sortBy);
    }
    navigate(`/catalog?f=${toBase64(facets)}`);
  };
  const showAll = () => {
    const facets: Facet[] = [];
    loadFacets(facets, 'hwgId');
  };

  const showNovelties = () => {
    const facets: Facet[] = [{ name: 'noveltyUntil', values: ['Neuheit'] }];
    loadFacets(facets, 'hwgId');
  };

  const showActions = () => {
    const facets: Facet[] = [{ name: 'actionPrice', values: ['Aktion'] }];
    loadFacets(facets, 'hwgId');
  };

  const showPubli = (publi: any) => {
    const facets: Facet[] = [{ name: 'publis', values: [publi] }];
    loadFacets(facets, 'hwgId');
  };

  const showProfitPlusSupplierArticles = (groupName: any) => {
    const facets: Facet[] = [{ name: 'supplierGroupName', values: [groupName] }];
    loadFacets(facets);
  };

  const close = (event: any) => {
    onClose && onClose(event);
  };

  const tgsBrands: { title: string; url: string }[] = useMemo(
    () => [
      {
        title: 'Premium',
        url: '/catalog?f=W3sibmFtZSI6ImJyYW5kIiwidmFsdWVzIjpbIlByZW1pdW0iXX1d'
      },
      {
        title: 'Quality',
        url: '/catalog?f=W3sibmFtZSI6ImJyYW5kIiwidmFsdWVzIjpbIlF1YWxpdHkiXX1d'
      },
      {
        title: 'Economy',
        url: '/catalog?f=W3sibmFtZSI6ImJyYW5kIiwidmFsdWVzIjpbIkVjb25vbXkiXX1d'
      },
      {
        title: 'Origine',
        url: '/catalog?f=W3sibmFtZSI6ImJyYW5kIiwidmFsdWVzIjpbIk9yaWdpbmUiXX1d'
      },
      {
        title: 'Natura',
        url: '/catalog?f=W3sibmFtZSI6ImJyYW5kIiwidmFsdWVzIjpbIk5hdHVyYSJdfV0='
      }
    ],
    []
  );

  return (
    <Grid container>
      <Grid item className={classes.area}>
        <Typography variant={'h6'}>{t('Aktuell')}</Typography>
        <MenuList>
          <MenuItem
            className={classes.menuItem}
            onClick={(event) => {
              close(event);
              showAll();
            }}
            id={'catalognav-full-catalog-button'}
          >
            <ListItemText className={classes.filterItemText} primary={t('Gesamter Katalog')} />
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            onClick={(event) => {
              close(event);
              showNovelties();
            }}
            id={'catalognav-novelties-button'}
          >
            <ListItemText className={classes.filterItemText} primary={t('Neuheiten')} />
          </MenuItem>
          {userStore.actionExclude ? null : (
            <MenuItem
              className={classes.menuItem}
              onClick={(event) => {
                close(event);
                showActions();
              }}
              id={'catalognav-action-button'}
            >
              <ListItemText className={classes.filterItemText} primary={t('Aktionen')} />
            </MenuItem>
          )}
        </MenuList>
        <Typography variant={'h6'}>{t('Eigenmarken')}</Typography>
        <MenuList>
          {tgsBrands.map((brand) => (
            <MenuItem
              className={classes.menuItem}
              onClick={(event) => {
                close(event);
                navigate(brand.url);
              }}
              key={brand.title}
              id={'catalognav-brand-' + brand.title + '-button'}
            >
              <ListItemText primary={brand.title} />
            </MenuItem>
          ))}
        </MenuList>
      </Grid>
      <Grid item className={classes.area}>
        <Typography variant={'h6'}>{t('Sonstige')}</Typography>
        <MenuList>
          {masterDataStore.filteredWineSocieties.map((item: WineSociety, index: number) => {
            return (
              <Link key={item.name + index} href={item.link} color={'textPrimary'} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} className={classes.externalLinkItem}>
                <MenuItem
                  className={classes.menuItem}
                  onClick={(event) => {
                    close(event);
                  }}
                  id={'catalognav-winesocieties-' + index + '-button'}
                >
                  <ListItemText primary={item.name} />
                  <ListItemIcon>
                    <OpenInNewIcon fontSize={'small'} />
                  </ListItemIcon>
                </MenuItem>
              </Link>
            );
          })}
          {masterDataStore.filteredWineSocieties.length > 0 && <Divider />}
          {masterDataStore.profitPlusSuppliers.map((item, index: number) => {
            return (
              <MenuItem
                className={classes.menuItem}
                onClick={(event) => {
                  close(event);
                  showProfitPlusSupplierArticles(item.name);
                }}
                key={item.supplierGroupId}
                id={'catalognav-profit-plus-' + index + '-button'}
              >
                <ListItemText primary={item.name} />
              </MenuItem>
            );
          })}
          <Divider />
          <MenuItem
            className={classes.menuItem}
            onClick={(event) => {
              close(event);
              navigate('/care');
            }}
            id={'catalognav-care-button'}
          >
            <ListItemText primary={t('Care Sortiment')} />
          </MenuItem>
        </MenuList>
      </Grid>
      {masterDataStore.publis.length > 0 && (
        <Grid item className={classes.area}>
          <Typography variant={'h6'}>{t('Publikationen')}</Typography>
          <MenuList>
            {masterDataStore.publis.map((publi, index) => {
              return (
                <MenuItem
                  className={classes.menuItem}
                  key={index}
                  onClick={(event) => {
                    close(event);
                    showPubli(publi);
                  }}
                  id={'catalognav-publis-' + index + '-button'}
                >
                  <ListItemIcon>
                    <PubliIcon />
                  </ListItemIcon>
                  <ListItemText primary={publi} />
                </MenuItem>
              );
            })}
          </MenuList>
        </Grid>
      )}
      <Grid item className={classes.area}>
        <Typography variant={'h6'}>{t('Hauptwarengruppen')}</Typography>
        <MenuList>
          {masterDataStore.getHwgsByCustomer.map((item, i) => {
            if (item.id < 12) {
              const itemText = find(item.texts, (txt) => {
                return txt.languageId === getLanguageId();
              });

              if (itemText) {
                return (
                  <MenuItem
                    className={classes.menuItem}
                    onClick={(event) => {
                      close(event);
                      navigate('/hwg/' + item.id);
                    }}
                    key={i}
                    id={'catalognav-hwg-' + item.id + '-button'}
                  >
                    <ListItemText primary={itemText.name} />
                  </MenuItem>
                );
              }

              return null;
            } else {
              return null;
            }
          })}
        </MenuList>
      </Grid>
    </Grid>
  );
});
