import { ppConstants } from '../../../../util/ppConstants';
import CartService from '../../services/cartService';
import CartStore from '../../stores/cartStore';
import { IntentServerState } from '../../types/IntentServerState';
import AbstractOrderIntent from './AbstractOrderIntent';
import { ICartResponseData } from '../../../../api';
import { sendPurchaseToGoogleAnalytics } from '../../../../tracking/googleAnalytics';

export default class SendOrderIntent extends AbstractOrderIntent {
  private readonly reference: string;
  private readonly remark: string;

  constructor(cartStore: CartStore, reference = '', remark = '') {
    super(cartStore);
    this.reference = reference;
    this.remark = remark;
  }

  public isPending(intentSpec: any) {
    return super.isPending(intentSpec);
  }

  public async internalOrderIntentProcess(resolve: () => Promise<void>, reject: (reason?: any) => Promise<void>): Promise<any> {
    switch (this.internalState.state) {
      case IntentServerState.INITIAL: {
        await this.handleInit();
        break;
      }
      case IntentServerState.SERVER_RESPONSE_CUSTOMER_ORDER: {
        await this.handleCustomerOrder(resolve, reject);
        break;
      }
    }
  }

  private async handleInit(): Promise<any> {
    await this.handleServiceCallResponse(CartService.completeOrder(this.cartStore.order!.id, this.reference, this.remark), IntentServerState.SERVER_RESPONSE_CUSTOMER_ORDER);
    if (this.cartStore.order) {
      sendPurchaseToGoogleAnalytics(this.cartStore.order);
    }
  }

  private async handleCustomerOrder(resolve: () => Promise<void>, reject: (reason?: any) => Promise<void>): Promise<any> {
    const response = this.internalState.serverResponse!;

    const responseData = response.data as ICartResponseData;
    if (responseData.result.mostImportantMessage) {
      await this.userPrompter.userAlertRequest(responseData.result.mostImportantMessage);

      if (responseData.customerOrder) {
        const cartDiscountInfo = responseData.cartDiscountInfo;
        this.cartStore.orderReceived(responseData.customerOrder, cartDiscountInfo);
      }
      return reject('Alert');
    } else if (
      responseData.customerOrder &&
      !(responseData.customerOrder.state === 'APPROVED' || responseData.customerOrder.state === 'BLOCKED_WITH_REMARKS') &&
      !(responseData.customerOrder.creationSystem === ppConstants.CREATIONSYSTEM_OCI && responseData.customerOrder.state === 'CANCELED') // Im OCI-Workflow wird der Auftrag auf den Status 'CANCELLED' gesetzt, wenn er 'abgeschickt' wird
    ) {
      await this.userPrompter.userAlertRequest({
        message: ppConstants.DEFAULT_FAILED_ORDER_SENT_MESSAGE,
        additionalInfo: ppConstants.DEFAULT_FAILED_ORDER_SENT_MESSAGE,
        level: null as any,
        source: null as any,
        throwable: {} as any,
        timestamp: null as any
      });
      const cartDiscountInfo = responseData.cartDiscountInfo;
      this.cartStore.orderReceived(responseData.customerOrder, cartDiscountInfo);
      return reject('Alert');
    } else {
      if (this.cartStore.order!.creationSystem === ppConstants.CREATIONSYSTEM_OCI) {
        const ociUrl = responseData.ociCustomerOrderMessage.callbackUrl;
        const data = responseData.ociCustomerOrderMessage.data;

        this.cartStore.ociOrderSent(ociUrl, data); // this will clear remove the current order ... the user will just have one chance to submit the order via OCI ...
      } else {
        this.cartStore.orderSent();
      }
      resolve();
    }
  }
}
