import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import { Article } from '../../../shared/articles/models/article';
import { observer } from 'mobx-react-lite';
import { useOrderEndTimesShortText } from '../../../shared/articles/util/useOrderEndTimes';

export interface IArticleDetailOrderEndTimesProps {
  article: Article;
}

const ArticleDetailOrderEndTimes = observer(({ article }: IArticleDetailOrderEndTimesProps) => {
  const { t } = useTranslation();
  const orderEndTimesText = useOrderEndTimesShortText(article);

  if (!article.articleOrZzArticle.orderEndTimes || article.articleOrZzArticle.orderEndTimes.length != 7) {
    return <></>;
  }

  return (
    <>
      <Grid container alignItems={'center'} sx={{ minHeight: '60', alignItems: 'center', margin: 0 }} direction={'row'} spacing={1}>
        <Grid item sx={{ width: '200px', minWidth: '200px' }}>
          <Typography variant={'subtitle2'}> {t('Bestellschlusszeit')}</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'body2'}>{orderEndTimesText}</Typography>
        </Grid>
      </Grid>
    </>
  );
});

export default ArticleDetailOrderEndTimes;
