import React from 'react';
import { useTranslation } from 'react-i18next';
import { Article } from '../../models/article';
import OutboundLink from '../../../components/OutboundLink';
import { mapEcoScoreLabelToImageUrl } from '../../../../../util/ecoscore';

type EcoScoreProps = {
  article: Article;
};

export function EcoScore({ article }: EcoScoreProps) {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        rowGap: 8
      }}
    >
      <img src={mapEcoScoreLabelToImageUrl(article.ecoscoreLabel ?? '')} alt={`ECO-SCORE ${article.ecoscoreLabel}`} style={{ height: 56 }} />
      {article.sustainabilityDatasheetUrl && (
        <div>
          <OutboundLink target="_blank" href={article.sustainabilityDatasheetUrl}>
            {t('Details ansehen')}
          </OutboundLink>
        </div>
      )}
    </div>
  );
}
