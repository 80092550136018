import { ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../../../StoreContext';
import NavigationDropDown from '../NavigationDropDown';
import { useNavigate } from 'react-router-dom';
import { useScreenSize } from '../../../hooks/useScreenSize';
import { crop } from '../../../../../util/stringUtil';
import { PPCONTEXT } from '../../../../../util/ppContext';
import ExternalLinkIcon from 'mdi-material-ui/OpenInNew';

export const AccountNav = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userStore } = useContext(StoreContext);
  const { isXLargeScreenDown } = useScreenSize();

  const performLogout = () => {
    userStore.performLogout();
  };

  const performChangeCustomer = () => {
    userStore.performChangeCustomerSSO();
  };

  if (userStore.currentCustomer) {
    const currentCustomer = userStore.currentCustomer;

    const menuText = isXLargeScreenDown ? (
      <Stack direction={'column'}>
        <div>{crop(userStore.currentCustomer.companyName, 20)}</div>
        <div>{userStore.currentCustomer.formattedCustomerNumberWithCategory}</div>
      </Stack>
    ) : (
      <div>
        <div>{crop(`${userStore.currentCustomer.formattedCustomerNumberWithCategory} ${crop(userStore.currentCustomer.companyName, 30)}`, 30)}</div>
        <Typography variant="caption"> {crop(userStore.currentCustomer.displayName, 35)}</Typography>
      </div>
    );

    return (
      <NavigationDropDown
        buttonSx={{ height: 'auto' }}
        title={menuText}
        menu={(close) => (
          <MenuList>
            <MenuItem
              onClick={(event) => {
                navigate('/deliverydays');
                close(event);
              }}
              id={'accountnav-deliverydays-button'}
            >
              <ListItemText primary={t('Liefertage')} />
            </MenuItem>
            {!userStore.invoicesSuppressed && (
              <MenuItem
                onClick={(event) => {
                  navigate('/invoices');
                  close(event);
                }}
                id={'accountnav-invoices-button'}
              >
                <ListItemText primary={t('Rechnungen')} id="Invoices" />
              </MenuItem>
            )}
            <MenuItem
              onClick={(event) => {
                navigate('/shipments');
                close(event);
              }}
              id={'accountnav-shipments-button'}
            >
              <ListItemText primary={t('Lieferscheine')} />
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                navigate('/usersettings');
                close(event);
              }}
              id={'accountnav-usersettings-button'}
            >
              <ListItemText primary={t('Einstellungen')} />
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                close(event);
                window.open(PPCONTEXT.PORTAL_URL, '_blank', 'noopener,noreferrer');
              }}
              id={'accountnav-customerportal-button'}
            >
              <ListItemText primary={t('Kundenportal')} />
              <ListItemIcon>
                <ExternalLinkIcon />
              </ListItemIcon>
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                close(event);
                performLogout();
              }}
              id={'accountnav-logout-button'}
            >
              <ListItemText primary={t('Logout')} />
            </MenuItem>
            <Divider />
            <MenuItem disableRipple disableTouchRipple disabled>
              <ListItemText primary={t('Kundennummer')} secondary={`${currentCustomer.number} ${currentCustomer.displayName}`} />
            </MenuItem>
            {userStore.canChangeUser && (
              <MenuItem
                onClick={(event) => {
                  close(event);
                  performChangeCustomer();
                }}
                id={'accountnav-accountchange-button'}
              >
                <ListItemText primary={t('ändern')} />
              </MenuItem>
            )}
          </MenuList>
        )}
        id="accountNav"
      ></NavigationDropDown>
    );
  } else {
    return <NavigationDropDown id={'login-button'} title={t('Login')} onClick={() => userStore.triggerLoginFlow()} />;
  }
});
