import React, { ReactNode } from 'react';
import { ConfirmationDialog, ConfirmationOptions } from './ConfirmationDialog';

const ConfirmationContext = React.createContext<(options: ConfirmationOptions) => Promise<void>>(Promise.reject);

export const useConfirmation = () => React.useContext(ConfirmationContext);

export const ConfirmationProvider = ({ children }: { children: ReactNode }) => {
  const [confirmationState, setConfirmationState] = React.useState<ConfirmationOptions | null>(null);

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
    reject: () => void;
  }>();

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationContext.Provider value={openConfirmation} children={children} />

      <ConfirmationDialog
        open={Boolean(confirmationState)}
        title={confirmationState?.title ?? ''}
        variant={confirmationState?.variant ?? 'info'}
        description={confirmationState?.description}
        onSubmit={handleSubmit}
        onClose={handleClose}
        content={confirmationState?.content}
        submitLabel={confirmationState?.submitLabel}
      />
    </>
  );
};
