import type { IWineSocietyDTOData } from '../../../api';
import { makeAutoObservable } from 'mobx';
import { getLanguageId } from '../../../util/i18n';

export class WineSociety {
  get show() {
    return !!this.link;
  }

  name: string;
  link = '';
  text = '';

  constructor(dto: IWineSocietyDTOData) {
    makeAutoObservable(this);

    this.name = dto.name;

    const textCurrentLanguage = dto.texts.find((t) => t.languageId === getLanguageId());
    if (textCurrentLanguage) {
      this.link = textCurrentLanguage.link;
      this.text = textCurrentLanguage.text;
    }
  }
}
