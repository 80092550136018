import React from 'react';
import { Badge, CircularProgress, Fade, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import CartIcon from 'mdi-material-ui/Cart';
import { Article } from '../../models/article';
import PlusMinusAmount from '../../../components/PlusMinusAmount';
import SignOkIcon from 'mdi-material-ui/CheckboxMarkedCircle';
import LoginFirstBadge from '../LoginFirstBadge';
import { observer } from 'mobx-react-lite';
import NotInWebshopMessage from './NotInWebshopMessage';
import { makeStyles } from 'tss-react/mui';

interface ArticleDetailAddCartProps {
  article: Article;
  isLoggedIn: boolean;
  addToCart: (article: Article) => void;
  isLoading: (article: Article) => boolean;
  isInCart: (article: Article) => boolean;
  flexWidth?: boolean;
  actionExclude?: boolean;
}

const useStyles = makeStyles()(() => {
  return {
    icon: {
      height: 12,
      width: 12
    }
  };
});

const ArticleDetailAddCart = observer(({ article, addToCart, isInCart, isLoading, isLoggedIn, actionExclude }: ArticleDetailAddCartProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const isArticleInCart = isInCart(article.articleOrZzArticle);

  const handleOnChange = (value: number, articleToChange: Article) => {
    articleToChange.updateAmountProperty(value > 0 ? value : 1);
  };

  const handleAddToCart = async (event: any, art: Article) => {
    event.stopPropagation();
    addToCart(art.articleOrZzArticle);
  };

  return (
    <Fade in={true} timeout={600}>
      <Grid container direction={'column'} alignItems={'center'} justifyContent={'space-between'}>
        {!isLoggedIn && (
          <Grid item>
            <Typography variant="subtitle1" align="center">
              {article.calculatedTotalPrice(actionExclude)}
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Grid container alignItems={'flex-end'} spacing={2}>
            {isLoggedIn && !article.hideCart && (
              <>
                <Grid item>
                  <Grid container direction={'column'}>
                    <Grid item>
                      <Typography variant="subtitle1" align="center" id={'article-detail-totalprice'}>
                        {article.calculatedTotalPrice(actionExclude)}
                      </Typography>
                    </Grid>
                    <Grid item style={{ paddingBottom: 6 }}>
                      <PlusMinusAmount amount={article.amount} onAmountChanged={(e) => handleOnChange(e, article)} onDecreaseIncreaseAmount={(e) => handleOnChange(e, article)} idx={0} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  {isLoading(article.articleOrZzArticle) && <CircularProgress size={32} variant={'indeterminate'} />}
                  {!isLoading(article.articleOrZzArticle) && (
                    <IconButton color="primary" onClick={(e) => handleAddToCart(e, article)} aria-label={t('Hinzufügen')} id={'article-detail-add-to-cart'}>
                      <Badge badgeContent={<SignOkIcon className={classes.icon} fontSize={'small'} />} invisible={!isArticleInCart}>
                        <CartIcon color="primary" />
                      </Badge>
                    </IconButton>
                  )}
                </Grid>
              </>
            )}
            {!isLoggedIn && (
              <Grid item>
                <LoginFirstBadge />
              </Grid>
            )}
            {article.notInWebShop && (
              <Grid item>
                <NotInWebshopMessage />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  );
});

export default ArticleDetailAddCart;
