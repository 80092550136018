import { BASE_PATH } from './urlUtils';

export let ppConstants: {
  ACCESS_TOKEN_MIN_VALIDITY: number;
  API_HOST_PUB: string;
  API_HOST_SEC: string;
  API_HOST_HUB: string;
  ASYNC_LOAD_BATCH_SIZE: number;
  ASYNC_LOAD_PARALLEL_REQUESTS: number;
  CREATIONSYSTEM_OCI: string;
  DEBOUNCE_TIME: number;
  DEFAULT_FAILED_ORDER_SENT_MESSAGE: string;
  INVALID_DELIVERY_DATE: string;
  KEYKCLOAK_ROLE_WEBSHOP_ADMIN: string;
  MAX_DESIRED_QUANTITY: number;
  PRICECODE_WEIGHTCONTROLLED: number;
};

export let PP_URL: {
  BASE_PATH: string;
  CALLBACK: string;
  CALLBACK_URL: string;
  FILTER: string;
  HOOK_URL: string;
  QUERY: string;
  SCAN_ID: string;
  SHOP_URL: string;
  SKIP_NOTIFICATION: string;
  SUBMISSION_ID: string;
  USERNAME: string;
};

export function initPPConstants(ppContext: any) {
  const basePath = BASE_PATH;
  const shopUrl = window.location.origin + basePath;
  console.log('Webshop starting at:', { shopUrl, basePath });

  const BACKEND_URL = ppContext.BACKEND_URL;
  const backendPrefix = BACKEND_URL === undefined || BACKEND_URL.includes('%') ? '/api/webshop' : BACKEND_URL;
  ppConstants = {
    API_HOST_SEC: backendPrefix + '/sec/', // API calls are proxied. In Dev by vite-dev-server, in Prod by the WAF
    API_HOST_PUB: backendPrefix + '/pub/',
    API_HOST_HUB: backendPrefix + '/hub/',

    ACCESS_TOKEN_MIN_VALIDITY: 30,

    DEBOUNCE_TIME: 500,

    PRICECODE_WEIGHTCONTROLLED: 3,

    INVALID_DELIVERY_DATE: 'INVALID_DELIVERY_DATE',

    DEFAULT_FAILED_ORDER_SENT_MESSAGE: 'DEFAULT_FAILED_ORDER_SENT_MESSAGE',

    ASYNC_LOAD_BATCH_SIZE: 5,

    ASYNC_LOAD_PARALLEL_REQUESTS: 4,

    CREATIONSYSTEM_OCI: 'OCI',

    MAX_DESIRED_QUANTITY: 999,

    KEYKCLOAK_ROLE_WEBSHOP_ADMIN: 'WebShopAdmin'
  };

  PP_URL = {
    SHOP_URL: shopUrl,
    BASE_PATH: basePath,
    QUERY: 'q',
    FILTER: 'f',
    SCAN_ID: 'scanId', // Scanpool
    SUBMISSION_ID: 'submissionId', // Optisoft Api
    CALLBACK_URL: 'callbackUrl', // redirects at login/logout
    USERNAME: 'username', // OCI Login Flow
    CALLBACK: 'callback', // OCI Login Flow
    HOOK_URL: 'HOOK_URL', // OCI Login Flow
    SKIP_NOTIFICATION: 'skipNotification' // used for automated tests
  };
}
