import { ExternalArticlePosition } from '../customerOrderStore';
import { CustomerOrderExternalArticlePosition } from './customerOrderExternalArticlePosition';

export class CustomerOrderSupplier {
  id: number;
  name: string;
  articles: ExternalArticlePosition[];

  constructor(id: number, name: string, articles: CustomerOrderExternalArticlePosition[]) {
    this.id = id;
    this.name = name;
    this.articles = articles;
  }
  public get total() {
    let total = 0;
    for (const article of this.articles) {
      total += article.total;
    }
    return total;
  }
}
