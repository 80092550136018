import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Link, useNavigate } from 'react-router-dom';
import { Promotion } from '../common/models/promotion';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { StoreContext } from '../../StoreContext';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { sentryCaptureMessage } from '../../tracking/tracking';

interface IPromotionCarousel {
  promotionId: number | undefined;
}

const PromotionCarousel = observer(({ promotionId }: IPromotionCarousel) => {
  const navigate = useNavigate();
  const { masterDataStore, uiStore } = useContext(StoreContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (promotionId) {
      masterDataStore.fetchPromotionPreview(promotionId);
    } else {
      masterDataStore.fetchPromotions();
    }
  }, [masterDataStore, promotionId]);

  const openPromotion = (promotion: Promotion) => {
    navigate(promotion.promotionUrl);
  };

  let promotions: Promotion[] = [];

  if (promotionId && masterDataStore.promotion) {
    promotions = [masterDataStore.promotion];
  } else if (masterDataStore.promotions && masterDataStore.promotions.length > 0) {
    promotions = masterDataStore.promotions;
  } else {
    return null;
  }

  function onErrorLoadingPromotionImage(promotion: Promotion) {
    masterDataStore.removePromotion(promotion);
    const errorMessage = `Not showing promotion ${promotion.id} - "${promotion.displayName}" => Error loading promotion image: ${promotion.backgroundImageUrl}!`;
    sentryCaptureMessage(errorMessage);
  }

  return (
    <Box sx={{ margin: 'auto', maxWidth: '1920px', minHeight: 300 }}>
      <Carousel autoPlay={!uiStore.isSmallScreenDown} infiniteLoop={true} showStatus={false} interval={5000} showThumbs={false} transitionTime={700} dynamicHeight>
        {promotions.map((promotion: Promotion, idx) => {
          return (
            <div key={promotion.id} onClick={() => openPromotion(promotion)}>
              <img
                src={promotion.backgroundImageUrl}
                style={{
                  width: '100%',
                  maxWidth: '1920px',
                  height: 'auto'
                }}
                alt={promotion.displayName}
                id={'promotion-carousel-image-' + idx}
                onError={() => onErrorLoadingPromotionImage(promotion)}
              />
              {uiStore.isMediumScreenUp && (
                <Grid
                  container
                  justifyContent={'center'}
                  alignItems={'center'}
                  sx={{
                    position: 'absolute',
                    bottom: (theme) => theme.spacing(5),
                    width: '100%'
                  }}
                >
                  <Grid item>
                    <Grid container justifyContent={'center'} alignItems={'center'} direction={'row'} sx={{ backgroundColor: 'white', opacity: 0.9 }}>
                      <Grid item sx={{ p: 1 }}>
                        <Typography variant={'subtitle1'}>{promotion.displayName}</Typography>
                      </Grid>
                      <Grid item sx={{ p: 1 }}>
                        <Button variant={'contained'} color={'primary'} to={promotion.promotionUrl} component={Link} id={'promotion-carousel-button-' + idx}>
                          {t('mehr')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </div>
          );
        })}
      </Carousel>
    </Box>
  );
});

export default PromotionCarousel;
