import React, { useContext, useRef } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import BackIcon from 'mdi-material-ui/ArrowLeft';
import IconButton from '@mui/material/IconButton';
import useSearch from './useSearch';
import { CatalogSearchType, CATALOGSEARCHTYPE_ALLARTICLE } from '../../../../catalog/util/catalogConstants';
import { StoreContext } from '../../../../../StoreContext';
import SearchResults from './SearchResults';
import { Article } from '../../../../shared/articles/models/article';
import SearchTextField from './SearchTextField';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { useModalArticleDetailDialogControl } from '../../../../shared/articles/util/useModalArticleDetailDialog';

interface SearchDialogProps {
  onClose: (searchTerm?: string) => void;
  open: boolean;
  handleToggleFilterSearchType: (search: CatalogSearchType) => void;
}

const SearchDialog = observer(({ onClose, open, handleToggleFilterSearchType }: SearchDialogProps) => {
  const { userStore } = useContext(StoreContext);
  const searchBoxRef = useRef(undefined);

  const { searchTerm, setIsActive, changeSearchTerm, performSearch, suggestions, articles } = useSearch(userStore.isLoggedIn, CATALOGSEARCHTYPE_ALLARTICLE, 3, 100);
  const { showArticleDetailsDialog } = useModalArticleDetailDialogControl();

  const handleArticleClick = (article: Article) => {
    setIsActive(false);
    showArticleDetailsDialog(article.articleNumber);
    onClose();
  };

  const handlePerformSearch = (suggestion?: string) => {
    performSearch(suggestion);
    onClose(suggestion);
  };

  return (
    <Dialog open={open} fullScreen={true}>
      <DialogTitle style={{ padding: 0 }}>
        <Grid container>
          <Grid item>
            <IconButton onClick={() => onClose()}>
              <BackIcon sx={{ mt: 0.5 }} />
            </IconButton>
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <SearchTextField
              ref={searchBoxRef}
              small={true}
              searchTerm={searchTerm}
              onSearchTermChanged={changeSearchTerm}
              performSearch={handlePerformSearch}
              handleToggleFilterSearchType={handleToggleFilterSearchType}
              onFocus={() => setIsActive(true)}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ padding: 0 }}>
        <SearchResults searchTerm={searchTerm} suggestions={suggestions} articles={articles} onArticleClicked={handleArticleClick} onSearchSuggestionClicked={handlePerformSearch} />
      </DialogContent>
    </Dialog>
  );
});

export default SearchDialog;
