import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import ExpandMoreIcon from 'mdi-material-ui/ChevronDown';
import ImportantIcon from 'mdi-material-ui/AlertCircleOutline';
import InfoIcon from 'mdi-material-ui/InformationOutline';
import { ChangeEvent } from 'react';
import { INotification, NotificationType } from './notification';
import Grid from '@mui/material/Grid';
import { Accordion, AccordionDetails, AccordionSummary, Slide, Theme, useMediaQuery, useTheme } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from 'tss-react/mui';

const Transition = React.forwardRef(function Transition(props: TransitionProps & { children: React.ReactElement<any, any> }, ref: React.Ref<unknown>) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles()(({ palette, spacing }: Theme) => {
  return {
    icon: {
      paddingRight: spacing(1),
      color: palette.grey.A700
    },
    importantIcon: {
      paddingRight: spacing(1),
      color: palette.error.main
    }
  };
});

interface INotificationsDialogProps {
  title: string;
  closeText: string;
  open: boolean;
  onClose: () => void;
  notifications?: INotification[];
}

type Props = INotificationsDialogProps;

export const NotificationsDialog = (props: Props) => {
  const { classes } = useStyles();
  const { notifications, open, closeText, onClose, title } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [expanded, setExpanded] = React.useState<string[]>(notifications?.filter((x) => x.type === 'important').map((x) => x.id) ?? []);

  if (!notifications || notifications.length === 0) {
    return null;
  }

  const handleChange =
    (panel: string): any =>
    (event: ChangeEvent, isExpanded: boolean): void => {
      if (isExpanded) {
        setExpanded([...expanded, panel]);
      } else {
        setExpanded(expanded.filter((x) => x !== panel));
      }
    };

  const handleClose = (): void => {
    onClose();
  };

  const getIcon = (type: NotificationType): any => {
    if (type === 'important') {
      return <ImportantIcon fontSize={'large'} className={classes.importantIcon} />;
    } else {
      return <InfoIcon fontSize={'large'} className={classes.icon} />;
    }
  };

  return (
    <Dialog fullScreen={fullScreen} open={open} fullWidth={true} TransitionComponent={Transition} aria-labelledby="notifications-dialog-title">
      <DialogTitle id="notifications-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {notifications?.map((notification: INotification) => (
          <Accordion variant={'outlined'} square key={notification.id} expanded={expanded.findIndex((e) => e === notification.id) > -1} onChange={handleChange(notification.id)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container alignItems={'center'}>
                <Grid item>{getIcon(notification.type)}</Grid>
                <Grid item>
                  <Typography variant={'subtitle1'}>{notification.title}</Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 2 }}>
              <Typography variant={'body1'}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: notification.content
                  }}
                />
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          {closeText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
