import React, { useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import BackIcon from 'mdi-material-ui/ArrowLeft';
import NextIcon from 'mdi-material-ui/ArrowRight';
import Fab from '@mui/material/Fab';
import { SxProps } from '@mui/system';
import Box from '@mui/material/Box';
import Slider from 'react-slick';

interface SwipeableCarouselProps {
  items: any[];
  autoplay?: boolean;
  interval?: number;
  sx?: SxProps;
}

const SwipeableCarousel = ({ sx, items, autoplay = true, interval = 7000, ...other }: SwipeableCarouselProps) => {
  const [activeItem, setActiveItem] = useState<number>(0);
  const sliderRef = useRef<Slider | null>(null);

  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  const handleBack = () => {
    sliderRef.current?.slickPrev();
  };

  if (items.length < 1) {
    return null;
  }

  return (
    <Box sx={{ ...sx, position: 'relative' }} {...other}>
      {activeItem !== 0 && (
        <Fab
          size={'small'}
          color={'secondary'}
          sx={{
            top: 'calc(50% - 15px)',
            position: 'absolute',
            zIndex: 9999
          }}
          onClick={handleBack}
          id={'carousel-prev'}
        >
          <BackIcon />
        </Fab>
      )}
      {activeItem !== items.length - 1 && (
        <Fab
          size={'small'}
          color={'secondary'}
          sx={{
            top: 'calc(50% - 15px)',
            position: 'absolute',
            right: 0,
            zIndex: 9999
          }}
          onClick={handleNext}
          id={'carousel-next'}
        >
          <NextIcon />
        </Fab>
      )}
      <Box sx={{ width: '300px' }}>
        <Slider
          ref={sliderRef}
          dots={true}
          autoplay={autoplay}
          pauseOnHover={true}
          slidesToScroll={1}
          swipeToSlide={true}
          slidesToShow={1}
          autoplaySpeed={interval}
          speed={500}
          infinite={true}
          beforeChange={(current: number, next: number) => setActiveItem(next)}
        >
          {items.map((item: any, index: number) => {
            return (
              <Grid container justifyContent={'center'} key={index}>
                <Grid item>{item}</Grid>
              </Grid>
            );
          })}
        </Slider>
      </Box>
    </Box>
  );
};

export default SwipeableCarousel;
