import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import NoImage from '../../../img/noimage.png';
import { IArticleDto } from '../../../api/hub/hub.models.generated';
import ImageContainer from '../../../modules/common/components/ImageContainer';
import { buildArticleThumbnailUrlFromArticleNumber } from '../../../util/articleImage';

interface ISearchSuggestionItemProps {
  article: IArticleDto;
  showPictures?: boolean;
  onArticleClicked: (article: IArticleDto) => void;
  id: string;
}

/*TODO:
- Display as action style
- Add price
 */

export const QuickSearchArticleItem = observer(({ article, showPictures = true, onArticleClicked, id }: ISearchSuggestionItemProps) => {
  const { t } = useTranslation();

  const handleNavigate = (selectedArticle: IArticleDto) => {
    onArticleClicked(selectedArticle);
  };

  const thumbImageUrl = useMemo(() => {
    const url = buildArticleThumbnailUrlFromArticleNumber(article.articleNumber);
    return url;
  }, [article]);

  return (
    <ListItemButton tabIndex={0} key={article.articleNumber} onClick={() => handleNavigate(article)} sx={{ cursor: 'pointer' }} id={id}>
      <ListItemAvatar style={{ marginRight: 8 }}>
        <>{showPictures ? <ImageContainer src={thumbImageUrl} width={80} height={80} /> : <img src={NoImage} alt={t('Kein Bild')} />}</>
      </ListItemAvatar>
      <ListItemText secondary={article.text} />
      {/*
      <ListItemText secondary={article.articleText} primary={article.formattedPriceArticleUnit} sx={{ color: article.displayAsAction ? 'primary' : 'text.primary' }} />
      */}
    </ListItemButton>
  );
});
