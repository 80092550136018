import { IFactDocumentData } from '../../../../api';
import { makeAutoObservable } from 'mobx';
import { ppConstants } from '../../../../util/ppConstants';

export class FactDocument {
  id: number;
  version: number;
  languageId: number;
  name: string;
  documentName: string;
  url: string;
  internal: boolean;

  constructor(dto: IFactDocumentData) {
    makeAutoObservable(this);

    this.id = dto.id;
    this.version = dto.version;
    this.languageId = dto.languageId ? dto.languageId : 0;
    this.name = dto.name ? dto.name : '';
    this.documentName = dto.documentName ? dto.documentName : '';
    this.url = dto.url ? dto.url : '';
    this.internal = dto.internal;
  }

  get downloadUrl() {
    return ppConstants.API_HOST_PUB + 'articles/factdocuments/' + this.id;
  }
}
