import IntentProcessor from './IntentProcessor';
import { IntentState } from '../../types/IntentState';

export default abstract class BaseIntent {
  public intentState = IntentState.INITIAL;
  public intentPromise: Promise<any>;
  protected intentProcessor: IntentProcessor | undefined;
  private resolve: ((args: any) => any) | undefined;
  private reject: ((args: any) => any) | undefined;

  protected constructor() {
    this.intentPromise = new Promise((resolve, reject) => {
      this.resolve = () => {
        // let me in
        this.intentState = IntentState.DONE;
        resolve(this);
      };
      this.reject = () => {
        // let me out
        this.intentState = IntentState.DONE;
        reject(this);
      };
    });
  }

  public process(intentProcessor: IntentProcessor) {
    this.intentProcessor = intentProcessor;
    this.intentState = IntentState.SCHEDULED;
    if (this.resolve === undefined || this.reject === undefined) {
      console.error('BaseIntent#process: resolve or reject is undefined', this.resolve, this.reject);
    }
    this.internalProcess(this.resolve!, this.reject!);
    return this.intentPromise;
  }

  public isPending(intentSpec: any) {
    return intentSpec.intentType === this.constructor;
  }

  protected abstract internalProcess(resolve: (args?: any) => Promise<void>, reject: (reason?: any) => Promise<void>): any;
}
