import React, { useContext } from 'react';
import find from 'lodash/find';
import { formatDate, formatDateByLanguage, isToday, weekdays } from '../../../util/dateUtils';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LeftIcon from 'mdi-material-ui/ArrowLeft';
import RightIcon from 'mdi-material-ui/ArrowRight';
import { Theme, Typography } from '@mui/material';
import classNames from 'classnames';
import { IDeliveryAssortmentData, IDeliveryDateData } from '../../../api';
import { useTranslation } from 'react-i18next';
import DeliveryDaysLegend from './deliverydays/DeliveryDaysLegend';
import { getContrastTextColorForRgb } from '../../../util/colorUtil';
import { observer } from 'mobx-react-lite';
import { getWeek, getYear } from 'date-fns';
import { StoreContext } from '../../../StoreContext';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(({ spacing, palette }: Theme) => {
  return {
    root: {
      marginTop: spacing(1)
    },
    toolbar: {
      height: spacing(10)
    },
    header: {
      height: spacing(5),
      width: spacing(5),
      backgroundColor: palette.grey['600'],
      color: 'white',
      margin: '2px',
      '@media (max-width:400px)': {
        height: spacing(4),
        width: spacing(4)
      }
    },
    dayCell: {
      height: spacing(5),
      width: spacing(5),
      boxShadow: 'inset 0px 0px 0px 1px ' + palette.grey.A100,
      opacity: 0.7,
      margin: '2px',
      '@media (max-width:400px)': {
        height: spacing(4),
        width: spacing(4)
      },
      '&:hover': {
        cursor: 'not-allowed',
        fontSize: '5em !important'
      }
    },
    calendarWeekCell: {
      backgroundColor: palette.grey['400'],
      height: spacing(5),
      width: spacing(5),
      margin: '2px',
      color: palette.grey.A200,
      '@media (max-width:400px)': {
        height: spacing(4),
        width: spacing(4)
      }
    },
    weekDayFirstDelivery: {
      boxShadow: 'inset 0px 0px 0px 3px red'
    },
    today: {
      boxShadow: 'inset 0px 0px 0px 3px ' + palette.grey.A700
    },
    selectable: {
      opacity: 1,
      '&:hover': {
        cursor: 'pointer',
        opacity: 0.9,
        fontSize: '5em !important'
      }
    },
    selected: {
      fontWeight: 900,
      color: 'white',
      backgroundColor: '#ff002b !important'
    },
    readonly: {
      pointerEvents: 'none'
    }
  };
});

export interface ICalendarProps {
  deliveryDates: IDeliveryDateData[] | undefined;
  onPreviousMonth: () => void;
  onNextMonth: () => void;
  isReadonly?: boolean;
  onDateSelected?: (deliveryDate: IDeliveryDateData) => void;
  selectedDate?: Date;
}

interface DeliveryDate extends IDeliveryDateData {
  isFirstDeliveryDate?: boolean;
}
type CalendarRow = DeliveryDate[];
type CalendarMatrix = CalendarRow[];

function getCalendarData(deliveryDates: DeliveryDate[] | undefined) {
  let calendarRows: CalendarMatrix = [];
  const deliveryAssortments: IDeliveryAssortmentData[] = [];
  let index = 0;
  let i = 0;

  const firstDeliveryDate = find(deliveryDates, (d) => d.selectable);
  if (firstDeliveryDate) {
    firstDeliveryDate.isFirstDeliveryDate = true;
  }

  if (deliveryDates) {
    for (const deliveryDay of deliveryDates) {
      let currentCalendarRow = calendarRows[index];
      if (!currentCalendarRow) {
        currentCalendarRow = [];
        calendarRows[index] = currentCalendarRow;
      }
      currentCalendarRow.push(deliveryDay);

      const legendEntry = {
        r: deliveryDay.r,
        g: deliveryDay.g,
        b: deliveryDay.b
      };
      const isNew = deliveryAssortments.findIndex((entry: { r: number; g: number; b: number }) => entry.r === legendEntry.r && entry.g === legendEntry.g && entry.b === legendEntry.b) === -1;

      if (isNew) {
        if (deliveryDay.deliveryAssortment) {
          deliveryAssortments.push(deliveryDay.deliveryAssortment);
        }
      }

      i++;
      if (i % 7 === 0 && i !== 0) {
        index++;
      }
    }
  } else {
    // Create an empty matrix 6x7 so that the calender is rendered empty when no data is available
    calendarRows = Array(6)
      .fill(null)
      .map(() => Array(7).fill(null));
  }

  return {
    calendarEntries: calendarRows,
    deliveryAssortments: [...deliveryAssortments]
  };
}

function getYearLabel(monthFrom: any, monthTill: any) {
  let yearFromLabel;
  if (monthFrom) {
    yearFromLabel = getYear(monthFrom);
  }

  let yearTillLabel;
  if (monthTill && monthFrom && monthFrom!.getYear() !== monthTill.getYear()) {
    yearTillLabel = getYear(monthTill);
  }

  return `${yearFromLabel ? yearFromLabel : ''} ${yearTillLabel ? '-' : ''} ${yearTillLabel ? yearTillLabel : ''}`;
}

function getMonthLabel(monthFrom: any, monthTill: any, language: string) {
  let monthFromLabel;
  if (monthFrom) {
    monthFromLabel = formatDateByLanguage(monthFrom, language, 'MMMM');
  } else {
    monthFromLabel = '...';
  }

  let monthTillLabel;
  if (monthTill) {
    monthTillLabel = formatDateByLanguage(monthTill, language, 'MMMM');
  }

  return `${monthFromLabel ? monthFromLabel : ''} ${monthTill ? '-' : ''} ${monthTillLabel ? monthTillLabel : ''}`;
}

function fillWeek(monthDate: any, deliveryDates: any, isTillDate: boolean) {
  let dayCount = 1;
  const weekFillDay = {
    date: new Date(),
    r: 189,
    b: 189,
    g: 189,
    selectable: false
  };
  if (isTillDate) {
    for (let i = monthDate.getDay(); i < 7; i++) {
      const tempDate = new Date(monthDate);
      tempDate.setDate(tempDate.getDate() + dayCount);
      weekFillDay.date = tempDate;

      deliveryDates.push(weekFillDay);
      dayCount++;
    }
  } else {
    for (let i = monthDate.getDay(); i > 1; i--) {
      const tempDate = new Date(monthDate);
      tempDate.setDate(tempDate.getDate() - dayCount);
      weekFillDay.date = tempDate;

      deliveryDates.unshift(weekFillDay);
      dayCount++;
    }
  }

  return deliveryDates;
}

function CalendarImpl({ deliveryDates, isReadonly, onPreviousMonth, onNextMonth, selectedDate, onDateSelected }: Readonly<ICalendarProps>) {
  const { uiStore } = useContext(StoreContext);
  const { t } = useTranslation();
  const { classes } = useStyles();

  const monthFrom = deliveryDates && deliveryDates.length > 0 ? deliveryDates[0].date : null;
  const monthTill = deliveryDates && deliveryDates.length > 0 ? deliveryDates[deliveryDates.length - 1].date : null;

  const checkFullWeek = () => {
    if (monthFrom && monthFrom.getDay() !== 1) {
      deliveryDates = fillWeek(monthFrom, deliveryDates, false);
    }
    if (monthTill && monthTill.getDay() !== 0) {
      deliveryDates = fillWeek(monthTill, deliveryDates, true);
    }
  };
  checkFullWeek();
  const { calendarEntries, deliveryAssortments } = getCalendarData(deliveryDates);

  return (
    <Grid container={true} direction={'row'} justifyContent={'center'} className={classes.root}>
      <Grid item={true}>
        <Toolbar className={classes.toolbar}>
          <Grid container={true} justifyContent={'space-between'}>
            <Grid item={true}>
              <IconButton onClick={onPreviousMonth} id={'calendar-arrow-left-button'}>
                <LeftIcon />
              </IconButton>
            </Grid>
            <Grid item={true}>
              <Typography variant={'h5'} align={'center'}>
                {getYearLabel(monthFrom, monthTill)}
              </Typography>
              <Typography variant={'h6'} align={'center'}>
                {getMonthLabel(monthFrom, monthTill, uiStore.language)}
              </Typography>
            </Grid>
            <Grid item={true}>
              <IconButton onClick={onNextMonth} id={'calendar-arrow-right-button'}>
                <RightIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>

        <Grid container={true} justifyContent={'center'} alignContent={'center'} wrap={'nowrap'}>
          <Grid item={true} container={true} justifyContent={'center'} alignContent={'center'} className={classNames(classes.calendarWeekCell)}>
            <Typography variant={'h6'} color={'inherit'}>
              {t('KW')}
            </Typography>
          </Grid>
          {weekdays(uiStore.language).map((x, index) => (
            <Grid key={index} item={true} container={true} justifyContent={'center'} alignContent={'center'} className={classes.header}>
              <Typography variant={'h6'} color={'inherit'}>
                {x.substring(0, 2)}
              </Typography>
            </Grid>
          ))}
        </Grid>

        {calendarEntries.map((row, index: number) => {
          return (
            <Grid container={true} key={index} justifyContent={'center'} alignContent={'center'} wrap={'nowrap'}>
              {row.map((day, dayIndex: number) => {
                const assignedClasses: any[] = [classes.dayCell];

                if (day !== null) {
                  let textColor = getContrastTextColorForRgb({
                    r: day.r,
                    g: day.g,
                    b: day.b
                  });

                  if (isReadonly) {
                    assignedClasses.push(classes.readonly);
                  }

                  if (day.selectable) {
                    assignedClasses.push(classes.selectable);
                  }

                  if (isToday(day.date)) {
                    assignedClasses.push(classes.today);
                  }
                  if (day.isFirstDeliveryDate) {
                    assignedClasses.push(classes.weekDayFirstDelivery);
                  }

                  if (selectedDate && selectedDate.getTime() === day.date.getTime()) {
                    assignedClasses.push(classes.selected);
                    textColor = 'white';
                  }

                  const dayCell = (
                    <Grid
                      item={true}
                      container={true}
                      justifyContent={'center'}
                      alignContent={'center'}
                      className={classNames(...assignedClasses)}
                      style={{
                        backgroundColor: 'rgb(' + day.r + ', ' + day.g + ', ' + day.b + ')'
                      }}
                      onClick={day.selectable ? () => onDateSelected?.(day) : undefined}
                      key={dayIndex}
                      id={'calendar-day-' + dayIndex.toString()}
                    >
                      <Typography variant={'body1'} style={{ color: textColor }}>
                        {formatDate(day.date, 'd')}
                      </Typography>
                    </Grid>
                  );

                  if (dayIndex === 0) {
                    // Nummerierung der Kalender Wochen gemäss ISO8601: https://en.wikipedia.org/wiki/ISO_week_date
                    // => Kalenderwoche '1' ist die Woche mit dem 4. Januar.
                    const weekNumber = getWeek(day.date, {
                      firstWeekContainsDate: 4
                    });
                    return (
                      <React.Fragment key={dayIndex}>
                        <Grid item={true} container={true} justifyContent={'center'} alignContent={'center'} className={classes.calendarWeekCell}>
                          <Typography variant={'body1'} color={'inherit'}>
                            {weekNumber}
                          </Typography>
                        </Grid>
                        {dayCell}
                      </React.Fragment>
                    );
                  } else {
                    return dayCell;
                  }
                } else {
                  // Placeholder while loading data
                  return (
                    <Grid item={true} container={true} justifyContent={'center'} alignContent={'center'} className={classes.dayCell} key={dayIndex}>
                      <Typography variant={'body1'}>...</Typography>
                    </Grid>
                  );
                }
              })}
            </Grid>
          );
        })}
      </Grid>
      <Grid item={true}>
        <DeliveryDaysLegend isReadonly={!!isReadonly} deliveryAssortments={deliveryAssortments} />
      </Grid>
    </Grid>
  );
}
export const Calendar = observer(CalendarImpl);
