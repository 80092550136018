import React from 'react';
import { Theme, Typography } from '@mui/material';
import { NotificationBox } from '../../../../../@tgs-web-ui/notificationbox';
import { Variant } from '@mui/material/styles/createTypography';
import AvailabilityMessageSubstitute from './AvailabilityMessageSubstitute';
import { makeStyles } from 'tss-react/mui';

export interface IAvailabilityMessageProps {
  articleNumber: string;
  messageType: 'notAvailable' | 'temporaryNotAvailable';
  message: string;
  variant?: Variant | 'inherit';
}

export const useStyles = makeStyles()(({ spacing }: Theme) => {
  return {
    root: {
      marginTop: spacing(1),
      display: 'table'
    },
    row: {
      marginBottom: spacing(0.2)
    }
  };
});

export default function AvailabilityMessage({ articleNumber, messageType, message, variant }: Readonly<IAvailabilityMessageProps>) {
  const { classes } = useStyles();

  const articleAddon = (
    <div>
      <Typography className={classes.row} variant={variant ?? 'subtitle2'}>
        {message}
      </Typography>
      <AvailabilityMessageSubstitute articleNumber={articleNumber} variant={variant ?? 'body2'} />
    </div>
  );

  return (
    <div className={classes.root}>
      <NotificationBox infoType={messageType === 'notAvailable' ? 'error' : 'warning'} control={articleAddon} />
    </div>
  );
}
