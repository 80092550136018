import { useMediaQuery, useTheme } from '@mui/material';

export function useScreenSize() {
  const theme = useTheme();
  const isMediumScreenDown = useMediaQuery(theme.breakpoints.down('md'));
  const isLargeScreenDown = useMediaQuery(theme.breakpoints.down('lg'));
  const isXLargeScreenDown = useMediaQuery(theme.breakpoints.down('xl'));
  const isLargeScreenUp = useMediaQuery(theme.breakpoints.up('lg'));

  return { isMediumScreenDown, isLargeScreenDown, isXLargeScreenDown, isLargeScreenUp };
}
