import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from 'mdi-material-ui/Magnify';
import DeleteIcon from 'mdi-material-ui/Close';
import React, { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SxProps } from '@mui/system';
import { useScreenSize } from '../../../modules/common/hooks/useScreenSize';
import { QuickSearchFilterButton } from './QuickSearchFilterButton';

interface SearchTextFieldProps {
  value: string;
  performSearch?: (searchTerm: string) => void;
  onSearchTermChanged: (term: string) => void;
  onClear: () => void;
  onFocus?: () => void;
  onTextFieldClick?: () => void;
  autoFocus?: boolean;
  paperSx?: SxProps;
  paperElevation?: number;
}

export const QuickSearchInput = React.forwardRef(({ value, performSearch, onClear, onSearchTermChanged, onFocus, paperSx, paperElevation = 1, onTextFieldClick, autoFocus }: SearchTextFieldProps, ref: any) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>();
  const { isMediumScreenDown } = useScreenSize();

  const handleSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
    onSearchTermChanged(event.target.value);
  };

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target && onFocus) {
      onFocus();
    }
  };

  return (
    <Paper
      elevation={paperElevation}
      sx={{
        height: '40px',
        pl: '2px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        ...paperSx
      }}
      square={true}
      ref={ref}
    >
      <InputBase
        autoFocus={autoFocus}
        id={'search-textfield'}
        inputRef={inputRef}
        onFocus={handleOnFocus}
        value={value}
        sx={{ ml: 1, flex: 1 }}
        fullWidth
        placeholder={isMediumScreenDown ? t('Suche') : t('Welches Produkt dürfen wir Ihnen heute zeigen?')}
        onChange={handleSearchTermChange}
        inputProps={{ maxLength: 70 }}
        onClick={onTextFieldClick}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            performSearch && performSearch((e.target as HTMLInputElement).value?.trim());
            if (e.target) {
              (e.target as any).blur();
            }
          }
        }}
      />
      {value && (
        <IconButton
          tabIndex={-1}
          id={'clear-button'}
          aria-label={t('löschen')}
          onClick={() => {
            onClear();
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}
      <IconButton tabIndex={-1} id={'search-button'} aria-label={t('suchen')} onClick={() => performSearch && performSearch(inputRef?.current?.value?.trim() ?? '')}>
        <SearchIcon />
      </IconButton>
      <QuickSearchFilterButton />
    </Paper>
  );
});
