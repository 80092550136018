import { makeAutoObservable } from 'mobx';
import i18n from 'i18next';
import { ORDER_OF_ENTRY_CATEGORY_NAME, UNKNOWN_ARTICLES_CATEGORY_NAME } from '../helper/InventoryCategoryBuilder';
import InventoryPosition from './inventoryPosition';

export default class ProductCategory {
  name: string;
  isMultilanguage: boolean;
  items: InventoryPosition[] = [];

  constructor(name: string, isMultilanguage: boolean, items: InventoryPosition[]) {
    makeAutoObservable(this);
    this.name = name;
    this.isMultilanguage = isMultilanguage;
    this.items = items;
  }

  get displayName() {
    if (this.isMultilanguage) {
      return this.name;
    } else if ([ORDER_OF_ENTRY_CATEGORY_NAME, UNKNOWN_ARTICLES_CATEGORY_NAME].indexOf(this.name) > -1) {
      return this.name === ORDER_OF_ENTRY_CATEGORY_NAME ? i18n.t('Erfassungsreihenfolge') : i18n.t('Unbekannte Artikel');
    }

    return this.name;
  }
}
