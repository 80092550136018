import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import type { Integration } from '@sentry/types';
import { BrowserOptions, SeverityLevel } from '@sentry/react';
import { httpClientIntegration } from '@sentry/integrations';
import { PPCONTEXT } from '../util/ppContext';
import { rootStore } from '../StoreContext';
import { FEATURES } from '../modules/common/stores/featureStore';
import { toJS } from 'mobx';
import uuid from '../util/uuid';

export const TRANSGOURMET_TRACING_ID_KEY = 'ch.transgourmet.tracing.session';

let enableTealium = false;
let enableSentry = false;
let enableSentryPerformance = false;
let enableSentrySession = false;
let enableDebugLog = false;
let sentryConfigExtension = {};

export function initTracking() {
  if (!PPCONTEXT) {
    throw new Error('PPCONTEXT not available!');
  }

  initTransgourmetTracingId();

  const featureStore = rootStore.featureStore;

  enableTealium = featureStore.isFeatureEnabled(FEATURES.enableTealium);
  enableSentry = featureStore.isFeatureEnabled(FEATURES.enableSentry);
  enableSentryPerformance = featureStore.isFeatureEnabled(FEATURES.enableSentryPerformance);
  enableSentrySession = featureStore.isFeatureEnabled(FEATURES.enableSentrySession);
  enableDebugLog = featureStore.isFeatureEnabled(FEATURES.enableDebugLog);
  sentryConfigExtension = featureStore.sentryConfigExtension;

  if (enableDebugLog) {
    console.log('Setting up tracking ...', { enableTealium, enableSentry, enableSentryPerformance, enableSentrySession, sentryConfigExtension });
  }

  if (PPCONTEXT.DEPLOYMENT_ENVIRONMENT === 'PROD') {
    if (enableTealium) {
      setUpTealiumTracking('prod');
    }
    if (enableSentry) {
      setUpSentryTracking('PROD');
    }
  } else if (PPCONTEXT.DEPLOYMENT_ENVIRONMENT === 'DEVELOPMENT') {
    if (enableTealium) {
      setUpTealiumTracking('dev');
    }
    if (enableSentry) {
      setUpSentryTracking('DEVELOPMENT');
    }
  } else if (PPCONTEXT.DEPLOYMENT_ENVIRONMENT === 'TEST') {
    if (enableTealium) {
      setUpTealiumTracking('dev');
    }
    if (enableSentry) {
      setUpSentryTracking('TEST');
    }
  } else if (PPCONTEXT.DEPLOYMENT_ENVIRONMENT === 'STAGE') {
    if (enableTealium) {
      setUpTealiumTracking('dev');
    }
    if (enableSentry) {
      setUpSentryTracking('STAGE');
    }
  } else if (PPCONTEXT.DEPLOYMENT_ENVIRONMENT === 'PREVIEW') {
    if (enableTealium) {
      setUpTealiumTracking('qa');
    }
    if (enableSentry) {
      setUpSentryTracking('PREVIEW');
    }
  }

  return { enableSentryPerformance };
}

function setUpTealiumTracking(env: string) {
  if (enableDebugLog) {
    console.log(`Setting up Tealium for ${env} ...`);
  }
  const a = `//tags.tiqcdn.com/utag/coop-ch/tgch-transgourmet/${env}/utag.js`;
  const b = document;
  const c = 'script';
  const d = b.createElement(c);
  d.src = a;
  d.type = 'text/java' + c;
  d.async = true;
  const e = b.getElementsByTagName(c)[0];
  e.parentNode!.insertBefore(d, e);
}

function setUpSentryTracking(env: string) {
  if (enableDebugLog) {
    console.log(`Setting up Sentry for ${env} - performance: ${enableSentryPerformance}, session: ${enableSentrySession}`);
  }

  const sentryOptions: BrowserOptions = {
    dsn: 'https://1d0c07dc569492e682b8ed8b80ccea94@o4507028866334720.ingest.us.sentry.io/4507047432159232',
    environment: env,
    release: PPCONTEXT.RELEASE_VERSION,
    debug: false
  };

  const sentryIntegrations: Integration[] = [httpClientIntegration()];

  if (enableSentryPerformance) {
    sentryIntegrations.push(
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      })
    );
  }

  if (enableSentrySession) {
    sentryIntegrations.push(
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    );
  }
  sentryOptions.integrations = sentryIntegrations;

  sentryOptions.sampleRate = 1.0; // sampleRate controls the rate for both error events and performance monitoring transactions,
  sentryOptions.tracesSampleRate = 0.1; // tracesSampleRate controls the rate for performance monitoring transactions and takes precedence over sampleRate.
  sentryOptions.replaysSessionSampleRate = 0.1;
  sentryOptions.replaysOnErrorSampleRate = 0.1;

  const sentryOptionsExtended = { ...sentryOptions, ...sentryConfigExtension };

  if (enableDebugLog) {
    console.log(`Sentry Options:`, sentryOptionsExtended);
  }
  Sentry.init(sentryOptionsExtended);
}

export function sentryCaptureException(error: Error) {
  if (enableDebugLog) {
    console.error(`Error captured ${error.message}`, error);
  }
  if (enableSentry) {
    Sentry.captureException(error);
  }
}

export function sentryCaptureMessage(message: string, infos: Record<string, any> = {}, level: SeverityLevel = 'info') {
  const user = toJS(rootStore.userStore.loginUser);
  const customer = toJS(rootStore.userStore.selectedCustomer);

  const extra = { loginUser: user, loginCustomer: customer, ...infos };

  if (enableDebugLog) {
    console.warn(message, infos);
  }
  if (enableSentry) {
    Sentry.captureMessage(message, { level, extra });
  }
}

function initTransgourmetTracingId() {
  const tracingId = sessionStorage.getItem(TRANSGOURMET_TRACING_ID_KEY);
  if (!tracingId) {
    const newTracingId = uuid();
    sessionStorage.setItem(TRANSGOURMET_TRACING_ID_KEY, newTracingId);
  }
}

export function throwTestError() {
  // to test Sentry tracing
  throw new Error(`Test Error ${new Date().toISOString()}`);
}
