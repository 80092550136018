import { RouterProvider } from 'react-router-dom';
import { RouterType } from '../index';
import React from 'react';
import { styled, ThemeProvider, CssBaseline } from '@mui/material';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import webshopConfig from '../config/webshopConfig';
import { StoreContext } from '../StoreContext';
import RootStore from '../rootStore';
import { getLocale } from '../util/dateUtils';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { deDE, frFR, itIT, LocalizationProvider } from '@mui/x-date-pickers';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from '../queryClient';

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#666'
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#e20026'
  }
}));

export function App({ router, rootStore }: Readonly<{ router: RouterType; rootStore: RootStore }>) {
  const locale = getLocale(rootStore.uiStore.language);
  const localeText =
    rootStore.uiStore.language === 'fr'
      ? frFR.components.MuiLocalizationProvider.defaultProps.localeText
      : rootStore.uiStore.language === 'it'
        ? itIT.components.MuiLocalizationProvider.defaultProps.localeText
        : deDE.components.MuiLocalizationProvider.defaultProps.localeText;

  return (
    <QueryClientProvider client={queryClient}>
      <StoreContext.Provider value={rootStore}>
        <ThemeProvider theme={webshopConfig.theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale} localeText={localeText}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              Components={{
                success: StyledMaterialDesignContent,
                error: StyledMaterialDesignContent
              }}
            >
              <CssBaseline />
              <RouterProvider router={router} />
            </SnackbarProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StoreContext.Provider>
      <ReactQueryDevtools initialIsOpen={true} />
    </QueryClientProvider>
  );
}
