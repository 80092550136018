import { IExternalArticleData, IExternalArticleDTOData } from '../../../api';
import sortList from 'lodash/sortBy';
import { makeAutoObservable, runInAction } from 'mobx';
import RootStore from '../../../rootStore';
import { sortBy } from '../../../util/filterConstants';
import SupplierService from '../../suppliers/services/supplierService';
import { Category } from '../category';
import { createCategories } from '../ExternalArticleCategoryBuilder';
import { ISelectable } from '../ISelectable';
import ExternalArticle from '../models/externalArticle';
import { ExternalArticleFilter, externalArticleGroupBy } from '../models/externalArticleFilter';
import ExternalArticleService from '../services/externalArticleService';
import { formatServerMessage } from '../../../util/stringUtil';
import { executeAsyncAction } from '../../../util/exceptionHandler';

type ListItem = Category<ExternalArticle> | ExternalArticle;
export type ExternalArticleEditData = Partial<IExternalArticleData>;
export class ExternalArticleStore {
  static createNew(): ExternalArticleEditData {
    return {
      articleNumber: '',
      nameDe: '',
      nameFr: '',
      nameIt: '',
      groupDe: '',
      groupFr: '',
      groupIt: '',
      deliveryUnitDe: '',
      deliveryUnitFr: '',
      deliveryUnitIt: '',
      contentAmount: 0,
      contentUnitDe: '',
      contentUnitFr: '',
      contentUnitIt: '',
      price: 0.0,
      eans: [],
      mwst: 8.1
    };
  }

  store: RootStore;

  filter: ExternalArticleFilter;
  articles: ExternalArticle[] = [];
  suppliers: any[] = [];
  pendingRequestsCount = 0;
  loaded: boolean = false;
  showImportFileDialogArticle: boolean = false;
  suppIdToImport: number | undefined;
  showImportFileFeedbackDialog: boolean = false;
  importFileFeedbackTitle: string = '';
  importFileFeedbackMessage: string[] = [];
  expandedCategoryKeys: string[] = [];
  articleToBeRemoved?: ExternalArticle;

  constructor(store: RootStore) {
    makeAutoObservable(this);
    this.store = store;
    this.filter = new ExternalArticleFilter();
  }

  fetchData() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;

        const dtos = await ExternalArticleService.fetchArticles();
        const articles = dtos.map((x) => new ExternalArticle(this, x));

        runInAction(() => {
          this.articles = articles;
          this.loaded = true;
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  fetchSuppliers() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;

        const suppliers = await SupplierService.fetchSuppliers();

        runInAction(() => {
          this.suppliers = suppliers;
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  fetchById(articleId: string): Promise<any> {
    return executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const article = await ExternalArticleService.fetchArticle(articleId);
        article.articleNumber = article.articleNumber ? article.articleNumber : '';
        article.nameDe = article.nameDe ? article.nameDe : '';
        article.nameFr = article.nameFr ? article.nameFr : '';
        article.nameIt = article.nameIt ? article.nameIt : '';
        article.groupDe = article.groupDe ? article.groupDe : '';
        article.groupFr = article.groupFr ? article.groupFr : '';
        article.groupIt = article.groupIt ? article.groupIt : '';
        article.deliveryUnitDe = article.deliveryUnitDe ? article.deliveryUnitDe : '';
        article.deliveryUnitFr = article.deliveryUnitFr ? article.deliveryUnitFr : '';
        article.deliveryUnitIt = article.deliveryUnitIt ? article.deliveryUnitIt : '';
        article.contentAmount = article.contentAmount ? article.contentAmount : 0;
        article.contentUnitDe = article.contentUnitDe ? article.contentUnitDe : '';
        article.contentUnitFr = article.contentUnitFr ? article.contentUnitFr : '';
        article.contentUnitIt = article.contentUnitIt ? article.contentUnitIt : '';
        article.customLedgerAccount = article.customLedgerAccount ? article.customLedgerAccount : '';
        return article;
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  removeArticle(articleId: number) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;

        await ExternalArticleService.removeArticle(articleId);
        runInAction(() => {
          this.articles = this.articles.filter((x) => x.id !== articleId);
          this.articleToBeRemoved = undefined;
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  save(article: any) {
    return executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        await ExternalArticleService.saveArticle(article);
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  showImportFileDialog(suppId: number) {
    this.showImportFileDialogArticle = true;
    this.suppIdToImport = suppId;
  }

  closeImportFileDialog() {
    this.showImportFileDialogArticle = false;
  }

  closeImportFileFeedbackDialog() {
    this.showImportFileFeedbackDialog = false;
    this.importFileFeedbackTitle = '';
    this.importFileFeedbackMessage = [];
  }

  importArticlesFromCSVFile(data: any) {
    executeAsyncAction(
      async () => {
        if (this.suppIdToImport) {
          this.pendingRequestsCount++;
          const responseDto = await ExternalArticleService.importArticlesFromCSVFile(data, this.suppIdToImport);
          runInAction(() => {
            this.importFileFeedbackTitle = responseDto.message;
            this.importFileFeedbackMessage = formatServerMessage(responseDto.additionalInfo);
            this.showImportFileFeedbackDialog = true;
            this.articles = responseDto.result.map((x: IExternalArticleDTOData) => new ExternalArticle(this, x));
          });
        }
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  toggleCategory(key: string) {
    if (this.isExpandedCategory(key)) {
      this.expandedCategoryKeys = this.expandedCategoryKeys.filter((x) => x !== key);
    } else {
      this.expandedCategoryKeys.push(key);
    }
  }

  updateSortByCriteria(sort: string) {
    switch (sort) {
      case sortBy.ARTNR.name:
        this.filter.sortBy = sortBy.ARTNR;
        break;
      case sortBy.ARTTXT.name:
        this.filter.sortBy = sortBy.ARTTXT;
        break;
    }
  }

  updateGroupByCriteria(group: string) {
    switch (group) {
      case externalArticleGroupBy.SUPPLIER.name:
        this.filter.groupBy = externalArticleGroupBy.SUPPLIER;
        break;
      case externalArticleGroupBy.GROUP.name:
        this.filter.groupBy = externalArticleGroupBy.GROUP;
        break;
      case externalArticleGroupBy.LEDGERACC.name:
        this.filter.groupBy = externalArticleGroupBy.LEDGERACC;
        break;
    }
  }

  updateTerm(term: string) {
    this.filter.term = term;
  }

  showRemoveArticleDialog(externalArticle: ExternalArticle) {
    this.articleToBeRemoved = externalArticle;
  }

  async confirmArticleDelete() {
    await this.removeArticle(this.articleToBeRemoved!.id);
  }

  hideRemoveArticleDialog() {
    return (this.articleToBeRemoved = undefined);
  }

  get categories() {
    const categories = createCategories(this.articles, this.filter.groupBy);

    return categories.map((x: Category<ExternalArticle>) => {
      x.isExpanded = this.isExpandedCategory(x.key);
      return x;
    });
  }

  get isLoading() {
    return this.pendingRequestsCount > 0;
  }

  get isRemoveArticleDialogVisible() {
    return !!this.articleToBeRemoved;
  }

  /**
   * Flat list for rendering in react virtualized
   */
  get items(): ListItem[] {
    const items: ListItem[] = [];

    const hasFilterTerm = !!this.filter.term && this.filter.term.length > 0;
    const hasGroupKeyFilter = this.filter.groupKey ? this.categories.find((c) => c.key === this.filter.groupKey) : undefined;

    const categories = hasGroupKeyFilter ? this.categories.filter((c) => c.key === hasGroupKeyFilter.key) : this.categories;

    for (const category of categories) {
      const articles = this.filter.isSelected ? category.items.filter((a: ExternalArticle) => a.isSelected === this.filter.isSelected) : category.items;

      let articlesToAdd: ExternalArticle[] = [];
      for (const article of articles) {
        if (hasFilterTerm) {
          if ((article.articleText && article.articleText.toLowerCase().indexOf(this.filter.term.toLowerCase()) !== -1) || (article.articleNumber && article.articleNumber.toLowerCase().indexOf(this.filter.term.toLowerCase()) !== -1)) {
            articlesToAdd.push(article);
          }
        } else {
          articlesToAdd.push(article);
        }
      }

      articlesToAdd = sortList<ExternalArticle>(articlesToAdd, (article: ISelectable) => {
        return (article as any)[this.filter.sortBy.name];
      });

      if (articlesToAdd && articlesToAdd.length > 0) {
        items.push(category);

        if (category.isExpanded) {
          for (const articleToAdd of articlesToAdd) {
            items.push(articleToAdd);
          }
        }
      }
    }

    return items;
  }

  get suppliersLoaded() {
    return this.suppliers && this.suppliers.length > 0;
  }

  isExpandedCategory(key: string) {
    return this.expandedCategoryKeys.indexOf(key) > -1;
  }
}
