import type { IPositionSubmissionData } from '../../../api';
import axios from '../../../util/axios';
import { ppConstants } from '../../../util/ppConstants';

const SubmissionService = {
  async getPositionSubmission(submissionId: string): Promise<IPositionSubmissionData> {
    const response = await axios.get(`${ppConstants.API_HOST_SEC}positionsubmission/${submissionId}`);
    return response.data;
  },

  async removeSubmissionPosition(submissionId: any, positionId: any): Promise<any> {
    return axios({
      method: 'DELETE',
      url: `${ppConstants.API_HOST_SEC}positionsubmission/${submissionId}/${positionId}`
    });
  },

  /* Old Scanpool */
  async removeAllSubmissionPositions(submissionId: any): Promise<any> {
    return axios({
      method: 'DELETE',
      url: `${ppConstants.API_HOST_SEC}positionsubmission/${submissionId}`
    });
  },

  async importFromCSV(data: any): Promise<any> {
    const response = await axios.post(ppConstants.API_HOST_PUB + 'scanpool/import/order', data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });

    return response.data;
  }
};

export default SubmissionService;
