import type { IAddCustomerOrderCommandParamsData, IAvailabilityOptionData, IExternalArticlePositionData, ICartResponseData } from '../../../../api';
import CartService from '../../services/cartService';
import CartStore from '../../stores/cartStore';
import { IntentServerState } from '../../types/IntentServerState';
import { IPromptInfo } from '../../types/IPrompt';
import AbstractOrderIntent from './AbstractOrderIntent';

export type AddPositionParameterType = Pick<IAddCustomerOrderCommandParamsData, Exclude<keyof IAddCustomerOrderCommandParamsData, 'orderId'>>;

export default class AddPositionsIntent extends AbstractOrderIntent {
  private readonly addPositionParameters: AddPositionParameterType[];

  constructor(cartStore: CartStore, addPositionParameters: AddPositionParameterType[]) {
    super(cartStore);
    this.addPositionParameters = addPositionParameters;
  }

  public isPending(intentSpec: any) {
    return super.isPending(intentSpec) && this.addPositionParameters === intentSpec.addPositionParameters;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async internalOrderIntentProcess(resolve: () => Promise<void>, reject: (reason?: any) => Promise<void>, storeState: CartStore): Promise<any> {
    switch (this.internalState.state) {
      case IntentServerState.INITIAL: {
        await this.handleInit(resolve);
        break;
      }
      case IntentServerState.SERVER_REQUEST_CREATE_ORDER: {
        await this.handleCreateOrder();
        break;
      }
      case IntentServerState.SERVER_RESPONSE_CREATE_ORDER: {
        await this.handleCreateOrderResponse(reject);
        break;
      }
      case IntentServerState.SERVER_REQUEST_ADD_POSITION: {
        await this.handleAddPositions();
        break;
      }
      case IntentServerState.SERVER_RESPONSE_ADD_POSITION: {
        await this.handleAddPositionResponse(resolve);
        break;
      }
      case IntentServerState.SERVER_REQUEST_SEND_OPTION: {
        await this.handleSendOption();
        break;
      }
    }
  }

  private async handleInit(resolve: () => Promise<void>): Promise<any> {
    if (this.cartStore.order) {
      this.internalState = {
        state: IntentServerState.SERVER_REQUEST_ADD_POSITION,
        serverResponse: undefined
      };
    } else {
      const selectedDate = this.cartStore.selectedDeliveryDate ? this.cartStore.selectedDeliveryDate : await this.userPrompter.userDeliveryDateSelectionRequest();
      if (!selectedDate) {
        this.intentProcessor?.reset();
        return resolve();
      } else {
        this.internalState = {
          state: IntentServerState.SERVER_REQUEST_CREATE_ORDER,
          serverResponse: undefined
        };
      }
    }
  }

  private async handleCreateOrder(): Promise<any> {
    await this.handleServiceCallResponse(CartService.createOrder(this.cartStore.selectedDeliveryDate, this.cartStore.isTempCashAccepted), IntentServerState.SERVER_RESPONSE_CREATE_ORDER);
  }

  private async handleCreateOrderResponse(reject: (reason?: any) => Promise<void>): Promise<any> {
    const response = this.internalState.serverResponse!;

    const data = response.data as ICartResponseData;
    if (data.customerOrder) {
      const order = data.customerOrder;
      const cartDiscountInfo = data.cartDiscountInfo;
      this.cartStore.orderReceived(order, cartDiscountInfo);
      this.internalState = {
        state: IntentServerState.SERVER_REQUEST_ADD_POSITION,
        serverResponse: undefined
      };
    } else if (data.result.mostImportantMessage) {
      await this.userPrompter.userAlertRequest(this.internalState.serverResponse!.data.result.mostImportantMessage.message);
      return reject('Alert');
    } else {
      return reject('IllegalStateException');
    }
  }

  private async handleAddPositions(): Promise<any> {
    const addPostionCommandParams: IAddCustomerOrderCommandParamsData[] = this.addPositionParameters.map((param) => ({
      ...param,
      orderId: this.cartStore.order!.id
    }));

    await this.handleServiceCallResponse(CartService.addPosition(addPostionCommandParams), IntentServerState.SERVER_RESPONSE_ADD_POSITION);
  }

  private async handleAddPositionResponse(resolve: () => Promise<void>): Promise<any> {
    const response = this.internalState.serverResponse!;

    const positionAddResults = response.data as ICartResponseData[];
    const serverMessages = positionAddResults
      .filter((addResult) => addResult.result && addResult.result.mostImportantMessage && !(addResult.options && addResult.options.length > 0))
      .map((addResult) => ({
        message: addResult.result.mostImportantMessage.message,
        options: []
      }));

    const serverOptions: IPromptInfo[] = positionAddResults
      .filter((addResult) => addResult.options && addResult.options.length > 0)
      .map((problemResult) => ({
        message: problemResult.result.mostImportantMessage.message,
        options: problemResult.options
      }));

    const promptInfos = [...serverMessages, ...serverOptions];
    const resultWithError = positionAddResults.find((addResult) => addResult.result.mostImportantMessage != null);
    const latestData = positionAddResults[positionAddResults.length - 1];

    if (promptInfos.length > 0) {
      const option = await this.userPrompter.userPromptRequest(promptInfos);
      if (!option) {
        return resolve();
      }

      this.internalState = {
        state: IntentServerState.SERVER_REQUEST_SEND_OPTION,
        option
      };
    } else if (resultWithError) {
      await this.userPrompter.userAlertRequest(resultWithError.result.mostImportantMessage);
      return resolve();
    } else {
      const order = latestData.customerOrder;
      const cartDiscountInfo = latestData.cartDiscountInfo;
      if (order) {
        this.cartStore.orderReceived(order, cartDiscountInfo);
      }

      const dataWithExternalPositions = latestData as any;
      if (dataWithExternalPositions.extlArticlePositions && dataWithExternalPositions.extlArticlePositions.length > 0) {
        dataWithExternalPositions.extlArticlePositions.forEach((p: IExternalArticlePositionData) => {
          this.cartStore.updateExternalPosition(p);
        });
      }
      return resolve();
    }
  }

  private async handleSendOption(): Promise<any> {
    if (!this.internalState.option) {
      throw new Error('Inconsistent state: handleSendOption -> option is undefined');
    }
    const commands = (this.internalState.option as any).map((option: IAvailabilityOptionData) => option.command);
    const response = await CartService.sendCommands(commands);
    this.internalState = {
      state: IntentServerState.SERVER_RESPONSE_ADD_POSITION,
      serverResponse: response
    };
  }
}
