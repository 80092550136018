import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import { StoreContext } from '../../../StoreContext';

const LoginTimeoutDialog = observer(() => {
  const { masterDataStore, userStore } = useContext(StoreContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const okPressed = () => {
    userStore.performLogout();
    masterDataStore!.clearGlobalError();
  };

  return (
    <Dialog fullScreen={fullScreen} open={masterDataStore.showLoginTimeout}>
      <DialogTitle>{t('Login abgelaufen!')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('Ihr Login ist abgelaufen. Bitte loggen Sie sich erneut ein!')}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={okPressed} variant={'contained'} color="primary" autoFocus>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default LoginTimeoutDialog;
