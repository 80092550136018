import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ListItemIcon, ListItemText, Theme } from '@mui/material';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';
import { QUERY_SEARCH_PARAM_ACTION, QUERY_SEARCH_PARAM_BRANDS, QUERY_SEARCH_PARAM_NOVELTIES, QUERY_SEARCH_PARAM_PAGE, QUERY_SEARCH_PARAM_SPECIAL_ASSORTMENT, QUERY_SEARCH_PARAM_SUPPLIERGROUPNAMES } from '../search/useSearchQueryParams';
import { useCatalogComplete } from '../search/data-access/useCatalog';
import Divider from '@mui/material/Divider';
import { ArticleSpecialAssortment } from '../../api/hub/hub.enums.generated';
import { WineSociety } from '../../modules/common/models/wineSociety';
import Link from '@mui/material/Link';
import OpenInNewIcon from 'mdi-material-ui/OpenInNew';
import { useWineSocieties } from '../search/data-access/useWineSocieties';
import { StoreContext } from '../../StoreContext';
import { useQuery } from '@tanstack/react-query';
import { CATALOG_STALE_TIME, qkOwnBrands, qkTgsSupplierGroups } from '../search/data-access/searchQueryKeys';
import { fetchOwnBrands, fetchTgsSupplierGroups } from '../search/data-access/useSearchFilter';
import { executeAsyncActionNew } from '../../util/exceptionHandler';
import { ISearchFilterIdItemDto } from '../../api/hub/hub.models.generated';

const useStyles = makeStyles()(({ spacing }: Theme) => {
  return {
    area: {
      margin: spacing(2),
      minWidth: '200px'
    },
    externalLinkItem: {
      '&:hover': {
        textDecoration: 'none'
      }
    },
    filterItemText: {
      paddingLeft: 0
    },
    menuItem: {},
    icon: {
      marginRight: spacing(2)
    }
  };
});

interface ICatalogContent {
  onClose: (event: any) => void;
}

type Props = ICatalogContent;

export default ({ onClose }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { catalogItems } = useCatalogComplete();
  const { wineSocietyData } = useWineSocieties();
  const { userStore } = useContext(StoreContext);

  const { data: ownBrands } = useQuery({
    queryKey: [qkOwnBrands],
    queryFn: () => executeAsyncActionNew(async () => await fetchOwnBrands()),
    staleTime: CATALOG_STALE_TIME
  });

  const { data: tgsSupplierGroups } = useQuery({
    queryKey: [qkTgsSupplierGroups],
    queryFn: () => executeAsyncActionNew(async () => await fetchTgsSupplierGroups()),
    staleTime: CATALOG_STALE_TIME
  });

  const showAll = () => {
    navigate(`/catalog`);
  };

  const showActions = () => {
    navigate(`/catalog?${QUERY_SEARCH_PARAM_ACTION}=true&${QUERY_SEARCH_PARAM_PAGE}=0`);
  };

  const showNovelties = () => {
    navigate(`/catalog?${QUERY_SEARCH_PARAM_NOVELTIES}=true&${QUERY_SEARCH_PARAM_PAGE}=0`);
  };

  const close = (event: any) => {
    onClose && onClose(event);
  };

  return (
    <Grid container>
      <Grid item className={classes.area}>
        <Typography variant={'h6'}>{t('Aktuell')}</Typography>
        <MenuList>
          <MenuItem
            className={classes.menuItem}
            onClick={(event) => {
              close(event);
              showAll();
            }}
            id={'catalognav-full-catalog-button'}
          >
            <ListItemText className={classes.filterItemText} primary={t('Gesamtsortiment')} />
          </MenuItem>
          <MenuItem
            className={classes.menuItem}
            id={'catalognav-novelties-button'}
            onClick={(event) => {
              close(event);
              showNovelties();
            }}
          >
            <ListItemText className={classes.filterItemText} primary={t('Neuheiten')} />
          </MenuItem>
          {!userStore.actionExclude && (
            <MenuItem
              className={classes.menuItem}
              id={'catalognav-action-button'}
              onClick={(event) => {
                close(event);
                showActions();
              }}
            >
              <ListItemText className={classes.filterItemText} primary={t('Aktionen')} />
            </MenuItem>
          )}
        </MenuList>
        <Typography variant={'h6'}>{t('Eigenmarken')}</Typography>
        <MenuList>
          {ownBrands?.map((brand: ISearchFilterIdItemDto) => (
            <MenuItem
              className={classes.menuItem}
              onClick={(event) => {
                close(event);
                navigate(`/catalog?${QUERY_SEARCH_PARAM_BRANDS}=${brand.id}&${QUERY_SEARCH_PARAM_PAGE}=0`);
              }}
              key={brand.id}
              id={'catalognav-brand-' + brand.name + '-button'}
            >
              <ListItemText primary={brand.name} />
            </MenuItem>
          ))}
        </MenuList>
      </Grid>
      <Grid item className={classes.area}>
        <Typography variant={'h6'}>{t('Sonstige')}</Typography>
        {wineSocietyData
          ?.filter((x) => x.show)
          .map((item: WineSociety, index: number) => {
            return (
              <Link key={item.name + index} href={item.link} color={'textPrimary'} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} className={classes.externalLinkItem}>
                <MenuItem
                  className={classes.menuItem}
                  onClick={(event) => {
                    close(event);
                  }}
                  id={'catalognav-winesocieties-' + index + '-button'}
                >
                  <ListItemText primary={item.name} />
                  <ListItemIcon>
                    <OpenInNewIcon fontSize={'small'} />
                  </ListItemIcon>
                </MenuItem>
              </Link>
            );
          })}
        {wineSocietyData?.filter((x) => x.show).length > 0 && <Divider />}
        <MenuList>
          {tgsSupplierGroups?.map((item: ISearchFilterIdItemDto, index: number) => {
            return (
              <MenuItem
                className={classes.menuItem}
                onClick={(event) => {
                  close(event);
                  navigate(`/catalog?${QUERY_SEARCH_PARAM_SUPPLIERGROUPNAMES}=${item.id}&${QUERY_SEARCH_PARAM_PAGE}=0`);
                }}
                key={item.id}
                id={'catalognav-profit-plus-' + index + '-button'}
              >
                <ListItemText primary={item.name} />
              </MenuItem>
            );
          })}
          <Divider />
          <MenuItem
            className={classes.menuItem}
            onClick={(event) => {
              close(event);
              navigate(`/catalog?${QUERY_SEARCH_PARAM_SPECIAL_ASSORTMENT}=${ArticleSpecialAssortment.care}&${QUERY_SEARCH_PARAM_PAGE}=0`);
            }}
            id={'catalognav-care-button'}
          >
            <ListItemText primary={t('Care Sortiment')} />
          </MenuItem>
        </MenuList>
      </Grid>
      <Grid item className={classes.area}>
        <Typography variant={'h6'}>{t('Hauptwarengruppen')}</Typography>
        <MenuList>
          {catalogItems.map((item: ISearchFilterIdItemDto, i: number) => {
            if (item.id < 12) {
              return (
                <MenuItem
                  className={classes.menuItem}
                  onClick={(event) => {
                    close(event);
                    navigate('/hwg/' + item.id);
                  }}
                  key={i}
                  id={'catalognav-hwg-' + item.id + '-button'}
                >
                  <ListItemText primary={item.name} />
                </MenuItem>
              );
            } else {
              return null;
            }
          })}
        </MenuList>
      </Grid>
    </Grid>
  );
};
