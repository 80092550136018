import type { IWebTemplateDTOData } from '../../../../api';
import { makeAutoObservable } from 'mobx';
import { TemplateStore } from '../templateStore';

export class Template {
  store: TemplateStore;

  id: number;
  name: string;
  mainArticleIds: number[];
  positionCount: number;
  isFavorit: boolean;

  constructor(store: TemplateStore, dto: IWebTemplateDTOData) {
    makeAutoObservable(this);
    this.store = store;

    this.id = dto.id;
    this.name = dto.name;
    this.mainArticleIds = dto.mainArticleIds;
    this.positionCount = dto.positionCount ? dto.positionCount : 0;
    this.isFavorit = dto.isFavorit;
  }

  updateFavorit() {
    this.isFavorit = !this.isFavorit;
    this.store.updateTemplate(this);
  }

  toDto(): any {
    return {
      id: this.id,
      name: this.name,
      favorite: this.isFavorit
    };
  }
}
