import React, { Suspense, useContext } from 'react';
import { StoreContext } from '../StoreContext';
import { NoAccessScreen } from './NoAccessScreen';
import { AGB } from './user/components/login/AGB';
import ComponentLoadingIndicator from './common/components/ComponentLoadingIndicator';
import { ConfirmationProvider } from './common/confirmation/ConfirmContext';
import { Outlet } from 'react-router-dom';
import { ArticleDetailDialog } from './shared/articles/components/article/ArticleDetailDialog';
import { useModalArticleDetailDialogArticle } from './shared/articles/util/useModalArticleDetailDialog';

export function AppContent() {
  const { userStore } = useContext(StoreContext);
  const { modalDialogArticleNumber } = useModalArticleDetailDialogArticle();

  if (userStore.webshopAccessError) {
    return <NoAccessScreen />;
  } else if (userStore.forceAcceptWebshopAgb) {
    return <AGB onLoginFinished={() => {}} />;
  } else {
    return (
      <Suspense fallback={<ComponentLoadingIndicator />}>
        <ConfirmationProvider>
          <Outlet />
          {modalDialogArticleNumber ? <ArticleDetailDialog articleNumber={modalDialogArticleNumber} /> : null}
        </ConfirmationProvider>
      </Suspense>
    );
  }
}
