import type { IWebshopInvoicePresentationData } from '../../../../api';
import { makeAutoObservable } from 'mobx';

export class Invoice {
  id: number;
  invoiceDate: Date;

  constructor(dto: IWebshopInvoicePresentationData) {
    makeAutoObservable(this);

    this.id = dto.invoiceId;
    this.invoiceDate = new Date(dto.date as any);
  }
}
