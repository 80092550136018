import React from 'react';
import Grid from '@mui/material/Grid';
import { Article } from '../../models/article';
import { CatalogArticle } from '../../../../../features/search/catalog/useCatalogArticle';

interface ComparisonPriceProps {
  article: Article | CatalogArticle;
}

const ComparisonPrice = ({ article }: ComparisonPriceProps) => {
  if (!article.hasComparisonPrice) {
    return null;
  }

  return (
    <Grid container justifyContent={'space-around'} spacing={2}>
      <Grid item>{article.formattedComparisonPrice}</Grid>
    </Grid>
  );
};

export default ComparisonPrice;
