import SearchIcon from 'mdi-material-ui/Magnify';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import SearchFilter from './SearchFilter';
import { CatalogSearchType, CATALOGSEARCHTYPE_ALLARTICLE } from '../../../../catalog/util/catalogConstants';
import { StoreContext } from '../../../../../StoreContext';
import SearchDialog from './SearchDialog';
import { getQueryParameterByName } from '../../../../../util/urlUtils';
import { makeStyles } from 'tss-react/mui';
import { Box, Grid, IconButton, Paper, Theme, Typography } from '@mui/material';

const useStyles = makeStyles()(({ spacing }: Theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '40px'
    },
    searchTerm: {
      marginLeft: spacing(1)
    }
  };
});

const MobileSearch = observer(() => {
  const { catalogStore } = useContext(StoreContext);
  const [searchTerm, setSearchTerm] = useState<string>(getQueryParameterByName('q') ?? '');
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { classes } = useStyles();

  const handleToggleFilterSearchType = (searchType: CatalogSearchType) => {
    if (catalogStore.searchType === searchType) {
      catalogStore.changeSearchType(CATALOGSEARCHTYPE_ALLARTICLE);
    } else {
      catalogStore.changeSearchType(searchType);
    }
  };

  return (
    <>
      <Paper elevation={1} className={classes.root} square={true}>
        <Grid container>
          <Grid item style={{ flexGrow: 1 }} onClick={() => setIsSearchDialogOpen(true)}>
            <Box display={'flex'} alignItems={'center'} height={'100%'} id={'mobile-search-textfield-button'}>
              <Typography variant={'body2'} className={classes.searchTerm}>
                {searchTerm}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <IconButton aria-label={t('suchen')} onClick={() => setIsSearchDialogOpen(true)} id={'mobile-search-button'}>
              <SearchIcon sx={{ mt: 0.5 }} />
            </IconButton>
          </Grid>
          <Grid item>
            <SearchFilter handleToggleFilterSearchType={handleToggleFilterSearchType} />
          </Grid>
        </Grid>
      </Paper>

      <SearchDialog
        handleToggleFilterSearchType={handleToggleFilterSearchType}
        open={isSearchDialogOpen}
        onClose={(term?: string) => {
          if (term) {
            setSearchTerm(term);
          }
          setIsSearchDialogOpen(false);
        }}
      />
    </>
  );
});

export default MobileSearch;
