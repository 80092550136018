import type { IExternalArticleDTOData } from '../../../api';
import { makeAutoObservable } from 'mobx';
import { getMLSelector } from '../../../util/i18n';
import { ISelectable } from '../ISelectable';
import { ExternalArticleStore } from '../stores/externalArticleStore';
import i18n from 'i18next';

export default class ExternalArticle implements ISelectable {
  id: number;
  articleId: number;
  mainArticleId: number;
  articleNumber: string;
  articleText: string;
  group: string;
  ledgerAccount: string;
  deliveryUnit: string;
  contentUnit: string;
  contentAmount: number;
  price: number;
  isSelected = false;
  supplierName: string;
  supplierId: number;
  amount = 0;
  available: boolean;
  mainArticleKind: number;
  articleStatus: string;
  externalArticle: boolean = true;
  eans: string[] = [];
  effectivePrice: number;
  isAction: boolean;
  isIndividualPrice: boolean;
  graduatedPriceSteps: { [index: string]: number };
  sellAmount: number;
  priceCode: number;
  approxWeight: number;

  public store: ExternalArticleStore;

  constructor(store: ExternalArticleStore, dto: IExternalArticleDTOData) {
    makeAutoObservable(this);
    const mls = getMLSelector();

    this.store = store;

    this.id = dto.externalArticle.id;
    this.articleId = dto.orderArticlePresentation.articleId;
    this.mainArticleId = dto.orderArticlePresentation.mainArticleId;
    this.articleNumber = dto.orderArticlePresentation.articleNumber;
    this.articleText = dto.externalArticle.name ? mls(dto.externalArticle.name) : '';
    this.group = dto.externalArticle.group ? mls(dto.externalArticle.group) : '';
    this.ledgerAccount = dto.externalArticle.customLedgerAccount ? dto.externalArticle.customLedgerAccount : dto.externalArticle.ledgerAccount ? mls(dto.externalArticle.ledgerAccTxt) : '';
    this.deliveryUnit = mls(dto.externalArticle.deliveryUnit);
    this.contentUnit = mls(dto.externalArticle.contentUnit);
    this.contentAmount = dto.externalArticle.contentAmount;
    this.price = dto.externalArticle.price;
    this.supplierName = dto.externalArticle.supplierName;
    this.supplierId = dto.externalArticle.supplier.id;
    this.available = dto.orderArticlePresentation.available;
    this.mainArticleKind = dto.orderArticlePresentation.mainArticleKind;
    this.articleStatus = dto.orderArticlePresentation.articleStatus;
    this.eans = dto.externalArticle.eans;
    this.effectivePrice = dto.orderArticlePresentation.effectivePrice;
    this.isAction = dto.orderArticlePresentation.action;
    this.isIndividualPrice = dto.orderArticlePresentation.individualPrice;
    this.graduatedPriceSteps = dto.orderArticlePresentation.graduatedPriceSteps;
    this.sellAmount = dto.orderArticlePresentation.sellAmount;
    this.priceCode = dto.orderArticlePresentation.priceCode;
    this.approxWeight = dto.orderArticlePresentation.approxWeight;
  }

  remove() {
    this.store.removeArticle(this.id);
  }

  setAmount(amount: number) {
    this.amount = amount;
  }

  displayUnit() {
    const units = this.contentAmount > 1 && this.deliveryUnit ? this.deliveryUnit + ` ${i18n.t('sellUnitSeparator')} ` : '';
    const contentAmount = this.contentAmount ? this.contentAmount : '';
    return units + contentAmount + ' ' + this.contentUnit;
  }

  displayPrice() {
    return `CHF ${this.price ? this.price.toFixed(2) : '0.00'}`;
  }

  showAction() {
    return true;
  }
}
