import { Button, Dialog, DialogContent, DialogTitle, Grid, useMediaQuery, useTheme, Alert } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../StoreContext';
import { useToggle } from '../util/useToggle';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => {
  return {
    dialog: {
      position: 'relative',
      top: '-25%'
    },
    dialogButton: {
      minWidth: 100,
      marginLeft: 7
    },
    agbLink: {
      fontSize: 'larger'
    }
  };
});

interface WebshopErrorScreenProps {
  error: Error;
}

export function WebshopErrorScreen({ error }: WebshopErrorScreenProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { userStore } = useContext(StoreContext);
  const [expandDetail, toggleExpandDetail] = useToggle(false);

  function reloadShop() {
    userStore.triggerShopReload();
  }

  return (
    <Dialog open={true} fullScreen={fullScreen} classes={fullScreen ? undefined : { paper: classes.dialog }}>
      <DialogTitle id="simple-dialog-title">{t('Technischer Fehler')}</DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Alert severity="info" onClick={() => toggleExpandDetail()}>
              <div>{t('Ein technischer Fehler ist aufgetreten. Bitte laden Sie den Webshop neu!')}</div>
            </Alert>
          </Grid>
          {expandDetail && error && (
            <Grid item sx={{ width: '100%' }}>
              <div style={{ maxHeight: 300, overflow: 'scroll', padding: 5 }}>
                <div>{error.message}</div>
                <div>{error.stack}</div>
              </div>
            </Grid>
          )}

          <Grid item>
            <Grid container direction="row-reverse" justifyContent="space-between">
              <Grid item>
                <Button variant="contained" color="primary" type="submit" className={classes.dialogButton} onClick={reloadShop} id="cancel">
                  {t('Neu laden'!)}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
