export const IntentServerState = {
  INITIAL: 'initial',
  SERVER_RESPONSE_CART_EXPIRATION: 'server-response-cartExpiration',
  SERVER_RESPONSE_TEMP_CASH: 'server-response-tempCash',
  SERVER_RESPONSE_ERROR: 'server-response-error',
  SERVER_REQUEST_ADD_POSITION: 'server-request-add-position',
  SERVER_REQUEST_CREATE_ORDER: 'server-request-create-order',
  SERVER_RESPONSE_CREATE_ORDER: 'server-response-create-order',
  SERVER_RESPONSE_ADD_POSITION: 'server-response-add-position',
  SERVER_REQUEST_SEND_OPTION: 'server-request-send-option',
  SERVER_RESPONSE_SEND_OPTION: 'server-response-send-option',
  SERVER_RESPONSE_CHANGE_ORDER_DATE: 'server-response-change-orderdate',
  SERVER_RESPONSE_CHANGE_ORDER_DATE_WITH_CHECK: 'server-response-change-orderdate-with-check',
  SERVER_RESPONSE_ORDER_DISCARD: 'server-response-order-discard',
  SERVER_RESPONSE_ORDER_RECEIVED: 'server-response-order-received',
  SERVER_RESPONSE_MODIFY_POSITION: 'server-response-modify-position',
  SERVER_RESPONSE_POSITION_REMOVED: 'server-response-position-removed',
  SERVER_RESPONSE_CUSTOMER_ORDER: 'server-response-customer-order'
};
