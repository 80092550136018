import { makeAutoObservable } from 'mobx';
import CartExternalArticlePosition from './cartExternalArticlePosition';

export default class CartSupplierExternalArticle {
  constructor(id: number, name: string, articles: CartExternalArticlePosition[]) {
    makeAutoObservable(this);

    this.supplierId = id;
    this.supplierName = name;
    this.articles = articles;
  }

  supplierId: number;
  supplierName: string;
  articles: CartExternalArticlePosition[];
}
