import { Grid, useTheme } from '@mui/material';
import { Palette } from '@mui/material/styles/createPalette';
import Typography from '@mui/material/Typography';
import WarningIcon from 'mdi-material-ui/AlertOutline';
import ErrorIcon from 'mdi-material-ui/AlertCircleOutline';
import SuccessIcon from 'mdi-material-ui/Check';
import InfoIcon from 'mdi-material-ui/InformationOutline';
import React from 'react';
import { SxProps } from '@mui/system';

export type InfoType = 'info' | 'warning' | 'error' | 'success';

const getColor = (infoType: InfoType, palette: Palette) => {
  switch (infoType) {
    case 'error':
      return palette.error.main;
    case 'warning':
      return '#ff9800';
    case 'success':
      return '#2E7D32';
    default:
      return palette.primary.main;
  }
};

interface IInfoBoxProps {
  htmlMessage?: string;
  message?: string;
  control?: JSX.Element;
  infoType: InfoType;
  justify?: any;
}

export const NotificationBox = ({ htmlMessage, message, control, infoType, justify }: IInfoBoxProps) => {
  const theme = useTheme();

  const iconSx: SxProps = {
    color: getColor(infoType, theme.palette),
    marginRight: theme.spacing(1)
  };

  return (
    <Grid container={true} p={1} border={'1px solid ' + getColor(infoType, theme.palette)} wrap={'nowrap'} alignItems={'center'} justifyContent={justify ? justify : 'flex-start'}>
      <Grid item={true}>
        {infoType === 'info' && <InfoIcon color={'inherit'} sx={iconSx} data-testid={infoType} />}
        {infoType === 'warning' && <WarningIcon color={'inherit'} sx={iconSx} data-testid={infoType} />}
        {infoType === 'error' && <ErrorIcon color={'inherit'} sx={iconSx} data-testid={infoType} />}
        {infoType === 'success' && <SuccessIcon color={'inherit'} sx={iconSx} data-testid={infoType} />}
      </Grid>
      <Grid item={true}>
        {htmlMessage && <Typography variant={'body1'} dangerouslySetInnerHTML={{ __html: htmlMessage }} />}
        {message && <Typography variant={'body1'}>{message}</Typography>}
        {control}
      </Grid>
    </Grid>
  );
};
