import React, { useContext, useEffect } from 'react';
import i18n from 'i18next';
import DefaultBreadcrumb from '../common/components/navigation/breadcrumb/DefaultBreadcrumb';
import withContentLayout from '../withContentLayout';
import Carousel from './PromotionCarousel';
import Grid from '@mui/material/Grid';
import ScreenTitle from '../shared/components/ScreenTitle';
import { Article } from '../shared/articles/models/article';
import TilesRow from '../shared/articles/components/tiles/TilesRow';
import { StoreContext } from '../../StoreContext';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useModalArticleDetailDialogControl } from '../shared/articles/util/useModalArticleDetailDialog';
import { useParams } from 'react-router-dom';

const Overview = observer(() => {
  const { id: promotionIdValue } = useParams();
  const { masterDataStore, catalogStore, userStore, cartStore, uiStore } = useContext(StoreContext);
  const { t } = useTranslation();
  const { showArticleDetailsDialog } = useModalArticleDetailDialogControl();

  const promotionId = promotionIdValue ? parseInt(promotionIdValue) : undefined;

  useEffect(() => {
    if (!userStore.actionExclude) {
      masterDataStore.fetchActionArticles();
    }
  }, [masterDataStore, userStore]);

  const handleAddToCart = (article: Article) => {
    catalogStore.addArticleToCart(article);
  };

  const handleIsLoading = (article: Article) => {
    return cartStore.hasPendingIntents && catalogStore.isAddPositionIntentPending(article);
  };

  const handleNavigate = (article: Article) => {
    showArticleDetailsDialog(article.articleNumber);
  };

  const handleIsInCart = (article: Article) => {
    const articleIdCartInfo = cartStore.getAmountInCartByArticleId(article.articleId);
    const articleCartInfo = cartStore.getAmountInCartByArticleNr(article.articleNumber);
    if (articleCartInfo) {
      return articleCartInfo > 0;
    } else if (articleIdCartInfo) {
      return articleIdCartInfo > 0;
    } else {
      return false;
    }
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <Carousel promotionId={promotionId} />
      </Grid>

      {!userStore.actionExclude && masterDataStore.actionArticles && masterDataStore.actionArticles.length > 0 && (
        <Grid container direction="column">
          <Grid item mt={2} pl={2}>
            <ScreenTitle title={t('Top Aktionen')} indent={true} />
          </Grid>
          <Grid item>
            <TilesRow
              loadLedger={false}
              addToCart={handleAddToCart}
              isInCart={handleIsInCart}
              isLoading={handleIsLoading}
              isLoggedIn={userStore.isLoggedIn}
              navigateToDetail={handleNavigate}
              articles={masterDataStore.actionArticles}
              screenWidth={uiStore.width}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
});

const breadCrumb = () => <DefaultBreadcrumb breadCrumbName={i18n.t('Startseite')} />;
const layout = withContentLayout(breadCrumb, null, Overview, false);

export default layout;
