import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';

export interface INormalLinkProps {
  link: string;
  linkName: string;
  state?: any;
  className?: string;
  color?: any;
  id?: string;
  sx?: SxProps<Theme>;
}

type Props = INormalLinkProps;

const NormalLink = React.forwardRef(({ sx, link, linkName, color = 'primary', className, id }: Props, ref: any) => {
  const MyLink = React.forwardRef((props, ref: any) => <RouterLink id={id} ref={ref} to={link} {...props} />);

  return (
    <Link component={MyLink} className={className} color={color} ref={ref} sx={sx}>
      {linkName}
    </Link>
  );
});

export default NormalLink;
