import type { IWebTemplateDTOData } from '../../../api';
import findIndex from 'lodash/findIndex';
import { makeAutoObservable, runInAction } from 'mobx';
import RootStore from '../../../rootStore';
import { isSameArticle } from '../../shared/articles/util/ArticleHelper';
import TemplateService from '../services/templateService';
import { Template } from './models/template';
import { executeAsyncAction } from '../../../util/exceptionHandler';

export class TemplateStore {
  rootStore: RootStore;

  // Common
  pendingRequestsCount = 0;
  selectedArticles: any[] = [];

  // Templates
  templates: Template[] = [];
  templateArticleToAdd?: any;
  isTemplateNameDialogShown = false;
  selectedTemplateId?: number;
  isTemplateArticleDialogShown = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  fetchTemplates() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const templates = await TemplateService.fetchTemplates();
        runInAction(() => {
          this.templates = templates ? templates.map((t) => new Template(this, t)) : [];
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  removeTemplate(id: number) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        await TemplateService.removeTemplate(id);

        if (this.templates) {
          runInAction(() => {
            this.templates = this.templates.filter((t) => t.id !== id);
          });
        }
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  getTemplatesByArticleId(articleId: number) {
    const templates = [];
    for (const template of this.templates) {
      const index = template.mainArticleIds.findIndex((mainArtId: number) => mainArtId === articleId);
      if (index >= 0) {
        const templateIndex = templates.findIndex((templ) => templ === template.name);
        if (templateIndex === -1) {
          templates.push(template.name);
        }
      }
    }

    return templates;
  }

  get isSelectedTemplateStillInTemplates(): boolean {
    const i = this.otherTemplates.findIndex((template: any) => this.selectedTemplateId === template.id);
    return i >= 0;
  }

  setDefaultTemplate() {
    if (!this.selectedTemplateId || !this.isSelectedTemplateStillInTemplates) {
      this.resetSelectedTemplateId();
    }
  }

  setSelectedTemplateId(templateId: number) {
    this.selectedTemplateId = templateId;
  }

  resetSelectedTemplateId() {
    this.selectedTemplateId = this.otherTemplates.length > 0 ? (this.otherTemplates[0] as any).id : undefined;
  }

  setTemplates(templates: IWebTemplateDTOData[]) {
    this.templates = templates ? templates.map((t) => new Template(this, t)) : [];
  }

  addTemplateArticleToAdd(articles: any) {
    this.templateArticleToAdd = articles;
    if (this.templateArticleToAdd) {
      this.showTemplateArticleDialog();
    }
  }

  addSelectedArticlesToTemplate() {
    executeAsyncAction(
      async () => {
        const templateId = this.selectedTemplateId;
        const articles = this.templateArticleToAdd.length ? this.templateArticleToAdd : [this.templateArticleToAdd];

        this.pendingRequestsCount++;
        this.clearTemplateArticleToAdd(); // This closes the AddToTemplateDialog

        const template = await TemplateService.addArticlesToTemplate(templateId, articles);
        // fix: remove when Server returns the correct object back
        const dto = {
          id: template.id,
          isFavorit: template.favorite,
          name: template.name,
          positionCount: template.externalArticles.length + template.articles.length,
          mainArticleIds: template.mainArticleIds
        } as IWebTemplateDTOData;

        const i = findIndex(this.templates, (t: any) => t.id === dto.id);

        if (i >= 0) {
          runInAction(() => {
            this.templates[i] = new Template(this, dto);
          });
        }
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  clearTemplateArticleToAdd() {
    this.templateArticleToAdd = undefined;
  }

  showCreateTemplateDialog() {
    this.isTemplateNameDialogShown = true;
  }

  showTemplateArticleDialog() {
    this.isTemplateArticleDialogShown = true;
  }

  closeTemplateArticleDialog() {
    this.isTemplateArticleDialogShown = false;
  }

  createNewTemplate(templateName: string) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const template = await TemplateService.createNewTemplate(templateName);

        runInAction(() => {
          this.templates.push(new Template(this, template));
          if (this.templateArticleToAdd) {
            this.selectedTemplateId = template.id;
            this.addSelectedArticlesToTemplate();
          }
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  closeTemplateNameDialog() {
    this.isTemplateNameDialogShown = false;
  }

  addArticleToSelection(article: any) {
    this.selectedArticles.push(article);
  }

  removeArticleFromSelection(article: any) {
    this.selectedArticles = this.selectedArticles.filter((sa) => !isSameArticle(sa, article));
  }

  get otherTemplates() {
    const templates = this.templates || [];
    return templates;
  }

  get favouriteTemplates(): Template[] {
    return this.templates.filter((t) => {
      return t.isFavorit;
    });
  }

  get sortedTemplates(): Template[] {
    let templates = this.templates || [];
    if (templates.length > 0) {
      templates = templates.slice().sort((t1, t2) => {
        return (t1 as any).name.localeCompare((t2 as any).name);
      });
    }
    return templates;
  }

  get isLoading() {
    return this.pendingRequestsCount > 0;
  }

  updateTemplate(template: Template) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        await TemplateService.changeTemplate(template.id, template.toDto());
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }
}
