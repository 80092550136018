import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, useMediaQuery, useTheme } from '@mui/material';

import { isObservableArray } from 'mobx';
import { useTranslation } from 'react-i18next';

export interface IOkDialogProps {
  message: string | string[];
  showDialog: boolean;
  title: string;
  okPressed: () => void;
}

export default function OkDialog(props: IOkDialogProps) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const okPressed = () => {
    props.okPressed();
  };

  const isArray: boolean = isObservableArray(props.message) || Array.isArray(props.message);

  return (
    <Dialog open={props.showDialog} fullScreen={fullScreen}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} direction="column">
          {isArray &&
            (props.message as string[]).map((x: string, index: number) => {
              return (
                <Grid key={index} item>
                  {x}
                </Grid>
              );
            })}
          {!isArray && <Grid item>{props.message}</Grid>}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={okPressed} variant={'contained'} color="primary" id={'ok-dialog-ok-button'} autoFocus>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
