import find from 'lodash/find';
import sortList from 'lodash/sortBy';
import { sortBy } from '../../../util/filterConstants';
import { getMLSelector } from '../../../util/i18n';
import InventoryPosition from '../models/inventoryPosition';
import ProductCategory from '../models/productCategory';

export const ORDER_OF_ENTRY_CATEGORY_NAME = 'ORDER_OF_ENTRY_CATEGORY_NAME';
export const UNKNOWN_ARTICLES_CATEGORY_NAME = 'UNKNOWN_ARTICLES_CATEGORY_NAME';

export function createCategories(positions: InventoryPosition[], groupByObject: any, sortByObject: any): ProductCategory[] {
  const mls = getMLSelector();

  let categories: ProductCategory[] = [];
  // This is a position for a unknown article
  const unknownCategory: any = new ProductCategory(UNKNOWN_ARTICLES_CATEGORY_NAME, false, []);
  if (positions) {
    for (const position of positions) {
      if (sortByObject.name === sortBy.INPUTORDER.name) {
        if (!categories[0]) {
          categories[0] = new ProductCategory(ORDER_OF_ENTRY_CATEGORY_NAME, false, []);
        }
        categories[0].items.push(position);
      } else if (!position.hasArticle) {
        unknownCategory.items.push(position);
      } else {
        let categoryKey = (position as any)[groupByObject.name];
        if (groupByObject.isList) {
          categoryKey = categoryKey.slice();
        }
        const foundCategory: any = find(categories, { name: categoryKey });
        if (!foundCategory) {
          const newCat: any = new ProductCategory(categoryKey, groupByObject.isList, []);
          newCat.items.push(position);
          categories.push(newCat);
        } else {
          foundCategory.items.push(position);
        }
      }
    }

    if (unknownCategory.items.length > 0) {
      categories.push(unknownCategory);
    }

    if (categories.length > 0) {
      categories = sortList(categories, (c: ProductCategory) => {
        if (c.isMultilanguage) {
          return mls(c.name);
        } else {
          return c.name;
        }
      }) as ProductCategory[];
    }

    for (const category of categories) {
      category.items = sortList<InventoryPosition>(category.items.slice(), (itemPosition: InventoryPosition) => {
        if (sortByObject === sortBy.INPUTORDER || !itemPosition.hasArticle) {
          return itemPosition.id;
        } else if (sortByObject.isList) {
          return (itemPosition as any)[sortByObject.name];
        } else {
          return (itemPosition as any)[sortByObject.name];
        }
      });
    }
  }

  categories.sort(sortCategories);

  return categories;
}

function sortCategories(a: ProductCategory, b: ProductCategory) {
  const groupA = a.name ? a.name.toUpperCase() : '';
  const groupB = b.name ? b.name.toUpperCase() : '';

  let comparison = 0;
  if (groupA > groupB) {
    comparison = 1;
  } else if (groupA < groupB) {
    comparison = -1;
  }
  return comparison;
}

export function calculateTotalForInventory(positions: any) {
  let totalRated = 0;
  let total = 0;
  if (positions) {
    for (const position of positions) {
      const sellAmount = position.article && position.article.sellAmount ? position.article.sellAmount : 1;

      totalRated += position.position.price * position.position.quantity * sellAmount * (position.position.rating / 100);
      total += position.position.price * position.position.quantity * sellAmount;
    }
  }

  return { totalRated, total };
}
