import { format } from 'date-fns';
import type { IAddCustomerOrderCommandParamsData, ICreateOciOrderParamsData, ICustomerOrderPositionCommandData } from '../../../api';
import axios from '../../../util/axios';
import { ppConstants } from '../../../util/ppConstants';

// CancelReason must be consistent with the Backend. See: com/prodega/model/customerorder/CposCancelReason.java
export enum CancelReason {
  ORDER_CANCELED = 11,
  ORDER_EXPIRED = 12
}

function convertDateToUnixDateStringIfNeeded(deliveryDateParam: Date | string): string {
  // NOTE: Seit ProlixPro 8 (Wildfly) müssen DateTime Query Parameters zwingend
  // als Unix-Timestamp (Number) oder 'yyyy-MM-dd' übergeben werden. ISO-Strings (z.B. '2020-11-24T00:00:00.000Z') funktionieren nicht!
  let deliveryDate: string;
  if (deliveryDateParam instanceof Date) {
    const localFormat = 'yyyy-MM-dd';
    deliveryDate = format(deliveryDateParam, localFormat);
  } else {
    deliveryDate = deliveryDateParam;
  }
  return deliveryDate;
}

const CartService = {
  addPosition(data: IAddCustomerOrderCommandParamsData[]) {
    return axios.post(ppConstants.API_HOST_SEC + 'positions/add', data);
  },
  createOrder(deliveryDate: string | undefined, isTempCashAccepted: boolean) {
    const params = {
      system: 'WEBSHOP',
      deliveryDate,
      tempCash: isTempCashAccepted
    };
    return axios.post(ppConstants.API_HOST_SEC + 'customerorders', {}, { params });
  },
  sendCommands(commands: ICustomerOrderPositionCommandData[]) {
    return axios.post(ppConstants.API_HOST_SEC + 'positions/commands', commands);
  },
  updatePosition(id: number, articleNumber: string, quantity: number, deliveryDate: string | undefined, externalArticle: boolean) {
    const params = {
      articleNumber,
      quantity,
      deliveryDate,
      externalArticle
    };

    return axios.put(ppConstants.API_HOST_SEC + 'positions/' + id, {}, { params });
  },
  removePosition(id: number, externalArticle: boolean) {
    const params = { externalArticle };
    return axios.delete(ppConstants.API_HOST_SEC + 'positions/' + id, {
      params
    });
  },
  changeDate(id: number, deliveryDateParam: Date | string | undefined) {
    // Aufträge können "verfallen" weil das Lieferdatum nicht mehr gültig ist oder weil sie eine Weile nicht mehr verändert wurden (lastModified zu weit in der Vergangenheit)
    // Wenn wir ein deliveryDate mit Wert `null` zum Server schickenist,
    // dann wird das Lieferdatum auf denselben Wert wie vorher gesetzt, aber das lastModified Date wird neu gesetzt
    // -> Auftrag ist nicht mehr "verfallen"
    let deliveryDate: string | undefined;
    if (deliveryDateParam) {
      deliveryDate = convertDateToUnixDateStringIfNeeded(deliveryDateParam);
    } else {
      deliveryDate = undefined;
    }
    const params = {
      deliveryDate
    };
    return axios.post(ppConstants.API_HOST_SEC + `customerorders/${id}/changedate`, {}, { params });
  },
  changeDateWithAvailabilityCheck(id: number, deliveryDateParam: string | Date) {
    const deliveryDate = convertDateToUnixDateStringIfNeeded(deliveryDateParam);
    const params = {
      deliveryDate
    };
    return axios.post(ppConstants.API_HOST_SEC + `customerorders/${id}/changedatewithcheck`, {}, { params });
  },
  setTempCash(id: number) {
    return axios.put(ppConstants.API_HOST_SEC + `customerorders/${id}/tempcash`, {});
  },

  cancelOrder(orderId: number, cancelReasonId: CancelReason) {
    return axios.post(ppConstants.API_HOST_SEC + `customerorders/cancel`, {
      orderId,
      cancelReasonId
    });
  },
  getCurrent() {
    return axios.get(ppConstants.API_HOST_SEC + 'customerorders/current');
  },
  completeOrder(id: number, reference: string, remark: string) {
    const params = {
      reference,
      remark
    };
    return axios.post(ppConstants.API_HOST_SEC + `customerorders/${id}/complete`, {}, { params });
  },
  createOciOrder(ociCallback: string | undefined) {
    if (ociCallback) {
      return axios.post<ICreateOciOrderParamsData>(ppConstants.API_HOST_SEC + 'customerorders/createociorder', { ociCallback });
    } else {
      return axios.post<ICreateOciOrderParamsData>(ppConstants.API_HOST_SEC + 'customerorders/createociorder');
    }
  }
};

export default CartService;
