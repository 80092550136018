import { AxiosResponse } from 'axios';
import axios from '../../../util/axios';
import { ISearchResponseDto } from '../../../api/hub/hub.models.generated';
import type { IArticleOrderEndTimeInfoData, IArticlePriceInfoData, IArticleSpecialDeliveryDateInfoData, ISearchRequestDTOData, ISearchRequestPagingDTOData } from '../../../api';
import { sentryCaptureMessage } from '../../../tracking/tracking';
import { API } from './apiEndpoints';

export async function fetchArticleSearch(searchRequest: ISearchRequestDTOData | ISearchRequestPagingDTOData): Promise<ISearchResponseDto> {
  const response = await axios.post<ISearchRequestDTOData, AxiosResponse<ISearchResponseDto>>(API.ARTICLE_SEARCH, searchRequest);
  return response.data;
}

export async function fetchArticleIndividualPriceInfosPrecalculated(mainArticleIds: number[]) {
  // Der folgende fetch funktioniert nur eingeloggt!
  const response = await axios.get<IArticlePriceInfoData[]>(API.ARTICLEINFO_PRICES, {
    params: {
      mainArticleIds: mainArticleIds
    }
  });
  const priceInfos = response.data;

  if (priceInfos.length !== mainArticleIds.length) {
    const fetchedMainArticleIds = priceInfos.map((a) => a.mainArticleId);
    const maIdsOfMissingPrices = mainArticleIds.filter((maId) => !fetchedMainArticleIds.includes(maId));
    sentryCaptureMessage('Some article prices are not precalculated!', { mainArticleIds: maIdsOfMissingPrices });
  }

  return priceInfos;
}

export async function fetchArticleIndividualPriceInfosWithCalculation(mainArticleId: number) {
  // Der folgende fetch funktioniert nur eingeloggt!
  const response = await axios.get<IArticlePriceInfoData>(API.ARTICLEINFO_PRICE, {
    params: {
      mainArticleId: mainArticleId
    }
  });
  return response.data;
}

export async function fetchIndividualOrderEndTimes(mainArticleIds: number[]) {
  const response = await axios.get<IArticleOrderEndTimeInfoData[]>(API.ARTICLEINFO_ORDERENDTIMES, {
    params: {
      mainArticleIds: mainArticleIds
    }
  });
  const deliveryInfos = response.data;

  return deliveryInfos;
}

export async function fetchIndividualSpecialDeliveryDates(mainArticleIds: number[]) {
  const response = await axios.get<IArticleSpecialDeliveryDateInfoData[]>(API.ARTICLEINFO_SPECIALDELIVERYDATE, {
    params: {
      mainArticleIds: mainArticleIds
    }
  });
  const deliveryInfos = response.data;

  return deliveryInfos;
}
