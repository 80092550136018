import type { ICartResponseData } from '../../../../api';
import CartService from '../../services/cartService';
import { IntentServerState } from '../../types/IntentServerState';
import AbstractOrderIntent from './AbstractOrderIntent';

export default class GetOrderIntent extends AbstractOrderIntent {
  public async internalOrderIntentProcess(resolve: () => Promise<void>): Promise<void> {
    switch (this.internalState.state) {
      case IntentServerState.INITIAL: {
        await this.handleInit();
        break;
      }
      case IntentServerState.SERVER_RESPONSE_ORDER_RECEIVED: {
        await this.handleOrderReceived(resolve);
        break;
      }
    }
  }

  private async handleInit(): Promise<any> {
    await this.handleServiceCallResponse(CartService.getCurrent(), IntentServerState.SERVER_RESPONSE_ORDER_RECEIVED);
  }

  private async handleOrderReceived(resolve: () => Promise<void>): Promise<void> {
    const response = this.internalState.serverResponse!;
    const data = response.data as ICartResponseData;
    const order = data.customerOrder;
    const cartDiscountInfo = data.cartDiscountInfo;
    this.cartStore.orderReceived(order, cartDiscountInfo);
    if (resolve) {
      resolve();
    }
  }
}
