import type { IPositionSubmissionArticleSummaryData } from '../../../../api';
import { makeAutoObservable } from 'mobx';
import { Article } from '../../../shared/articles/models/article';

export class SubmissionPosition {
  id: number;
  articleRef: string;
  article: Article;
  positionAvailable: boolean;

  constructor(dto: IPositionSubmissionArticleSummaryData) {
    makeAutoObservable(this);

    this.id = dto.id;

    this.articleRef = dto.articleRef;
    this.article = new Article();
    this.article.mainArticleId = dto.mainArticleId;
    this.article.ean = dto.ean;
    this.article.amount = dto.amount;
    this.article.articleNumber = dto.articleNumber;
    this.article.articleStatus = dto.articleStatus?.toString();
    this.article.notInWebShop = dto.notInWebshop;
    this.positionAvailable = dto.positionAvailable;
  }
}
