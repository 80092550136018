import type { IArticleDTOData, ICareDetailDTOData, IHwgData, ISuggestTermData, IWebHwgDetailDTOData, IWebPromotionData, IWebshopMessageData, IWineSocietyDTOData } from '../../../api';
import axios from '../../../util/axios';
import { ppConstants } from '../../../util/ppConstants';

const MasterDataService = {
  async fetchSellUnits(language: string): Promise<any> {
    const response = await axios.get(ppConstants.API_HOST_PUB + 'webshop/sellunits', {
      params: {
        language
      }
    });
    return response.data;
  },

  async fetchMessages(): Promise<IWebshopMessageData[]> {
    const response = await axios.get(ppConstants.API_HOST_PUB + 'webshop/messages');
    return response.data;
  },

  async setMessageAsRead(messageId: any) {
    const response = await axios.put(ppConstants.API_HOST_PUB + 'webshop/messages/' + messageId, {}, {});
    return response.data;
  },

  async fetchHwgs(): Promise<IHwgData[]> {
    const response = await axios.get(ppConstants.API_HOST_PUB + 'webshop/hwgs');
    return response.data;
  },

  async fetchHwgDetail(hwgId: any): Promise<IWebHwgDetailDTOData> {
    const response = await axios.get(ppConstants.API_HOST_PUB + 'webshop/hwgs/' + hwgId + '/detail');
    return response.data;
  },

  async fetchCareDetail(): Promise<ICareDetailDTOData> {
    const response = await axios.get(ppConstants.API_HOST_PUB + 'webshop/care');
    return response.data;
  },

  async fetchProfitPlusSuppliers(): Promise<any> {
    const response = await axios.get(ppConstants.API_HOST_PUB + 'webshop/ppsupps');
    return response.data;
  },

  async fetchPromotions(): Promise<IWebPromotionData[]> {
    const response = await axios.get(ppConstants.API_HOST_PUB + 'webpromotions');
    return response.data;
  },

  async fetchPromotionPreview(promotionId: number): Promise<IWebPromotionData> {
    const response = await axios.get(ppConstants.API_HOST_PUB + 'webpromotions/' + promotionId);
    return response.data;
  },

  async fetchPromotion(promotionId: number): Promise<IWebPromotionData> {
    const response = await axios.get(ppConstants.API_HOST_PUB + 'webpromotions/' + promotionId);
    return response.data;
  },

  async fetchPromotionArticles(promotionId: any): Promise<IArticleDTOData[]> {
    const response = await axios.get(ppConstants.API_HOST_PUB + 'webpromotions/' + promotionId + '/articles');
    return response.data;
  },

  async fetchActionArticles(): Promise<IArticleDTOData[]> {
    const response = await axios.get(ppConstants.API_HOST_PUB + 'articles/actions', { params: {} });
    return response.data;
  },

  async fetchActivePublis(): Promise<any> {
    const response = await axios.get(ppConstants.API_HOST_PUB + 'webshop/publis');
    return response.data;
  },

  async fetchWineSocieties(): Promise<IWineSocietyDTOData[]> {
    const response = await axios.get(ppConstants.API_HOST_PUB + 'webshop/wineSociety');
    return response.data;
  },

  async fetchSearchSuggestions(term: string): Promise<string[]> {
    const response = await axios.get<ISuggestTermData[]>(ppConstants.API_HOST_PUB + 'articles/suggest', { params: { term } });
    return response.data.map((x) => x.term);
  }
};

export default MasterDataService;
