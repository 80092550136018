import type { IArticleDetailDTOData, IWebArticleSearchResultDTOData } from '../../../api';
import axios from '../../../util/axios';
import { ppConstants } from '../../../util/ppConstants';
import { CATALOGSEARCHTYPE_ALLARTICLE, ROW_COUNT } from '../util/catalogConstants';

const CatalogService = {
  async queryCatalog(loggedIn: boolean, searchTerm = '*', start = 0, rows = ROW_COUNT, searchType = CATALOGSEARCHTYPE_ALLARTICLE, logSearch = false, facetFilter?: { name: string; values: string[] }[]) {
    const path = loggedIn ? ppConstants.API_HOST_SEC + 'search' : ppConstants.API_HOST_PUB + 'articles/search';

    let filter = null;
    if (facetFilter && facetFilter.length > 0) {
      //filter = facetFilter.map((x, index)=>`${x.name}=${x.values}` );
      filter = JSON.stringify(facetFilter);
    }

    const response = await axios.get(path, {
      params: {
        term: searchTerm,
        start,
        rows,
        searchType,
        withPrices: true,
        logSearch,
        filter
      }
    });
    response.data.searchType = searchType;

    return response.data;
  },

  async fetchInitialCatalogItems(loggedIn: boolean, activeSearchType: any, searchTerm: string = '*', logSearch: boolean = false): Promise<IWebArticleSearchResultDTOData> {
    return this.queryCatalog(loggedIn, searchTerm, 0, ROW_COUNT, activeSearchType, logSearch);
  },

  async fetchCatalogItems(
    loggedIn: boolean,
    activeSearchType: string,
    searchTerm: string = '*',
    logSearch: boolean = false,
    sortBy: string,
    facetFilter: { name: string; values: string[] }[] | undefined,
    start: number = 0,
    rows: number = ROW_COUNT
  ): Promise<IWebArticleSearchResultDTOData> {
    return this.queryCatalog(loggedIn, searchTerm, start, rows, activeSearchType, logSearch, facetFilter);
  },

  async fetchCatalogItem(articleNumber: string, isLoggedIn: boolean): Promise<IArticleDetailDTOData> {
    const response = await axios.get<IArticleDetailDTOData>((isLoggedIn ? ppConstants.API_HOST_SEC : ppConstants.API_HOST_PUB) + 'articles/' + articleNumber);
    return response.data;
  },

  async fetchSimilarArticles(isLoggedIn: boolean, articleText: string, facetfilter: any, start = 0, rows = 7): Promise<IWebArticleSearchResultDTOData> {
    const response = await axios.post((isLoggedIn ? ppConstants.API_HOST_SEC : ppConstants.API_HOST_PUB) + 'articles/search', facetfilter, {
      params: {
        term: articleText,
        start,
        rows,
        searchWithOr: true,
        withPrices: true
      }
    });

    return response.data;
  }
};

export default CatalogService;
