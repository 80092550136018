import React from 'react';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';
import transgourmetTheme from '../../../../config/transgourmet/transgourmetTheme';
import { URL_DATENSCHUTZ } from '../../../../config/transgourmet/transgourmetConfig';

export function CookieConsentBanner() {
  const { t } = useTranslation();
  return (
    <CookieConsent
      buttonText={t('Schliessen')}
      buttonStyle={{
        backgroundColor: transgourmetTheme.palette.primary.main,
        color: transgourmetTheme.palette.common.white
      }}
    >
      {t('COOKIE_CONSENT_TEXT')}{' '}
      <a style={{ color: transgourmetTheme.palette.common.white }} href={URL_DATENSCHUTZ}>
        {' '}
        {t('COOKIE_CONSENT_LINK')}.
      </a>
    </CookieConsent>
  );
}
