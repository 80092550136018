import type { IWebshopMessageData, IWebshopMessageTypeData } from '../../../api';
import { makeAutoObservable } from 'mobx';
import { getMLSelector } from '../../../util/i18n';

export class Message {
  id: number;
  title: string;
  content: string;
  startDate: Date;
  endDate: Date;
  type: IWebshopMessageTypeData;

  constructor(dto: IWebshopMessageData) {
    makeAutoObservable(this);

    const mls = getMLSelector();

    this.id = dto.id;
    this.title = mls([dto.titleDe, dto.titleFr, dto.titleIt]);
    this.content = mls([dto.messageDe, dto.messageFr, dto.messageIt]);
    this.startDate = dto.startDate ? (dto.startDate as any) : undefined;
    this.endDate = dto.endDate ? (dto.endDate as any) : undefined;
    this.type = dto.type;
  }
}
