import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Article } from '../../models/article';
import { observer } from 'mobx-react-lite';
import { Grid, Typography } from '@mui/material';
import { StoreContext } from '../../../../../StoreContext';
import IconButton from '@mui/material/IconButton';
import PdfIcon from 'mdi-material-ui/FilePdfBox';
import getFileByRequest from '../../../../../util/fileUtil';
import { ppConstants } from '../../../../../util/ppConstants';
import { FactDocument } from '../../models/factDocument';
import OutboundLink from '../../../components/OutboundLink';
import OpenInNewIcon from 'mdi-material-ui/OpenInNew';
import FileIcon from 'mdi-material-ui/FileDocument';

interface IProductInformationDownloadsAndLinksProps {
  article: Article;
}

const ProductInformationDownloadsAndLinks = observer(({ article }: IProductInformationDownloadsAndLinksProps) => {
  const { userStore, uiStore } = useContext(StoreContext);
  const { t } = useTranslation();

  if (!userStore.isLoggedIn) {
    return null;
  }

  const isCustomer = userStore.loginCustomer?.displayName && (userStore.loginCustomer.webShopAdmin || userStore.loginCustomer.agbAccepted);

  return (
    <Grid container direction={'column'} sx={{ width: 'fit-content', maxWidth: 'none' }}>
      <Grid item>
        <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'} wrap={'nowrap'}>
          <Grid item>
            <Typography variant={'body2'} color={'primary'}>
              {t('Produktkurzspezifikation')}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              color={'primary'}
              onClick={() => {
                getFileByRequest(ppConstants.API_HOST_PUB + 'articles/factsheet/' + article.articleOrZzArticle.articleNumber, { language: uiStore.language });
              }}
              id={'product-info-pdf-download'}
            >
              <PdfIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {isCustomer &&
        article.factDocuments.map((factDoc: FactDocument, index: number) => (
          <Grid item key={index}>
            <Grid container spacing={2} justifyContent={'space-between'} alignItems={'center'} wrap={'nowrap'}>
              <Grid item>
                <Typography variant={'body2'}>{factDoc.name ? factDoc.name : factDoc.documentName}</Typography>
              </Grid>
              <Grid item>
                {factDoc.url ? (
                  <OutboundLink target="_blank" href={factDoc.url} sx={{ height: '24px' }}>
                    <IconButton>
                      <OpenInNewIcon color={'secondary'} />
                    </IconButton>
                  </OutboundLink>
                ) : (
                  <IconButton
                    onClick={() => {
                      getFileByRequest(factDoc.downloadUrl);
                    }}
                  >
                    <FileIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
});

export default ProductInformationDownloadsAndLinks;
