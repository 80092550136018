import Grid from '@mui/material/Grid';
import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { IDeliveryAssortmentData } from '../../../../api';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

const useStyles = makeStyles()(({ spacing, palette }: Theme) => {
  return {
    root: {
      height: '100%',
      paddingLeft: spacing(4),

      '@media (max-width:600px)': {
        paddingLeft: 0
      }
    },
    cell: {
      height: spacing(3),
      width: spacing(3),
      boxShadow: 'inset 0px 0px 0px 1px ' + palette.grey.A100,
      margin: '2px',
      marginRight: spacing(1)
    },
    today: {
      boxShadow: 'inset 0px 0px 0px 3px ' + palette.grey.A700
    },
    weekDayFirstDelivery: {
      boxShadow: 'inset 0px 0px 0px 3px red'
    },
    selected: {
      backgroundColor: 'red'
    }
  };
});

interface IDeliveryDaysLegend {
  deliveryAssortments: IDeliveryAssortmentData[];
  isReadonly: boolean;
}

const DeliveryDaysLegend = observer(({ deliveryAssortments, isReadonly }: IDeliveryDaysLegend) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Grid className={classes.root} item={true} container={true} direction={'column'} justifyContent={'flex-end'}>
      <Grid item={true} container={true} alignItems={'center'}>
        <Typography variant={'h6'}>{t('Legende')}</Typography>
      </Grid>

      <Grid item={true} container={true} wrap={'nowrap'} alignItems={'center'}>
        <Grid item={true} className={classNames(classes.cell, classes.today)} />
        <Grid item={true}>
          <Typography variant={'body1'} color={'secondary'}>
            {t('Aktueller Tag')}
          </Typography>
        </Grid>
      </Grid>

      <Grid item={true} container={true} wrap={'nowrap'} alignItems={'center'}>
        <Grid item={true} className={classNames(classes.cell, classes.weekDayFirstDelivery)} />
        <Grid item={true}>
          <Typography variant={'body1'} color={'secondary'}>
            {t('Nächstmöglicher Liefertag')}
          </Typography>
        </Grid>
      </Grid>

      {!isReadonly && (
        <Grid item={true} container={true} wrap={'nowrap'} alignItems={'center'}>
          <Grid item={true} className={classNames(classes.cell, classes.selected)} />
          <Grid item={true}>
            <Typography variant={'body1'} color={'secondary'}>
              {t('Selektierter Liefertag')}
            </Typography>
          </Grid>
        </Grid>
      )}

      {deliveryAssortments.map((entry: IDeliveryAssortmentData, index: number) => {
        const cellStyle = {
          backgroundColor: 'rgb(' + entry.r + ', ' + entry.g + ', ' + entry.b + ')'
        };
        return (
          <Grid item={true} container={true} wrap={'nowrap'} key={index} alignItems={'center'}>
            <Grid item={true} style={cellStyle} className={classes.cell} />
            <Grid item={true}>
              <Typography variant={'body1'} color={'secondary'}>
                {t(entry.name)}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
});

export default DeliveryDaysLegend;
