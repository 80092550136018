import { eachDayOfInterval, endOfISOWeek, format, isValid, startOfISOWeek } from 'date-fns';
import { de, fr, it } from 'date-fns/locale';

export const isToday = (date: Date) => {
  const today = new Date();
  return today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate();
};

export const stripTime = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const addDay = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
};

export const formatDateByLanguage = (date: Date, language: string, dateFormat?: string) => {
  return formatDate(date, dateFormat ? dateFormat : 'dd.MM.yyyy', getLocale(language));
};

export const formatDate = (date: Date, dateFormat?: string, locale?: Locale): string | undefined => {
  if (isValid(date)) {
    return format(date, dateFormat ? dateFormat : 'dd.MM.yyyy', {
      locale: locale
    });
  }
  return undefined;
};

export const getLocale = (language: string): Locale => {
  switch (language) {
    case 'de':
      return de;
    case 'fr':
      return fr;
    case 'it':
      return it;
    default:
      return de;
  }
};

export const weekdays = (language: string): string[] => {
  const locale = getLocale(language);

  const start = startOfISOWeek(new Date());
  const end = endOfISOWeek(new Date());

  const arr = eachDayOfInterval({ start: start, end: end });

  const weekdays = arr.map((a) => formatDate(a, 'EEEEEE', locale)).filter<string>((d): d is string => d !== undefined);

  return weekdays;
};
