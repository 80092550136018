import { ISearchRequestPagingDTOData } from '../../../api';

export const defaultPageSize: number = 50;

export function getDefaultSearchRequest(): ISearchRequestPagingDTOData {
  return {
    page: 0,
    pageSize: defaultPageSize,
    searchTerm: '',
    brands: [],
    rohstoffHerkunft: [],
    hergestellt: [],
    ecoScore: [],
    labels: [],
    specialDiet: [],
    allergene: []
  };
}
