import type { IPositionSubmissionArticleSummaryData } from '../../../../api';
import { makeAutoObservable } from 'mobx';
import { Article } from '../../../shared/articles/models/article';

export class SubmitPosition {
  posNr: number;
  scanId: string;
  article: Article;

  constructor(dto: IPositionSubmissionArticleSummaryData, scanId: string) {
    makeAutoObservable(this);

    this.posNr = dto.id;
    this.scanId = scanId;

    this.article = new Article();
    this.article.mainArticleId = dto.mainArticleId;
    this.article.articleNumber = dto.articleNumber;
    this.article.ean = dto.ean;
    this.article.amount = dto.amount;
    this.article.notInWebShop = dto.notInWebshop;
    this.article.fetchArticleDeepLoaded(true);
  }

  get articleNotFound() {
    return !this.article.articleNumber && (!this.article.articleId || this.article.articleId <= 0);
  }
  get notInWebShop() {
    return this.article && this.article.notInWebShop;
  }
}
