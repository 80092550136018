import type { IShipmentWebshopPresentationData } from '../../../../api';
import { makeAutoObservable } from 'mobx';

export class Shipment {
  id: number;
  deliveryDate: Date;

  constructor(dto: IShipmentWebshopPresentationData) {
    makeAutoObservable(this);

    this.id = dto.shipmentNr;
    this.deliveryDate = new Date(dto.deliveryDate as any);
  }
}
