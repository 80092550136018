import type { ICustomerOrderPresentationData, ICustomerOrderStateData, ICustomerOrderTypeData } from '../../../../api';
import { makeAutoObservable } from 'mobx';

export class CustomerOrder {
  id: number;
  creationDate: Date;
  customerNumber: string;
  deliveryDateInfo: string;
  stateText: string;
  state: ICustomerOrderStateData;
  creationSystemDecriptionText: string;
  type: ICustomerOrderTypeData;
  typeText: string;
  userLoginName: string;
  posCount: number;
  extArtPosCount: number;
  totalPrice: number;
  isExpanded: boolean = true;

  constructor(dto: ICustomerOrderPresentationData) {
    makeAutoObservable(this);

    this.id = dto.id;
    this.customerNumber = dto.customerNumber;
    this.creationDate = new Date(dto.creationDate as any);
    this.deliveryDateInfo = dto.deliveryDateInfo;
    this.stateText = dto.stateText;
    this.state = dto.state;
    this.creationSystemDecriptionText = dto.creationSystemDecriptionText;
    this.type = dto.type;
    this.typeText = dto.typeText;
    this.userLoginName = dto.userLoginName;
    this.posCount = dto.posCount;
    this.extArtPosCount = dto.extArtPosCount;
    this.totalPrice = dto.totalPrice;
  }
}
