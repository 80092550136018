import type { IWebInventoryData, IWebInventoryDetailData, IWebInventoryPositionArticleData, IWebInventoryPositionData } from '../../../api';
import { makeAutoObservable } from 'mobx';
import i18n from 'i18next';
import { InventoryDetailStore } from '../stores/inventoryDetailStore';
import InventoryPosition from './inventoryPosition';
import { formatDate } from '../../../util/dateUtils';

export default class InventoryDetail {
  static createFromInventoryDetailDto(store: InventoryDetailStore, dto: IWebInventoryDetailData): InventoryDetail {
    const inventory = new InventoryDetail(store);
    inventory.id = dto.inventory.id;
    inventory.name = dto.inventory.name;
    inventory.isReadOnly = !!dto.inventory.endDate;
    inventory.startCalcPrice = dto.inventory.startCalcPrice;
    inventory.endCalcPrice = dto.inventory.endCalcPrice;
    inventory.creationDate = new Date(dto.inventory.creationDate as any);
    inventory.endDate = dto.inventory.endDate ? new Date(dto.inventory.endDate as any) : undefined;
    inventory.positions = dto.positions.map((positionDto: IWebInventoryPositionArticleData) => InventoryPosition.createFromDto(positionDto));
    return inventory;
  }

  static createFromInventoryDto(store: InventoryDetailStore, dto: IWebInventoryData): InventoryDetail {
    const inventory = new InventoryDetail(store);
    inventory.id = dto.id;
    inventory.name = dto.name;
    inventory.isReadOnly = !!dto.endDate;
    inventory.startCalcPrice = dto.startCalcPrice;
    inventory.endCalcPrice = dto.endCalcPrice;
    inventory.creationDate = new Date(dto.creationDate as any);
    inventory.endDate = dto.endDate ? new Date(dto.endDate as any) : undefined;
    inventory.positions = dto.positions.map((positionDto: IWebInventoryPositionData) => InventoryPosition.createFromInventoryPositionDto(positionDto));
    return inventory;
  }

  store: InventoryDetailStore;

  id!: number;
  positions: InventoryPosition[] = [];
  name!: string;
  isReadOnly!: boolean;
  startCalcPrice: any;
  endCalcPrice: any;
  creationDate!: Date;
  endDate?: Date;

  constructor(store: InventoryDetailStore) {
    makeAutoObservable(this);
    this.store = store;
  }

  removePosition(id: number) {
    this.positions = this.positions.filter((x) => x.id !== id);
  }

  updateAllRatings(rating: number) {
    const ids = this.positions.map((x) => x.id);
    this.store.updatePositionsRating(ids, rating);
  }

  calculatePrices() {
    this.store.calculatePrices(this.id);
  }

  setShowSureToCalcPricesDialog(show: boolean) {
    this.store.setShowSureToCalcPricesDialog(show);
  }

  setShowSureToCloseDialog(show: boolean) {
    this.store.setShowSureToCloseDialog(show);
  }

  get isClosable(): boolean {
    return this.isReadOnly || !this.endCalcPrice;
  }

  get isCalculationInProgress() {
    return this.store.isCalculatingPrices;
  }

  get isMassMutationInProgress() {
    return this.store.isPositionMassMutationLoading;
  }

  get displayName() {
    let name = this.name;
    if (this.isCalculationInProgress) {
      name += ' (' + i18n.t('Preisberechnung läuft') + ')';
    }
    return name;
  }

  get total(): { totalRated: number; totalPrice: number } {
    let totalRated = 0;
    let totalPrice = 0;
    if (this.positions) {
      for (const position of this.positions) {
        if (!position.price || !position.sellAmount) {
          continue;
        }
        totalRated += position.price * position.quantity * position.sellAmount * (position.rating / 100);
        totalPrice += position.price * position.quantity * position.sellAmount;
      }
    }

    return { totalRated, totalPrice };
  }

  get hasPositions() {
    return !!this.positions && this.positions.length > 0;
  }

  get startFormatted() {
    return formatDate(this.creationDate);
  }

  get positionCount() {
    return this.positions ? this.positions.length : 0;
  }

  get endDateFormatted() {
    return this.endDate ? formatDate(this.endDate) : i18n.t('noch offen');
  }

  get hasUnratedPositions() {
    return this.positions.findIndex((x) => x.rating === 0) > -1;
  }

  updateFromInventoryDto(dto: IWebInventoryData) {
    this.id = dto.id;
    this.name = dto.name;
    this.isReadOnly = !!dto.endDate;
    this.startCalcPrice = dto.startCalcPrice;
    this.endCalcPrice = dto.endCalcPrice;
    this.creationDate = new Date(dto.creationDate as any);
    this.endDate = dto.endDate ? new Date(dto.endDate as any) : undefined;
  }

  updateFromInventoryDetailDto(dto: IWebInventoryDetailData) {
    this.id = dto.inventory.id;
    this.name = dto.inventory.name;
    this.isReadOnly = !!dto.inventory.endDate;
    this.startCalcPrice = dto.inventory.startCalcPrice;
    this.endCalcPrice = dto.inventory.endCalcPrice;

    for (const detailDto of dto.positions) {
      const position = this.positions.find((x) => x.id === detailDto.position.id);
      if (!position) {
        this.positions.push(InventoryPosition.createFromDto(detailDto));
      } else {
        position.updateFromInventoryPositionArticle(detailDto);
      }
    }
  }
}
