import React, { useContext } from 'react';
import { Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Article } from '../../models/article';
import { formatDateByLanguage } from '../../../../../util/dateUtils';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../../../../StoreContext';
import { makeStyles } from 'tss-react/mui';

interface IPriceProps {
  item: Article;
  id?: string;
  actionExclude?: boolean;
}

const useStyles = makeStyles()((theme: Theme) => {
  return {
    sellUnit: {
      textAlign: 'left'
    },
    price: {
      backgroundColor: 'white',
      color: 'black',
      height: theme.spacing(10),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    actionPrice: {
      backgroundColor: theme.palette.primary.main, //'#E20026',
      color: theme.palette.common.white,
      height: theme.spacing(10),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    pricePart1: {
      textAlign: 'center',
      fontSize: '3rem'
    },
    pricePart2: {
      textAlign: 'center',
      fontSize: '2.125rem'
    }
  };
});

function Price({ item, id, actionExclude }: Readonly<IPriceProps>) {
  const { uiStore } = useContext(StoreContext);
  const { t } = useTranslation();
  const { classes } = useStyles();

  const price = actionExclude ? item.regularPrice : item.effectivePrice;

  if (price == null) {
    return null; // article without price should not show any price information
  }

  const priceParts = price.toFixed(2).toString().split('.');

  return (
    <div className={item.displayAsAction && !actionExclude ? classes.actionPrice : classes.price}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '3px'
        }}
      >
        <Typography variant="caption" color={'inherit'} className={classes.sellUnit} id={id + '-price-sellunit-text'}>
          {item.articleOrZzArticle.articleUnit}
        </Typography>
        {item.displayAsAction && !actionExclude && item.articleActionValidTo && (
          <Typography variant="caption" color={'inherit'} id={id + '-valid-to'}>
            {t('Gültig bis')}: {formatDateByLanguage(item.articleActionValidTo, uiStore.language)}
          </Typography>
        )}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
            <Typography variant="caption" color={'inherit'} style={{ textAlign: 'left' }} id={id + '-currency-text'}>
              CHF
            </Typography>
            <Typography variant="h3" color={'inherit'} className={classes.pricePart1} id={id + '-price-part1-text'}>
              {priceParts[0]}
            </Typography>
            <Typography variant="h4" color={'inherit'} className={classes.pricePart2} id={id + '-price-part2-text'}>
              {priceParts[1]}
            </Typography>
          </div>
          {item.displayAsAction && !actionExclude && (
            <Typography variant="caption" color={'inherit'} style={{ alignSelf: 'flex-end', textDecoration: 'line-through' }} id={id + '-action-oldprice'}>
              {`${t('CHF')} ${item.regularPrice.toFixed(2)}`}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}

export default observer(Price);
