import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import i18n from 'i18next';
import { FEATURES } from '../modules/common/stores/featureStore';
import { rootStore } from '../StoreContext';
import { Order } from '../modules/cart/stores/order';

let eventsToSendLater: TrackingInfo[] = [];

type GoogleAnalyticsEvent = 'other' | 'detail' | 'purchase';
type TrackingInfo = ReturnType<typeof createDefaultTrackingInfo>;
type PurchaseTrackingInfo = ReturnType<typeof createPurchaseTrackingInfo>;

export function useRouteTrackingForGoogleAnalytics() {
  const location = useLocation();

  useEffect(() => {
    sendEventToGoogleAnalytics('other');
  }, [location]);
}

export function sendEventToGoogleAnalytics(eventName: GoogleAnalyticsEvent, eventTrackingInfo?: Partial<TrackingInfo | PurchaseTrackingInfo>) {
  const featureStore = rootStore.featureStore;
  if (featureStore.isFeatureEnabled(FEATURES.enableTealium)) {
    const utag = (window as any).utag;
    const enableDebugLog = featureStore.isFeatureEnabled(FEATURES.enableDebugLog);

    const defaultTrackingInfo = createDefaultTrackingInfo(eventName);
    const trackingInfo = { ...defaultTrackingInfo, ...eventTrackingInfo };

    if (utag) {
      sendQueuedEvents(enableDebugLog);

      if (enableDebugLog) {
        console.log(`Sending event '${eventName}' to Google Analytics:`, { ...trackingInfo }); // logging a cloned object, because the object is mutated by `utag.view`
      }
      utag.view(trackingInfo);
    } else {
      eventsToSendLater = [...eventsToSendLater, trackingInfo];
      setTimeout(() => sendQueuedEvents(enableDebugLog), 3000);
      if (enableDebugLog) {
        console.log(`Not sending event '${eventName}' to Google Analytics, because 'window.utag' is not available!`);
      }
    }
  }
}

export function sendPurchaseToGoogleAnalytics(order: Order) {
  const purchaseTrackingData = createPurchaseTrackingInfo(order);
  sendEventToGoogleAnalytics('purchase', purchaseTrackingData);
}

function createDefaultTrackingInfo(eventName: GoogleAnalyticsEvent) {
  const language = i18n.language;
  const userStore = rootStore.userStore;

  const defaultTrackingInfo = {
    platform: 'tgch-transgourmet',
    event_attributes_eventName: eventName,
    page_category_00: language,
    page_category_01: 'shop',
    page_pageInfo_language: language,
    page_pageInfo_pageName: 'Transgourmet Web Shop',
    page_attributes_URL: window.location.href,
    user_profile_profileInfo_language: language,
    user_profile_profileInfo_loginStatus: userStore.isLoggedIn ? 'registered' : 'anonymous',
    user_profile_profileInfo_profileID: userStore.selectedCustomer?.kundenNummer ?? ''
  };
  return defaultTrackingInfo;
}

function createPurchaseTrackingInfo(order: Order) {
  const purchaseTrackingData = {
    transaction_transactionID: `${order.id}`,
    product_productInfo_productName: order.positions.map((pos) => pos.articleDescriptionText),
    product_productInfo_sku: order.positions.map((pos) => pos.articleBaseNo),
    transaction_total_totalPrice: `${order.total}`
  };
  return purchaseTrackingData;
}

function sendQueuedEvents(enableDebugLog: boolean) {
  try {
    if (eventsToSendLater.length > 0) {
      const utag = (window as any).utag;

      if (utag) {
        eventsToSendLater.forEach((event) => {
          if (enableDebugLog) {
            console.log(`Sending queued event to Google Analytics, because 'window.utag' is now available!`, { ...event }); // logging a cloned object, because the object is mutated by `utag.view`
          }
          utag.view(event);
        });
      } else {
        if (enableDebugLog) {
          console.log(`Not sending ${eventsToSendLater.length} queued events to Google Analytics, because 'window.utag' is still not available!`);
        }
      }
    }
  } finally {
    eventsToSendLater = [];
  }
}
