const localStorageWrapper = createStorageWrapper(window.localStorage);

const PP_SCANID = 'PP_SCANID';

export function loadScanId(): string | null {
  return localStorageWrapper.getString(PP_SCANID);
}

export function storeScanId(scanId: any) {
  localStorageWrapper.setString(PP_SCANID, scanId);
}

function createStorageWrapper(webStorage: Storage) {
  // Local storage / Session storage can throw exceptions
  // ie: Safari throws an exception in private mode when calling localStorage.setItem

  function setString(key: string, value: string): void {
    try {
      webStorage.setItem(key, value);
    } catch (e) {
      console.error('Setting item into localstorage failed! Key: ' + key, e);
    }
  }

  function getString(key: string): string | null {
    try {
      return webStorage.getItem(key);
    } catch (e) {
      console.error('Getting item from localstorage failed! Key: ' + key, e);
      return null;
    }
  }

  function setObject(key: string, value: any) {
    let jsonValue;
    try {
      jsonValue = JSON.stringify(value);
    } catch {
      throw new Error(`The value can't be stored in web storage, because it can't be serialized to JSON. - Key: ${key} - Value: ${value}`);
    }
    if (jsonValue) {
      setString(key, jsonValue);
    }
  }

  function getObject(key: string): unknown {
    const itemValue = getString(key);
    if (itemValue) {
      try {
        return JSON.parse(itemValue);
      } catch (e) {
        const message = `Value in web storage could is not valid JSON - Key: ${key} - Value: ${itemValue}`;
        console.error(message, e);
        removeItem(key);
      }
    }
    return undefined;
  }

  function removeItem(key: string): void {
    try {
      webStorage.removeItem(key);
    } catch (e) {
      console.error('Removing item from localstorage failed! Key: ' + key, e);
    }
  }

  return {
    getString,
    getObject,
    setString,
    setObject,
    removeItem
  };
}
