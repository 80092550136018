import type { IWebshopMessageData, IWineSocietyDTOData, IWebshopMessageTypeData, IWebHwgDetailDTOData, ICareDetailDTOData, IHwgData } from '../../../api';
import { makeAutoObservable, runInAction } from 'mobx';
import i18n from 'i18next';
import RootStore from '../../../rootStore';
import { getLanguageId, getMLSelector } from '../../../util/i18n';
import { PP_URL } from '../../../util/ppConstants';
import { getQueryParameterByName } from '../../../util/urlUtils';
import { Article } from '../../shared/articles/models/article';
import { messageTypes } from '../models/MasterDataConstants';
import { Message } from '../models/message';
import { Promotion } from '../models/promotion';
import { WineSociety } from '../models/wineSociety';
import MasterDataService from '../services/masterDataService';
import { INotification, NotificationType } from '../../../@tgs-web-ui/notificationcenter';
import { AppError, executeAsyncAction } from '../../../util/exceptionHandler';

export interface IHwgDetailData {
  hwgDetail: IWebHwgDetailDTOData | undefined;
  calculatedArticles: Article[];
}

export default class MasterDataStore {
  private static lsKeyConfirmedNotifications = 'confirmedNotifications';

  private static mapNotificationType(type: IWebshopMessageTypeData): NotificationType {
    switch (type) {
      case 'GENERALMESSAGE':
        return 'general';
      case 'KNOWLEDGEMESSAGE':
        return 'knowledge';
      default:
        return 'important';
    }
  }

  rootStore: RootStore;

  sellUnits: any;
  hwgs: IHwgData[] = [];
  hwgDetailData: IHwgDetailData = {
    calculatedArticles: [],
    hwgDetail: undefined
  };
  careDetail: ICareDetailDTOData | undefined;
  promotions: Promotion[] = [];
  promotion: Promotion | undefined;
  promotionArticles: Article[] = [];
  actionArticles: Article[] = [];
  messages: Message[] = [];
  publis: any[] = [];
  profitPlusSuppliers: any[] = [];
  globalError: any;
  showLoginTimeout: boolean = false;
  pendingRequestsCount = 0;
  wineSocieties: WineSociety[] = [];
  isNotificationDialogOpen: boolean = false;
  unreadNotificationCount: number = 0;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  getSellUnit = (sellUnitId: any) => {
    if (this.sellUnits) {
      return this.sellUnits[sellUnitId];
    }
  };

  get isLoading() {
    return this.pendingRequestsCount > 0;
  }

  get sortedPromotionArticles() {
    const mls = getMLSelector();
    return this.promotionArticles.slice().sort((a1, a2) => {
      let sortValue = a1.hwgId - a2.hwgId;
      if (sortValue === 0) {
        sortValue = mls(a1.articleText).localeCompare(mls(a2.articleText));
      }
      return sortValue;
    });
  }

  get getPromotionNames() {
    let name = this.promotion?.name ?? '';
    if (this.promotion?.isDateVisible && this.promotion?.endDate !== null) {
      const dateStart = this.promotion.startDate ? new Date(this.promotion.startDate) : null;
      const startDate = dateStart ? dateStart.getDate() + '.' + (dateStart.getMonth() + 1) + '.' + dateStart.getFullYear() : '';

      const dateEnd = this.promotion.endDate ? new Date(this.promotion.endDate) : null;
      const endDate = dateEnd ? dateEnd.getDate() + '.' + (dateEnd.getMonth() + 1) + '.' + dateEnd.getFullYear() : '';

      name = name + ', ' + i18n.t('gültig von {{0}} bis {{1}}', { 0: startDate, 1: endDate });
    }

    return name;
  }

  get sortedCareCategories(): string[] {
    if (this.careDetail) {
      const mls = getMLSelector();
      const categories = this.careDetail.categories.map((x) => mls(x.texts));
      return categories.sort(([a], [b]) => a.localeCompare(b));
    }

    return [];
  }

  get sortedMessages(): Message[] {
    return this.messages.slice().sort((m1, m2) => {
      if (messageTypes[m1.type].sortIndex < messageTypes[m2.type].sortIndex) {
        return -1;
      }
      if (messageTypes[m1.type].sortIndex > messageTypes[m2.type].sortIndex) {
        return 1;
      }

      const d1 = new Date(m1.startDate);
      const d2 = new Date(m2.startDate);
      return d1.getTime() - d2.getTime();
    });
  }

  get filteredWineSocieties() {
    if (this.wineSocieties) {
      return this.wineSocieties.filter((w) => w.show);
    }
    return [];
  }

  get notifications(): INotification[] {
    return this.sortedMessages.map((m: Message) => {
      return {
        id: m.id.toString(),
        title: m.title,
        content: m.content,
        type: MasterDataStore.mapNotificationType(m.type)
      };
    });
  }

  get hasNotifications() {
    return this.notifications && this.notifications.length > 0;
  }

  get getHwgsByCustomer() {
    return this.rootStore.userStore.isLoggedIn ? this.hwgs.filter((h) => this.rootStore.userStore.hwgs.find((hw) => hw === h.id)) : this.hwgs;
  }

  fetchInitialMasterData() {
    this.fetchMessages();
    this.fetchHwgs();
    this.fetchProfitPlusSuppliers();
    this.fetchWineSocieties();
  }

  setShowLoginTimeout() {
    this.showLoginTimeout = true;
  }

  clearGlobalError() {
    this.globalError = null;
    this.showLoginTimeout = false;
  }

  clearHwgDetail() {
    this.hwgDetailData.hwgDetail = undefined;
    this.hwgDetailData.calculatedArticles = [];
  }

  fetchMessages() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const messages = await MasterDataService.fetchMessages();
        this.setMessages(messages);
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  setMessages(messages: IWebshopMessageData[]) {
    this.messages = messages.map((x: IWebshopMessageData) => new Message(x));
    this.calculateUnreadNotificationCount();

    const confirmedNotificationsString = localStorage.getItem(MasterDataStore.lsKeyConfirmedNotifications);

    if (confirmedNotificationsString) {
      const confirmedNotifications = JSON.parse(confirmedNotificationsString!);
      const showDialog = this.notifications.map((x) => x.id).some((v) => confirmedNotifications.indexOf(v) === -1);
      if (showDialog) {
        this.openNotificationDialog();
      }
    } else {
      this.openNotificationDialog();
    }
  }

  fetchSellUnits(language: string) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;

        const sellUnits = await MasterDataService.fetchSellUnits(language);
        runInAction(() => {
          this.sellUnits = sellUnits;
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  fetchHwgs() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const loadedHwgs = await MasterDataService.fetchHwgs();

        runInAction(() => {
          const langId = getLanguageId();
          const sortedHwgs = loadedHwgs.toSorted((a, b) => {
            const aName = a.texts.find((t) => t.languageId === langId)!.name;
            const bName = b.texts.find((t) => t.languageId === langId)!.name;
            return aName.localeCompare(bName);
          });
          this.hwgs = sortedHwgs;
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  fetchHwgDetail(hwgId: any) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const hwgDetail = await MasterDataService.fetchHwgDetail(hwgId);
        runInAction(() => {
          this.hwgDetailData.hwgDetail = hwgDetail;
          this.hwgDetailData.calculatedArticles = hwgDetail.articles.map((a) => Article.createFromDto(a, 1));
          this.hwgDetailData.hwgDetail.hagrs = hwgDetail.hagrs
            ? hwgDetail.hagrs.sort((a1, a2) => {
                return a1.localeCompare(a2);
              })
            : [];
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  fetchCareDetail() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const careDetail = await MasterDataService.fetchCareDetail();
        runInAction(() => {
          this.careDetail = careDetail;
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  fetchProfitPlusSuppliers() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const profitPlusSuppliers = await MasterDataService.fetchProfitPlusSuppliers();
        runInAction(() => {
          this.profitPlusSuppliers = profitPlusSuppliers;
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  fetchPromotions() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const promotions = await MasterDataService.fetchPromotions();
        runInAction(() => {
          this.promotions = promotions.map((dto) => new Promotion(dto));
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  fetchPromotionPreview(promotionId: number) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const promotionDto = await MasterDataService.fetchPromotionPreview(promotionId);
        runInAction(() => {
          this.promotion = new Promotion(promotionDto);
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  fetchPromotion(promotionId: number) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const promotionDto = await MasterDataService.fetchPromotion(promotionId);
        runInAction(() => {
          this.promotion = new Promotion(promotionDto);
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  fetchPromotionArticles(promotionId: number) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const articles = await MasterDataService.fetchPromotionArticles(promotionId);
        runInAction(() => {
          this.promotionArticles = articles.map((a) => Article.createFromDto(a, 1));
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  fetchActionArticles() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const articles = await MasterDataService.fetchActionArticles();
        runInAction(() => {
          this.actionArticles = articles.map((a) => Article.createFromDto(a, 1));
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  fetchActivePublis() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const publis = await MasterDataService.fetchActivePublis();
        runInAction(() => {
          this.publis = publis;
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  changeLocale() {
    const langId = getLanguageId();
    const sortedHwgs = this.hwgs.toSorted((a, b) => {
      const aName = a.texts.find((t) => t.languageId === langId)!.name;
      const bName = b.texts.find((t) => t.languageId === langId)!.name;
      return aName.localeCompare(bName);
    });
    this.hwgs = sortedHwgs;
  }

  fetchWineSocieties() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const societies = await MasterDataService.fetchWineSocieties();
        runInAction(() => {
          this.wineSocieties = societies.map((x: IWineSocietyDTOData) => new WineSociety(x));
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  setGlobalError(appError: AppError) {
    if (appError.appErrorCode === 'unauthorized') {
      return;
    }

    this.globalError = appError as any;
  }

  openNotificationDialog() {
    const skipNotifications = getQueryParameterByName(PP_URL.SKIP_NOTIFICATION);
    if (!skipNotifications) {
      this.isNotificationDialogOpen = true;
    }
  }

  closeNotificationDialog() {
    this.isNotificationDialogOpen = false;

    localStorage.setItem(MasterDataStore.lsKeyConfirmedNotifications, JSON.stringify(this.notifications.map((x) => x.id)));

    this.calculateUnreadNotificationCount();
  }

  private calculateUnreadNotificationCount() {
    const notificationIds = this.notifications.map((x) => x.id);

    const confirmedNotificationsString = localStorage.getItem(MasterDataStore.lsKeyConfirmedNotifications);

    if (confirmedNotificationsString) {
      const confirmedNotifications = JSON.parse(confirmedNotificationsString!);

      confirmedNotifications.forEach((id: string) => {
        notificationIds.splice(notificationIds.indexOf(id), 1);
      });
    }

    this.unreadNotificationCount = notificationIds.length;
  }

  removePromotion(promotion: Promotion) {
    runInAction(() => {
      this.promotions = this.promotions.filter((p) => p.id !== promotion.id);
    });
  }
}
