import i18n from 'i18next';
import { PPCONTEXT } from '../../../../util/ppContext';
import { Article } from '../models/article';

export interface IIcon {
  celumId: string;
  imgSrc: string;
  text?: string;
}
export const getIconSourceFromArticleDetail = (article: Article, actionExclude?: boolean) => {
  const icons = [] as IIcon[];
  if (article.herkunftLabel) {
    icons.push({
      celumId: article.herkunftLabel.toString(),
      imgSrc: PPCONTEXT.STATIC_IMAGES_BASE_URL + '/icon/' + article.herkunftLabel + '.jpg',
      text: article.herkunftLabelText
    });
  }
  if (article.fairtradeLabel) {
    icons.push({
      celumId: article.fairtradeLabel.toString(),
      imgSrc: PPCONTEXT.STATIC_IMAGES_BASE_URL + '/icon/' + article.fairtradeLabel + '.jpg',
      text: article.fairtradeLabelText
    });
  }
  if (article.nachhaltigkeitLabel) {
    icons.push({
      celumId: article.nachhaltigkeitLabel.toString(),
      imgSrc: PPCONTEXT.STATIC_IMAGES_BASE_URL + '/icon/' + article.nachhaltigkeitLabel + '.jpg',
      text: article.nachhaltigkeitLabelText
    });
  }
  if (article.spezialproduktLabel) {
    icons.push({
      celumId: article.spezialproduktLabel.toString(),
      imgSrc: PPCONTEXT.STATIC_IMAGES_BASE_URL + '/icon/' + article.spezialproduktLabel + '.jpg',
      text: article.spezialproduktLabelText
    });
  }
  if (article.bioLabel) {
    icons.push({
      celumId: article.bioLabel.toString(),
      imgSrc: PPCONTEXT.STATIC_IMAGES_BASE_URL + '/icon/' + article.bioLabel + '.jpg',
      text: article.bioLabelText
    });
  }
  if (article.palmoelLabel) {
    icons.push({
      celumId: article.palmoelLabel.toString(),
      imgSrc: PPCONTEXT.STATIC_IMAGES_BASE_URL + '/icon/' + article.palmoelLabel + '.jpg',
      text: article.palmoelLabelText
    });
  }

  if (article.displayAsAction && !actionExclude) {
    icons.push({
      celumId: 'action',
      imgSrc: PPCONTEXT.STATIC_IMAGES_BASE_URL + '/webshop/action.jpg',
      text: i18n.t('AKTION')
    });
  }
  if (article.noveltyUntil) {
    icons.push({
      celumId: 'novelity',
      imgSrc: PPCONTEXT.STATIC_IMAGES_BASE_URL + '/webshop/novelity.jpg',
      text: 'NEW'
    });
  }
  if (article.factPictos) {
    for (const pikto of article.factPictos) {
      // 2024-02-21: Seid ProlixPrp 8.17, können die Pictos auch Bilder enthalten,
      // welche bereits durch die obigen labels referenziert sind
      const celumId = pikto.celumId.toString();
      if (!icons.some((icon) => icon.celumId === celumId)) {
        icons.push({
          celumId: celumId,
          imgSrc: PPCONTEXT.STATIC_IMAGES_BASE_URL + '/icon/' + pikto.celumId + '.jpg',
          text: pikto.text
        });
      }
    }
  }

  return icons;
};
