import axios from 'axios';
import { LOCALE_LOADER } from './i18n';
import { TRANSGOURMET_TRACING_ID_KEY } from '../tracking/tracking';

axios.interceptors.request.use((request) => {
  request.headers['Accept-Language'] = LOCALE_LOADER.getLocale();
  request.headers['Cache-Control'] = 'no-cache,no-store';
  request.headers['Pragma'] = 'no-cache';

  const tracingId = sessionStorage.getItem(TRANSGOURMET_TRACING_ID_KEY);
  if (tracingId) {
    request.headers[TRANSGOURMET_TRACING_ID_KEY] = tracingId;
  }

  return request;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
