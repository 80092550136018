export const messageTypes = {
  IMPORTANTMESSAGE: {
    sortIndex: 10,
    value: 'IMPORTANTMESSAGE',
    removeable: false,
    style: 'message message-important'
  },
  GENERALMESSAGE: {
    sortIndex: 20,
    value: 'GENERALMESSAGE',
    removeable: true,
    style: 'message message-general'
  },
  KNOWLEDGEMESSAGE: {
    sortIndex: 30,
    value: 'KNOWLEDGEMESSAGE',
    removeable: true,
    style: 'message message-knowledge'
  }
};
