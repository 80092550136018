import React from 'react';
import { NotificationBox } from '../../../../../@tgs-web-ui/notificationbox';
import { useTranslation } from 'react-i18next';

const NotInWebshopMessage = () => {
  const { t } = useTranslation();

  return <NotificationBox infoType={'warning'} message={t('Im Webshop nicht bestellbar')} justify={'center'} />;
};

export default NotInWebshopMessage;
