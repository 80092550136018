import { makeAutoObservable } from 'mobx';
import { sortBy } from '../../../util/filterConstants';

export const externalArticleGroupBy = {
  SUPPLIER: { name: 'supplierName', value: 'SUPPLIER', isList: false },
  LEDGERACC: { name: 'ledgerAccount', value: 'LEDGERACCOUNT', isList: true },
  GROUP: { name: 'group', value: 'GROUP', isList: true }
};

export class ExternalArticleFilter {
  groupBy: { name: string; value: string };
  sortBy: { name: string; value: string; blockGroupBy: boolean };
  term: string = '';
  groupKey: string | undefined;
  isSelected: boolean;

  constructor() {
    makeAutoObservable(this);
    this.sortBy = sortBy.ARTTXT;
    this.groupBy = externalArticleGroupBy.SUPPLIER;
    this.isSelected = false;
  }
}
