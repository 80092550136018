import type { IWebshopCustomerOrderPositionDTOData } from '../../../../api';
import { getMLSelector } from '../../../../util/i18n';

export class CustomerOrderArticlePosition {
  public id: number;
  public position: number;
  public orderQuantity: number;
  public sellAmount: number;
  public articleBaseNo: string;
  public articleText: string;
  public articleDescriptionText: string;
  public deliveryDate: Date;
  public price: number;
  public articleTextUnitType: number;
  public total: number;
  public sellUnitType: number;
  public articlePriceCode: number;
  public weight: number;
  public container: boolean;
  public notInWebShop: boolean;
  public actualWeight?: number;
  public actualQuantity: number;
  public isStorno: boolean;

  constructor(dto: IWebshopCustomerOrderPositionDTOData) {
    const mls = getMLSelector();
    this.id = dto.id;
    this.position = dto.position;
    this.orderQuantity = dto.orderQuantity;
    this.sellAmount = dto.sellAmount;
    this.articleBaseNo = dto.articleBaseNo;
    this.articleText = dto.articleText;
    this.articleDescriptionText = dto.articleDescriptionText ? mls(dto.articleDescriptionText) : '';
    this.deliveryDate = new Date(dto.deliveryDate as any);
    this.price = dto.price;
    this.articleTextUnitType = dto.articleTextUnitType;
    this.total = dto.total;
    this.sellUnitType = dto.sellUnitType;
    this.articlePriceCode = dto.articlePriceCode;
    this.weight = dto.weight;
    this.container = dto.container;
    this.notInWebShop = dto.notInWebshop;
    this.actualWeight = dto.actualWeight ? dto.actualWeight : undefined;
    this.actualQuantity = dto.actualQuantity;
    this.isStorno = dto.isStorno;
  }
}
