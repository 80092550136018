import React, { useContext } from 'react';
import { Button, ListItemButton, SwipeableDrawer, Theme } from '@mui/material';
import { StoreContext } from '../../../StoreContext';
import { observer } from 'mobx-react-lite';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import Logo from './header/Logo';
import { IWebTemplateDTOData } from '../../../api';
import StarIcon from 'mdi-material-ui/Star';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import MobileLanguageSelector from './header/MobileLanguageSelector';
import { makeStyles } from 'tss-react/mui';
import { FEATURES } from '../stores/featureStore';

const useStyles = makeStyles()(({ spacing, palette }: Theme) => {
  return {
    userInfo: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      minHeight: '60px'
    },
    navItem: {
      padding: 0
    },
    navItemLink: {
      textDecoration: 'none',
      width: '100%',
      height: '100%',
      paddingLeft: spacing(2),
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
      color: palette.text.primary
    },
    content: {
      width: '300px'
    },
    favouriteTemplateItemText: {
      paddingLeft: 0
    },
    header: {
      paddingRight: spacing(1),
      minHeight: '60px'
    }
  };
});

interface ISideDrawerProps {
  showLanguageSwitch: boolean;
  onSwitchLanguage: (language: any) => any;
  onCatalogOpen: () => void;
}

function SideDrawer({ showLanguageSwitch, onSwitchLanguage, onCatalogOpen }: Readonly<ISideDrawerProps>) {
  const navigate = useNavigate();
  const { uiStore, userStore, templateStore, featureStore } = useContext(StoreContext);
  const { t } = useTranslation();
  const { classes } = useStyles();

  const customer =
    userStore.loginCustomer && userStore.loginCustomer!.displayName && ((userStore.loginUser && (userStore.loginUser!.webShopAdmin || userStore.loginUser!.customerAdvisor)) || userStore.loginCustomer!.agbAccepted)
      ? userStore.loginCustomer
      : undefined;

  const openDrawer = () => {
    uiStore!.openSideMenu();
  };

  const closeDrawer = () => {
    uiStore!.closeSideMenu();
  };

  const handleLogout = () => {
    userStore.performLogout();
  };

  const navigateTo = (url: string) => {
    if (!url.startsWith('/')) {
      url = '/' + url;
    }
    navigate(url);
  };

  const selectTemplate = (id: number) => {
    navigateTo('/templates/' + id);
  };

  const switchLanguage = (locale: any) => {
    onSwitchLanguage(locale);
  };

  function performChangeCustomer() {
    userStore.performChangeCustomerSSO();
  }

  return (
    <SwipeableDrawer open={uiStore.showSideMenu} onClose={closeDrawer} onOpen={openDrawer}>
      <Grid container justifyContent={'space-between'} alignItems={'center'} className={classes.header}>
        <Grid item>
          <div>
            <Logo width={200} />
          </div>
        </Grid>
        <Grid item>{showLanguageSwitch && <MobileLanguageSelector onSwitchLanguage={switchLanguage} />}</Grid>
      </Grid>
      <div tabIndex={0} role="button" onClick={closeDrawer} onKeyDown={closeDrawer} className={classes.content}>
        {customer && (
          <Grid container className={classes.userInfo} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
              <Typography variant={'body1'} color={'textSecondary'}>
                {customer.displayName}
              </Typography>
              <Typography variant={'body2'} color={'textSecondary'} gutterBottom>
                {customer.formattedCustomerNumberWithCategory}
              </Typography>
            </Grid>
            <Grid item>
              {userStore!.canChangeUser && (
                <Button onClick={performChangeCustomer} variant="text" color="primary">
                  {t('ändern')}
                </Button>
              )}
            </Grid>
          </Grid>
        )}
        <List component={'ul'}>
          <Divider component={'hr'} />
          <ListItemButton component={'li'} className={classes.navItem} onClick={() => onCatalogOpen()} id={'mobile-navigation-catalog'}>
            <div className={classes.navItemLink}>
              <ListItemText primary={t('Katalog')} />
            </div>
          </ListItemButton>
          {customer && (
            <>
              <Divider component={'hr'} />
              <ListItem component={'li'}>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'primary'
                  }}
                  primary={t('Vorlagen')}
                />
              </ListItem>
              {featureStore.isFeatureEnabled(FEATURES.scanPool) && (
                <ListItemButton className={classes.navItem} component={'li'} id={'mobile-navigation-scanpositions'}>
                  <RouterLink to={'/scanpool'} className={classes.navItemLink}>
                    <ListItemText primary={t('Scan-Positionen')} />
                  </RouterLink>
                </ListItemButton>
              )}
              <ListItemButton className={classes.navItem} component={'li'} id={'mobile-navigation-salesorderset'}>
                <RouterLink to={'/salesorderset'} className={classes.navItemLink}>
                  <ListItemText primary={t('Gekaufte Artikel')} />
                </RouterLink>
              </ListItemButton>
              <ListItemButton className={classes.navItem} component={'li'} id={'mobile-navigation-alltemplates'}>
                <RouterLink to={'/templates'} className={classes.navItemLink}>
                  <ListItemText primary={t('Alle Vorlagen')} />
                </RouterLink>
              </ListItemButton>
              {templateStore!.favouriteTemplates.map((item: IWebTemplateDTOData, index: number) => {
                return (
                  <ListItemButton key={item.id} onClick={() => selectTemplate(item.id)} component={'li'} id={'mobile-navigation-favtemplate-' + index}>
                    <ListItemIcon>
                      <StarIcon fontSize={'small'} />
                    </ListItemIcon>
                    <ListItemText primary={item.name} className={classes.favouriteTemplateItemText} />
                  </ListItemButton>
                );
              })}
              <Divider component={'hr'} />
              <ListItemButton className={classes.navItem} component={'li'} id={'mobile-navigation-orders'}>
                <RouterLink to={'/order'} className={classes.navItemLink}>
                  <ListItemText primary={t('Aufträge')} />
                </RouterLink>
              </ListItemButton>
              <Divider component={'hr'} />
              <ListItem component={'li'}>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'primary'
                  }}
                  primary={t('Organisation')}
                />
              </ListItem>
              <ListItemButton className={classes.navItem} component={'li'} id={'mobile-navigation-downloads'}>
                <RouterLink to={'/downloads'} className={classes.navItemLink}>
                  <ListItemText primary={t('Downloads')} />
                </RouterLink>
              </ListItemButton>
              {featureStore.isFeatureEnabled(FEATURES.inventory) && (
                <ListItemButton className={classes.navItem} component={'li'} id={'mobile-navigation-inventory'}>
                  <RouterLink to={'/inventory'} className={classes.navItemLink}>
                    <ListItemText primary={t('Inventar')} />
                  </RouterLink>
                </ListItemButton>
              )}
              {featureStore.isFeatureEnabled(FEATURES.externalArticle) && (
                <ListItemButton className={classes.navItem} component={'li'} id={'mobile-navigation-externalarticle'}>
                  <RouterLink to={'/externalarticles'} className={classes.navItemLink}>
                    <ListItemText primary={t('Fremdartikel')} />
                  </RouterLink>
                </ListItemButton>
              )}
              <Divider component={'hr'} />
              <ListItem component={'li'}>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'primary'
                  }}
                  primary={customer.companyName}
                />
              </ListItem>
              <ListItemButton className={classes.navItem} component={'li'} id={'mobile-navigation-deliverydays'}>
                <RouterLink to={'/deliverydays'} className={classes.navItemLink}>
                  <ListItemText primary={t('Liefertage')} />
                </RouterLink>
              </ListItemButton>
              <ListItemButton className={classes.navItem} component={'li'} id={'mobile-navigation-invoices'}>
                <RouterLink to={'/invoices'} className={classes.navItemLink}>
                  <ListItemText primary={t('Rechnungen')} />
                </RouterLink>
              </ListItemButton>
              <ListItemButton className={classes.navItem} component={'li'} id={'mobile-navigation-shipments'}>
                <RouterLink to={'/shipments'} className={classes.navItemLink}>
                  <ListItemText primary={t('Lieferscheine')} />
                </RouterLink>
              </ListItemButton>
              <ListItemButton className={classes.navItem} component={'li'} id={'mobile-navigation-usersettings'}>
                <RouterLink to={'/usersettings'} className={classes.navItemLink}>
                  <ListItemText primary={t('Einstellungen')} />
                </RouterLink>
              </ListItemButton>
              <Divider component={'hr'} />
              <ListItemButton onClick={handleLogout} component={'li'} id={'mobile-navigation-logout'}>
                <ListItemText primary={t('Logout')} />
              </ListItemButton>
            </>
          )}
          {!customer && (
            <ListItemButton className={classes.navItem} component={'li'} id={'mobile-navigation-login'}>
              <ListItemText primary={t('Login')} onClick={() => userStore.triggerLoginFlow()} sx={{ ml: 2 }} />
            </ListItemButton>
          )}
        </List>
      </div>
    </SwipeableDrawer>
  );
}

export default observer(SideDrawer);
