import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import { Template } from '../stores/models/template';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { StoreContext } from '../../../StoreContext';
import notifications from '../../../notifications';

export const AddToTemplateForm = observer(() => {
  const { t } = useTranslation();
  const { templateStore } = useContext(StoreContext);

  const onChangeTemplate = (value: string) => {
    const templateId = parseInt(value, 10);
    templateStore.setSelectedTemplateId(templateId);
  };

  const addArticleToTemplate = async () => {
    if (templateStore.selectedTemplateId !== null) {
      await templateStore.addSelectedArticlesToTemplate();
      notifications.emit('success', t('Artikel wurde erfolgreich zur Vorlage hinzugefügt'));
      templateStore.closeTemplateArticleDialog();
    }
  };

  const cancelDialog = () => {
    templateStore.clearTemplateArticleToAdd();
    templateStore.closeTemplateArticleDialog();
  };

  return (
    <form noValidate autoComplete="off">
      <Grid container item direction="column" spacing={2}>
        <Grid item>{t('In welche Vorlage möchten Sie den Artikel hinzufügen?')}</Grid>
        <Grid item>
          <TextField
            variant={'outlined'}
            label={t('Wählen Sie eine Vorlage aus')}
            value={templateStore.selectedTemplateId ? templateStore.selectedTemplateId : ''}
            onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeTemplate(event.target.value)}
            select
            fullWidth
            autoFocus
          >
            {templateStore.otherTemplates.map((template: Template) => (
              <MenuItem key={template.id} value={template.id}>
                {template.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid container item spacing={1} justifyContent={'flex-end'}>
          <Grid item>
            <Button size={'small'} onClick={cancelDialog} variant={'contained'} color="secondary">
              {t('Schliessen')}
            </Button>
          </Grid>
          <Grid item>
            <Button size={'small'} onClick={addArticleToTemplate} variant={'contained'} color="primary">
              {t('Hinzufügen')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
});
