export const sortBy = {
  ARTNR: {
    name: 'articleNumber',
    value: 'ARTNR',
    isList: false,
    blockGroupBy: false
  },
  ARTTXT: {
    name: 'articleText',
    value: 'ARTTXT',
    isList: true,
    blockGroupBy: false
  },
  INPUTORDER: {
    name: 'inputOrder',
    value: 'INPUTORDER',
    isList: false,
    blockGroupBy: true
  },
  CUSTOM: {
    name: 'CUSTOM',
    value: 'CUSTOM',
    isList: false,
    blockGroupBy: true
  }
};

export const groupBy = {
  HWG: { name: 'hwg', value: 'HWG', isList: true },
  HAGR: { name: 'hagr', value: 'HAGR', isList: true },
  BRAND: { name: 'brand', value: 'BRAND', isList: false },
  LEDGERACCOUNT: {
    name: 'ledgerAccount',
    value: 'LEDGERACCOUNT',
    isList: false
  }
};

export const UNSORTED_CATEGORY = ['unsortiert', 'non triés', 'indifferenziati'];
export const SALESORDERSET_ID = -1;
export const WHITESPACE_BRAND = ' ';

export function createDefaultSortSpec() {
  return createSortSpec({
    sortBy: sortBy.ARTTXT,
    groupBy: groupBy.HWG,
    filterCategory: {
      key: 'Alle anzeigen',
      isMultilanguage: false,
      showAll: true
    },
    filterTerm: '',
    filterOnlySelected: false,
    useSlimLayout: false
  });
}

export function createSortSpec(sortSpecData: any) {
  const sortSpec = { ...sortSpecData };
  sortSpec.isDragDropEnabled = function () {
    return this.sortBy.value === sortBy.CUSTOM.value;
  };
  return sortSpec;
}
