let environment: {
  pathname: string;
  userAgent: string;
  cookie: any;
  btoa: any;
};

if (typeof window === 'undefined') {
  environment = {
    pathname: '',
    userAgent: 'DUMMY-USER-AGENT',
    cookie: undefined,
    btoa: undefined // set btoa in test-setup!
  };
} else {
  environment = {
    pathname: window.location.pathname,
    userAgent: window.navigator.userAgent,
    cookie: document.cookie,
    btoa: window.btoa.bind(window)
  };
}

export default environment;
