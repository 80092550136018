import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { Calendar } from './Calendar';
import { StoreContext } from '../../../StoreContext';
import { Button, Typography, Grid } from '@mui/material';
import { IDeliveryDateData, IDeliveryDateWarningData } from '../../../api';
import { useTranslation } from 'react-i18next';

interface DeliveryCalendarProps {
  isReadonly?: boolean;
  selectedDate?: Date;
  onDateSelected?: (date: Date) => void;
}

function DeliveryCalendarComponent({ isReadonly, selectedDate, onDateSelected }: Readonly<DeliveryCalendarProps>) {
  const { userStore } = useContext(StoreContext);
  const [currentWeekOffset, setCurrentWeekOffset] = useState(0);
  const [date, setDate] = useState(selectedDate);
  const [deliveryDateWarning, setDeliveryDateWarning] = useState<IDeliveryDateWarningData | null>(null);

  function commitDateSelection(date: Date) {
    setDeliveryDateWarning(null);
    if (onDateSelected) {
      onDateSelected(date);
    }
  }

  function handleOnDateSelected(deliveryDate: IDeliveryDateData) {
    setDate(deliveryDate.date);
    if (deliveryDate.deliveryDateWarning) {
      setDeliveryDateWarning(deliveryDate.deliveryDateWarning);
    } else {
      commitDateSelection(deliveryDate.date);
    }
  }

  function abortSundayWarning() {
    setDeliveryDateWarning(null);
    setDate(selectedDate); // set date back to initial value
  }

  function confirmSundayWarning() {
    commitDateSelection(date!);
  }

  function loadNextMonth() {
    const nextWeekOffset = currentWeekOffset + 6;
    setCurrentWeekOffset(nextWeekOffset);

    if (!userStore.getDeliveryDates(nextWeekOffset)) {
      userStore.fetchDeliveryCalendar(nextWeekOffset);
    }
  }

  function loadPreviousMonth() {
    const previousWeekOffset = currentWeekOffset - 6;
    setCurrentWeekOffset(previousWeekOffset);

    if (!userStore.getDeliveryDates(previousWeekOffset)) {
      userStore.fetchDeliveryCalendar(previousWeekOffset);
    }
  }

  const currentDeliveryDates = userStore.getDeliveryDates(currentWeekOffset);

  const handleDateSelected = isReadonly ? undefined : handleOnDateSelected;

  const calendar = <Calendar isReadonly={isReadonly} deliveryDates={currentDeliveryDates} selectedDate={date} onDateSelected={handleDateSelected} onNextMonth={loadNextMonth} onPreviousMonth={loadPreviousMonth} />;

  if (deliveryDateWarning) {
    return (
      <DateWarningOverlay deliveryDateWarning={deliveryDateWarning} confirm={confirmSundayWarning} abort={abortSundayWarning}>
        {calendar}
      </DateWarningOverlay>
    );
  } else {
    return calendar;
  }
}

export const DeliveryCalendar = observer(DeliveryCalendarComponent);

const OVERLAY_STYLE = {
  position: 'absolute' as const,
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255,255,255,0.95)',
  zIndex: 1000,
  display: 'flex',
  flexDirection: 'column' as const,
  justifyContent: 'center',
  alignItems: 'center',
  padding: 30
};

type DateWarningOverlayProps = {
  children: JSX.Element;
  deliveryDateWarning: IDeliveryDateWarningData;
  confirm: () => void;
  abort: () => void;
};
function DateWarningOverlay({ children, deliveryDateWarning, confirm, abort }: Readonly<DateWarningOverlayProps>) {
  const { t } = useTranslation();
  return (
    <div>
      <div style={OVERLAY_STYLE}>
        <Grid container direction="column" spacing={2} style={{ background: 'white' }}>
          <Grid item>
            <Typography variant="h2">{deliveryDateWarning.warningTitle}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{deliveryDateWarning.warningText}</Typography>
          </Grid>
          <Grid item>
            <Grid container direction="row-reverse" spacing={2}>
              <Grid item>
                <Button onClick={confirm} variant={'contained'} autoFocus color="primary" style={{ minWidth: 130 }} id="calendar-date-warning-confirm">
                  {t('Ok')}
                </Button>
              </Grid>
              <Grid item>
                <Button onClick={abort} variant={'contained'} color="primary" style={{ minWidth: 130 }} id="calendar-date-warning-abort">
                  {t('Abbrechen')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {children}
    </div>
  );
}
