import React from 'react';
import { useTranslation } from 'react-i18next';
import { getShortLocale } from '../../../../../util/i18n';
import { PPCONTEXT } from '../../../../../util/ppContext';
import OutboundLink from '../../../components/OutboundLink';
import { Article } from '../../models/article';
import { Grid, Tooltip, Typography } from '@mui/material';
import InfoIcon from 'mdi-material-ui/Information';
import { observer } from 'mobx-react-lite';
import { makeStyles } from 'tss-react/mui';

interface ICatalogItemSupplierInfoProps {
  article: Article;
  compact?: boolean;
}

const useStyles = makeStyles()(() => {
  return {
    text: {
      alignSelf: 'center'
    }
  };
});

const CatalogItemSupplierInfo = observer(({ article, compact = false }: ICatalogItemSupplierInfoProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  if (!article.isProfitPlus) {
    return null;
  }

  const shortLocale = getShortLocale();

  let warningIcon;

  if (article.supplierGroupMinOrderValue) {
    const text = `${t('Mindestbestellwert')} CHF ${article.supplierGroupMinOrderValue.toFixed(2)}`;
    warningIcon = (
      <Tooltip title={text} placement="bottom-end">
        <InfoIcon color={'secondary'} />
      </Tooltip>
    );
  } else {
    warningIcon = <InfoIcon fontSize="small" color={'secondary'} />;
  }

  let info;

  if (article.supplierGroupName && article.supplierGroupTermsDocumentName) {
    info = (
      <OutboundLink target="_blank" className={classes.text} href={PPCONTEXT.STATIC_IMAGES_BASE_URL + `/pdf/suppliergroup/${article.supplierGroupTermsDocumentName}_${shortLocale}.pdf`}>
        {t('Hier finden Sie die Preis- und Lieferkonditionen von {{0}}.', {
          0: article.supplierGroupName
        })}
      </OutboundLink>
    );
  } else {
    info = <span className={classes.text}>{t('Es gelten spezielle Preis- und Lieferkonditionen!')}</span>;
  }

  return (
    <Grid alignItems={'flex-start'} spacing={1} container>
      <Grid item>{warningIcon}</Grid>
      {!compact && (
        <Grid item>
          <Typography variant={'body2'}>
            {info}
            <br />
            {t('KEIN RÜCKGABERECHT')}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
});

export default CatalogItemSupplierInfo;
