import type { IFacetData, IFacetItemData } from '../../../../api';
import sortList from 'lodash/sortBy';
import { makeAutoObservable } from 'mobx';
import { CatalogFacetItem } from './catalogFacetItem';

export class CatalogFacet {
  displayName: string;
  facetName: string;
  items: CatalogFacetItem[];

  constructor(dto: IFacetData) {
    makeAutoObservable(this);

    this.displayName = dto.displayName ? dto.displayName : '';
    this.facetName = dto.facetName ? dto.facetName : '';
    this.items = dto.items ? sortList(dto.items, (x: IFacetItemData) => x.name).map((x) => new CatalogFacetItem(x)) : [];
  }

  get hasCheckedChild() {
    return this.items.some((i) => i.checked);
  }
}
