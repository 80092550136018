import type { IFacetData, IFacetFilterData } from '../../../api';
import without from 'lodash/without';
import { runInAction, makeAutoObservable } from 'mobx';
import RootStore from '../../../rootStore';
import { getMLSelector } from '../../../util/i18n';
import IntentProcessor from '../../cart/models/intent/IntentProcessor';
import { Article } from '../../shared/articles/models/article';
import CatalogService from '../services/catalogService';
import { type CatalogSearchType, CATALOGSEARCHTYPE_ALLARTICLE, CATALOGSEARCHTYPE_COREASSORTMENT, CATALOGSEARCHTYPE_SALESORDERSET, ROW_COUNT } from '../util/catalogConstants';
import { CatalogFacet } from './models/catalogFacet';
import { CatalogFacetFilter } from './models/catalogFacetFilter';
import AddPositionIntent from '../../cart/models/intent/AddPositionIntent';
import { fromBase64, toBase64 } from '../util/catalogUtils';
import { executeAsyncAction } from '../../../util/exceptionHandler';
import { rootStore } from '../../../StoreContext';

export default class CatalogStore {
  static findMatchingFacetItem(newFacets: IFacetData[], facetName: string, facetItemName: string) {
    for (const newFacet of newFacets) {
      if (newFacet.facetName === facetName) {
        for (const newFacetItem of newFacet.items) {
          if (newFacetItem.name === facetItemName) {
            return newFacetItem;
          }
        }
      }
    }
    return null;
  }

  rootStore: RootStore;
  intentProcessor: IntentProcessor;

  pageLoading: boolean = false;
  nextPageLoading: boolean = false;
  catalogItems: Article[] = [];
  facets: CatalogFacet[] = [];
  facetFilter: CatalogFacetFilter[] = [];
  rows: number = 0;
  numFound: number = 0;
  start: number = 0;
  term: string = '';
  searchType: CatalogSearchType = CATALOGSEARCHTYPE_ALLARTICLE;
  sortBy = '';
  suggestions: string[] = [];
  articleDetail?: Article;
  similarArticles: Article[] = [];
  itemsCount: number = 0;

  isIntentPending = (intentSpec: any) => this.intentProcessor.isPending(intentSpec);

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.intentProcessor = new IntentProcessor();
  }

  addIntent(intent: any) {
    this.intentProcessor.scheduleIntent(intent);
  }

  resetState() {
    this.catalogItems = [];
    this.facets = [];
    this.facetFilter = [];
    this.term = '';
    this.start = 0;
    this.rows = 0;
    this.numFound = 0;
    this.pageLoading = false;
    this.itemsCount = 0;
  }

  changeSearchType(searchType: CatalogSearchType) {
    this.searchType = searchType;
    this.fetchCatalogItems(0, ROW_COUNT, true);
  }

  startPaging(start: any) {
    this.catalogItems = [];
    this.pageLoading = true;
    this.start = start;
  }

  fetchCatalogItems(start?: any, rows?: any, isInitial?: boolean) {
    executeAsyncAction(async () => {
      const userStore = this.rootStore.userStore;
      const startParam = start !== undefined ? start : this.start;
      const rowsParam = rows !== undefined ? rows : this.rows;
      const term = this.term !== undefined ? this.term.replace(/%25/g, '+') : this.term;
      const catalog = await CatalogService.fetchCatalogItems(
        userStore.isLoggedIn,
        this.searchType,
        term,
        isInitial,
        this.sortBy,
        this.facetFilter.map((f) => f.toDto()),
        startParam,
        rowsParam
      );
      runInAction(() => {
        this.catalogItems = catalog.articles ? catalog.articles.map((a) => Article.createFromDto(a, 1)) : [];
        if (isInitial) {
          this.facets = catalog.facets.map((f) => new CatalogFacet(f));
          this.facetFilter = [];
          this.createFacetFilter();
        } else {
          this.handleFilterChangeOnFacets(catalog.facets);
        }
        this.numFound = catalog.numberOfResults;
        this.sortBy = catalog.sortBy;
        this.start = catalog.start;
        this.rows = catalog.maxResults;
        this.pageLoading = false;
        this.itemsCount = catalog.articles.length;
      });
    });
  }

  fetchMoreCatalogItems() {
    executeAsyncAction(
      async () => {
        this.nextPageLoading = true;
        const userStore = this.rootStore.userStore;
        const startParam = this.start + ROW_COUNT;
        const rowsParam = this.rows;
        const catalog = await CatalogService.fetchCatalogItems(
          userStore.isLoggedIn,
          this.searchType,
          this.term,
          false,
          this.sortBy,
          this.facetFilter.map((f) => f.toDto()),
          startParam,
          rowsParam
        );

        runInAction(() => {
          const newArticles = catalog.articles.map((a: any) => {
            return Article.createFromDto(a, 1);
          });

          newArticles.forEach((x) => this.catalogItems.push(x));

          this.handleFilterChangeOnFacets(catalog.facets);
          this.numFound = catalog.numberOfResults;
          this.sortBy = catalog.sortBy;
          this.start = catalog.start;
          this.pageLoading = false;
          this.itemsCount = this.itemsCount + catalog.articles.length;
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.nextPageLoading = false;
          });
        }
      }
    );
  }

  createFacetFilter() {
    this.facetFilter = [];

    for (const facet of this.facets) {
      const filter = new CatalogFacetFilter();
      for (const item of facet.items) {
        if (item.checked) {
          if (!filter.name) {
            filter.name = facet.facetName;
          }
          filter.values.push(item.name);
        }
      }

      if (filter.name) {
        this.facetFilter.push(filter);
      }
    }
  }

  loadArticle(articleNumber: string) {
    let article = this.catalogItems.find((x: Article) => x.articleNumber === articleNumber);

    if (article && article.isDeepLoaded) {
      this.articleDetail = article;
      this.similarArticles = [];
    } else {
      executeAsyncAction(async () => {
        const loadedArticle = await CatalogService.fetchCatalogItem(articleNumber, this.rootStore.userStore.isLoggedIn);
        runInAction(() => {
          if (article) {
            article.update(loadedArticle);
          } else {
            article = Article.createFromDto(loadedArticle.article, 1);
            article.update(loadedArticle, 1);
          }
          this.articleDetail = article;
          this.similarArticles = [];
        });
      });
    }

    this.loadSimilarArticles();
  }

  loadSimilarArticles() {
    executeAsyncAction(async () => {
      const mls = getMLSelector();
      const hagrName = mls(['hagrDE', 'hagrFR', 'hagrIT']);
      const hagrValue = this.articleDetail!.hagr;
      const similarArticlesResult = await CatalogService.fetchSimilarArticles(this.rootStore.userStore.isLoggedIn, this.articleDetail!.articleText, [
        {
          name: hagrName,
          values: [hagrValue]
        }
      ]);

      const articleNumber = this.articleDetail!.articleNumber;
      const currentArticle = similarArticlesResult.articles.find((x) => x.articleNumber === articleNumber);
      let similarArticles = without(similarArticlesResult.articles, currentArticle);
      if (similarArticles.length === 7) {
        similarArticles = without(similarArticles, similarArticles[6]);
      }

      runInAction(() => {
        this.similarArticles = similarArticles ? similarArticles.map((x) => Article.createFromDto(x!, 1)) : [];
      });
    });
  }

  updateSortBy(sortBy: string) {
    this.sortBy = sortBy;
  }

  changeFilter(facetValue: { facetName: string; name: string; checked: boolean }) {
    for (const facet of this.facets) {
      if (facet.facetName === facetValue.facetName) {
        for (const item of facet.items) {
          if (item.name === facetValue.name) {
            item.checked = facetValue.checked;
          }
        }
      }
    }

    this.createFacetFilter();
    this.applyChangesToUrl();
  }

  loadSearch(searchTerm: string | null, searchFilter: string | null) {
    this.resetState();
    this.term = searchTerm ?? '';
    this.pageLoading = true;

    if (searchFilter) {
      const facets = fromBase64<IFacetFilterData>(searchFilter);
      this.facetFilter = facets.map((f) => CatalogFacetFilter.createFromDto(f));
    }

    this.fetchCatalogItems(0, ROW_COUNT, true);
  }

  addArticleToCart(article: Article) {
    const addPositionIntent = new AddPositionIntent(this.rootStore.cartStore, article.externalArticle ? article.articleId.toString() : article.articleNumber, article.amount, article.externalArticle);
    this.rootStore.cartStore.addIntent(addPositionIntent);
  }

  isAddPositionIntentPending(article: Article) {
    const isIntentPending = this.rootStore.cartStore.isIntentPending({
      intentType: AddPositionIntent,
      articleNumber: article.externalArticle ? article.articleId : article.articleNumber,
      isExternal: article.externalArticle
    });

    return isIntentPending;
  }

  get breadCrumbArticle() {
    return this.articleDetail;
  }

  get totalCount() {
    let total = this.catalogItems.length;

    this.catalogItems.forEach((x) => {
      if (x.isZzArticle) {
        const zzTotal = x.zzArticles.length;
        total = total + zzTotal;
      }
    });

    return total;
  }

  get isSearchTypeFiltered() {
    return this.searchType !== CATALOGSEARCHTYPE_ALLARTICLE;
  }

  get isSearchTypeSalesOrderSet() {
    return this.searchType === CATALOGSEARCHTYPE_SALESORDERSET;
  }

  get isSearchTypeCoreAssortment() {
    return this.searchType === CATALOGSEARCHTYPE_COREASSORTMENT;
  }

  handleFilterChangeOnFacets(newFacets: IFacetData[]) {
    for (const facet of this.facets) {
      for (const facetItem of facet.items) {
        const facetName = facet.facetName;
        const facetItemName = facetItem.name;
        const newFacetItem = CatalogStore.findMatchingFacetItem(newFacets, facetName, facetItemName);

        if (newFacetItem !== null) {
          facetItem.updateProperty('count', newFacetItem.count);
          facetItem.updateProperty('checked', newFacetItem.checked);
        } else {
          facetItem.updateProperty('count', 0);
        }
      }
    }
  }

  private applyChangesToUrl = () => {
    const termFilter = this.term && this.term.length > 0 && this.term !== '*' ? 'q=' + this.term : undefined;

    let url = '/catalog';

    if (termFilter) {
      url += '?' + termFilter;
    }

    if (this.facetFilter) {
      url += (termFilter ? '&' : '?') + 'f=' + toBase64(this.facetFilter);
    }

    rootStore.router.navigate(encodeURI(url));
  };
}
