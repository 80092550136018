import React from 'react';
import ReactDOM from 'react-dom/client';
import { createHashRouter, useRouteError } from 'react-router-dom';
import i18n from 'i18next';
import { configure } from 'mobx';
import * as Sentry from '@sentry/react';
import { initTracking } from './tracking/tracking';
import { initializeUiLanguage } from './i18nConfig';
import { initPPConstants } from './util/ppConstants';
import { initRoutes, OCI_ENTRY_PATH } from './modules/Routes';
import { initPPContext } from './util/ppContext';
import { rootStore } from './StoreContext';
import { App } from './modules/App';
import { AppLayout } from './modules/AppLayout';
import { WebshopErrorScreen } from './modules/WebshopErrorScreen';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { initSearchApi } from './features/search/data-access/apiEndpoints';
import { queryClient } from './queryClient';

export type RouterType = ReturnType<typeof createHashRouter>; // TODO should probably be moved

configure({ enforceActions: 'observed' }); // MobX global configuration

async function main() {
  const ppContext = await initPPContext();
  initPPConstants(ppContext);
  initSearchApi();

  await initializeUiLanguage();
  try {
    const isOciEntry = window.location.href.includes(OCI_ENTRY_PATH);
    console.log('isOciEntry', isOciEntry);
    await rootStore.userStore.initializeLogin({ isOciEntry });
  } catch {
    rootStore.userStore.removeLoginData();
  }
  if (!rootStore.userStore.isLoggedIn) {
    await rootStore.featureStore.fetchDefaultSettings();
  }
  const { enableSentryPerformance } = initTracking();

  await loadLanguageDependentData();
  rootStore.masterDataStore.fetchInitialMasterData();
  const routes = initRoutes(rootStore.featureStore, rootStore.userStore, queryClient);

  const routeDefinitions = [
    {
      path: '/',
      element: <AppLayout />,
      errorElement: <RootErrorBoundary />,
      children: routes
    }
  ];

  let createRouter = createHashRouter;
  if (enableSentryPerformance) {
    createRouter = Sentry.wrapCreateBrowserRouter(createHashRouter);
  }
  const router = createRouter(routeDefinitions);
  rootStore.router = router;
  startApplication(router);
}

main().catch((error) => {
  console.error('Error during application startup', error);
});

function loadLanguageDependentData() {
  return Promise.all([rootStore.uiStore.setLanguage(i18n.language), rootStore.masterDataStore.fetchSellUnits(i18n.language), rootStore.masterDataStore.fetchActivePublis()]);
}

function startApplication(router: RouterType) {
  ReactDOM.createRoot(document.getElementById('app-content') as HTMLElement).render(<App router={router} rootStore={rootStore} />);
}

function RootErrorBoundary() {
  const error = useRouteError() as any;

  return <WebshopErrorScreen error={error} />;
}
