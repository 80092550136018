import CartStore from '../../stores/cartStore';
import BaseIntent from './BaseIntent';
import { IntentState } from '../../types/IntentState';

export default class IntentProcessor {
  public SCHEDULE_INTERVAL = 100;
  public pendingIntents: any[] = [];
  public scheduler: any;
  public completePromise: any;
  public resolveCompletePromise: any;
  public cartStore?: CartStore;

  constructor(cartStore?: CartStore) {
    this.cartStore = cartStore;
  }

  public start() {
    if (!this.scheduler) {
      this.scheduler = setInterval(() => this.schedule(), this.SCHEDULE_INTERVAL);
    }
    return this.getCompletedPromise();
  }

  public stop() {
    if (this.scheduler) {
      clearInterval(this.scheduler);
      this.scheduler = null;
    }

    if (this.resolveCompletePromise) {
      this.resolveCompletePromise();
      this.completePromise = null;
      this.resolveCompletePromise = null;
    }
  }

  public enqueue(intent: BaseIntent) {
    if (intent !== null) {
      this.pendingIntents.push(intent);
    }
    return this;
  }

  public isPending(intent: BaseIntent) {
    return this.pendingIntents.some((pendingIntent) => {
      return pendingIntent.isPending(intent);
    });
  }

  public front() {
    if (this.isIdle) {
      return null;
    }
    return this.pendingIntents[0];
  }

  public get isIdle() {
    return this.pendingIntents.length === 0;
  }

  public getCompletedPromise(): Promise<any> {
    if (!this.completePromise) {
      this.completePromise = new Promise((resolve) => {
        this.resolveCompletePromise = resolve;
      });
    }
    return this.completePromise;
  }

  public done(currentIntent: BaseIntent) {
    const indexOfCurrentIntent = this.pendingIntents.indexOf(currentIntent);
    if (indexOfCurrentIntent >= 0) {
      this.pendingIntents.splice(indexOfCurrentIntent, 1);
    }
  }

  public scheduleIntent(intent: BaseIntent) {
    this.enqueue(intent);
    this.start();
    return this;
  }

  public schedule() {
    if (this.isIdle) {
      this.stop();
      return;
    }

    const pendingIntent = this.front();
    if (pendingIntent.intentState === IntentState.DONE) {
      this.done(pendingIntent);
      this.schedule();
      return;
    } else if (pendingIntent.intentState === IntentState.INITIAL) {
      const pendingPromise = pendingIntent.process(this);
      pendingPromise
        .then(() => {
          if (pendingIntent.intentState === IntentState.DONE) {
            this.done(pendingIntent);
          }
        })
        .catch(() => {
          this.done(pendingIntent);
        });
      pendingPromise.catch(() => {
        this.done(pendingIntent);
      });
    }
  }

  public reset() {
    this.pendingIntents = [];
    this.stop();
  }
}
