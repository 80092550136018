import React from 'react';
import { WebshopErrorScreen } from './WebshopErrorScreen';

interface WebshopErrorBoundaryProps {
  children: React.ReactNode;
}
interface WebshopErrorBoundaryState {
  error: Error | undefined;
}

export class WebshopErrorBoundary extends React.Component<WebshopErrorBoundaryProps, WebshopErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      // You can render any custom fallback UI
      return <WebshopErrorScreen error={this.state.error} />;
    }

    return this.props.children;
  }
}
