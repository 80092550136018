import type { IArticleDTOData, IImportedResultDTOData, IWebTemplateAssortmentData, IWebTemplateDetailDTOData, IWebTemplateDTOData } from '../../../api';
import axios from '../../../util/axios';
import { ppConstants } from '../../../util/ppConstants';

const TemplateService = {
  async fetchTemplates(): Promise<IWebTemplateDTOData[]> {
    const response = await axios.get(ppConstants.API_HOST_SEC + 'templates', {});
    return response.data;
  },

  async createNewTemplate(name: string): Promise<IWebTemplateDTOData> {
    const response = await axios.post(
      ppConstants.API_HOST_SEC + 'templates',
      {},
      {
        params: {
          name
        }
      }
    );
    return response.data;
  },

  async copyTemplate(id: number, name: string): Promise<IWebTemplateAssortmentData> {
    const response = await axios.post(
      ppConstants.API_HOST_SEC + 'templates/' + id + '/copy',
      {},
      {
        params: {
          name
        }
      }
    );
    return response.data;
  },

  async changeTemplate(templateId: number, changeSpec: any): Promise<IWebTemplateAssortmentData> {
    const response = await axios.put(ppConstants.API_HOST_SEC + 'templates/' + templateId, changeSpec);
    return response.data;
  },

  async removeTemplate(templateId: any): Promise<any> {
    await axios({
      method: 'DELETE',
      url: ppConstants.API_HOST_SEC + 'templates/' + templateId,
      data: {}
    });
    return templateId;
  },

  async changeTemplateItem(templateId: any, positionId: any, externalArticle: any, changeSpec: any): Promise<any> {
    const params = { externalArticle };
    return axios.put(ppConstants.API_HOST_SEC + 'templates/' + templateId + '/' + positionId, changeSpec, { params });
  },

  async updateQuantityForAllArticlesInTemplate(templateId: any, quantity: any): Promise<any> {
    return axios.put(ppConstants.API_HOST_SEC + 'templates/updateQuantity/' + templateId + '/' + quantity);
  },

  async createNewAssortmentGroup(templateId: number, assortmentGroupTitle: string): Promise<IWebTemplateDetailDTOData> {
    const payload = { assortmentGroupTitle };
    const response = await axios.post(ppConstants.API_HOST_SEC + 'templates/' + templateId + '/groups', payload);
    return response.data;
  },

  async changeAssortmentGroup(templateId: any, assortmentGroupId: any, changeSpec: any): Promise<IWebTemplateDetailDTOData> {
    return axios.put(ppConstants.API_HOST_SEC + 'templates/' + templateId + '/groups/' + assortmentGroupId, changeSpec);
  },

  async deleteAssortmentGroup(templateId: any, assortmentGroupId: any): Promise<any> {
    const response = await axios.delete(ppConstants.API_HOST_SEC + 'templates/' + templateId + '/groups/' + assortmentGroupId);
    return response.data;
  },

  async replaceArticleInTemplate(templateId: any, articleId: any): Promise<IArticleDTOData> {
    const response = await axios.post(
      ppConstants.API_HOST_SEC + 'templates/item/replace',
      {},
      {
        params: {
          templateId,
          articleId
        }
      }
    );
    return response.data;
  },

  async addArticlesToTemplate(templateId: any, articles: any[]): Promise<IWebTemplateAssortmentData> {
    const templateArticles = articles.map((art) => {
      // articles can be articles or SubmitScanPositions
      // convert these objects to the REST-API structure
      let externalArticle;
      let mainArticleId;
      let amount;
      if (art.externalArticle) {
        externalArticle = art.externalArticle;
        mainArticleId = art.articleId;
        amount = art.amount;
      } else {
        mainArticleId = art.mainArticleId;
        amount = art.amount ? art.amount : art.quantity;
      }

      return {
        externalArticle,
        mainArticleId,
        amount
      };
    });

    const response = await axios.post(ppConstants.API_HOST_SEC + `templates/${templateId}`, templateArticles);
    return response.data;
  },

  async fetchArticlesByTemplate(templateId: number) {
    const response = await axios.get<IWebTemplateDetailDTOData>(ppConstants.API_HOST_SEC + 'templates/' + templateId);
    return response.data;
  },

  async removeArticleFromTemplate(templateId: number, maId: number, externalArticle: boolean) {
    await axios.delete<void>(ppConstants.API_HOST_SEC + 'templates/' + templateId + '/' + maId, { params: { externalArticle } });
  },

  async importArticlesFromCSVFile(templateId: any, data: any): Promise<IImportedResultDTOData> {
    const response = await axios.post(ppConstants.API_HOST_SEC + 'templates/' + templateId + '/csv', data, {
      headers: {
        'Content-Type': 'text/plain'
      }
    });
    return response.data;
  }
};

export default TemplateService;
