import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from 'mdi-material-ui/Magnify';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import SearchFilter from './SearchFilter';
import { CatalogSearchType } from '../../../../catalog/util/catalogConstants';

interface SearchTextFieldProps {
  searchTerm: string;
  performSearch: (searchTerm: string) => void;
  onSearchTermChanged: (term: string) => void;
  handleToggleFilterSearchType: (filterType: CatalogSearchType) => void;
  onFocus: () => void;
  small?: boolean;
}

const SearchTextField = observer(
  React.forwardRef(({ searchTerm, performSearch, onSearchTermChanged, handleToggleFilterSearchType, onFocus, small = false }: SearchTextFieldProps, ref: any) => {
    const { t } = useTranslation();

    const handleSearchTermChange = (event: ChangeEvent<HTMLInputElement>) => {
      onSearchTermChanged(event.target.value);
    };

    const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      if (event.target) {
        onFocus();
      }
    };

    return (
      <Paper
        elevation={small ? 0 : 1}
        sx={{
          height: small ? '48px' : '40px',
          pl: '2px',
          display: 'flex',
          alignItems: 'center',
          width: '100%'
        }}
        square={true}
        ref={ref}
      >
        <InputBase
          id={'search-textfield'}
          autoFocus={small}
          onFocus={handleOnFocus}
          value={searchTerm}
          placeholder={t('Welches Produkt dürfen wir Ihnen heute zeigen?')}
          sx={{ ml: 1, flex: 1 }}
          fullWidth
          onChange={handleSearchTermChange}
          inputProps={{ maxLength: 70 }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              performSearch(searchTerm);
              if (e.target) {
                (e.target as any).blur();
              }
            }
          }}
        />
        <IconButton id={'search-button'} aria-label={t('suchen')} onClick={() => performSearch(searchTerm)}>
          <SearchIcon />
        </IconButton>
        <SearchFilter handleToggleFilterSearchType={handleToggleFilterSearchType} />
      </Paper>
    );
  })
);

export default SearchTextField;
