import { IManagedAssortmentGroupDTOData, IWebTemplateDetailDTOData } from '../../../../api';
import { makeAutoObservable } from 'mobx';
import { Article } from '../../../shared/articles/models/article';
import { TemplateDetailStore } from '../templateDetailStore';

export class TemplateDetail {
  store: TemplateDetailStore;

  id: number;
  name: string;
  sortCriteria: string;
  groupCriteria: string;
  articles: Article[];
  templateGroups: IManagedAssortmentGroupDTOData[];

  constructor(store: TemplateDetailStore, dto: IWebTemplateDetailDTOData) {
    makeAutoObservable(this);

    this.store = store;

    this.id = dto.id;
    this.name = dto.name;
    this.sortCriteria = dto.sortCriteria ? dto.sortCriteria : '';
    this.groupCriteria = dto.groupCriteria ? dto.groupCriteria : '';
    this.articles = dto.articles ? dto.articles.map((x) => Article.createFromDto(x)) : [];
    this.templateGroups = dto.templateGroups ? dto.templateGroups : [];
  }

  sortTemplateGroups() {
    const sortedGroups = this.templateGroups.sort((a1: any, a2: any) => a1.assortmentIndex - a2.assortmentIndex);
    this.templateGroups = sortedGroups;
  }
}
