import { observer } from 'mobx-react-lite';
import { Article } from '../models/article';
import { getIconSourceFromArticleDetail, IIcon } from '../util/imageSourceWrapper';
import { Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { mapEcoScoreLabelToPictoIconImageUrl } from '../../../../util/ecoscore';
import Box from '@mui/material/Box';

const ArticleIcons = observer(({ article, actionExclude }: { article: Article; actionExclude?: boolean }) => {
  const icons = article.isDeepLoaded ? getIconSourceFromArticleDetail(article, actionExclude) : [];

  // if the article has an ecoscore label, append the small ecoscore icon
  if (article.ecoscoreLabel) {
    icons.push({
      celumId: 'ecoscore' + article.ecoscoreLabel, // dummy id that is used below as key for React element
      imgSrc: mapEcoScoreLabelToPictoIconImageUrl(article.ecoscoreLabel),
      text: `ECO-SCORE ${article.ecoscoreLabel}`
    });
  }

  const controls = icons.map((icon, index) => {
    return <PiktoIcon key={icon.celumId + index} icon={icon} index={index} />;
  });

  if (icons.length === 0) {
    return <div style={{ display: 'flex' }} />;
  }
  return <div style={{ display: 'flex', marginBottom: '10px', flexWrap: 'wrap' }}>{controls}</div>;
});

export default ArticleIcons;

function PiktoIcon({ icon, index }: { icon: IIcon; index: number }) {
  // 2024-03-18, Jonas: WEB-1597, PLP-8679
  // Das Backend liefert fälschlicherweise auch Icons, welche eine ungültige CelumId haben.
  // Diese Icons sollen nicht angezeigt werden.
  // Dies ist zwar Symptombekämpfung, aber es wird aus Perspektive UX wird das Frontend dadurch robuster ...
  const [imageLoadingFailure, setImageLoadingFailure] = useState(false);

  const imgSrc = icon.imgSrc;

  function failedToLoadImage() {
    setImageLoadingFailure(true);
  }

  if (imageLoadingFailure) {
    return null;
  }

  return (
    <Tooltip key={icon.celumId + index} title={icon.text} placement="top">
      <Box key={icon.celumId + index} mt={0.5} mr={0.5}>
        <img src={imgSrc} alt={icon.text} onError={failedToLoadImage} />
      </Box>
    </Tooltip>
  );
}
