import React, { useContext, useEffect } from 'react';
import { StoreContext } from '../StoreContext';

export type RedirectRouteState = {
  referrerLocation: Location;
};

export function RequireAuth({ children }: { children: React.ReactElement }) {
  const { userStore } = useContext(StoreContext);
  if (!userStore.currentCustomer) {
    return <LoginRedirect />;
  } else {
    return children;
  }
}

function LoginRedirect() {
  const { userStore } = useContext(StoreContext);

  useEffect(() => {
    async function triggerLoginFlow() {
      await userStore.triggerLoginFlow();
    }
    triggerLoginFlow();
  }, [userStore]);

  return <div>Redirecting to login ...</div>;
}
