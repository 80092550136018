import React from 'react';
import match from 'autosuggest-highlight/match/index';
import parse from 'autosuggest-highlight/parse/index';
import { Typography, ListItem } from '@mui/material';

interface SearchSuggestionItemProps {
  searchTerm: string;
  suggestion: string;
  onSearchSuggestionClicked: (term: string) => void;
  id: string;
}
const SearchSuggestionItem = ({ suggestion, searchTerm, onSearchSuggestionClicked, id }: SearchSuggestionItemProps) => {
  const matches = match(suggestion, searchTerm);
  const parts = parse(suggestion, matches);

  return (
    <ListItem component="div" id={id} button={true} onClick={() => onSearchSuggestionClicked(suggestion)}>
      <Typography variant={'body1'}>
        {parts.map((part: any, index: number) =>
          part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 600 }}>
              {part.text}
            </span>
          ) : (
            <strong key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </strong>
          )
        )}
      </Typography>
    </ListItem>
  );
};

export default SearchSuggestionItem;
