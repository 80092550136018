import { runInAction, makeAutoObservable } from 'mobx';
import RootStore from '../../../rootStore';
import { LOCALE_LOADER } from '../../../util/i18n';
import type { Language } from '../models/language';
import { Article } from '../../shared/articles/models/article';
import { FEATURES } from './featureStore';

export default class UiStore {
  private static WIDTH_LG = 1200;
  private static WIDTH_MD = 960;
  private static WIDTH_SM = 600;
  private static MAX_WIDTH = 1920;

  rootStore: RootStore;

  sideMenuOpen: boolean = false;
  width: number = window.innerWidth > UiStore.MAX_WIDTH ? UiStore.MAX_WIDTH : window.innerWidth;
  height: number = window.innerHeight;
  breadcrumbRef: any;
  toolbarRef: any;
  mobileBreadcrumbRef: any;
  mobileToolbarRef: any;
  language: Language = 'de';
  tileView: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    window.addEventListener('resize', () => this.handleResize());
  }

  async setLanguage(lang: string) {
    switch (lang.substr(0, 2)) {
      case 'fr':
        this.language = 'fr';
        break;
      case 'it':
        this.language = 'it';
        break;
      default:
        this.language = 'de';
        break;
    }

    await LOCALE_LOADER.switchLanguage(lang, this.rootStore);
  }

  setTilesView(value: boolean) {
    if (this.rootStore.userStore.isLoggedIn) {
      if (value) {
        this.rootStore.featureStore.activateSetting('tileView');
      } else {
        this.rootStore.featureStore.deactivateSetting('tileView');
      }
    } else {
      this.tileView = value;
    }
  }

  setBreadcrumbRef(ref: any) {
    this.breadcrumbRef = ref;
  }

  setToolbarRef(ref: any) {
    this.toolbarRef = ref;
  }

  setMobileBreadcrumbRef(ref: any) {
    this.mobileBreadcrumbRef = ref;
  }

  setMobileToolbarRef(ref: any) {
    this.mobileToolbarRef = ref;
  }

  openSideMenu() {
    this.sideMenuOpen = true;
  }

  closeSideMenu() {
    this.sideMenuOpen = false;
  }

  private handleResize() {
    runInAction(() => {
      this.width = window.innerWidth > UiStore.MAX_WIDTH ? UiStore.MAX_WIDTH : window.innerWidth;
      this.height = window.innerHeight;
    });
  }

  get isLargeScreenDown() {
    return this.width <= UiStore.WIDTH_LG;
  }

  get isLargeScreenUp() {
    return this.width >= UiStore.WIDTH_LG;
  }

  get isMediumScreenDown() {
    return this.width <= UiStore.WIDTH_MD;
  }

  get isMediumScreenUp() {
    return this.width >= UiStore.WIDTH_MD;
  }

  get isSmallScreenDown() {
    return this.width <= UiStore.WIDTH_SM;
  }

  get isSmallScreenUp() {
    return this.width >= UiStore.WIDTH_SM;
  }

  get showSideMenu() {
    return this.isMediumScreenDown && this.sideMenuOpen;
  }

  get showTilesView() {
    return this.rootStore.userStore.isLoggedIn ? this.rootStore.featureStore.isFeatureEnabled(FEATURES.tileView) : this.tileView;
  }
}
