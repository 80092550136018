import React, { useContext, useEffect, useState } from 'react';
import { Article } from '../../models/article';
import SwipeableCarousel from '../../../../common/components/SwipeableCarousel';
import CatalogTilesItemSmall from '../tiles/CatalogTilesItemSmall';
import { StoreContext } from '../../../../../StoreContext';
import { getMLSelector } from '../../../../../util/i18n';
import CatalogService from '../../../../catalog/services/catalogService';
import Box from '@mui/material/Box';
import { useModalArticleDetailDialogControl } from '../../util/useModalArticleDetailDialog';

interface SimilarArticlesCarouselProps {
  article: Article;
}

const SimilarArticlesCarousel = ({ article }: SimilarArticlesCarouselProps) => {
  const { userStore } = useContext(StoreContext);
  const [similarArticles, setSimilarArticles] = useState<Article[]>([]);
  const { showArticleDetailsDialog } = useModalArticleDetailDialogControl();

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      const mls = getMLSelector();
      const hagrName = mls(['hagrDE', 'hagrFR', 'hagrIT']);
      const hagrValue = article.hagr;
      const similarArticlesResult = await CatalogService.fetchSimilarArticles(userStore.isLoggedIn, article.articleText, [
        {
          name: hagrName,
          values: [hagrValue]
        }
      ]);

      let similarArticles = similarArticlesResult.articles.filter((x) => x.articleNumber !== article.articleNumber);

      const maxNoOfSimilarArticles = similarArticles.length - (similarArticles.length % 2);

      if (similarArticles.length > 0) {
        similarArticles = similarArticles.slice(0, maxNoOfSimilarArticles);
      }

      if (!didCancel) {
        setSimilarArticles(similarArticles ? similarArticles.map((x) => Article.createFromDto(x, 1)) : []);
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [article, userStore.isLoggedIn]);

  const navigateToDetail = (goToArticle: Article) => {
    showArticleDetailsDialog(goToArticle.articleNumber);
  };

  if (similarArticles.length < 1) {
    return null;
  }

  const similarArticleViews = similarArticles.map((similarArticle: Article, index) => {
    return <CatalogTilesItemSmall key={similarArticle.articleId} loadLedger={false} article={similarArticle} navigateToDetail={navigateToDetail} isLoggedIn={userStore.isLoggedIn} idx={index} />;
  });

  return (
    <Box sx={{ width: 300 }}>
      <SwipeableCarousel items={similarArticleViews} sx={{ mt: 2, mb: 2 }} />
    </Box>
  );
};

export default SimilarArticlesCarousel;
