import type { IExternalArticlePositionData } from '../../../api';
import { makeAutoObservable } from 'mobx';
import { getMLSelector } from '../../../util/i18n';
import { ppConstants } from '../../../util/ppConstants';
import CartStore from './cartStore';
import ModifyPositionIntent from '../models/intent/ModifyPositionIntent';
import debounce from 'lodash/debounce';
import BaseIntent from '../models/intent/BaseIntent';
import i18n from 'i18next';

export default class CartExternalArticlePosition {
  constructor(dto: IExternalArticlePositionData) {
    makeAutoObservable(this);

    const mls = getMLSelector();

    this.id = dto.id;
    this.supplierId = dto.supplierId;
    this.supplierName = dto.supplierName;
    this.supplierStreet = dto.supplierStreet;
    this.supplierZip = dto.supplierZip;
    this.supplierCity = dto.supplierCity;
    this.orderByEmail = dto.orderByEmail;
    this.supplierEmail = dto.supplierEmail;
    this.supplierCustomerNumber = dto.supplierCustomerNumber;
    this.customerOrderId = dto.customerOrderId;
    this.articleId = dto.articleId;
    this.articleText = mls([dto.articleTextDe, dto.articleTextFr, dto.articleTextIt]);
    this.articleNumber = dto.articleNumber;
    this.deliveryUnit = mls([dto.deliveryUnitDe, dto.deliveryUnitFr, dto.deliveryUnitIt]);
    this.contentAmount = dto.contentAmount;
    this.contentUnit = mls([dto.contentUnitDe, dto.contentUnitFr, dto.contentUnitIt]);
    this.quantity = dto.quantity;
    this.price = dto.price;
    this.ean = dto.ean;
    this.inputOrderQuantity = dto.quantity;
  }

  id: number;
  supplierId: number;
  supplierName: string;
  supplierStreet: string;
  supplierZip: string;
  supplierCity: string;
  orderByEmail: boolean;
  supplierEmail: string;
  supplierCustomerNumber: string;
  customerOrderId: number;
  articleId: number;
  articleText: string;
  articleNumber: string;
  deliveryUnit: string;
  contentAmount: number;
  contentUnit: string;
  quantity: number;
  price: number;
  ean: string;

  inputOrderQuantity: number;
  deleteInProgress = false;

  update(dto: IExternalArticlePositionData) {
    const mls = getMLSelector();

    this.supplierId = dto.supplierId;
    this.supplierName = dto.supplierName;
    this.supplierStreet = dto.supplierStreet;
    this.supplierZip = dto.supplierZip;
    this.supplierCity = dto.supplierCity;
    this.orderByEmail = dto.orderByEmail;
    this.supplierEmail = dto.supplierEmail;
    this.supplierCustomerNumber = dto.supplierCustomerNumber;
    this.customerOrderId = dto.customerOrderId;
    this.articleId = dto.articleId;
    this.articleText = mls([dto.articleTextDe, dto.articleTextFr, dto.articleTextIt]);
    this.articleNumber = dto.articleNumber;
    this.deliveryUnit = mls([dto.deliveryUnitDe, dto.deliveryUnitFr, dto.deliveryUnitIt]);
    this.contentAmount = dto.contentAmount;
    this.contentUnit = mls([dto.contentUnitDe, dto.contentUnitFr, dto.contentUnitIt]);
    this.quantity = dto.quantity;
    this.price = dto.price;
    this.ean = dto.ean;

    this.inputOrderQuantity = this.quantity;
  }

  get total() {
    return this.price * (this.contentAmount ? this.contentAmount : 1) * this.quantity;
  }

  get contentAmountDescription() {
    const units = this.contentAmount > 1 && this.deliveryUnit ? this.deliveryUnit + ` ${i18n.t('sellUnitSeparator')} ` : '';
    const contentAmount = this.contentAmount ? this.contentAmount : '';
    return units + contentAmount + ' ' + this.contentUnit;
  }

  toogleDeleteInProgress() {
    this.deleteInProgress = !this.deleteInProgress;
  }

  increaseInputQuantity() {
    if (this.inputOrderQuantity + 1 <= ppConstants.MAX_DESIRED_QUANTITY) {
      this.inputOrderQuantity++;
    }
  }

  decreaseInputQuantity() {
    if (this.inputOrderQuantity > 0) {
      this.inputOrderQuantity--;
    }
  }

  changeInputQuantity(quantity: number) {
    this.inputOrderQuantity = Math.max(quantity || 0, 0);
  }

  commit(store: CartStore) {
    if (this.quantity !== this.inputOrderQuantity) {
      this.addIntentToCartStore(new ModifyPositionIntent(store, this.id, this.inputOrderQuantity, null, this.articleNumber, true), store);
    }
  }

  private addIntentToCartStore = debounce((intent: BaseIntent, store: CartStore) => {
    store.addIntent(intent);
  }, ppConstants.DEBOUNCE_TIME);
}
