import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Toolbar } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from 'mdi-material-ui/Menu';
import CompactCartInfoButton from '../CompactCartInfoButton';
import { NotificationInfo } from '../../../../@tgs-web-ui/notificationcenter';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import SideDrawer from '../SideDrawer';
import CatalogDialog from './navigation/CatalogDialog';
import { StoreContext } from '../../../../StoreContext';
import { observer } from 'mobx-react-lite';
import MobileSearch from './search/MobileSearch';
import Box from '@mui/material/Box';
import { QuickSearch } from '../../../../features/search/quicksearch/QuickSearch';

export interface ICompactHeaderProps {
  showLanguageSwitch: boolean;
  onSwitchLanguage: (language: any) => any;
}

const CompactHeader = observer(({ showLanguageSwitch, onSwitchLanguage }: ICompactHeaderProps) => {
  const { uiStore, masterDataStore, featureStore } = useContext(StoreContext);
  const [catalogDialogOpen, setCatalogDialogOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <>
      <AppBar color={'inherit'} elevation={1} sx={{ height: '120px', backgroundColor: 'secondary.light' }}>
        <Toolbar sx={{ p: 0, pr: 1 }}>
          <Grid container justifyContent={'space-between'} wrap={'nowrap'} alignItems={'center'}>
            <Grid item>
              <IconButton onClick={() => uiStore.openSideMenu()} id={'mobile-nav-button'}>
                <MenuIcon color={'primary'} />
              </IconButton>
            </Grid>
            <Grid item ml={1} mr={1} flexGrow={1} justifyContent={'center'} display={'flex'}>
              <Box width={'100%'} maxWidth={'650px'} minWidth={'120px'}>
                <Grid item>{featureStore.enableHubSearchNew ? <QuickSearch /> : <MobileSearch />}</Grid>
              </Box>
            </Grid>
            <Grid item>
              <Grid container wrap={'nowrap'} justifyContent={'flex-end'}>
                <Grid item>{masterDataStore.hasNotifications && <NotificationInfo onOpen={() => masterDataStore.openNotificationDialog()} tooltipText={t('Benachrichtigungen')} count={masterDataStore.unreadNotificationCount} />}</Grid>
                <Grid item>
                  <CompactCartInfoButton />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        <Toolbar sx={{ height: '60px', pl: 2, pr: 0 }}>
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid
              item
              ref={(ref) => {
                uiStore.setMobileBreadcrumbRef(ref);
              }}
            />
            <Grid
              item
              ref={(ref) => {
                uiStore.setMobileToolbarRef(ref);
              }}
            />
          </Grid>
        </Toolbar>
      </AppBar>
      <SideDrawer showLanguageSwitch={showLanguageSwitch} onSwitchLanguage={onSwitchLanguage} onCatalogOpen={() => setCatalogDialogOpen(true)} />
      <CatalogDialog open={catalogDialogOpen} onClose={() => setCatalogDialogOpen(false)} />
    </>
  );
});

export default CompactHeader;
