import { PPCONTEXT } from './ppContext';

export function mapEcoScoreLabelToPictoIconImageUrl(ecoscoreLabel: string) {
  // Jonas, 2023-10-30: Die Bilder im Celum haben Icon und Text. Für den Webshop wurden die Bilder bearbeitet, sodass nur noch das Icon übrig bleibt.
  const ecoscoreSmallIconName = ecoscoreLabel.replace('+', '_plus').replace('-', '_minus');
  return `${PPCONTEXT.STATIC_IMAGES_BASE_URL}/ecoscore/ECO_Score_KURZ_${ecoscoreSmallIconName}.jpg`;
}

export function mapEcoScoreLabelToImageUrl(ecoscoreLabel: string) {
  // Jonas, 2023-10-10:
  // Der automatische Export aus Celum funktioniert für diese EcoScore Bilder nicht befriedigend. Die Auflösung des Exports ist zu klein (die entsprechenden Bilder in /icon/...)
  // Die EcoScore Images wurden daher manuell auf den Apache kopiert under dem folgenden Pfad.
  const ecoscoreScoreImageName = ecoscoreLabel.replace('+', '_plus').replace('-', '_minus');
  return `${PPCONTEXT.STATIC_IMAGES_BASE_URL}/ecoscore/ECO_Score_${ecoscoreScoreImageName}.jpg`;
}
