import type { IWebPromotionData } from '../../../api';
import { makeAutoObservable } from 'mobx';
import i18n from 'i18next';
import { getMLSelector } from '../../../util/i18n';
import { PPCONTEXT } from '../../../util/ppContext';

export class Promotion {
  id: number | undefined;
  name: string | undefined;
  startDate: Date | undefined;
  endDate: Date | undefined;
  imageId: number | undefined;
  isDateVisible: boolean | undefined;
  hwgId?: string;

  constructor(dto: IWebPromotionData) {
    makeAutoObservable(this);

    const mls = getMLSelector();

    this.id = dto.id;
    this.name = mls([dto.nameDe, dto.nameFr, dto.nameIt]);
    //Note: null-checks with `!=` to match 'null' and 'undefined'
    this.imageId = mls([dto.celumId, dto.celumIdFr != null ? dto.celumIdFr : dto.celumId, dto.celumIdIt != null ? dto.celumIdIt : dto.celumId]);
    this.startDate = dto.startDate ? (dto.startDate as any) : undefined;
    this.endDate = dto.endDate ? (dto.endDate as any) : undefined;
    this.isDateVisible = dto.dateVisible;
    this.hwgId = (dto as any).hwgId;
  }

  get backgroundImageUrl() {
    return `${PPCONTEXT.STATIC_IMAGES_BASE_URL}/promo/${this.imageId}.jpg`;
  }

  get promotionUrl() {
    if (this.hwgId) {
      return '/hwg/' + this.hwgId;
    }
    return '/promotion/' + this.id;
  }

  get displayName() {
    let name = this.name ? this.name : '';
    if (this.isDateVisible && this.endDate !== null) {
      const dateStart = this.startDate ? new Date(this.startDate) : null;
      const startDate = dateStart ? dateStart.getDate() + '.' + (dateStart.getMonth() + 1) + '.' + dateStart.getFullYear() : '';

      const dateEnd = this.endDate ? new Date(this.endDate) : null;
      const endDate = dateEnd ? dateEnd.getDate() + '.' + (dateEnd.getMonth() + 1) + '.' + dateEnd.getFullYear() : '';

      name = name + ', ' + i18n.t('gültig von {{0}} bis {{1}}', { 0: startDate, 1: endDate });
    }

    return name;
  }
}
