import React from 'react';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import { Grow, Theme } from '@mui/material';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { makeStyles } from 'tss-react/mui';
import { SxProps } from '@mui/system';

const useStyles = makeStyles()(({ palette }: Theme) => {
  return {
    navButton: {
      fontSize: '1em',
      color: palette.text.secondary,
      borderBottom: '3px solid ' + palette.secondary.light,

      '&:hover': {
        color: palette.text.primary,
        backgroundColor: palette.secondary.light,
        borderBottom: '3px solid ' + palette.primary.main
      }
    },
    popper: {
      zIndex: 9999999999
    }
  };
});

interface INavigationDropDownProps {
  title: string | JSX.Element;
  menu?: (close: (event: any) => void) => JSX.Element;
  simpleStyle?: boolean;
  id?: string;
  onClick?: () => void;
  buttonSx?: SxProps;
}

const NavigationDropDown = observer(({ onClick, title, menu, simpleStyle, id, buttonSx }: INavigationDropDownProps) => {
  const [open, setOpen] = React.useState(false);
  const anchorEl = React.useRef<any>(null);
  const { classes } = useStyles();

  const handleToggle = () => {
    if (onClick) {
      onClick();
    } else {
      setOpen(!open);
    }
  };

  function handleClose(event: any) {
    if (anchorEl.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  }

  return (
    <>
      <Button
        variant={'text'}
        color={'primary'}
        ref={anchorEl}
        disableRipple
        className={simpleStyle ? undefined : classNames(classes.navButton)}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        id={id}
        sx={buttonSx}
      >
        {title}
      </Button>
      {menu && (
        <Popper open={open} className={classes.popper} anchorEl={anchorEl.current} transition disablePortal placement={'bottom-start'}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper square sx={{ maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <div style={{ margin: 0 }}>{menu(handleClose)}</div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
});

export default NavigationDropDown;
