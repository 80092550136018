import React from 'react';
import { PPCONTEXT } from '../../../../util/ppContext';
import OutboundLink from '../../../shared/components/OutboundLink';
import { Theme, Grid, Typography } from '@mui/material';
import webshopConfig, { IFooterLink } from '../../../../config/webshopConfig';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { throwTestError } from '../../../../tracking/tracking';
import { getMLSelector } from '../../../../util/i18n';

const useStyles = makeStyles()(({ spacing, palette }: Theme) => {
  return {
    root: {
      backgroundColor: palette.secondary.light,
      padding: spacing(2)
    },
    deploymentInfo: {
      paddingTop: spacing(5),
      textAlign: 'center'
    },
    link: {
      textDecoration: 'none',
      color: palette.secondary.main,
      padding: spacing(1)
    }
  };
});

const Footer = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  function triggerTestError(event: React.MouseEvent) {
    if (event.altKey) {
      throwTestError();
    }
  }

  return (
    <footer className={classes.root}>
      <Grid container justifyContent={'center'} spacing={2}>
        {webshopConfig.footerLinks.map((link: IFooterLink, index: number) => (
          <Grid item key={index}>
            <OutboundLink href={typeof link.href === 'function' ? link.href() : link.href} target="_blank" className={classes.link} id={'footer-link-' + index}>
              {t(link.labelKey)}
            </OutboundLink>
          </Grid>
        ))}
      </Grid>
      <div className={classes.deploymentInfo}>
        <Typography variant="caption" gutterBottom color={'textSecondary'} onClick={triggerTestError}>
          <small>{`WebShop Version: ${PPCONTEXT.RELEASE_VERSION}, Build: ${PPCONTEXT.BUILD_TIME}`}</small>
          {PPCONTEXT.CONTAINER_STARTUP_TIME && <small>{`, Deploy: ${PPCONTEXT.CONTAINER_STARTUP_TIME}`}</small>}
        </Typography>
      </div>
    </footer>
  );
};

export default Footer;
