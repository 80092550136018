import React from 'react';
import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import withContentLayout from '../../withContentLayout';
import useTimeout from '../hooks/useTimeout';

const ComponentLoadingIndicator = () => {
  const showLoadingIndicator = useTimeout(500);

  return showLoadingIndicator ? (
    <Grid container justifyContent={'center'}>
      <Grid item>
        <CircularProgress variant={'indeterminate'} />
      </Grid>
    </Grid>
  ) : null;
};

export default withContentLayout(null, null, ComponentLoadingIndicator);
