export function insertWhitespaceIfMissing(stringValue: string, searchPattern: string) {
  const regex = new RegExp(`${searchPattern}(?=\\S)`, 'gi'); // match searchPattern NOT followed by a whitespace, but only capture the searchPattern
  return stringValue.replace(regex, `${searchPattern} `);
}

export const fixCommas = (stringValue: string) => {
  const fixedString: string = insertWhitespaceIfMissing(stringValue, ',');
  return fixedString;
};

export const formatServerMessage = (stringValue: string): string[] => {
  return stringValue.split('\n').filter((v) => v.length > 0);
};

export const crop = (text: string, maxLength: number) => {
  if (text.length >= maxLength) {
    return text.substring(0, maxLength - 4) + '...';
  } else {
    return text;
  }
};

export const cropText = (text: string, screenWidth: number, settings: { screenWidth: number; maxLength: number }[]) => {
  let response;

  for (let index = 0; index < settings.length; index++) {
    const setting: { screenWidth: number; maxLength: number } = settings[index];
    if (settings && screenWidth <= setting.screenWidth) {
      if (text.length >= setting.maxLength) {
        response = text.substring(0, setting.maxLength - 4) + ' ...';
      }
      break;
    }
  }

  return response ? response : text;
};

export const summarise = (arr: string[], maxLength: number, prefix: string = '') => {
  let text = '';
  const numberOfItems = arr.length;
  let numberofVisibleItems = 0;

  for (let i = 0; i < numberOfItems; i++) {
    const currentText = arr[i];
    const prefix = i < 1 ? '' : ', ';
    if (text.length + currentText.length + prefix.length <= maxLength) {
      text += prefix + currentText;
      numberofVisibleItems++;
    } else {
      break;
    }
  }

  if (numberofVisibleItems === numberOfItems && text.length + prefix.length + 2 < maxLength) {
    text = `${prefix}: ${text}`;
  }

  return { summarizedText: text, notVisibleItems: numberOfItems - numberofVisibleItems };
};
