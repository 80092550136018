import { makeAutoObservable } from 'mobx';
import { groupBy, sortBy } from '../../../../util/filterConstants';
import { getMLSelector } from '../../../../util/i18n';

export class TemplateFilter {
  groupBy: { name: string; value: string };
  sortBy: { name: string; value: string; blockGroupBy: boolean };
  term: string = '';
  groupKey: string = '';
  isSelected: boolean;

  constructor() {
    makeAutoObservable(this);
    const mls = getMLSelector();

    this.sortBy = sortBy.ARTTXT;
    this.groupBy = groupBy.HWG;
    this.isSelected = false;
    this.groupKey = mls(['Alle anzeigen', 'Afficher tous', 'Mostra tutti']);
  }

  get isSortByCustomOrder() {
    return this.sortBy.name === sortBy.CUSTOM.name;
  }
}
