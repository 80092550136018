import React, { useContext, useEffect } from 'react';
import { Divider, Fade, Grid, Paper, Theme, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Price from './Price';
import { Article } from '../../models/article';
import classNames from 'classnames';
import ImageContainer from '../../../../common/components/ImageContainer';
import { StoreContext } from '../../../../../StoreContext';
import { observer } from 'mobx-react-lite';
import { makeStyles } from 'tss-react/mui';

interface ICatalogTilesItemSmallProps {
  article: Article;
  loadLedger: boolean;
  isLoggedIn: boolean;
  navigateToDetail: (article: Article) => void;
  flexWidth?: boolean;
  idx?: number;
  id?: string;
}

const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      width: TILES_WIDTH,
      height: 300,
      textAlign: 'center',
      padding: theme.spacing(0.1),
      border: '1px solid rgba(0,0,0,0.12)',
      boxShadow: 'none'
    },
    title: {
      marginBottom: theme.spacing(1),
      height: 40
    },
    textContent: {
      height: '100%',
      marginTop: theme.spacing(1),
      padding: theme.spacing(1),
      paddingTop: 0
    },
    imageContainer: {
      marginTop: theme.spacing(1),
      alignContent: 'center',
      alignSelf: 'center'
    },
    link: {
      cursor: 'pointer'
    }
  };
});

export const TILES_WIDTH = 300;
export const TILES_SPACING_WIDTH = 16;

const CatalogTilesItemSmall = observer(({ article, flexWidth, loadLedger, navigateToDetail, idx = 0, id }: ICatalogTilesItemSmallProps) => {
  const { userStore } = useContext(StoreContext);
  const { classes } = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    if (!article.isDeepLoaded && !article.isDeepLoading) {
      article.fetchArticleDeepLoaded(userStore.isLoggedIn, loadLedger);
    }
  }, [loadLedger, userStore.isLoggedIn]); // preventing endless loop when deeploading

  const handleNavigate = () => {
    navigateToDetail(article);
  };

  return (
    <Fade in={true} timeout={600}>
      <Paper className={classes.root} style={{ width: flexWidth ? 'auto' : TILES_WIDTH }} id={id}>
        <Grid container direction="column">
          <Grid item className={classes.link} onClick={handleNavigate}>
            <Price item={article} id={'tiles-item-small-article-price-' + idx} actionExclude={userStore.actionExclude} />
            <Divider />
          </Grid>
          <Grid item className={classNames(classes.imageContainer, classes.link)} onClick={handleNavigate}>
            <ImageContainer src={article.thumbImageUrl} width={120} height={120} id={idx + '-image'} />
          </Grid>
          <Grid container item className={classes.textContent} direction="column" justifyContent="space-between">
            <Grid item>
              <Typography variant={'caption'}>{`${t('Art. Nr.')} ${article.articleNumber}`}</Typography>
              <Typography variant="subtitle2" className={classNames(classes.title, classes.link)} onClick={handleNavigate} id={'tiles-item-small-article-' + idx}>
                {article.articleText}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Fade>
  );
});

export default CatalogTilesItemSmall;
