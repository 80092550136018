import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, DialogContent, DialogTitle, FormControlLabel, Grid, Switch, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ProgressButton } from '../../../../@tgs-web-ui/progressbutton';
import OutboundLink from '../../../shared/components/OutboundLink';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../../../StoreContext';
import { makeStyles } from 'tss-react/mui';

const AGB_LINK = 'https://www.transgourmet.ch/agb';

export interface IAgbProps {
  onLoginFinished: () => void;
}

export const useStyles = makeStyles()(() => {
  return {
    dialog: {
      position: 'relative',
      top: '-25%'
    },
    dialogButton: {
      minWidth: 100,
      marginLeft: 7
    },
    agbLink: {
      fontSize: 'larger'
    }
  };
});

export const AGB = observer(({ onLoginFinished }: IAgbProps) => {
  const { userStore } = useContext(StoreContext);
  const [agbAccepted, setAgbAccepted] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { classes } = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleChangeAGBAccepted = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgbAccepted(event.target.checked);
  };

  const confirmAcceptAG = async () => {
    if (agbAccepted) {
      setIsSaving(true);
      await userStore.acceptAGB();
      onLoginFinished();
    }
  };

  function cancel() {
    userStore.performLogout();
  }

  return (
    <Dialog open={true} fullScreen={fullScreen}>
      <DialogTitle id="simple-dialog-title">{t('AGB')}</DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="body1" gutterBottom>
              {t('Bitte lesen Sie unsere AGB durch und akzeptieren Sie diese, um bestellen zu können')}:
            </Typography>
            <OutboundLink href={AGB_LINK} target="_blank" className={classes.agbLink}>
              {t('Link zu unseren AGB')}
            </OutboundLink>
          </Grid>
          <Grid item>
            <Box mt={3}>
              <Grid container direction="row" justifyContent="space-between">
                <Grid item>
                  <FormControlLabel control={<Switch checked={agbAccepted} onChange={handleChangeAGBAccepted} color="primary" />} label={t('AGBs akzeptieren')} />
                </Grid>
                <Grid item>
                  <Button variant="contained" type="submit" className={classes.dialogButton} onClick={cancel} disabled={isSaving} id="cancel">
                    {t('Abbrechen')}
                  </Button>
                  <ProgressButton variant="contained" color="primary" text={t('Akzeptieren')} inProgress={isSaving} className={classes.dialogButton} disabled={!agbAccepted} onClick={confirmAcceptAG} id="confirmAcceptAG" />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
});
