import mitt, { Emitter } from 'mitt';

type Events = {
  error: string;
  success: string;
  warning: string;
  info: string;
};

const emitter: Emitter<Events> = mitt<Events>();

const singletonNotifications = Object.freeze(emitter);

export default singletonNotifications;
