import type { IWebInventoryPositionArticleData, IWebInventoryPositionData } from '../../../api';
import { makeAutoObservable } from 'mobx';
import { getMLSelector } from '../../../util/i18n';
import { ppConstants } from '../../../util/ppConstants';
import i18n from 'i18next';

export enum QuantityDisplay {
  SellAmount,
  ArticleAmount
}

export default class InventoryPosition {
  static createFromDto(dto: IWebInventoryPositionArticleData): InventoryPosition {
    const mls = getMLSelector();

    const inventoryPosition = new InventoryPosition();
    inventoryPosition.id = dto.position.id;
    inventoryPosition.quantity = dto.position.quantity;
    inventoryPosition.rating = dto.position.rating;
    inventoryPosition.isExternalArticle = dto.position.externalArticle;
    inventoryPosition.isCalculatedPrice = dto.position.calculatedPrice;
    inventoryPosition.isManualPrice = dto.position.manualPrice;
    inventoryPosition.hasArticle = !!dto.article;

    if (!dto.article) {
      inventoryPosition.articleText = dto.position.articleEan;
      inventoryPosition.price = dto.position.price;
    } else {
      inventoryPosition.price = dto.position.price ?? dto.article.regularPrice;
      inventoryPosition.articleText = mls(dto.article.articleText);
      inventoryPosition.sellUnit = mls(dto.article.sellUnit);
      inventoryPosition.articleUnit = mls(dto.article.articleUnit);
      inventoryPosition.hwg = mls(dto.article.hwg);
      inventoryPosition.hagr = mls(dto.article.hagr);
      inventoryPosition.brand = dto.article.brand;
      inventoryPosition.ledgerAccount = dto.article.ledgerAccount;
      inventoryPosition.sellAmount = dto.article.sellAmount;
      inventoryPosition.articleNumber = dto.article.articleNumber;
      inventoryPosition.unitDescription = mls(dto.article.articleUnit);
      inventoryPosition.priceCode = dto.article.priceCode;
      inventoryPosition.approxWeight = dto.article.approxWeight;
    }

    return inventoryPosition;
  }

  static createFromInventoryPositionDto(dto: IWebInventoryPositionData): InventoryPosition {
    const inventoryPosition = new InventoryPosition();
    inventoryPosition.id = dto.id;
    inventoryPosition.quantity = dto.quantity;
    inventoryPosition.price = dto.price;
    inventoryPosition.rating = dto.rating;
    inventoryPosition.articleText = dto.articleEan;
    inventoryPosition.isExternalArticle = dto.externalArticle;
    inventoryPosition.isCalculatedPrice = dto.calculatedPrice;
    inventoryPosition.isManualPrice = dto.manualPrice;
    inventoryPosition.hasArticle = false;

    return inventoryPosition;
  }

  id!: number;
  quantity!: number;
  price!: number;
  sellAmount!: number;
  rating!: number;
  articleNumber?: string;
  articleText!: string;
  unitDescription?: string;
  isExternalArticle!: boolean;
  isCalculatedPrice!: boolean;
  isManualPrice!: boolean;
  hasArticle!: boolean;
  priceCode?: number;
  sellUnit?: string;
  approxWeight?: number;
  articleUnit?: string;
  hwg?: string;
  hagr?: string;
  brand?: string;
  ledgerAccount?: string;

  quantityDisplay: QuantityDisplay = QuantityDisplay.SellAmount;

  private constructor() {
    makeAutoObservable(this);
  }

  positionUpdate(update: { id: number; quantity: number; rating: number; isManualPrice: boolean; price: number }) {
    this.quantity = update.quantity;
    this.rating = update.rating;
    this.isManualPrice = update.isManualPrice;
    this.price = update.price;
  }

  toggleQuantityDisplay() {
    this.quantityDisplay = this.quantityDisplay === QuantityDisplay.SellAmount ? QuantityDisplay.ArticleAmount : QuantityDisplay.SellAmount;
  }

  get isCalcUnitChangePossible(): boolean {
    return this.hasArticle && this.sellAmount > 1 && !this.isExternalArticle && this.priceCode !== ppConstants.PRICECODE_WEIGHTCONTROLLED;
  }

  get sellAmountDescription() {
    if (!this.sellAmount) {
      return '';
    }

    return (
      (this.sellAmount > 1 ? this.sellUnit + ` ${i18n.t('sellUnitSeparator')} ` : '') +
      (this.priceCode === ppConstants.PRICECODE_WEIGHTCONTROLLED ? (this.approxWeight ? this.approxWeight.toFixed(3) : 0) : this.sellAmount) +
      ' ' +
      this.articleUnit
    );
  }

  get quantityDigitCount() {
    let quantityDigitCount = 0;

    if ((this.quantityDisplay === QuantityDisplay.SellAmount && this.hasArticle && this.sellAmount > 1 && !this.isExternalArticle) || (this.hasArticle && this.priceCode === ppConstants.PRICECODE_WEIGHTCONTROLLED)) {
      quantityDigitCount = 4;
    }

    return quantityDigitCount;
  }

  get ratedPriceText() {
    if (!this.sellAmount) {
      return '';
    }
    return `CHF ${(((this.price * this.sellAmount * this.quantity) / 100) * this.rating).toFixed(2)}`;
  }

  get priceText() {
    if (!this.sellAmount) {
      return '';
    }
    return `CHF ${(this.price * this.sellAmount * this.quantity).toFixed(2)}`;
  }

  get quantityDescription() {
    if (this.quantityDisplay === QuantityDisplay.ArticleAmount) {
      return this.articleUnit;
    } else {
      return this.priceCode === ppConstants.PRICECODE_WEIGHTCONTROLLED ? this.articleUnit : this.sellAmount > 1 ? this.sellUnit : this.articleUnit;
    }
  }

  updateFromInventoryPositionArticle(dto: IWebInventoryPositionArticleData) {
    const mls = getMLSelector();

    this.id = dto.position.id;
    this.price = dto.position.price;
    this.quantity = dto.position.quantity;
    this.rating = dto.position.rating;
    this.hasArticle = !!dto.article;
    this.isManualPrice = dto.position.manualPrice;
    this.isExternalArticle = dto.position.externalArticle;
    this.isCalculatedPrice = dto.position.calculatedPrice;

    if (!dto.article) {
      this.articleText = dto.position.articleEan;
    } else {
      this.articleText = mls(dto.article.articleText);
      this.sellUnit = mls(dto.article.sellUnit);
      this.articleUnit = mls(dto.article.articleUnit);
      this.hwg = mls(dto.article.hwg);
      this.hagr = mls(dto.article.hagr);
      this.brand = dto.article.brand;
      this.ledgerAccount = dto.article.ledgerAccount;
      this.priceCode = dto.article.priceCode;
      this.approxWeight = dto.article.approxWeight;
      this.unitDescription = mls(dto.article.articleUnit);
      this.sellAmount = dto.article.sellAmount;
      this.articleNumber = dto.article.articleNumber;
    }
  }
}
