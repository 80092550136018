import type { IExternalSupplierData } from '../../../api';
import { makeAutoObservable, runInAction } from 'mobx';
import RootStore from '../../../rootStore';
import SupplierService from '../services/supplierService';
import { Supplier } from './models/supplier';
import { executeAsyncAction } from '../../../util/exceptionHandler';

export class SupplierStore {
  store: RootStore;

  suppliers: Supplier[] = [];
  pendingRequestsCount = 0;
  loaded: boolean = false;
  selectedSupplier?: Supplier;
  showRemoveDialogSupplier: boolean = false;

  constructor(store: RootStore) {
    makeAutoObservable(this);
    this.store = store;
  }

  fetchData() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const dtos = await SupplierService.fetchSuppliers();
        const suppliers = dtos.map((dto: IExternalSupplierData) => Supplier.createFromDto(this, dto));

        runInAction(() => {
          this.suppliers = suppliers;
          this.loaded = true;
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  fetchById(supplierId: number) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;

        const dto = await SupplierService.fetchSupplier(supplierId);
        const supplier = Supplier.createFromDto(this, dto);

        runInAction(() => {
          this.selectedSupplier = supplier;
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  removeSupplier(supplierId: number) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        await SupplierService.removeSupplier(supplierId);

        runInAction(() => {
          this.suppliers = this.suppliers.filter((x) => x.id !== supplierId);
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  save(supplier: Supplier) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        await SupplierService.saveSupplier(supplier.toDto());
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  setSelectedSupplier(supplier: Supplier) {
    this.selectedSupplier = supplier;
  }

  clearSelectedSupplier() {
    this.selectedSupplier = undefined;
  }

  showRemoveDialog() {
    this.showRemoveDialogSupplier = true;
  }

  closeRemoveDialog() {
    this.showRemoveDialogSupplier = false;
  }

  createNew(): Supplier {
    return new Supplier(this);
  }

  get isLoading() {
    return this.pendingRequestsCount > 0;
  }
}
