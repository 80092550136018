import type { IFacetItemData } from '../../../../api';
import { makeAutoObservable } from 'mobx';

export class CatalogFacetItem {
  name: string;
  count: number;
  checked: boolean;

  constructor(dto: IFacetItemData) {
    makeAutoObservable(this);

    this.name = dto.name ? dto.name : '';
    this.count = dto.count ? dto.count : 0;
    this.checked = dto.checked;
  }

  get capitalizedName() {
    return this.name && this.name[0].toUpperCase() + this.name.slice(1);
  }

  updateProperty(this: any, field: string, value: any) {
    this[field] = value;
  }
}
