import type { IExternalSupplierData } from '../../../api';
import axios from '../../../util/axios';
import { ppConstants } from '../../../util/ppConstants';

const SupplierService = {
  async fetchSuppliers(): Promise<IExternalSupplierData[]> {
    const searchResult = await axios.get(ppConstants.API_HOST_SEC + 'externalsuppliers', {});
    return searchResult.data;
  },

  async fetchSupplier(suppId: number): Promise<IExternalSupplierData> {
    const result = await axios.get(ppConstants.API_HOST_SEC + 'externalsuppliers/' + suppId, {});
    return result.data;
  },

  async saveSupplier(supplier: Omit<IExternalSupplierData, 'version'>): Promise<IExternalSupplierData> {
    const result = await axios.post(ppConstants.API_HOST_SEC + 'externalsuppliers', supplier, {});
    return result.data;
  },

  async removeSupplier(supplierId: number): Promise<number> {
    await axios({
      method: 'DELETE',
      url: ppConstants.API_HOST_SEC + 'externalsuppliers/' + supplierId,
      data: {}
    });

    return supplierId;
  }
};

export default SupplierService;
