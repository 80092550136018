import type { ICustomerOrderData, ICustomerOrderPositionData } from '../../../api';
import findIndex from 'lodash/findIndex';
import sumBy from 'lodash/sumBy';
import { makeAutoObservable } from 'mobx';
import CartExternalArticlePosition from './cartExternalArticlePosition';
import CartSupplierExternalArticle from './cartSupplierExternalArticle';
import CustomerOrderPosition from './customerOrderPosition';

export class Order {
  id: number;
  desiredDeliveryDate: any;
  creationSystem: any;
  positions: CustomerOrderPosition[] = [];
  externalArticlePositions: CartExternalArticlePosition[] = [];
  totalExcl: number;
  numberOfPositions: number;

  constructor(dto: ICustomerOrderData) {
    makeAutoObservable(this);

    this.id = dto.id;
    this.desiredDeliveryDate = dto.desiredDeliveryDate;
    this.creationSystem = dto.creationSystem;
    this.totalExcl = dto.totalExcl;
    this.numberOfPositions = dto.numberOfPositions;
    this.externalArticlePositions = dto.externalArticlePositions ? dto.externalArticlePositions.map((e) => new CartExternalArticlePosition(e)) : [];

    this.positions = dto.positions
      ? dto.positions.map((x: ICustomerOrderPositionData) => {
          return new CustomerOrderPosition(x);
        })
      : [];
  }

  get total() {
    return sumBy(this.positions, (p) => p.total);
  }

  get externalPositionsBySuppliers() {
    const suppliers: CartSupplierExternalArticle[] = [];

    for (const extArtPos of this.externalArticlePositions) {
      const i = findIndex(suppliers, (x) => x.supplierId === extArtPos.supplierId);
      if (i < 0) {
        const articles = [];
        articles.push(extArtPos);
        suppliers.push(new CartSupplierExternalArticle(extArtPos.supplierId, extArtPos.supplierName, articles));
      } else {
        suppliers[i].articles.push(extArtPos);
      }
    }

    return suppliers;
  }
}
