import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICartExpirationHandler } from '../../types/ICartExpirationHandler';
import { DeliveryCalendar } from '../DeliveryCalendar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

export interface ICartDeliveryDateExpiredDialogProps {
  handler: (data: ICartExpirationHandler) => void;
}

const CartDeliveryDateExpiredDialog = ({ handler }: ICartDeliveryDateExpiredDialogProps) => {
  const { t } = useTranslation();

  const onDateSelected = (date: Date) => {
    handler({ type: 'REFRESH', deliveryDate: date });
  };

  const doDiscardCart = () => {
    handler({ type: 'DISCARD' });
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid container item>
        <Typography variant="body1">{t('Bitte wählen Sie ein neues Lieferdatum.')}</Typography>
        <DeliveryCalendar onDateSelected={onDateSelected} />
      </Grid>
      <Divider />
      <Grid container item direction={'row'}>
        <Grid item xs={9}>
          <Typography variant="body1">{t('Sie können den Warenkorb verwerfen und einen neuen Warenkorb anlegen.')}</Typography>
        </Grid>
        <Grid item xs={3} container justifyContent={'flex-end'}>
          <Button variant={'contained'} color={'primary'} onClick={doDiscardCart}>
            {t('Verwerfen')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CartDeliveryDateExpiredDialog;
