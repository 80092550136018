import { useQuery } from '@tanstack/react-query';
import type { IWineSocietyDTOData } from '../../../api';
import axios from '../../../util/axios';
import { ppConstants } from '../../../util/ppConstants';
import { qkWineSocieties } from './searchQueryKeys';
import { WineSociety } from '../../../modules/common/models/wineSociety';
import { executeAsyncActionNew } from '../../../util/exceptionHandler';

export async function fetchWineSocieties(): Promise<IWineSocietyDTOData[]> {
  const response = await axios.get(ppConstants.API_HOST_PUB + 'webshop/wineSociety');
  return response.data;
}

export function useWineSocieties() {
  const wineSociety = useQuery<IWineSocietyDTOData[], Error>({
    queryKey: [qkWineSocieties],
    queryFn: () =>
      executeAsyncActionNew(async () => {
        return await fetchWineSocieties();
      })
  });

  const wineSocietyData = wineSociety?.data ? wineSociety?.data?.map((x) => new WineSociety(x)) : [];
  return { wineSocietyData };
}
