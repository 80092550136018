import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CartIcon from 'mdi-material-ui/Cart';
import EnvelopIcon from 'mdi-material-ui/Email';
import { IconButton } from '@mui/material';
import LoginIcon from 'mdi-material-ui/Login';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Badge from '@mui/material/Badge';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../../StoreContext';

const CompactCartInfoButton = observer(() => {
  const { cartStore, userStore } = useContext(StoreContext);
  const { t } = useTranslation();

  if (cartStore!.hasPendingIntents) {
    return (
      <IconButton disabled={true}>
        <CircularProgress size={20} />
      </IconButton>
    );
  } else if (!(userStore!.loginCustomer && userStore!.loginCustomer!.displayName)) {
    return (
      <Tooltip title={t('Login')}>
        <IconButton onClick={() => userStore.triggerLoginFlow()}>
          <LoginIcon />
        </IconButton>
      </Tooltip>
    );
  } else {
    const cartItemCount = cartStore!.cartOverview && cartStore!.cartOverview!.numberOfPositions ? cartStore!.cartOverview!.numberOfPositions : 0;
    const cartExternalItemCount = cartStore!.cartOverview && cartStore!.cartOverview!.numberOfExternalPositions ? cartStore!.cartOverview!.numberOfExternalPositions : 0;
    const cartIcon = cartStore!.cartOverview && cartStore!.cartOverview!.isOci ? <EnvelopIcon /> : <CartIcon />;

    return (
      <Link to="/cart" id="navigateToCartButton">
        <IconButton>
          <Badge badgeContent={cartItemCount + cartExternalItemCount} color="primary">
            {cartIcon}
          </Badge>
        </IconButton>
      </Link>
    );
  }
});

export default CompactCartInfoButton;
