import type { IDefaultSettingDTOData } from '../../../api';
import axios from '../../../util/axios';
import { ppConstants } from '../../../util/ppConstants';

const FeatureService = {
  async fetchDefaultSettings(): Promise<IDefaultSettingDTOData[]> {
    const response = await axios.get(ppConstants.API_HOST_PUB + 'webshop/defaultSettings');
    return response.data;
  }
};

export default FeatureService;
