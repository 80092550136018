import * as React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useTheme from '@mui/material/styles/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';

export interface ConfirmationOptions {
  catchOnCancel?: boolean;
  variant: 'warning' | 'info';
  title: string;
  description?: string;
  content?: any;
  submitLabel?: string;
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

export const ConfirmationDialog = ({ open, title, variant, description, onSubmit, onClose, content, submitLabel }: ConfirmationDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isFullscreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog open={open} fullScreen={isFullscreen}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {description && <DialogContentText>{description}</DialogContentText>}
        {content}
      </DialogContent>
      <DialogActions>
        {variant === 'warning' && (
          <>
            <Button variant={'contained'} onClick={onClose} autoFocus>
              {t('Abbrechen')}
            </Button>
            <Button variant={'contained'} color="primary" onClick={onSubmit}>
              {submitLabel ?? t('Ok')}
            </Button>
          </>
        )}

        {variant === 'info' && (
          <Button variant={'contained'} color="primary" onClick={onSubmit}>
            {t('Ok')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
