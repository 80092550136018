import React, { useContext } from 'react';
import IconButtonMenu from '../IconButtonMenu';
import { ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import FilterIcon from 'mdi-material-ui/FilterVariant';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { CatalogSearchType, CATALOGSEARCHTYPE_COREASSORTMENT, CATALOGSEARCHTYPE_SALESORDERSET } from '../../../../catalog/util/catalogConstants';
import Checkbox from '@mui/material/Checkbox';
import { StoreContext } from '../../../../../StoreContext';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

interface SearchFilterProps {
  handleToggleFilterSearchType: (search: CatalogSearchType) => void;
}

const SearchFilter = observer(({ handleToggleFilterSearchType }: SearchFilterProps) => {
  const { userStore, catalogStore } = useContext(StoreContext);
  const { t } = useTranslation();

  if (!userStore.isLoggedIn) {
    return null;
  }

  return (
    <IconButtonMenu
      icon={
        <Tooltip title={t('Filter')} placement="bottom">
          <FilterIcon />
        </Tooltip>
      }
      showBadge={catalogStore.isSearchTypeFiltered}
      menu={(close) => (
        <MenuList id={'search-filter-dropdown'}>
          <MenuItem
            sx={{ p: 0, pr: 1 }}
            onClick={(event) => {
              close(event);
              handleToggleFilterSearchType(CATALOGSEARCHTYPE_SALESORDERSET);
            }}
          >
            <ListItemIcon sx={{ mr: 0 }}>
              <Checkbox checked={catalogStore.isSearchTypeSalesOrderSet} color="secondary" id={'search-filter-1'} />
            </ListItemIcon>
            <ListItemText sx={{ p: 0, m: 0 }} primary={t('Gekaufte Artikel')} />
          </MenuItem>
          {userStore.isLoggedIn && userStore.coreAssortmentId !== null && (
            <MenuItem
              sx={{ p: 0, pr: 1 }}
              onClick={(event) => {
                close(event);
                handleToggleFilterSearchType(CATALOGSEARCHTYPE_COREASSORTMENT);
              }}
            >
              <ListItemIcon sx={{ mr: 0 }}>
                <Checkbox checked={catalogStore.isSearchTypeCoreAssortment} color="secondary" id={'search-filter-2'} />
              </ListItemIcon>

              <ListItemText sx={{ p: 0 }} primary={t('Nur Kernsortiment')} />
            </MenuItem>
          )}
        </MenuList>
      )}
    ></IconButtonMenu>
  );
});

export default SearchFilter;
