import type { IAvailabilityOptionData, IExpirationReasonData, IRuleMessageData } from '../../../api';
import CartStore from '../stores/cartStore';
import { IPromptInfo } from '../types/IPrompt';
import { ICartExpirationHandler } from '../types/ICartExpirationHandler';

export class UserPrompter {
  private cartStore: CartStore;

  constructor(cartStore: CartStore) {
    this.cartStore = cartStore;
  }

  public userTempCashRequest(): Promise<any> {
    return new Promise((resolve) => {
      const notify = (accepted: boolean) => {
        this.cartStore.tempCashAccepted(accepted);
        resolve(accepted);
      };

      this.cartStore.showTempCash({
        handler: notify
      });
    });
  }

  public userCartExpirationRequest(reason: IExpirationReasonData) {
    return new Promise<ICartExpirationHandler>((resolve, reject) => {
      const notify = (action: ICartExpirationHandler) => {
        if (action) {
          this.cartStore.expirationActionAnswered();
          resolve(action);
        } else {
          reject();
        }
      };

      this.cartStore.showExpirationAction({
        reason,
        handler: notify
      });
    });
  }

  public userDeliveryDateSelectionRequest() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;

    return new Promise<Date | void>((resolve) => {
      const notify = (date?: Date) => {
        this.cartStore.deliveryDateSelectionAnswered(date);
        if (date) {
          resolve(date);
        } else {
          resolve();
        }
      };

      that.cartStore.showDeliveryDateSelection({
        handler: notify
      });
    });
  }

  public userPromptRequest(promptInfos: IPromptInfo[]) {
    return new Promise<IAvailabilityOptionData[] | undefined>((resolve) => {
      const notify = (option?: IAvailabilityOptionData[]) => {
        this.cartStore.promptAnswered();
        resolve(option);
      };

      this.cartStore.showPrompt({
        handler: notify,
        info: promptInfos
      });
    });
  }

  public userAlertRequest(alertData: IRuleMessageData) {
    return new Promise<void>((resolve) => {
      const notify = () => {
        this.cartStore.alertAnswered();
        resolve();
      };

      this.cartStore.showAlert({
        handler: notify,
        alert: alertData
      });
    });
  }
}

let userPrompt: any;

export function configureMockPrompter(mock: any) {
  const logUserPrompt = (message: any) => {
    console.log('## -> User prompted:' + message); // tslint:disable-line
    return Promise.resolve();
  };

  userPrompt = Object.assign(
    {
      userTempCashRequest: () => logUserPrompt('userTempCashRequest'),
      userCartExpirationRequest: () => logUserPrompt('userCartExpirationRequest'),
      userDeliveryDateSelectionRequest: () => {
        console.log('## -> User prompted for delivery date! Returned: ' + mock.deliveryDate); // tslint:disable-line
        // CartActions.deliveryDateSelectionAnswered(mock.deliveryDate);
        // TODO: Check if required
        return Promise.resolve(mock.deliveryDate);
      },
      userPromptRequest: (promptInfo: any) => logUserPrompt('userPromptRequest: ' + JSON.stringify(promptInfo)),
      userAlertRequest: (message: any) => logUserPrompt('userAlertRequest: ' + JSON.stringify(message))
    },
    mock
  );
}

export const getUserPrompter = (cartStore: CartStore): UserPrompter => {
  if (!userPrompt) {
    userPrompt = new UserPrompter(cartStore);
  }

  return userPrompt;
};
