import SearchTextField from './SearchTextField';
import React, { useContext, useRef } from 'react';
import { Popper } from '@mui/material';
import Paper from '@mui/material/Paper';
import SearchResults from './SearchResults';
import { StoreContext } from '../../../../../StoreContext';
import { Article } from '../../../../shared/articles/models/article';
import { CatalogSearchType, CATALOGSEARCHTYPE_ALLARTICLE } from '../../../../catalog/util/catalogConstants';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/material/styles/useTheme';
import useSearch from './useSearch';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useModalArticleDetailDialogControl } from '../../../../shared/articles/util/useModalArticleDetailDialog';

export const Search = () => {
  const { userStore, catalogStore } = useContext(StoreContext);
  const { searchTerm, isActive, setIsActive, hasSearchResults, changeSearchTerm, performSearch, suggestions, articles } = useSearch(userStore.isLoggedIn, catalogStore.searchType, 3, 100);
  const searchBoxRef = useRef(undefined);
  const theme = useTheme();
  const showSmallSearch = useMediaQuery(theme.breakpoints.down('md'));
  const { showArticleDetailsDialog } = useModalArticleDetailDialogControl();

  const handleArticleClick = (article: Article) => {
    setIsActive(false);
    showArticleDetailsDialog(article.articleNumber);
  };

  const handleToggleFilterSearchType = (searchType: CatalogSearchType) => {
    if (catalogStore.searchType === searchType) {
      catalogStore.changeSearchType(CATALOGSEARCHTYPE_ALLARTICLE);
    } else {
      catalogStore.changeSearchType(searchType);
    }
  };

  return (
    <ClickAwayListener onClickAway={() => setIsActive(false)}>
      <div>
        <div style={{ width: showSmallSearch ? 400 : 650 }}>
          <SearchTextField ref={searchBoxRef} searchTerm={searchTerm} onSearchTermChanged={changeSearchTerm} performSearch={performSearch} handleToggleFilterSearchType={handleToggleFilterSearchType} onFocus={() => setIsActive(true)} />
        </div>
        <Popper
          open={isActive && hasSearchResults}
          anchorEl={searchBoxRef.current}
          placement={'bottom'}
          style={{ zIndex: 99999999999, width: showSmallSearch ? 400 : 650 }}
          modifiers={[
            {
              name: 'flip',
              enabled: false
            },
            {
              name: 'preventOverflow',
              enabled: true
            }
          ]}
        >
          <Paper square>
            <SearchResults searchTerm={searchTerm} suggestions={suggestions} articles={articles} onArticleClicked={handleArticleClick} onSearchSuggestionClicked={performSearch} />
          </Paper>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
