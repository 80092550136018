import React from 'react';
import { useTranslation } from 'react-i18next';
import ArticleDetailTextRow from '../../../../catalog/components/ArticleDetailTextRow';
import { Grid, Typography } from '@mui/material';
import ArticleDetailSpecialNextDeliveryDate from '../../../../catalog/components/article-detail/ArticleDetailSpecialNextDeliveryDate';
import { observer } from 'mobx-react-lite';
import { Article } from '../../models/article';
import ArticleDetailOrderEndTimes from '../../../../catalog/components/article-detail/ArticleDetailOrderEndTimes';
import ArticleDetailAmountPerPallet from '../../../../catalog/components/article-detail/ArticleDetailAmountPerPallet';

interface IProductInformationDescriptionProps {
  article: Article;
}

const ProductInformationDescription = observer(({ article }: IProductInformationDescriptionProps) => {
  const { t } = useTranslation();

  return (
    <div id={'article-detail-productinfo'}>
      <ArticleDetailTextRow translationText={'Art. Nr.'} value={article.articleNumber} id={'productinfo-articlenumber'} />
      <ArticleDetailTextRow translationText={'Sachbezeichnung'} id={'productinfo-foodfact'} value={article.foodFact} />
      <ArticleDetailTextRow translationText={'Lateinischer Name'} id={'productinfo-lateinname'} value={article.lateinName} />
      <ArticleDetailTextRow translationText={'Zutaten'} id={'productinfo-ingridients'} value={article.ingredients.replace(',', ', ')} />
      <ArticleDetailTextRow translationText={'Spezialernährung'} id={'productinfo-specialdiet'} value={article.specialDiet ? article.specialDiet.replace(',', ', ') : undefined} />
      <ArticleDetailTextRow
        translationText={'Allergene'}
        id={'productinfo-allergens'}
        control={
          article.allergensContain.length > 0 || article.allergensMayContain.length > 0 ? (
            <>
              {article.allergensContain.length > 0 ? (
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    {' '}
                    <Typography variant="body2">{t('Enthält Allergene')}:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">{article.allergensContainFlatString}</Typography>
                  </Grid>
                </Grid>
              ) : null}
              {article.allergensMayContain.length > 0 ? (
                <Grid container spacing={1} alignItems="center">
                  <Grid item>
                    {' '}
                    <Typography variant="body2">{t('Kann enthalten')}:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">{article.allergensMayContainFlatString}</Typography>
                  </Grid>
                </Grid>
              ) : null}
            </>
          ) : null
        }
      />
      <ArticleDetailOrderEndTimes article={article} />
      <ArticleDetailAmountPerPallet article={article} />
      <ArticleDetailSpecialNextDeliveryDate article={article} />
      <ArticleDetailTextRow translationText={'Region'} id={'productinfo-region'} value={article.factRegion} />
      <ArticleDetailTextRow translationText={'Rohstoffherkunft'} id={'productinfo-rohstoffherkunft'} value={article.rohstoffHerkunft} />
      <ArticleDetailTextRow translationText={'Produktionsland'} id={'productinfo-hergestellt'} value={article.herkunftHergestelltIn} />
      <ArticleDetailTextRow translationText={'Artikel abgefüllt in'} id={'productinfo-abgefüllt'} value={article.herkunftAbgefuellt} />
      <ArticleDetailTextRow translationText={'Artikel abgepackt in'} id={'productinfo-abgepackt'} value={article.herkunftAbgepacktIn} />

      <ArticleDetailTextRow translationText={'Fischfangzone'} id={'productinfo-fishingzone'} value={article.fishingZoneFlatString} />
      <ArticleDetailTextRow translationText={'Fischfangmethode'} id={'productinfo-fishingtechnique'} value={article.fishingTechniqueFlatString} />
      <ArticleDetailTextRow translationText={'Abtropfgewicht'} id={'productinfo-drainednetweight'} value={article.drainedNetWeight} />
      <ArticleDetailTextRow translationText={'Jahrgang'} id={'productinfo-winevintage'} value={article.wineVintage} />
      <ArticleDetailTextRow translationText={'Lagerfähigkeit'} id={'productinfo-storability'} value={article.storability} />
      <ArticleDetailTextRow translationText={'Serviertemperatur'} id={'productinfo-servingtemperature'} value={article.servingTemperature} />
      <ArticleDetailTextRow translationText={'Traubensorten'} id={'productinfo-grapes'} value={article.grapesFlatString} />
      <ArticleDetailTextRow translationText={'Passt zu'} id={'productinfo-fitswellwith'} value={article.fitsWellWithFlatString} />
      <ArticleDetailTextRow translationText={'Bestellhinweis'} id={'productinfo-ordernotes'} value={article.orderNote} />
      <ArticleDetailTextRow translationText={'Deklaration nach LDV'} id={'productinfo-fakturatext'} value={article.fakturaText} />
      <ArticleDetailTextRow translationText={'Auftauhinweis'} id={'productinfo-augetaut'} value={article.aufgetaut} />
      <ArticleDetailTextRow translationText={'Aufbewahrungshinweis'} id={'productinfo-durability'} value={article.durability} />
      <ArticleDetailTextRow translationText={'Zubereitung'} id={'productinfo-preparation'} value={article.preparation} />
      {article.showAlcoholPercentage && <ArticleDetailTextRow translationText={'Alkoholgehalt'} id={'productinfo-alcoholPercentage'} value={article.formattedAlcoholPercentage} />}
    </div>
  );
});

export default ProductInformationDescription;
