import type { IManagedAssortmentGroupDTOData } from '../../../../api';
import find from 'lodash/find';
import sortList from 'lodash/sortBy';
import { UNSORTED_CATEGORY, WHITESPACE_BRAND } from '../../../../util/filterConstants';
import { getMLSelector } from '../../../../util/i18n';
import { Article } from '../../articles/models/article';
import { Category } from '../models/category';

export function createCategories(articles: Article[], groupByObject: { name: string; value: string }, sortByObject: { name: string; value: string; blockGroupBy: boolean }) {
  let categories: Category[] = [];

  for (const article of articles) {
    let categoryKey = (article as any)[groupByObject.name];

    if (categoryKey === null || categoryKey === undefined) {
      categoryKey = WHITESPACE_BRAND;
    }

    const foundCategory = find(categories, { key: categoryKey }) as Category;

    if (!foundCategory) {
      const newCat = new Category(categoryKey, []);
      newCat.articles.push(article);

      categories.push(newCat);
    } else {
      foundCategory.articles.push(article);
    }
  }

  if (categories.length > 0) {
    categories = sortList<Category>(categories, (c) => {
      return c.key;
    });
  }
  for (const category of categories) {
    category.articles = sortList<Article>(category.articles, (article: Article) => {
      return (article as any)[sortByObject.name];
    });
  }

  return categories;
}

export function applyCustomSorting(articles: Article[], templateGroups: IManagedAssortmentGroupDTOData[]) {
  const mls = getMLSelector();
  const categories: Category[] = [];
  const unsortedCategory = new Category(mls(UNSORTED_CATEGORY), []);

  templateGroups.forEach((t) => {
    categories.push(new Category(t.id.toString(), [], t));
  });

  for (const article of articles) {
    if (!article.managedAssortmentGroupId) {
      unsortedCategory.articles.push(article);
    } else {
      const foundCategory = categories.find((c) => c.key === article.managedAssortmentGroupId!.toString()); // find(categories, {key: article.managedAssortmentGroupId});

      if (!foundCategory) {
        const assortmentGroup = templateGroups.find((g) => g.id === article.managedAssortmentGroupId);

        if (assortmentGroup) {
          const newCat = new Category(article.managedAssortmentGroupId!.toString(), [], assortmentGroup);
          newCat.articles.push(article);

          categories.push(newCat);
        }
      } else {
        foundCategory.articles.push(article);
      }
    }
  }

  for (const category of categories) {
    category.articles = category.articles.slice().sort((a1, a2) => a1.assortmentIndex - a2.assortmentIndex);
  }

  if (unsortedCategory.articles.length > 0) {
    unsortedCategory.articles = unsortedCategory.articles.slice().sort((c1, c2) => {
      if (c1.articleNumber < c2.articleNumber) {
        return -1;
      }
      if (c1.articleNumber > c2.articleNumber) {
        return 1;
      }

      return 0;
    });
    categories.push(unsortedCategory);
  }

  categories.sort((c1, c2) => {
    if (!c1.assortmentGroup) {
      return -1;
    }
    if (!c2.assortmentGroup) {
      return 1;
    }
    return c1.assortmentGroup.assortmentIndex - c2.assortmentGroup.assortmentIndex;
  });

  return categories;
}
