import { observer } from 'mobx-react-lite';
import React, { ChangeEvent } from 'react';
import { Button, ButtonProps, Stack } from '@mui/material';
import { ppConstants } from '../../../util/ppConstants';
import PlusIcon from 'mdi-material-ui/Plus';
import MinusIcon from 'mdi-material-ui/Minus';
import TextField from '@mui/material/TextField';

interface IPlusMinusAmountProps {
  amount: number;
  onAmountChanged: (amount: number) => void;
  onDecreaseIncreaseAmount: (amount: number) => void;
  onBlur?: () => void;
  disabled?: boolean;
  idx?: number;
}

const QuantityButton = (props: ButtonProps) => (
  <Button
    variant="contained"
    size="small"
    sx={{
      minWidth: 0,
      width: 35,
      height: 35,
      backgroundColor: (theme) => theme.palette.grey.A400
    }}
    {...props}
  />
);

const PlusMinusAmount = observer(({ amount, disabled, onDecreaseIncreaseAmount, onAmountChanged, onBlur, idx = 0 }: IPlusMinusAmountProps) => {
  const handleDecreaseQuantity = (event: any) => {
    event.stopPropagation();

    const value = amount - 1;
    if (value >= 0) {
      onDecreaseIncreaseAmount(value);
    }
  };

  const handleIncreaseQuantity = (event: any) => {
    event.stopPropagation();

    if (amount + 1 <= ppConstants.MAX_DESIRED_QUANTITY) {
      onDecreaseIncreaseAmount(amount + 1);
    }
  };

  const updateQuantity = (value: number) => {
    if (value <= ppConstants.MAX_DESIRED_QUANTITY) {
      onAmountChanged(value);
    }
  };

  const handleOnChange = (value: string) => {
    const intValue = Math.max(parseInt(value, 10) || 0, 0);
    updateQuantity(intValue);
  };

  const handleOnBlur = () => {
    if (onBlur) {
      onBlur();
    }
  };

  function preventPropagation(event: React.BaseSyntheticEvent) {
    event.stopPropagation(); // Das Event soll nicht an übergeordneten Komponenten weitergereicht werden: Spezifisches Szenario: Click auf eine Row im Warenkorb löst dort eine Navigation aus ...
  }

  return (
    <Stack direction={'row'} spacing={0.5}>
      <QuantityButton disabled={disabled} onClick={handleDecreaseQuantity} id={'article-amount-minus-button-' + idx}>
        <MinusIcon />
      </QuantityButton>

      <TextField
        variant="outlined"
        value={amount}
        onChange={(event: ChangeEvent<HTMLInputElement>) => handleOnChange(event.target.value)}
        disabled={disabled}
        onBlur={handleOnBlur}
        onClick={preventPropagation}
        sx={{ width: (theme) => theme.spacing(6), height: 35 }}
        inputProps={{
          style: { textAlign: 'center', padding: 0, height: 35 }
        }}
        id={'article-amount-textfield-' + idx}
      />

      <QuantityButton onClick={handleIncreaseQuantity} disabled={disabled} id={'article-amount-plus-button-' + idx}>
        <PlusIcon />
      </QuantityButton>
    </Stack>
  );
});

export default PlusMinusAmount;
