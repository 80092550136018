import type { IManagedAssortmentGroupDTOData } from '../../../../api';
import { makeAutoObservable } from 'mobx';
import { Article } from '../../articles/models/article';

export class Category {
  key: string;
  articles: Article[];
  assortmentGroup?: IManagedAssortmentGroupDTOData;
  showAll?: boolean;
  isExpanded: boolean = true;

  constructor(key: string, articles: Article[], assortmentGroup?: IManagedAssortmentGroupDTOData, showAll?: boolean) {
    makeAutoObservable(this);

    this.key = key;

    this.articles = articles;
    if (assortmentGroup) {
      this.assortmentGroup = assortmentGroup;
    }

    if (showAll) {
      this.showAll = showAll;
    }
  }

  selectAll = () => {
    this.articles.forEach((a) => {
      a.isSelected = true;
    });
  };

  unSelectAll = () => {
    this.articles.forEach((a) => {
      a.isSelected = false;
    });
  };

  toogleExpanded = () => {
    this.isExpanded = !this.isExpanded;
  };

  expand = () => {
    this.isExpanded = true;
  };

  collapsed = () => {
    this.isExpanded = false;
  };

  get title() {
    return this.assortmentGroup ? this.assortmentGroup.title : this.key;
  }

  get hasAssortmentGroup(): boolean {
    return !!this.assortmentGroup;
  }

  get isAllSelected() {
    return this.articles.length > 0 && this.articles.every((a: Article) => a.isSelected);
  }
}
