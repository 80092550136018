import React from 'react';
import { UserStore } from '../../../user/stores/userStore';
import { Article } from '../models/article';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Theme, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import { Variant } from '@mui/material/styles/createTypography';
import { makeStyles } from 'tss-react/mui';

export interface IPriceTagProps {
  userStore?: UserStore;
  article: Article;
  ignoreGraduatedPriceSteps?: boolean;
  variant?: Variant | 'inherit';
  alignRight?: boolean;
}

export const useStyles = makeStyles()((theme: Theme) => {
  return {
    root: {
      textAlign: 'left'
    },
    right: {
      textAlign: 'right'
    },
    priceTag: {
      lineHeight: 1
    },
    action: {
      color: theme.palette.primary.main
    },
    notIndividual: {
      fontStyle: 'italic'
    }
  };
});

const PriceTag = observer(({ variant, alignRight, article }: IPriceTagProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  if (!article) {
    return <CircularProgress size={20} variant={'indeterminate'} />;
  }

  return (
    <div className={alignRight ? classes.right : classes.root}>
      {article.priceSteps &&
        article.priceSteps.map((step: any, i: number) => {
          const amount = step.quantity.toFixed(0);
          const unit = article.articleUnit;
          let sellUnitInfo;

          if (article.sellAmount > 1 && amount % article.sellAmount === 0) {
            const sellAmount = amount / article.sellAmount;
            const sellUnit = article.sellUnit;
            sellUnitInfo = '(' + sellAmount + ' ' + sellUnit + ') ';
          }

          return (
            <Grid key={i} container spacing={2} justifyContent={'space-between'}>
              <Grid item>
                <Typography variant={variant ? variant : 'body2'}>{`${t('ab')} ${amount} ${unit} ${sellUnitInfo ? sellUnitInfo : ''}`}</Typography>
              </Grid>
              <Grid item>
                <Typography variant={variant ? variant : 'body2'}>{`${t('CHF')} ${step.price.toFixed(2)} / ${article.articleUnit}`}</Typography>
              </Grid>
            </Grid>
          );
        })}
    </div>
  );
});

export default PriceTag;
