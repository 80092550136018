import type { IExternalSupplierData } from '../../../../api';
import { makeAutoObservable, toJS } from 'mobx';
import { SupplierStore } from '../supplierStore';
import Validator from '../../../../util/validatorjs/validator';
import i18n from 'i18next';
import { validate, validateField } from '../../../../util/validationHelper';

export class Supplier {
  private rules = {
    name: 'required|max:50',
    customerNumber: 'max:50',
    contact: 'max:50',
    phone: 'max:30',
    fax: 'max:30',
    email: 'max:50',
    street: 'max:100',
    street2: 'max:100',
    zip: 'max:10',
    city: 'max:50',
    languageId: 'required',
    orderByEmail: 'required',
    orderEmail: [{ required_if: ['orderByEmail', true] }, 'max:50', 'email'],
    info: 'max:200'
  };

  private getValidator(data: any) {
    const validator = new Validator(data, this.rules, {
      required_if: i18n.t('Wenn Sie die Artikel bei diesem Lieferanten per Email bestellen möchten, müssen Sie eine Email-Adresse eintragen!')
    });
    validator.setAttributeNames({
      name: i18n.t('Name'),
      customerNumber: i18n.t('Ihre Kundennummer'),
      contact: i18n.t('Kontaktperson'),
      phone: i18n.t('Telefon'),
      fax: i18n.t('Fax'),
      email: i18n.t('Email Kontakt'),
      street: i18n.t('Strasse'),
      street2: i18n.t('Strasse 2'),
      zip: i18n.t('PLZ'),
      city: i18n.t('Ort'),
      languageId: i18n.t('Korrespondenzsprache'),
      orderEmail: i18n.t('Email Bestellung'),
      info: i18n.t('Infotext')
    });

    return validator;
  }

  static createFromDto(store: SupplierStore, dto: IExternalSupplierData): Supplier {
    const supplier = new Supplier(store, dto.id);
    supplier.name = dto.name ? dto.name : '';
    supplier.firmId = dto.firmId ? dto.firmId : 0;
    supplier.customerNumber = dto.customerNumber ? dto.customerNumber : '';
    supplier.contact = dto.contact ? dto.contact : '';
    supplier.phone = dto.phone ? dto.phone : '';
    supplier.email = dto.email ? dto.email : '';
    supplier.fax = dto.fax ? dto.fax : '';
    supplier.street = dto.street ? dto.street : '';
    supplier.street2 = dto.street2 ? dto.street2 : '';
    supplier.zip = dto.zip ? dto.zip : '';
    supplier.city = dto.city ? dto.city : '';
    supplier.languageId = dto.languageId ? dto.languageId : 0;
    supplier.orderEmail = dto.orderEmail ? dto.orderEmail : '';
    supplier.orderByEmail = dto.orderByEmail;
    supplier.info = dto.info ? dto.info : '';
    supplier.version = dto.version;

    return supplier;
  }

  store: SupplierStore;
  id?: number;
  firmId: number = 0;
  name: string = '';
  customerNumber = '';
  contact: string = '';
  phone: string = '';
  email: string = '';
  fax: string = '';
  street: string = '';
  street2: string = '';
  zip: string = '';
  city: string = '';
  languageId = 0;
  orderEmail: string = '';
  orderByEmail = false;
  info: string = '';
  version: number | undefined;
  validationErrors: { [index: string]: any } = {};

  constructor(store: SupplierStore, id?: number) {
    makeAutoObservable(this);
    this.id = id;
    this.store = store;
  }

  toDto(): IExternalSupplierData {
    return {
      id: this.id!,
      firmId: this.firmId,
      name: this.name,
      customerNumber: this.customerNumber,
      contact: this.contact,
      phone: this.phone,
      fax: this.fax,
      email: this.email,
      street: this.street,
      street2: this.street2,
      zip: this.zip,
      city: this.city,
      languageId: this.languageId,
      orderEmail: this.orderEmail,
      orderByEmail: this.orderByEmail,
      info: this.info,
      version: this.version! // if the version is undefined the backend will create a new entity else it will update the entity
    };
  }

  updateProperty(this: any, field: string, value: any) {
    this[field] = value;

    if (field === 'orderEmail' || field === 'orderByEmail') {
      const data = {
        orderEmail: this.orderEmail,
        orderByEmail: this.orderByEmail
      };

      const validator = this.getValidator(data);
      this.validationErrors = validateField(validator, 'orderEmail', this.validationErrors);
      this.validationErrors = validateField(validator, 'orderByEmail', this.validationErrors);
      return;
    }

    const data = { [field]: value };
    const validator = this.getValidator(data);
    this.validationErrors = validateField(validator, field, this.validationErrors);
  }

  async save() {
    this.validateAll(toJS(this));
    if (this.isValid) {
      await this.store.save(this);
      return true;
    }

    return false;
  }

  validateAll(data: any) {
    const validator = this.getValidator(data);
    this.validationErrors = validate(validator);
  }

  get isValid() {
    return Object.keys(this.validationErrors).length === 0;
  }

  get zipCity() {
    return (this.zip ? this.zip : '') + ' ' + (this.city ? this.city : '');
  }
}
