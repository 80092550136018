import remove from 'lodash/remove';
import sortList from 'lodash/sortBy';
import { makeAutoObservable, runInAction } from 'mobx';
import i18n from 'i18next';
import RootStore from '../../../rootStore';
import { groupBy, sortBy, WHITESPACE_BRAND } from '../../../util/filterConstants';
import { getMLSelector } from '../../../util/i18n';
import { Article } from '../../shared/articles/models/article';
import { Category } from '../../shared/category/models/category';
import { applyCustomSorting, createCategories } from '../../shared/category/util/CategoryBuilder';
import TemplateService from '../services/templateService';
import { TemplateDetail } from './models/templateDetail';
import { TemplateFilter } from './models/templateFilter';
import { formatServerMessage } from '../../../util/stringUtil';
import { executeAsyncAction } from '../../../util/exceptionHandler';

type TemplateItem = Category | Article;

export class TemplateDetailStore {
  rootStore: RootStore;

  filter: TemplateFilter;
  template!: TemplateDetail;
  pendingRequestsCount: number = 0;
  articleToRemoveId?: number;
  categoryToRename?: Category;
  isCategoryNameDialogShown: boolean = false;
  articleNumberToMove?: string = '';
  isArticleMoveCategoryDialogShown: boolean = false;
  isTemplateExpanded: boolean = true;
  isTemplateCopyDialogShown = false;
  isTemplateRenameDialogShown = false;
  showImportFileDialogOpen: boolean = false;
  showImportFileFeedbackDialog: boolean = false;
  importFileFeedbackTitle: string = '';
  importFileFeedbackMessage: string[] = [];

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.filter = new TemplateFilter();
  }

  get isLoading() {
    return this.pendingRequestsCount > 0;
  }

  get categoriesForFilter(): Category[] {
    const mls = getMLSelector();
    let categories = [new Category(mls(['Alle anzeigen', 'Afficher tous', 'Mostra tutti']), [], undefined, true)];

    if (this.template) {
      categories = categories.concat(this.categoriesGroupedBy);
    }

    return categories;
  }

  get categoriesGroupedBy(): Category[] {
    const categories: Category[] = [];
    if (this.template.articles.length < 1) {
      return [];
    }

    for (const article of this.template.articles) {
      let categoryKey = (article as any)[this.filter.groupBy.name];

      if (categoryKey === null) {
        categoryKey = WHITESPACE_BRAND;
      }

      const foundCategory = categories.find((c) => c.key === categoryKey);

      if (!foundCategory) {
        categories.push(new Category(categoryKey, [], undefined, false));
      }
    }
    categories.sort((c1: Category, c2: Category) => {
      if (c1.key && c2.key) {
        return c1.key.localeCompare(c2.key);
      } else {
        return 0;
      }
    });
    return categories;
  }

  get filteredCategories(): Category[] {
    let categories: Category[] = [];

    if (this.template) {
      if (this.filter.isSortByCustomOrder) {
        categories = applyCustomSorting(this.template.articles, this.template.templateGroups);
      } else {
        categories = createCategories(this.template.articles, this.filter.groupBy, this.filter.sortBy);
      }
    }

    return categories;
  }

  /**
   * Flat list for rendering in react virtualized
   */
  get items(): TemplateItem[] {
    const items: TemplateItem[] = [];

    const hasFilterTerm = !!this.filter.term && this.filter.term.length > 0;
    const hasGroupKeyFilter = this.filter.groupKey ? this.filteredCategories.find((c) => c.key === this.filter.groupKey) : undefined;

    let categories = hasGroupKeyFilter ? this.filteredCategories.filter((c) => c.key === hasGroupKeyFilter.key) : this.filteredCategories;

    if (this.filter.isSortByCustomOrder) {
      categories = sortList<Category>(categories, (c: Category) => {
        return c.assortmentGroup && c.assortmentGroup.assortmentIndex;
      });
    }
    for (const category of categories) {
      let articles = this.filter.isSelected ? category.articles.filter((a: Article) => a.isSelected === this.filter.isSelected) : category.articles;

      articles = articles.slice().sort((a1: any, a2: any) => a1.assortmentIndex - a2.assortmentIndex);

      if (articles.length > 0 || this.filter.isSortByCustomOrder) {
        items.push(category);
      }

      if (category.isExpanded) {
        for (const article of articles) {
          if (hasFilterTerm) {
            if (article.articleText.toLowerCase().indexOf(this.filter.term.toLowerCase()) !== -1 || (article.articleNumber && article.articleNumber.toLowerCase().indexOf(this.filter.term.toLowerCase()) !== -1)) {
              items.push(article);
            }
          } else {
            items.push(article);
          }
        }
      }
    }

    return items;
  }

  get selectedArticles(): Article[] {
    const items: Article[] = [];

    this.filteredCategories.forEach((category: Category) => {
      const articles = category.articles.filter((a) => a.isSelected);
      articles.forEach((a) => {
        items.push(a);
      });
    });

    return items;
  }

  get selectedMainArticleIds() {
    return this.selectedArticles.map((x) => x.mainArticleId);
  }

  get allArticleIds() {
    return this.template.articles.map((x) => x.mainArticleId);
  }

  get removeArticleMessage(): string {
    if (!this.template || !this.template.articles) {
      return '';
    }

    const position = this.template.articles.find((x) => x.articleId === this.articleToRemoveId);

    if (!position) {
      return '';
    } else {
      return i18n.t("Sind Sie sicher, dass Sie die Vorlagenposition '{{0}}' wirklich löschen möchten?").replace('{{0}}', position!.articleText);
    }
  }

  get isAllSelected() {
    return (
      this.items.length > 0 &&
      this.items.every((x: TemplateItem) => {
        if (x instanceof Category) {
          return x.isAllSelected;
        }
        // ignore articles
        return true;
      })
    );
  }

  get isDragDropEnabled(): boolean {
    return this.filter.sortBy.name === sortBy.CUSTOM.name;
  }

  get articleToMoveCategoryId() {
    const findTemplateGroup = (id: any) => {
      return this.template.templateGroups.find((g) => g.id === id);
    };

    const targetPosition = this.template.articles.find((a: Article) => a.articleNumber === this.articleNumberToMove);
    if (targetPosition === undefined || targetPosition!.managedAssortmentGroupId === undefined) {
      return undefined;
    }
    const newAssortmentGroup = findTemplateGroup(targetPosition!.managedAssortmentGroupId);
    return newAssortmentGroup?.id;
  }

  updateSortByCriteria(sort: string) {
    switch (sort) {
      case sortBy.ARTNR.name:
        this.filter.sortBy = sortBy.ARTNR;
        break;
      case sortBy.ARTTXT.name:
        this.filter.sortBy = sortBy.ARTTXT;
        break;
      case sortBy.CUSTOM.name:
        this.filter.sortBy = sortBy.CUSTOM;
        break;
    }

    this.saveFilter();
  }

  updateGroupByCriteria(group: string) {
    switch (group) {
      case groupBy.HWG.name:
        this.filter.groupBy = groupBy.HWG;
        break;
      case groupBy.HAGR.name:
        this.filter.groupBy = groupBy.HAGR;
        break;
      case groupBy.BRAND.name:
        this.filter.groupBy = groupBy.BRAND;
        break;
      case groupBy.LEDGERACCOUNT.name:
        this.filter.groupBy = groupBy.LEDGERACCOUNT;
        break;
    }

    this.saveFilter();
  }

  updateTerm(term: string) {
    this.filter.term = term;
  }

  updateGroupKeyCriteria(groupKey: string) {
    this.filter.groupKey = groupKey;
  }

  updateIsSelectedCriteria(isSelected: boolean) {
    this.filter.isSelected = isSelected;
  }

  fetchArticles(templateId: number) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;

        const template = await TemplateService.fetchArticlesByTemplate(templateId);

        runInAction(() => {
          if (template !== null) {
            this.template = new TemplateDetail(this, template);

            if (template.sortCriteria) {
              this.filter.sortBy = (sortBy as any)[template.sortCriteria];

              if (this.filter.isSortByCustomOrder) {
                this.template.sortTemplateGroups();
              }
            }
            if (template.groupCriteria) {
              this.filter.groupBy = (groupBy as any)[template.groupCriteria];
            }
          }
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => this.pendingRequestsCount--);
        }
      }
    );
  }

  toogleExpanded() {
    this.isTemplateExpanded = !this.isTemplateExpanded;
    this.items.forEach((item: TemplateItem) => {
      if (item instanceof Category) {
        if (this.isTemplateExpanded) {
          item.expand();
        } else {
          item.collapsed();
        }
      }
    });
  }

  selectAllArticles() {
    this.items.forEach((item: TemplateItem) => {
      if (item instanceof Category) {
        item.selectAll();
      }
    });
  }

  unSelectAllArticles() {
    this.items.forEach((item: TemplateItem) => {
      if (item instanceof Category) {
        item.unSelectAll();
      }
    });
  }

  setArticleToRemoveId(id: number) {
    this.articleToRemoveId = id;
  }

  resetArticleToRemove() {
    this.articleToRemoveId = undefined;
  }

  removeArticle() {
    executeAsyncAction(async () => {
      const article = this.template.articles.find((x) => x.articleId === this.articleToRemoveId);
      if (article) {
        if (article.externalArticle) {
          await TemplateService.removeArticleFromTemplate(this.template.id, article!.articleId, article!.externalArticle);
        } else {
          await TemplateService.removeArticleFromTemplate(this.template.id, article!.mainArticleId, article!.externalArticle);
        }

        runInAction(() => {
          remove(this.template.articles, article);
        });
      }
    });
  }

  saveArticleAmount(article: Article) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        await TemplateService.changeTemplateItem(this.template.id, article.mainArticleId, article.externalArticle, { quantity: article.amount });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  resetAmount() {
    this.template.articles.forEach((x: any) => {
      x.amount = 0;

      if (x.zzArticles && x.zzArticles.length > 0) {
        x.zzArticles.forEach((zz: any) => {
          zz.amount = 0;
        });
      }
    });

    TemplateService.updateQuantityForAllArticlesInTemplate(this.template.id, 0);
  }

  createNewAssortmentGroup(title: string) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;

        const loadedTemplate = await TemplateService.createNewAssortmentGroup(this.template.id, title);

        runInAction(() => {
          this.template = new TemplateDetail(this, loadedTemplate);
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  removeCategory = (category: Category) => {
    executeAsyncAction(async () => {
      await TemplateService.deleteAssortmentGroup(this.template.id, category.key);
      runInAction(() => {
        this.template.templateGroups = this.template.templateGroups.filter((t) => t.id.toString() !== category.key);
      });
    });
  };

  updateAssortmentGroupTitle(id: number, title: string) {
    executeAsyncAction(async () => {
      const changeSpec = {
        assortmentGroupId: id,
        title
      };

      await TemplateService.changeAssortmentGroup(this.template.id, changeSpec.assortmentGroupId, changeSpec);

      const assortmentGroup = this.template.templateGroups.find((x) => x.id === id);
      if (assortmentGroup) {
        runInAction(() => {
          assortmentGroup.title = title;
        });
      }
    });
  }

  closeCategoryNameDialog = () => {
    this.categoryToRename = undefined;
    this.isCategoryNameDialogShown = false;
  };

  showCategoryNameDialog = (category?: Category) => {
    this.categoryToRename = category;
    this.isCategoryNameDialogShown = true;
  };

  closeArticleMoveCategoryDialog = () => {
    this.articleNumberToMove = undefined;
    this.isArticleMoveCategoryDialogShown = false;
  };

  showArticleMoveCategoryDialog = (articleNumber: string) => {
    this.articleNumberToMove = articleNumber;
    this.isArticleMoveCategoryDialogShown = true;
  };

  changeTemplateItemOrderDown(articleNumber: string) {
    const sourcePositionIndex = this.items.findIndex((a) => (a as Article).articleNumber === articleNumber);
    const targetPosition = this.items[sourcePositionIndex + 1];
    if (targetPosition && targetPosition instanceof Article) {
      this.changeTemplateItemOrder({
        sourceArticleNumber: articleNumber,
        targetArticleNumber: targetPosition.articleNumber,
        dropPlacement: 'after'
      });
    }
  }

  changeTemplateItemOrderUp(articleNumber: string) {
    const sourcePositionIndex = this.items.findIndex((a) => (a as Article).articleNumber === articleNumber);
    const targetPosition = this.items[sourcePositionIndex - 1];
    if (targetPosition && targetPosition instanceof Article) {
      this.changeTemplateItemOrder({
        sourceArticleNumber: articleNumber,
        targetArticleNumber: targetPosition.articleNumber,
        dropPlacement: 'before'
      });
    }
  }

  changeTemplateItemCategory(articleNumber: string, targetCategoryId: number) {
    this.changeTemplateItemOrder({
      sourceArticleNumber: articleNumber,
      targetCategoryId: targetCategoryId
    });
  }

  changeTemplateItemOrder(changeSpec: { sourceArticleNumber: string; targetArticleNumber?: string; dropPlacement?: string; targetCategoryId?: number }) {
    const findTemplateGroup = (id: any) => {
      return this.template.templateGroups.find((g) => g.id === id);
    };

    const sourcePosition = this.template.articles.find((a: Article) => a.articleNumber === changeSpec.sourceArticleNumber);
    if (!sourcePosition) {
      return;
    }

    let targetPosition;

    let newAssortmentGroup: any;
    if (changeSpec.targetArticleNumber) {
      targetPosition = this.template.articles.find((a: Article) => a.articleNumber === changeSpec.targetArticleNumber);
      newAssortmentGroup = findTemplateGroup(targetPosition!.managedAssortmentGroupId);
    } else if (changeSpec.targetCategoryId) {
      newAssortmentGroup = findTemplateGroup(changeSpec.targetCategoryId);
    }

    if (!newAssortmentGroup) {
      // drop on the 'unsorted' group -> nothing should happen
      // drop should be prevented in UI, so this should never happen
      return;
    }

    let newAssortmentIndex;
    const positionsOfGroup = this.template.articles.filter((a: any) => a.managedAssortmentGroupId && a.managedAssortmentGroupId === newAssortmentGroup.id);
    positionsOfGroup.sort((a1: any, a2: any) => a1.assortmentIndex - a2.assortmentIndex);
    if (!targetPosition) {
      if (positionsOfGroup[0]) {
        newAssortmentIndex = positionsOfGroup[0].assortmentIndex / 2;
      } else {
        newAssortmentIndex = 1;
      }
    } else {
      const targetIndex = positionsOfGroup.indexOf(targetPosition);
      let assortmentIndexAbove = 0;
      let assortmentIndexBelow = positionsOfGroup.length;
      if (changeSpec.dropPlacement === 'before') {
        if (targetIndex > 0) {
          assortmentIndexAbove = positionsOfGroup[targetIndex - 1].assortmentIndex;
        }
        assortmentIndexBelow = targetPosition.assortmentIndex;
      } else {
        assortmentIndexAbove = targetPosition.assortmentIndex;

        if (positionsOfGroup[targetIndex + 1]) {
          assortmentIndexBelow = positionsOfGroup[targetIndex + 1].assortmentIndex;
        }
      }

      newAssortmentIndex = assortmentIndexAbove + (assortmentIndexBelow - assortmentIndexAbove) / 2;
    }

    if (sourcePosition.managedAssortmentGroupId === newAssortmentGroup.id && sourcePosition.assortmentIndex === newAssortmentIndex) {
      // drop on the same item -> nothing should happen
      // drop should be prevented in UI, so this should never happen
      return;
    }

    sourcePosition.managedAssortmentGroupId = newAssortmentGroup.id;
    sourcePosition.assortmentIndex = newAssortmentIndex;

    const positionId = sourcePosition.externalArticle ? sourcePosition.articleId : sourcePosition.mainArticleId;
    const assortmentGroupId = sourcePosition.managedAssortmentGroupId ? sourcePosition.managedAssortmentGroupId : -1;

    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;

        await TemplateService.changeTemplateItem(this.template.id, positionId, sourcePosition.externalArticle, {
          assortmentGroupId,
          assortmentIndex: sourcePosition.assortmentIndex
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  changeTemplateGroupOrder(changeSpec: any) {
    const findTemplateGroup = (id: any) => {
      return this.template.templateGroups.find((g: any) => g.id === id);
    };
    const sourceTemplateGroup = findTemplateGroup(changeSpec.sourceGroupId);
    const targetTemplateGroup = findTemplateGroup(changeSpec.targetGroupId);

    if (!sourceTemplateGroup || !targetTemplateGroup) {
      throw new Error('source or target template group is undefined');
    }

    let assortmentIndexAbove = 0;
    const targetArrayPos = this.filteredCategories.findIndex((c) => c.assortmentGroup === targetTemplateGroup);
    if (targetArrayPos > 0) {
      const templateDetailCategoryAbove = this.filteredCategories[targetArrayPos - 1];
      if (templateDetailCategoryAbove.assortmentGroup) {
        assortmentIndexAbove = templateDetailCategoryAbove.assortmentGroup.assortmentIndex;
      }
    }
    const newAssortmentIndex = assortmentIndexAbove + (targetTemplateGroup.assortmentIndex - assortmentIndexAbove) / 2;

    sourceTemplateGroup.assortmentIndex = newAssortmentIndex;

    executeAsyncAction(async () => {
      await TemplateService.changeAssortmentGroup(this.template.id, changeSpec.sourceGroupId, { assortmentIndex: newAssortmentIndex });

      const sortedGroups = this.template.templateGroups.slice().sort((a1: any, a2: any) => a1.assortmentIndex - a2.assortmentIndex);

      runInAction(() => {
        this.template.templateGroups = sortedGroups;
      });
    });
  }

  showTemplateCopyDialog() {
    this.isTemplateCopyDialogShown = true;
  }

  closeTemplateCopyDialog() {
    this.isTemplateCopyDialogShown = false;
  }

  copyTemplate(name: string): Promise<number | undefined> {
    return executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const template = await TemplateService.copyTemplate(this.template.id, name);
        return template.id;
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  showTemplateRenameDialog() {
    this.isTemplateRenameDialogShown = true;
  }

  closeTemplateRenameDialog() {
    this.isTemplateRenameDialogShown = false;
  }

  renameTemplate(name: string) {
    executeAsyncAction(
      async () => {
        const data = { name };
        this.pendingRequestsCount++;

        const template = await TemplateService.changeTemplate(this.template.id, data);
        runInAction(() => {
          this.template.name = template.name;
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  showImportFileDialog(show: boolean) {
    this.showImportFileDialogOpen = show;
  }

  async importArticlesFromCSVFile(data: any) {
    await executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;

        const result = await TemplateService.importArticlesFromCSVFile(this.template.id, data);
        runInAction(() => {
          this.importFileFeedbackTitle = result.message;
          this.importFileFeedbackMessage = formatServerMessage(result.additionalInfo);
          this.showImportFileFeedbackDialog = true;
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => this.pendingRequestsCount--);
        }
      }
    );

    this.fetchArticles(this.template.id);
  }

  closeImportFileFeedbackDialog() {
    this.showImportFileFeedbackDialog = false;
    this.importFileFeedbackTitle = '';
    this.importFileFeedbackMessage = [];
  }

  replaceArticleInTemplate(article: Article) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;

        const substituteIndex = this.template.articles.findIndex((a: Article) => a.articleId === article.substituteArticle!.articleId);

        if (substituteIndex > -1) {
          runInAction(() => {
            remove(this.template.articles, article);
          });
        } else {
          const replaceArticle = await TemplateService.replaceArticleInTemplate(this.template.id, article.mainArticleId);
          const index = this.template.articles.findIndex((a: Article) => a.articleId === article.articleId);

          runInAction(() => {
            this.template.articles[index] = Article.createFromDto(replaceArticle, article.amount);
          });
        }
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  private saveFilter() {
    if (this.template) {
      const data = {
        sortCriteria: this.filter.sortBy.value,
        groupCriteria: this.filter.groupBy.value
      };

      executeAsyncAction(
        async () => {
          this.pendingRequestsCount++;
          TemplateService.changeTemplate(this.template.id, data);

          runInAction(() => {
            this.template.sortTemplateGroups();
          });
        },
        true,
        {
          finallyAction: () => {
            runInAction(() => {
              this.pendingRequestsCount--;
            });
          }
        }
      );
    }
  }
}
