import i18nConfig from '../i18nConfig';
import i18n from 'i18next';
import Validator from './validatorjs/validator';

i18nConfig.then(() => {
  const language = i18n.language ?? 'de';
  Validator.useLang(language.substr(0, 2));
});

export const validateField = (validator: any, field: string, validationErrors: { [index: string]: any }): { [index: string]: any } => {
  if (validator.fails()) {
    const error = validator.errors.first(field);
    return { ...validationErrors, [field]: error };
  } else {
    const errors = { ...validationErrors };
    delete errors[field];
    return errors;
  }
};

export const validate = (validator: any): { [index: string]: any } => {
  if (validator.fails()) {
    return validator.errors.all();
  } else {
    return {};
  }
};
