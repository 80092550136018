import { IObservableArray, makeAutoObservable, runInAction } from 'mobx';
import RootStore from '../../../rootStore';
import { loadScanId } from '../../../util/webStorage';
import AddPositionIntent from '../../cart/models/intent/AddPositionIntent';
import AddPositionsIntent, { AddPositionParameterType } from '../../cart/models/intent/AddPositionsIntent';
import { Article } from '../../shared/articles/models/article';
import SubmissionService from '../../submission/services/submissionService';
import { SubmitPosition } from './models/submitPosition';
import { formatServerMessage } from '../../../util/stringUtil';
import { executeAsyncAction } from '../../../util/exceptionHandler';
import { rootStore } from '../../../StoreContext';
import { FEATURES } from '../../common/stores/featureStore';

export class SubmitStore {
  rootStore: RootStore;

  submitPositions: SubmitPosition[] = [];
  showImportFileFeedbackDialog = false;
  importFileFeedbackTitle: string = '';
  importFileFeedbackMessage: string[] = [];
  isLoading = false;
  isCsvLoading = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  removePosition(position: SubmitPosition) {
    if (position.scanId) {
      executeAsyncAction(async () => {
        await SubmissionService.removeSubmissionPosition(position.scanId, position.posNr);
        runInAction(() => (this.submitPositions as IObservableArray<SubmitPosition>).remove(position));
      });
    } else {
      (this.submitPositions as IObservableArray<SubmitPosition>).remove(position);
    }
  }

  removeScanPosition(position: SubmitPosition) {
    executeAsyncAction(async () => {
      await SubmissionService.removeSubmissionPosition(position.scanId, position.posNr);
      (this.submitPositions as IObservableArray<SubmitPosition>).remove(position);
    });
  }

  removeScanPositions(scanId: string) {
    executeAsyncAction(async () => {
      await SubmissionService.removeAllSubmissionPositions(scanId);
      runInAction(() => {
        this.submitPositions = [];
      });
    });
  }

  getPositionsFromScanner(scanId: any) {
    return executeAsyncAction(
      async () => {
        this.isLoading = true;
        this.submitPositions = [];
        const positions = await SubmissionService.getPositionSubmission(scanId);

        runInAction(() => {
          this.submitPositions = positions.positionSubmissionArticleSummaries.map((p) => new SubmitPosition(p, positions.submissionId));
        });
        this.submitPositions.filter((s) => !s.article.externalArticle).forEach((s) => s.article.fetchArticleDeepLoaded(this.rootStore.userStore.isLoggedIn, false));
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.isLoading = false;
          });
        }
      }
    );
  }

  submitAllScanPoolPositions() {
    const validScanPositions = this.submitPositions.filter((x) => !x.articleNotFound && !x.article.notInWebShop);

    if (this.rootStore.featureStore.isFeatureEnabled(FEATURES.batchPositionAdd)) {
      const addPositionParameters: AddPositionParameterType[] = [];

      for (const p of validScanPositions) {
        let articleNumber = p.article.externalArticle ? p.article.articleId.toString() : p.article.articleNumber;
        let amount = p.article.amount;
        if (p.article.isZzArticle) {
          articleNumber = p.article.zzArticles[p.article.zzIndex].articleNumber;
          amount = p.article.zzArticles[p.article.zzIndex].amount;
        }

        addPositionParameters.push({
          articleNumber,
          quantity: amount,
          externalArticle: p.article.externalArticle,
          articleIsEan: false
        });
      }

      if (addPositionParameters.length > 0) {
        const addPositionsIntent = new AddPositionsIntent(this.rootStore.cartStore, addPositionParameters);
        this.rootStore.cartStore.addIntent(addPositionsIntent);
      }
    } else {
      validScanPositions.forEach((p: SubmitPosition) => {
        if (p.article) {
          const addIntent = new AddPositionIntent(this.rootStore.cartStore, p.article.externalArticle ? p.article.articleId.toString() : p.article.articleNumber, p.article.amount, p.article.externalArticle);

          addIntent.intentPromise.then(() => {
            this.removePosition(p);
          });

          this.rootStore.cartStore.addIntent(addIntent);
        }
      });
    }
  }

  importFromCSV(data: any) {
    executeAsyncAction(
      async () => {
        this.isCsvLoading = true;
        const dto = await SubmissionService.importFromCSV(data);
        runInAction(() => {
          this.importFileFeedbackTitle = dto.message;
          this.importFileFeedbackMessage = formatServerMessage(dto.additionalInfo);
          this.showImportFileFeedbackDialog = true;
        });

        rootStore.router.navigate('/scanpool?scanId=' + dto.result);
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.isCsvLoading = false;
          });
        }
      }
    );
  }

  closeImportFileFeedbackDialog() {
    this.showImportFileFeedbackDialog = false;
    this.importFileFeedbackTitle = '';
    this.importFileFeedbackMessage = [];
  }

  addPositionsToTemplate() {
    const articles = [];
    for (const pos of this.submitPositions) {
      articles.push({
        articleId: pos.article.articleId,
        mainArticleId: pos.article.mainArticleId,
        amount: pos.article.amount,
        externalArticle: pos.article.externalArticle
      });
    }
    this.rootStore.templateStore.addTemplateArticleToAdd(articles);
  }

  reloadScanPosition() {
    const scannerId = loadScanId();
    this.getPositionsFromScanner(scannerId);
  }

  get articles(): Article[] {
    return this.submitPositions.map((x) => x.article);
  }
}
