import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { formatDate } from '../../../../util/dateUtils';
import { StoreContext } from '../../../../StoreContext';
import ShoppingCartButton from './ShoppingCartButton';

const ShoppingCart = observer(() => {
  const { userStore, cartStore } = useContext(StoreContext);
  const { t } = useTranslation();

  const getCartItemsText = (countPos: number, countExtPos: number) => {
    const cartTotal = cartStore.cartOverview && cartStore.cartOverview.totalPrice ? cartStore.cartOverview!.totalPrice : 0.0;
    const cartDeliveryDate = cartStore.cartOverview && cartStore.cartOverview!.deliveryDate ? formatDate(cartStore.cartOverview!.deliveryDate) : '';
    let extArts = null;
    if (countExtPos > 0) {
      extArts = `${countExtPos} ${t('FA')}`;
    }

    const text = `${countPos + countExtPos} ${t('Positionen')} ${extArts ? extArts : ''}`;

    return (
      <>
        <span>{text}</span>&nbsp;<b>CHF {cartTotal}</b>&nbsp;
        <span>{cartDeliveryDate}</span>
      </>
    );
  };

  const cartItemCount = cartStore.cartOverview && cartStore.cartOverview!.numberOfPositions ? cartStore.cartOverview!.numberOfPositions : 0;
  const cartExternalItemCount = cartStore.cartOverview && cartStore.cartOverview!.numberOfExternalPositions ? cartStore.cartOverview!.numberOfExternalPositions : 0;
  const notLoggedIn = !(userStore.loginCustomer && userStore.loginCustomer!.displayName);
  const cartIsEmpty = cartItemCount === 0 && cartExternalItemCount === 0;

  let displayText;
  if (notLoggedIn) {
    displayText = t('Bitte loggen Sie sich ein');
  } else if (cartIsEmpty && !cartStore.hasPendingIntents) {
    displayText = t('Warenkorb leer');
  } else if (!cartIsEmpty) {
    displayText = getCartItemsText(cartItemCount, cartExternalItemCount);
  } else {
    displayText = t('Warenkorb');
  }

  return <ShoppingCartButton displayText={displayText} />;
});

export default ShoppingCart;
