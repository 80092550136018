import CartService from '../../services/cartService';
import CartStore from '../../stores/cartStore';
import { IntentServerState } from '../../types/IntentServerState';
import AbstractOrderIntent from './AbstractOrderIntent';
import type { IAvailabilityOptionData, ICustomerOrderPosition2DTOData, IExternalArticlePositionData, ICartResponseData } from '../../../../api';
import { IPromptInfo } from '../../types/IPrompt';
import { format } from 'date-fns';

export default class ChangeOrderDateWithCheckIntent extends AbstractOrderIntent {
  private readonly deliveryDate: any;

  constructor(cardStore: CartStore, deliveryDate: Date) {
    super(cardStore);
    const localFormat = 'yyyy-MM-dd';
    this.deliveryDate = format(deliveryDate, localFormat);
  }

  public async internalOrderIntentProcess(
    resolve: () => Promise<void>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    reject: (reason?: any) => Promise<void>
  ): Promise<void> {
    switch (this.internalState.state) {
      case IntentServerState.INITIAL: {
        await this.handleInit();
        break;
      }
      case IntentServerState.SERVER_RESPONSE_CHANGE_ORDER_DATE_WITH_CHECK: {
        await this.handleChangeOrderDateWithCheck(resolve);
        break;
      }
      case IntentServerState.SERVER_REQUEST_SEND_OPTION: {
        await this.handleSendOption();
        break;
      }
    }
  }

  private async handleInit(): Promise<any> {
    await this.handleServiceCallResponse(CartService.changeDateWithAvailabilityCheck(this.cartStore.order!.id, this.deliveryDate), IntentServerState.SERVER_RESPONSE_CHANGE_ORDER_DATE_WITH_CHECK);
  }

  private async handleChangeOrderDateWithCheck(resolve: () => Promise<void>): Promise<any> {
    const response = this.internalState.serverResponse!;

    const data = response.data as ICartResponseData;
    const positions = data.positions ? data.positions : data;

    const changedPositions = positions as ICustomerOrderPosition2DTOData[];
    const serverMessages = changedPositions
      .filter((addResult) => addResult.result?.mostImportantMessage && !(addResult.options && addResult.options.length > 0))
      .map((addResult) => ({
        message: addResult.result.mostImportantMessage.message,
        options: []
      }));

    const serverOptions: IPromptInfo[] = changedPositions
      .filter((changedResult) => changedResult.options && changedResult.options.length > 0)
      .map((problemResult) => ({
        message: problemResult.result.mostImportantMessage.message,
        options: problemResult.options
      }));

    const promptInfos = [...serverMessages, ...serverOptions];
    const resultWithError = changedPositions.find((changedResult) => changedResult.result.mostImportantMessage != null);
    const latestData = changedPositions[changedPositions.length - 1];

    if (serverOptions.length > 0) {
      const option = await this.userPrompter.userPromptRequest(promptInfos);
      if (!option) {
        return resolve();
      }

      this.internalState = {
        state: IntentServerState.SERVER_REQUEST_SEND_OPTION,
        option
      };
    } else if (resultWithError) {
      await this.userPrompter.userAlertRequest(resultWithError.result.mostImportantMessage);
      return resolve();
    } else {
      const cartDiscountInfo = data.cartDiscountInfo;
      const order = latestData ? latestData.customerOrder : undefined;
      if (order) {
        this.cartStore.orderReceived(order, cartDiscountInfo);
      } else if (data.customerOrder) {
        // jonas, 2024-06-14: Dieser Code-Pfad ist der "Normalfall" bei dem Szenario "Lieferdatum anpassen"
        this.cartStore.orderReceived(data.customerOrder, cartDiscountInfo);
      }

      const dataWithExternalPositions = latestData as any;
      if (dataWithExternalPositions?.extlArticlePositions && dataWithExternalPositions.extlArticlePositions.length > 0) {
        dataWithExternalPositions.extlArticlePositions.forEach((p: IExternalArticlePositionData) => {
          this.cartStore.updateExternalPosition(p);
        });
      }
      return resolve();
    }
  }

  private async handleSendOption(): Promise<any> {
    if (!this.internalState.option) {
      throw new Error('Inconsistent state: handleSendOption -> option is undefined');
    }
    const commands = (this.internalState.option as any).map((option: IAvailabilityOptionData) => option.command);
    const response = await CartService.sendCommands(commands);
    this.internalState = {
      state: IntentServerState.SERVER_RESPONSE_CHANGE_ORDER_DATE_WITH_CHECK,
      serverResponse: response
    };
  }
}
