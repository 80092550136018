import { IWebshopConfiguration } from '../webshopConfig';
import transgourmetTheme from './transgourmetTheme';
import logo from './assets/logo.svg';
import { getMLSelector } from '../../util/i18n';

const mls = getMLSelector();

export const URL_DATENSCHUTZ = 'https://www.transgourmet.ch/datenschutz';

const transgourmetConfig: IWebshopConfiguration = {
  name: 'Transgourmet',
  theme: transgourmetTheme,
  logo: logo,
  footerLinks: [
    { labelKey: 'Hilfe', href: 'https://www.transgourmet.ch/support' },
    { labelKey: 'Kontakt', href: 'https://www.transgourmet.ch/kundencenter' },
    { labelKey: 'Impressum', href: 'https://www.transgourmet.ch/impressum' },
    { labelKey: 'AGB', href: 'https://www.transgourmet.ch/agb' },
    { labelKey: 'Datenschutz', href: URL_DATENSCHUTZ },
    {
      labelKey: 'Bestellschlusszeiten',
      href: () =>
        `https://www.transgourmet.ch/${mls(['de/co/service/bestellschlusszeiten-und-liefertermine-im-webshop', 'fr/co/service/heures-de-clotures-des-commandes-du-magasin-en-ligne', 'it/co/servizio/termini-di-ordinazione-nel-webshop'])}`
    },
    { labelKey: 'Jahresbonus', href: 'https://www.transgourmet.ch/turnover' },
    { labelKey: 'Transgourmet', href: 'https://www.transgourmet.ch' }
  ]
};

export default transgourmetConfig;
