import { runInAction } from 'mobx';
import type { IArticleDetailDTOData } from '../../../../api';
import { Article } from '../models/article';

export function isSameArticle(a1: Article, a2: Article) {
  if (!a1 || !a2) {
    return false;
  }
  const articleId1 = a1.articleId;
  const articleId2 = a2.articleId;

  return articleId1 === articleId2;
}

export function handleUpdateArticleItem(existingItems: Article[], updates: IArticleDetailDTOData[]) {
  function updateArticleWithDeepLoadedData(article: Article, matchingArticleDetail: IArticleDetailDTOData) {
    if (article.isZzArticle) {
      const zzArticles = article.zzArticles;
      const artNumber = article.articleNumber; // jba, 2019-06-04: Copying from legacy implementation ... not sure if still needed ...
      article.update(matchingArticleDetail);
      article.zzArticles = zzArticles;
      article.articleNumber = artNumber;
    } else {
      article.update(matchingArticleDetail);
    }
  }

  for (const article of existingItems) {
    const matchingArticleDetail = updates.find((detail) => detail.mainArticleId === article.mainArticleId);
    if (matchingArticleDetail) {
      if (matchingArticleDetail.article) {
        updateArticleWithDeepLoadedData(article, matchingArticleDetail);
      } else {
        runInAction(() => {
          article.notInWebShop = true;
          article.isDeepLoaded = true;
        });
      }
    }

    // Check if there are any zusammenzug-child-articles that need to be updated
    if (article.isZzArticle) {
      for (const childArticle of article.zzArticles) {
        const matchingArticleDetail = updates.find((detail) => detail.mainArticleId === childArticle.mainArticleId);
        if (matchingArticleDetail) {
          childArticle.update(matchingArticleDetail);
        }
      }
    }
  }
}
