import { ListItemText } from '@mui/material';
import React from 'react';
import NavigationDropDown from './NavigationDropDown';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { getShortLocale } from '../../../../util/i18n';

interface ILanguageSelectorProps {
  onSwitchLanguage: (locale: string) => void;
}

type Props = ILanguageSelectorProps;

export default ({ onSwitchLanguage }: Props) => {
  const switchLanguage = (locale: any) => {
    onSwitchLanguage(locale);
  };

  const locale = getShortLocale();
  const languageLabelDe = 'Deutsch';
  const languageLabelFr = 'Français';
  const languageLabelIt = 'Italiano';

  let selectedLanguageLabel = languageLabelDe;
  if (locale === 'fr') {
    selectedLanguageLabel = languageLabelFr;
  } else if (locale === 'it') {
    selectedLanguageLabel = languageLabelIt;
  }

  return (
    <NavigationDropDown
      title={selectedLanguageLabel}
      id={'language-selector-dropdown'}
      menu={(close) => (
        <MenuList>
          <MenuItem
            id={'language-selector-de'}
            onClick={(event) => {
              switchLanguage('de-CH');
              close(event);
            }}
          >
            <ListItemText primary={languageLabelDe} />
          </MenuItem>
          <MenuItem
            id={'language-selector-fr'}
            onClick={(event) => {
              switchLanguage('fr-CH');
              close(event);
            }}
          >
            <ListItemText primary={languageLabelFr} />
          </MenuItem>
          <MenuItem
            id={'language-selector-it'}
            onClick={(event) => {
              switchLanguage('it-CH');
              close(event);
            }}
          >
            <ListItemText primary={languageLabelIt} />
          </MenuItem>
        </MenuList>
      )}
    ></NavigationDropDown>
  );
};
