import React from 'react';
import { Article } from '../../models/article';
import { useTranslation } from 'react-i18next';
import { Variant } from '@mui/material/styles/createTypography';
import AvailabilityMessageCompact from './AvailabilityMessageCompact';
import AvailabilityMessage from './AvailabilityMessage';

export interface IAvailabilityMessageProps {
  article: Article;
  variant?: Variant | 'inherit';
  showCompactMessage: boolean;
}

export default function AvailabilityCheck({ article, variant, showCompactMessage }: Readonly<IAvailabilityMessageProps>) {
  const { t } = useTranslation();

  if (article.available && article.articleStatusAvailable && !article.stopselling) {
    return null;
  }

  let text = '';
  let messageType: 'notAvailable' | 'temporaryNotAvailable' = 'notAvailable';

  if (article.stopselling || (!article.available && article.articleStatusAvailable)) {
    text = t('Artikel aktuell oder dauerhaft nicht verfügbar');
    messageType = 'temporaryNotAvailable';
  } else {
    text = t('Artikel aktuell oder dauerhaft nicht verfügbar');
  }

  if (showCompactMessage) {
    return <AvailabilityMessageCompact articleNumber={article.articleNumber} messageType={messageType} message={text} variant={variant} />;
  } else {
    return <AvailabilityMessage articleNumber={article.articleNumber} messageType={messageType} message={text} variant={variant} />;
  }
}
