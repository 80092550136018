import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';
import webshopConfig from '../../../../config/webshopConfig';

interface ILogoProps {
  width?: number;
}

const logo = (props: ILogoProps) => {
  return (
    <Box height={'60px'} p={1}>
      <Link to="/">
        <img src={webshopConfig.logo} alt="" width={props.width} />
      </Link>
    </Box>
  );
};

export default logo;
