import { makeAutoObservable, runInAction } from 'mobx';
import RootStore from '../../../rootStore';
import InventoryService from '../services/inventoryService';
import Inventory from '../models/inventory';
import type { IWebInventoryData } from '../../../api';
import { executeAsyncAction } from '../../../util/exceptionHandler';
import { rootStore } from '../../../StoreContext';

export class InventoryStore {
  static INVENTORYNAMEDIALOGTYPE_NEW = 1;
  static INVENTORYNAMEDIALOGTYPE_CHANGENAME = 2;
  static INVENTORYNAMEDIALOGTYPE_NEWSALESORDERSET = 3;

  rootStore: RootStore;

  pendingRequestsCount = 0;
  inventories: Inventory[] = [];
  articleToAdd: any;
  showAddToInventoryDialog: boolean = false;
  isInventoryNameDialogShown: boolean = false;
  showClosedInventories: boolean = false;
  inventoryNameDialogType = InventoryStore.INVENTORYNAMEDIALOGTYPE_NEW;

  constructor(store: RootStore) {
    makeAutoObservable(this);
    this.rootStore = store;
  }

  addPositionToInventory(article: any) {
    this.articleToAdd = article;
    this.showAddToInventoryDialog = true;
  }

  fetchInventories() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const inventories = await InventoryService.fetchInventories(this.showClosedInventories);
        runInAction(() => (this.inventories = inventories.map((i) => new Inventory(i))));
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  setInventories(inventories: IWebInventoryData[]) {
    this.inventories = inventories.map((x) => new Inventory(x));
  }

  removeInventory(id: number) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        await InventoryService.removeInventory(id);
        runInAction(() => {
          this.inventories = this.inventories.filter((i) => i.id !== id);
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  createInventoryFromSalesOrderSet(name: string) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const inventory = await InventoryService.createInventoryFromSalesOrderSet(name);

        runInAction(() => {
          this.inventories.push(new Inventory(inventory));
        });

        rootStore.router.navigate('/inventory/' + inventory.id);
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  saveInventory(name: string) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const inventory = await InventoryService.saveInventory(name);

        runInAction(() => {
          this.inventories.push(new Inventory(inventory));
        });

        rootStore.router.navigate('/inventory/' + inventory.id);
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  closeInventoryNameDialog() {
    this.isInventoryNameDialogShown = false;
  }

  closeAddPositionDialog() {
    this.articleToAdd = null;
    this.showAddToInventoryDialog = false;
  }

  showNewInventoryFromSalesOrderSetNameDialog() {
    this.inventoryNameDialogType = InventoryStore.INVENTORYNAMEDIALOGTYPE_NEWSALESORDERSET;
    this.isInventoryNameDialogShown = true;
  }

  showNewInventoryNameDialog() {
    this.inventoryNameDialogType = InventoryStore.INVENTORYNAMEDIALOGTYPE_NEW;
    this.isInventoryNameDialogShown = true;
  }

  showChangeNameInventoryNameDialog() {
    this.inventoryNameDialogType = InventoryStore.INVENTORYNAMEDIALOGTYPE_CHANGENAME;
    this.isInventoryNameDialogShown = true;
  }

  setShowClosedInventories(showClosed: boolean) {
    this.showClosedInventories = showClosed;
  }

  addPosition(invId: number, artId: number, quantity: number, extArt: boolean) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;

        const inventory = await InventoryService.addPosition(invId, artId, quantity, extArt);
        const i = this.inventories.findIndex((inv) => inv.id === inventory.id);
        if (i >= 0) {
          runInAction(() => {
            this.inventories[i].update(inventory);
          });
        }
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  importInventoryPositions(webInvId: any, positions: any) {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        await InventoryService.importInventoryPositions(webInvId, positions);
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  get filteredInventories() {
    if (this.showClosedInventories) {
      return this.inventories;
    } else {
      return this.openInventories;
    }
  }

  get areOpenInventoriesAvailable() {
    for (const inventory of this.inventories) {
      if (!inventory.endDate) {
        return true;
      }
    }
    return false;
  }

  get openInventories(): any[] {
    return this.inventories.filter((inv: any) => !inv.endDate);
  }

  get hasOpenInventories(): boolean {
    return !!this.openInventories && this.openInventories.length > 0;
  }

  get isLoading(): boolean {
    return this.pendingRequestsCount > 0;
  }
}
