import { IObservableArray, makeAutoObservable, runInAction } from 'mobx';
import RootStore from '../../../rootStore';
import AddPositionIntent from '../../cart/models/intent/AddPositionIntent';
import AddPositionsIntent, { AddPositionParameterType } from '../../cart/models/intent/AddPositionsIntent';
import { Article } from '../../shared/articles/models/article';
import { executeAsyncAction } from '../../../util/exceptionHandler';
import SubmissionService from '../services/submissionService';
import { SubmissionPosition } from './models/submissionPosition';
import { FEATURES } from '../../common/stores/featureStore';

export class SubmissionStore {
  rootStore: RootStore;

  submissionId?: string;
  clientId?: string;
  submissionDateTime?: Date;
  submissionPositions: SubmissionPosition[] = [];
  isLoading: boolean = true;
  processingInitialized: boolean = false;
  totalPositionsCount: number = 0;
  processingPositionsCount: number = 0;
  hasLoadingError: boolean = false;
  processed: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  reset() {
    this.hasLoadingError = false;
    this.totalPositionsCount = 0;
    this.processingPositionsCount = 0;
    this.processingInitialized = false;
    this.submissionPositions = [];
    this.submissionDateTime = undefined;
    this.submissionId = undefined;
    this.clientId = undefined;
  }

  removePosition(position: SubmissionPosition) {
    if (this.submissionId && position.id) {
      executeAsyncAction(async () => {
        await SubmissionService.removeSubmissionPosition(this.submissionId, position.id);
        runInAction(() => (this.submissionPositions as IObservableArray<SubmissionPosition>).remove(position));
      });
    } else {
      (this.submissionPositions as IObservableArray<SubmissionPosition>).remove(position);
    }
  }

  loadPositionsFromSubmission(submissionId: any) {
    this.reset();

    executeAsyncAction(
      async () => {
        this.isLoading = true;
        const positionSubmission = await SubmissionService.getPositionSubmission(submissionId);

        //await sleep(2000);

        runInAction(() => {
          this.clientId = positionSubmission.clientId;
          this.submissionId = positionSubmission.submissionId;
          this.submissionDateTime = new Date(positionSubmission.submissionDateTime as any);
          this.submissionPositions = positionSubmission.positionSubmissionArticleSummaries.map((p) => new SubmissionPosition(p));
          this.totalPositionsCount = positionSubmission.positionSubmissionArticleSummaries.length;
        });
        this.submissionPositions.filter((x) => x.article?.mainArticleId).forEach((s) => s.article.fetchArticleDeepLoaded(this.rootStore.userStore.isLoggedIn, false));
      },
      false,
      {
        finallyAction: () => {
          runInAction(() => {
            this.isLoading = false;
          });
        },
        errorAction: () => {
          runInAction(() => (this.hasLoadingError = true));
        }
      }
    );
  }

  get invalidPositions() {
    return this.submissionPositions.filter((x) => !x.positionAvailable);
  }

  get validPositions() {
    return this.submissionPositions.filter((x) => x.positionAvailable);
  }

  get validPositionsCount() {
    return this.validPositions.length;
  }

  get invalidPositionsCount() {
    return this.invalidPositions.length;
  }

  get hasValidPositions() {
    return this.validPositionsCount > 0;
  }

  get hasInvalidPositions() {
    return this.invalidPositionsCount > 0;
  }

  submitAllSubmissionPositions() {
    if (this.rootStore.featureStore.isFeatureEnabled(FEATURES.batchPositionAdd)) {
      const addPositionParameters: AddPositionParameterType[] = [];

      for (const p of this.validPositions) {
        addPositionParameters.push({
          articleNumber: p.article.articleNumber,
          quantity: p.article.amount,
          externalArticle: false,
          articleIsEan: false
        });
      }

      if (addPositionParameters.length > 0) {
        const addPositionsIntent = new AddPositionsIntent(this.rootStore.cartStore, addPositionParameters);
        addPositionsIntent.intentPromise.then(() => {
          runInAction(() => (this.processingPositionsCount = this.validPositionsCount));
        });
        this.rootStore.cartStore.addIntent(addPositionsIntent);
      }
    } else {
      const positions = [...this.validPositions];
      console.table(positions);
      positions.forEach((p: SubmissionPosition) => {
        if (p.article) {
          const addIntent = new AddPositionIntent(this.rootStore.cartStore, p.article.articleNumber, p.article.amount, p.article.externalArticle);

          addIntent.intentPromise.then(() => {
            this.removePosition(p);
            runInAction(() => this.processingPositionsCount++);
          });

          this.rootStore.cartStore.addIntent(addIntent);
        }
      });
    }

    runInAction(() => {
      this.processingInitialized = true;
    });
  }

  get hasPendingRequests() {
    return this.rootStore.cartStore.hasPendingIntents;
  }

  get isProcessing() {
    return this.rootStore.cartStore.hasPendingIntents;
  }

  get articles(): Article[] {
    return this.submissionPositions.map((x) => x.article);
  }
}
