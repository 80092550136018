import find from 'lodash/find';
import sortList from 'lodash/sortBy';
import { WHITESPACE_BRAND } from '../../util/filterConstants';
import { Category } from './category';
import { ISelectable } from './ISelectable';

import ExternalArticle from './models/externalArticle';

export function createCategories(articles: ExternalArticle[], groupByObject: { name: string; value: string }): Array<Category<ExternalArticle>> {
  let categories: Array<Category<ExternalArticle>> = [];

  for (const article of articles) {
    let categoryKey = (article as any)[groupByObject.name];

    if (categoryKey === null) {
      categoryKey = WHITESPACE_BRAND;
    }

    const foundCategory = find(categories, {
      key: categoryKey
    }) as Category<ExternalArticle>;

    if (!foundCategory) {
      const newCat: Category<ISelectable> = new Category(categoryKey, [], article.supplierId);
      newCat.items.push(article);
      categories.push(newCat as Category<ExternalArticle>);
    } else {
      foundCategory.items.push(article);
    }
  }

  if (categories.length > 0) {
    categories = sortList<Category<ExternalArticle>>(categories, (c) => {
      return c.key;
    });
  }

  return categories;
}
