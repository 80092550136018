import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { StoreContext } from '../../../../StoreContext';
import { observer } from 'mobx-react-lite';

export interface IDefaultErrorDialogProps {
  options: any[];
  handler?: (option: any) => void;
}

const DefaultErrorDialogFragment = observer(({ options, handler }: IDefaultErrorDialogProps) => {
  const { cartStore } = useContext(StoreContext);
  const { t } = useTranslation();

  const choose = (option: any) => {
    if (handler) {
      handler(option);
    } else {
      cartStore.promptAnswered();
    }
  };

  return (
    <div>
      {options.map((o, i) => (
        <div key={i}>
          <Typography variant={'body1'}>{o.description}</Typography>
          <Grid container justifyContent={'flex-end'}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={() => choose(o)}>
                {t('Auswählen')}
              </Button>
            </Grid>
          </Grid>
          {i !== options.length - 1 && <Divider />}
        </div>
      ))}
    </div>
  );
});

export default DefaultErrorDialogFragment;
