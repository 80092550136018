import CartStore from './modules/cart/stores/cartStore';
import CatalogStore from './modules/catalog/stores/catalogStore';
import { FeatureStore } from './modules/common/stores/featureStore';
import MasterDataStore from './modules/common/stores/masterDataStore';
import UiStore from './modules/common/stores/uiStore';
import CustomerOrderStore from './modules/customerorders/stores/customerOrderStore';
import { ExternalArticleStore } from './modules/externalarticles/stores/externalArticleStore';
import { InventoryDetailStore } from './modules/inventory/stores/inventoryDetailStore';
import { InventoryStore } from './modules/inventory/stores/inventoryStore';
import InvoiceStore from './modules/invoice/stores/invoiceStore';
import { SalesOrderSetStore } from './modules/salesOrderSet/stores/salesOrderSetStore';
import ShipmentStore from './modules/shipment/stores/shipmentStore';
import { SubmissionStore } from './modules/submission/stores/submissionStore';
import { SubmitStore } from './modules/submit/stores/submitStore';
import { SupplierStore } from './modules/suppliers/stores/supplierStore';
import { TemplateDetailStore } from './modules/templates/stores/templateDetailStore';
import { TemplateStore } from './modules/templates/stores/templateStore';
import { UserStore } from './modules/user/stores/userStore';
import { RouterType } from './index';

export default class RootStore {
  static getInstance() {
    if (!RootStore.instance) {
      RootStore.instance = new RootStore();
    }
    return RootStore.instance;
  }

  private static instance: RootStore;

  router!: RouterType;
  uiStore: UiStore;
  masterDataStore: MasterDataStore;
  featureStore: FeatureStore;
  userStore: UserStore;
  supplierStore: SupplierStore;
  templateStore: TemplateStore;
  templateDetailStore: TemplateDetailStore;
  cartStore: CartStore;
  catalogStore: CatalogStore;
  inventoryStore: InventoryStore;
  submitStore: SubmitStore;
  externalArticleStore: ExternalArticleStore;
  customerOrderStore: CustomerOrderStore;
  inventoryDetailStore: InventoryDetailStore;
  salesOrderSetStore: SalesOrderSetStore;
  shipmentStore: ShipmentStore;
  invoiceStore: InvoiceStore;
  submissionStore: SubmissionStore;

  private constructor() {
    this.uiStore = new UiStore(this);
    this.masterDataStore = new MasterDataStore(this);
    this.userStore = new UserStore(this);
    this.featureStore = new FeatureStore();
    this.supplierStore = new SupplierStore(this);
    this.templateStore = new TemplateStore(this);
    this.templateDetailStore = new TemplateDetailStore(this);
    this.cartStore = new CartStore(this);
    this.catalogStore = new CatalogStore(this);
    this.inventoryStore = new InventoryStore(this);
    this.submitStore = new SubmitStore(this);
    this.externalArticleStore = new ExternalArticleStore(this);
    this.customerOrderStore = new CustomerOrderStore(this);
    this.inventoryDetailStore = new InventoryDetailStore(this);
    this.salesOrderSetStore = new SalesOrderSetStore(this);
    this.shipmentStore = new ShipmentStore(this);
    this.invoiceStore = new InvoiceStore(this);
    this.submissionStore = new SubmissionStore(this);
  }
}
