import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import CatalogContentLegacy from './CatalogContentLegacy';
import { Button, Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../../../StoreContext';
import CatalogContent from '../../../../../features/overview/CatalogContent';

interface ICatalogDialogProps {
  open: boolean;
  onClose: () => void;
}

const CatalogDialog = observer(({ open, onClose }: ICatalogDialogProps) => {
  const { t } = useTranslation();
  const { featureStore } = useContext(StoreContext);
  return (
    <Dialog open={open} onBackdropClick={onClose} fullScreen={true}>
      <DialogContent>
        <Grid container justifyContent={'center'}>
          <Grid item>{featureStore?.enableHubSearchNew ? <CatalogContent onClose={onClose} /> : <CatalogContentLegacy onClose={onClose} />}</Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Schliessen')}</Button>
      </DialogActions>
    </Dialog>
  );
});

export default CatalogDialog;
