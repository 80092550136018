import React from 'react';
import { Grid, Theme, Typography } from '@mui/material';
import { Article } from '../../models/article';
import { ppConstants } from '../../../../../util/ppConstants';
import { makeStyles } from 'tss-react/mui';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

export interface IArticleSellUnitProps {
  article: Article;
  onChangeSellUnit: (value: string) => void;
  justify?: any;
  id?: string;
}

export const useStyles = makeStyles()(({ spacing, palette }: Theme) => {
  return {
    action: {
      textDecoration: 'line-through'
    },
    zzArticle: {
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      margin: spacing(1),
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: palette.secondary.main,
      cursor: 'pointer',
      minWidth: 100,
      textAlign: 'center'
    },
    selected: {
      backgroundColor: palette.secondary.main,
      color: palette.common.white,
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      margin: spacing(1),
      minWidth: 100,
      textAlign: 'center'
    }
  };
});

const ArticleSellUnit = ({ article, onChangeSellUnit, justify, id }: IArticleSellUnitProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  if (article.isZzArticle) {
    return (
      <Grid container justifyContent={justify ? justify : 'flex-start'}>
        {article.zzArticles.map((zzArt: Article, i: number) => {
          return (
            <Grid item key={i}>
              <Grid container wrap={'nowrap'} alignItems={'center'}>
                <Grid item>
                  <div
                    className={article.zzIndex === i ? classes.selected : classes.zzArticle}
                    onClick={(event: any) => {
                      event.stopPropagation();
                      onChangeSellUnit(i.toString());
                    }}
                  >
                    <Typography variant="body2" id={id + '-' + i}>
                      {zzArt.sellAmount > 1 ? zzArt.sellUnit + ` ${t('sellUnitSeparator')} ` : ''}
                      {zzArt.priceCode === ppConstants.PRICECODE_WEIGHTCONTROLLED ? zzArt.approxWeight.toFixed(3) : zzArt.sellAmount} {zzArt.articleUnit}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  } else {
    return (
      <Grid container wrap={'nowrap'} alignItems={'center'} justifyContent={justify ? justify : 'flex-start'}>
        <Grid item>
          <div className={classes.selected}>
            <Typography variant="body2" id={id}>
              {article.sellAmount > 1 ? article.sellUnit + ` ${t('sellUnitSeparator')} ` : ''}
              {article.priceCode === ppConstants.PRICECODE_WEIGHTCONTROLLED ? article.approxWeight.toFixed(3) : article.sellAmount} {article.articleUnit}
            </Typography>
          </div>
        </Grid>
      </Grid>
    );
  }
};

export default ArticleSellUnit;
