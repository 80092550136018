import React from 'react';
import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material';
import CatalogTilesItem, { TILES_SPACING_WIDTH, TILES_WIDTH } from './CatalogTilesItem';
import { Article } from '../../models/article';
import { observer } from 'mobx-react-lite';
import CatalogTilesItemSmall from './CatalogTilesItemSmall';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(({ spacing }: Theme) => {
  return {
    root: {
      padding: spacing(1),
      justifyContent: 'space-around'
    },
    item: {
      width: TILES_WIDTH,
      marginBottom: spacing(2)
    }
  };
});

interface ITilesRowProps {
  smallView?: boolean;
  loadLedger: boolean;
  articles: Article[];
  screenWidth: number;
  isLoggedIn: boolean;
  addToCart: (article: Article) => void;
  isLoading: (article: Article) => boolean;
  navigateToDetail: (article: Article) => void;
  isInCart: (article: Article) => boolean;
  id?: string;
}

export const TilesRow = observer(({ articles, screenWidth, loadLedger, isInCart, addToCart, isLoading, isLoggedIn, navigateToDetail, id, smallView = false }: ITilesRowProps) => {
  const { classes } = useStyles();

  const calculateEmptyRows = (fromIndex: number, toIndex: number, itemsPerRow: number) => {
    const i = toIndex - fromIndex;
    const emptyControlCount = itemsPerRow - i;
    const emptyControls = [];

    for (let i = 0; i < emptyControlCount; i++) {
      emptyControls.push(<div className={classes.item} key={'emtpy' + i}></div>);
    }

    return emptyControls;
  };

  const renderItem = (itemsPerRow: number, row: number, itemCount: number) => {
    const items = [];
    const fromIndex = row * itemsPerRow;
    const toIndex = Math.min(fromIndex + itemsPerRow, itemCount);

    for (let i = fromIndex; i < toIndex; i++) {
      const item = articles[i];
      items.push(
        <Grid item key={i + '-' + item.articleId} className={classes.item}>
          {smallView ? (
            <CatalogTilesItemSmall loadLedger={loadLedger} article={item} navigateToDetail={navigateToDetail} isLoggedIn={isLoggedIn} id={id + '-' + item.articleNumber} />
          ) : (
            <CatalogTilesItem loadArticleDeep={true} loadLedger={loadLedger} article={item} navigateToDetail={navigateToDetail} isLoggedIn={isLoggedIn} addToCart={addToCart} isLoading={isLoading} isInCart={isInCart} idx={i} />
          )}
        </Grid>
      );
    }

    return (
      <Grid container className={classes.root} key={row}>
        {items}
        {calculateEmptyRows(fromIndex, toIndex, itemsPerRow)}
      </Grid>
    );
  };

  const calculateItemsPerRow = Math.ceil(Math.floor(screenWidth / (TILES_WIDTH + TILES_SPACING_WIDTH)));
  const itemsPerRow = calculateItemsPerRow && calculateItemsPerRow > 1 ? calculateItemsPerRow : 1;
  const itemCount = articles.length;
  const rowCount = Math.ceil(itemCount / itemsPerRow);

  const items = [];
  for (let i = 0; i < rowCount; i++) {
    items.push(renderItem(itemsPerRow, i, itemCount));
  }

  return <>{items}</>;
});

export default TilesRow;
