import { useQuery } from '@tanstack/react-query';
import axios from '../../../util/axios';
import { qkArticleSuggestions } from './searchQueryKeys';
import { getDefaultSearchRequest } from '../model/searchModel';
import { API } from './apiEndpoints';
import { executeAsyncActionNew } from '../../../util/exceptionHandler';
import { ISearchRequestDTOData, ISuggestTermData } from '../../../api';

async function fetchArticleSuggestions(searchRequest: ISearchRequestDTOData): Promise<ISuggestTermData[]> {
  if (!searchRequest.searchTerm || searchRequest.searchTerm.length < 3) {
    return [];
  }
  const response = await axios.post<ISearchRequestDTOData, any>(API.ARTICLE_SUGGEST, searchRequest);
  return response.data;
}

export function useArticleSuggestions(debouncedSearchTerm: string, minSearchTermLength: number) {
  const suggestionsQuery = useQuery({
    queryKey: [qkArticleSuggestions, debouncedSearchTerm],
    queryFn: () => executeAsyncActionNew(async () => await fetchArticleSuggestions({ ...getDefaultSearchRequest(), searchTerm: debouncedSearchTerm })),
    enabled: debouncedSearchTerm?.length >= minSearchTermLength
  });

  const hasSuggestions = !!suggestionsQuery.data && suggestionsQuery.data?.length > 0;
  const articleSuggestions = suggestionsQuery.data ?? [];
  return { hasSuggestions, articleSuggestions };
}
