import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Article } from '../models/article';
import { Button, Grid, Popover, Theme, IconButton } from '@mui/material';
import CartIcon from 'mdi-material-ui/Cart';
import ForkIcon from 'mdi-material-ui/SilverwareForkKnife';
import ViewListIcon from 'mdi-material-ui/ViewList';
import MessageIcon from 'mdi-material-ui/AlertCircleOutline';
import { StoreContext } from '../../../../StoreContext';
import { formatDate } from '../../../../util/dateUtils';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router-dom';

export interface IArticleInfoIconsProps {
  article: Article;
  isMedium?: boolean;
  id?: string;
}

export const useStyles = makeStyles()(({ spacing, palette }: Theme) => {
  return {
    button: {
      minWidth: 0,
      width: spacing(5),
      backgroundColor: palette.grey.A100
    },
    root: {
      padding: spacing(1),
      overflowY: 'hidden'
    }
  };
});

const ArticleInfoIcons = observer(({ isMedium, article, id }: IArticleInfoIconsProps) => {
  const navigate = useNavigate();
  const { userStore, templateStore } = useContext(StoreContext);
  const [anchorEl, setAnchorEl] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  const { classes } = useStyles();

  const getSpecialDeliveryDateIcon = () => {
    if (article.specialNextDeliveryDate) {
      return (
        <Grid container spacing={1} wrap="nowrap">
          <Grid item>
            <Button variant="contained" className={classes.button}>
              <WarningIcon />
            </Button>
          </Grid>
          <Grid item>
            <div>{t('Frühester Liefertermin')}</div>
            <div>{formatDate(article.specialNextDeliveryDate)}</div>
            <div>{t('KEIN RÜCKGABERECHT')}</div>
          </Grid>
        </Grid>
      );
    }

    return null;
  };

  const getProfitplusIcon = () => {
    if (article.isProfitPlus) {
      return (
        <Grid container spacing={1} wrap="nowrap">
          <Grid item>
            <Button variant="contained" className={classes.button}>
              <WarningIcon />
            </Button>
          </Grid>
          <Grid item>
            <div>{t('Es gelten spezielle Preis- und Lieferkonditionen!')}</div>
            <div>{t('KEIN RÜCKGABERECHT')}</div>
          </Grid>
        </Grid>
      );
    }

    return null;
  };

  const getSalesOrderSetIcon = () => {
    if (article.inSalesOrderSet) {
      return (
        <Grid container spacing={1} wrap="nowrap">
          <Grid item>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => {
                navigate('/salesorderset');
              }}
            >
              <CartIcon />
            </Button>
          </Grid>
          <Grid item>{t('Dieser Artikel ist unter gekaufte Artikel vorhanden')}</Grid>
        </Grid>
      );
    }

    return null;
  };

  const toogleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  const getCoreAssortmentIcon = () => {
    if (userStore!.coreAssortmentId && article.articleAssortmentIndex !== null && article.articleAssortmentIndex.indexOf(userStore!.coreAssortmentId!) >= 0) {
      return (
        <Grid container spacing={1} wrap="nowrap">
          <Grid item>
            <Button variant="contained" className={classes.button}>
              <ForkIcon />
            </Button>
          </Grid>
          <Grid item>{t('Dieser Artikel ist im Kernsortiment enthalten')}</Grid>
        </Grid>
      );
    }

    return null;
  };

  const getTemplateIcon = () => {
    const templateNames = templateStore.getTemplatesByArticleId(article.mainArticleId);
    if (templateNames.length > 0) {
      return (
        <Grid container spacing={1} wrap="nowrap">
          <Grid item>
            <Button variant="contained" className={classes.button}>
              <ViewListIcon />
            </Button>
          </Grid>
          <Grid item>
            <div>{t('Bereits in folgenden Vorlagen vorhanden')}</div>
            {templateNames.map((name: string) => (
              <div key={article.articleId + name}>- {name}</div>
            ))}
          </Grid>
        </Grid>
      );
    }
    return null;
  };

  if (!userStore!.isLoggedIn || article.notInWebShop) {
    return null;
  }

  const coreAssortmentIcon = getCoreAssortmentIcon();
  const salesOrderIcon = getSalesOrderSetIcon();
  const templateIcon = getTemplateIcon();
  const specialDeliveryDateIcon = getSpecialDeliveryDateIcon();
  const profitplusIcon = getProfitplusIcon();
  return (
    <>
      {(coreAssortmentIcon || salesOrderIcon || templateIcon || specialDeliveryDateIcon || profitplusIcon) && (
        <>
          <IconButton onClick={toogleMenu} size={isMedium ? 'medium' : 'small'} aria-label="info" id={id}>
            <MessageIcon />
          </IconButton>
          <Popover
            open={isMenuOpen}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
          >
            <Grid container direction={'column'} className={classes.root} spacing={1}>
              {coreAssortmentIcon && <Grid item>{coreAssortmentIcon}</Grid>}
              {salesOrderIcon && <Grid item>{salesOrderIcon}</Grid>}
              {templateIcon && <Grid item>{templateIcon}</Grid>}
              {specialDeliveryDateIcon && <Grid item>{specialDeliveryDateIcon}</Grid>}
              {profitplusIcon && <Grid item>{profitplusIcon}</Grid>}
            </Grid>
          </Popover>
        </>
      )}
    </>
  );
});

export default ArticleInfoIcons;
