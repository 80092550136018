import type { IExternalArticlePositionData, ICartResponseData } from '../../../../api';
import CartService from '../../services/cartService';
import CartStore from '../../stores/cartStore';
import { IntentServerState } from '../../types/IntentServerState';
import AbstractOrderIntent from './AbstractOrderIntent';

export default class AddPositionIntent extends AbstractOrderIntent {
  private readonly articleNumber: string;
  private readonly quantity: number;
  private readonly externalArticle: boolean;

  constructor(cartStore: CartStore, articleNumber: string, quantity: number, externalArticle = false) {
    super(cartStore);
    this.articleNumber = articleNumber;
    this.quantity = quantity;
    this.externalArticle = externalArticle;
  }

  public isPending(intentSpec: any) {
    return super.isPending(intentSpec) && this.articleNumber === intentSpec.articleNumber && this.externalArticle === intentSpec.isExternal;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async internalOrderIntentProcess(resolve: () => Promise<void>, reject: (reason?: any) => Promise<void>, storeState: CartStore): Promise<any> {
    switch (this.internalState.state) {
      case IntentServerState.INITIAL: {
        await this.handleInit(resolve);
        break;
      }
      case IntentServerState.SERVER_REQUEST_CREATE_ORDER: {
        await this.handleCreateOrder();
        break;
      }
      case IntentServerState.SERVER_RESPONSE_CREATE_ORDER: {
        await this.handleCreateOrderResponse(reject);
        break;
      }
      case IntentServerState.SERVER_REQUEST_ADD_POSITION: {
        await this.handleAddPosition();
        break;
      }
      case IntentServerState.SERVER_RESPONSE_ADD_POSITION: {
        await this.handleAddPositionResponse(resolve);
        break;
      }
      case IntentServerState.SERVER_REQUEST_SEND_OPTION: {
        await this.handleSendOption();
        break;
      }
    }
  }

  private async handleInit(resolve: () => Promise<void>): Promise<any> {
    if (this.cartStore.order) {
      this.internalState = {
        state: IntentServerState.SERVER_REQUEST_ADD_POSITION,
        serverResponse: undefined
      };
    } else {
      const selectedDate = this.cartStore.selectedDeliveryDate ? this.cartStore.selectedDeliveryDate : await this.userPrompter.userDeliveryDateSelectionRequest();
      if (!selectedDate) {
        this.intentProcessor?.reset();
        return resolve();
      } else {
        this.internalState = {
          state: IntentServerState.SERVER_REQUEST_CREATE_ORDER,
          serverResponse: undefined
        };
      }
    }
  }

  private async handleCreateOrder(): Promise<any> {
    await this.handleServiceCallResponse(CartService.createOrder(this.cartStore.selectedDeliveryDate, this.cartStore.isTempCashAccepted), IntentServerState.SERVER_RESPONSE_CREATE_ORDER);
  }

  private async handleCreateOrderResponse(reject: (reason?: any) => Promise<void>): Promise<any> {
    const response = this.internalState.serverResponse!;

    const data = response.data as ICartResponseData;
    if (data.customerOrder) {
      const order = data.customerOrder;
      const cartDiscountInfo = data.cartDiscountInfo;
      this.cartStore.orderReceived(order, cartDiscountInfo);
      this.internalState = {
        state: IntentServerState.SERVER_REQUEST_ADD_POSITION,
        serverResponse: undefined
      };
    } else if (data.result.mostImportantMessage) {
      await this.userPrompter.userAlertRequest(this.internalState.serverResponse!.data.result.mostImportantMessage.message);
      return reject('Alert');
    } else {
      return reject('IllegalStateException');
    }
  }

  private async handleAddPosition(): Promise<any> {
    await this.handleServiceCallResponse(
      CartService.addPosition([
        {
          orderId: this.cartStore.order!.id,
          articleNumber: this.articleNumber,
          quantity: this.quantity,
          externalArticle: this.externalArticle,
          articleIsEan: false
        }
      ]),
      IntentServerState.SERVER_RESPONSE_ADD_POSITION
    );
  }

  private async handleAddPositionResponse(resolve: () => Promise<void>): Promise<any> {
    const response = this.internalState.serverResponse!;

    // TODO: (jba, 2019-02-26) remove exceptions after testing ...
    if (response.data.length === undefined) throw new Error('Inconsistent state: server did not responded with an array ... we probably called the wrong API!'); // tslint:disable-line:curly
    if (response.data.length !== 1) throw new Error('Inconsistent state: server responded with several results ... '); // tslint:disable-line:curly
    const data = response.data[0] as ICartResponseData;

    if (data.options && data.options.length > 0) {
      const promptInfo = {
        message: data.result.mostImportantMessage.message,
        options: data.options
      };
      const option = await this.userPrompter.userPromptRequest([promptInfo]);
      if (!option) {
        return resolve();
      }

      this.internalState = {
        state: IntentServerState.SERVER_REQUEST_SEND_OPTION,
        option
      };
    } else if (data.result.mostImportantMessage) {
      await this.userPrompter.userAlertRequest(data.result.mostImportantMessage);
      return resolve();
    } else {
      const order = data.customerOrder;
      const cartDiscountInfo = data.cartDiscountInfo;
      if (order) {
        this.cartStore.orderReceived(order, cartDiscountInfo);
      }

      const dataWithExternalPositions = data as any;
      if (dataWithExternalPositions.extlArticlePositions && dataWithExternalPositions.extlArticlePositions.length > 0) {
        dataWithExternalPositions.extlArticlePositions.forEach((p: IExternalArticlePositionData) => {
          this.cartStore.updateExternalPosition(p);
        });
      }
      return resolve();
    }
  }

  private async handleSendOption(): Promise<any> {
    if (!this.internalState.option) throw new Error('Inconsistent state: handleSendOption -> option array is undefined'); // tslint:disable-line:curly
    if (this.internalState.option.length > 1) throw new Error('Inconsistent state: handleSendOption -> option does contain several options'); // tslint:disable-line:curly
    if (!this.internalState.option[0]) throw new Error('Inconsistent state: handleSendOption -> option is undefined'); // tslint:disable-line:curly
    const commands = this.internalState.option.map((option) => option.command);
    const response = await CartService.sendCommands(commands);
    this.internalState = {
      state: IntentServerState.SERVER_RESPONSE_ADD_POSITION,
      serverResponse: response
    };
  }
}
