import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import NavigationDropDown from '../NavigationDropDown';
import { ListItemIcon, ListItemText, MenuItem, MenuList } from '@mui/material';
import { IWebTemplateDTOData } from '../../../../../api';
import { StoreContext } from '../../../../../StoreContext';
import FavouriteIcon from 'mdi-material-ui/Star';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import { FEATURES } from '../../../stores/featureStore';

const useStyles = makeStyles()(() => {
  return {
    filterItemText: {
      paddingLeft: 0
    },
    icon: {
      marginRight: 16
    }
  };
});

const TemplateNav = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userStore, templateStore, featureStore } = useContext(StoreContext);
  const { classes } = useStyles();

  if (!userStore.currentCustomer) {
    return null;
  }

  return (
    <NavigationDropDown
      title={t('Vorlagen')}
      id={'navigation-templates-button'}
      menu={(close) => (
        <MenuList>
          {featureStore.isFeatureEnabled(FEATURES.scanPool) && (
            <MenuItem
              onClick={(event) => {
                navigate('/scanpool');
                close(event);
              }}
              id={'navigation-templates-scanpositions-button'}
            >
              <ListItemText primary={t('Scan-Positionen')} />
            </MenuItem>
          )}
          <MenuItem
            onClick={(event) => {
              navigate('/salesorderset');
              close(event);
            }}
            id={'navigation-templates-salesorderset-button'}
          >
            <ListItemText primary={t('Gekaufte Artikel')} />
          </MenuItem>
          <MenuItem
            onClick={(event) => {
              navigate('/templates');
              close(event);
            }}
            id={'navigation-templates-alltemplates-button'}
          >
            <ListItemText primary={t('Alle Vorlagen')} />
          </MenuItem>
          {templateStore!.favouriteTemplates.map((item: IWebTemplateDTOData, index: number) => {
            return (
              <MenuItem
                key={item.id}
                onClick={(event) => {
                  navigate('/templates/' + item.id);
                  close(event);
                }}
                id={'navigation-templates-favtemplate-' + index + '-button'}
              >
                <ListItemIcon className={classes.icon}>
                  <FavouriteIcon />
                </ListItemIcon>
                <ListItemText className={classes.filterItemText} primary={item.name} />
              </MenuItem>
            );
          })}
        </MenuList>
      )}
    ></NavigationDropDown>
  );
});

export default TemplateNav;
