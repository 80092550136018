import { isObservableArray } from 'mobx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export interface IArticleDetailTextRowProps {
  translationText?: string;
  value?: string | string[] | number;
  control?: JSX.Element | null;
  id?: string;
}

const useStyles = makeStyles()(({ spacing }: Theme) => {
  return {
    root: {
      flexDirection: 'row',
      alignItems: 'top',
      '@media (max-width:960px)': {
        flexDirection: 'column',
        alignItems: 'flex-start'
      },
      margin: spacing(1)
    },
    labelColumn: {
      width: 200,
      minWidth: 200
    },
    valueColumn: {
      flexGrow: 1
    }
  };
});

const ArticleDetailTextRow = ({ value, control, translationText, id }: IArticleDetailTextRowProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  if (value || control) {
    if (value && isObservableArray(value) && value.length === 0) {
      return null;
    }

    return (
      <>
        <Grid container direction={'row'} className={classes.root} wrap={'nowrap'}>
          {translationText && (
            <Grid item className={classes.labelColumn}>
              <Typography variant={'subtitle2'}>{t(translationText)}</Typography>
            </Grid>
          )}
          <Grid item className={classes.valueColumn}>
            {value && (
              <Typography variant="body2" id={id}>
                {value}
              </Typography>
            )}
            {control}
          </Grid>
        </Grid>
      </>
    );
  }

  return null;
};

export default ArticleDetailTextRow;
