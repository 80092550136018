import { IExternalArticleData, IExternalArticleDTOData, IImportedResultDTOData } from '../../../api';
import axios from '../../../util/axios';
import { ppConstants } from '../../../util/ppConstants';

const ExternalArticleService = {
  async fetchArticles(): Promise<IExternalArticleDTOData[]> {
    const searchResult = await axios.get(ppConstants.API_HOST_SEC + 'externalarticles', {});
    return searchResult.data;
  },

  async fetchArticle(articleId: string) {
    const result = await axios.get<IExternalArticleData>(ppConstants.API_HOST_SEC + 'externalarticles/' + articleId);
    return result.data;
  },

  async saveArticle(article: any): Promise<any> {
    const result = await axios.post(ppConstants.API_HOST_SEC + 'externalarticles', article);
    return result.data;
  },

  async removeArticle(articleId: number): Promise<number> {
    await axios({
      method: 'DELETE',
      url: ppConstants.API_HOST_SEC + 'externalarticles/' + articleId,
      data: {}
    });

    return articleId;
  },

  async importArticlesFromCSVFile(data: any, suppIdToImport: number): Promise<IImportedResultDTOData> {
    const result = await axios.put(ppConstants.API_HOST_SEC + 'externalsuppliers/' + suppIdToImport + '/csv', data, {
      headers: {
        'Content-Type': 'text/csv'
      }
    });
    return result.data;
  }
};

export default ExternalArticleService;
