export const qkArticleSearch = 'article-search';
export const qkArticleSuggestions = 'article-suggestions';
export const qkArticleQuickSearch = 'article-quick-search';
export const qkCatalogComplete = 'catalog-complete';
export const qkCatalogSearch = 'catalog-search';
export const qkBrands = 'filter-brands';
export const qkOwnBrands = 'filter-own-brands';
export const qkEcoScore = 'filter-ecoscore';
export const qkLabels = 'filter-labels';
export const qkRohstoffHerkunft = 'filter-rohstoffherkunft';
export const qkSupplierGroups = 'filter-supplier-groups';
export const qkTgsSupplierGroups = 'filter-tgs-supplier-groups';
export const qkHergestellt = 'filter-hergestellt';
export const qkSpecialDiet = 'filter-special-diet';
export const qkSpecialAssortments = 'filter-special-assortments';
export const qkAllergen = 'filter-allergen';
export const qkFilterTkCount = 'filter-tk-count';
export const qkHasPurchasedArticles = 'has-purchased-articles';
export const qkFilterSalesOrderSetCount = 'filter-salesorderset-count';
export const qkFilterActionsCount = 'filter-actions-count';
export const qkWineSocieties = 'filter-wine-societies';
export const qkFilterNoveltiesCount = 'filter-novelties-count';
export const qkHwgs = 'filter-hwgs';

const qkArticleIndividualPriceInfo = 'article-individual-price-info';
const qkArticleIndividualOrderEndTime = 'article-individual-order-end-time';
const qkArticleIndividualSpecialDeliveryDate = 'article-individual-special-next-delivery';

export function getArticleIndividualPriceInfoQueryKey(mainArticleId: number) {
  return [qkArticleIndividualPriceInfo, mainArticleId];
}

export function getArticleIndividualOrderEndTimeQueryKey(mainArticleId: number) {
  return [qkArticleIndividualOrderEndTime, mainArticleId];
}

export function getArticleIndividualSpecialDeliveryDatesQueryKey(mainArticleId: number) {
  return [qkArticleIndividualSpecialDeliveryDate, mainArticleId];
}

export const ARTICLE_STALE_TIME = 1000 * 60 * 30; // 30 minutes
export const CATALOG_STALE_TIME = 1000 * 60 * 60 * 2; // 2 hours
