import { IFactPiktoDTOData } from '../../../../api';
import { makeAutoObservable } from 'mobx';
import { getMLSelector } from '../../../../util/i18n';

export class FactPicto {
  celumId: number;
  text: string;

  constructor(dto: IFactPiktoDTOData) {
    makeAutoObservable(this);
    const mls = getMLSelector();

    this.celumId = dto.celumId ? dto.celumId : 0;
    this.text = dto.texts ? mls(dto.texts) : '';
  }
}
