import { redirect } from 'react-router-dom';

export const BASE_PATH = getBasePath();

function getBasePath() {
  // 2024-06-10, jonas:
  // Aktuell verewenden wir den Hash-Router ...
  // Folgende Methode um den base-path zu ermitteln funktioniert nur dem Hash-Router,
  // sobald wir auf den BrowserRouter umstellen, muss diese Methode angepasst werden ... der effizienteste Weg wird wohl sein einen ENV-Parameter zu verwenden ...

  const path = window.location.pathname;
  // window.location.pathname kann `/shop/` oder `/shop/index.html` sein ...
  return path.substring(0, path.lastIndexOf('/') + 1) + '#/';
}

/**
 * Liefert den Wert eines Query-Parameters aus der mitgegebenen URL oder der aktuellen URL.
 * Der Wert des Query-Parameter wird so zurückgeliefert, wie er in der URL steht.
 * Falls der Wert URL-encoded ist, wird er *nicht* decodiert.
 */
export function getQueryParameterByNameRaw(name: string, url?: string) {
  if (!url) {
    url = window.location.href;
  }
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);

  let paramValueRaw: string | undefined = undefined;

  if (results) {
    if (results[2]) {
      paramValueRaw = results[2];
    } else {
      paramValueRaw = '';
    }
  }
  return paramValueRaw;
}

/**
 * Liefert den Wert eines Query-Parameters aus der mitgegebenen URL oder der aktuellen URL.
 * Der Wert des Query-Parameter wird URL-decoded zurückgeliefert.
 */
export const getQueryParameterByName = (name: string, url?: string): string | undefined => {
  const paramValueRaw = getQueryParameterByNameRaw(name, url);
  console.log(`PARAM RAW VALUE ${name}: ${paramValueRaw}`);
  if (!paramValueRaw) {
    return paramValueRaw;
  }
  return decodeURIComponent(paramValueRaw.replace(/\+/g, ' '));
};

export function redirectIfPathAndQueryParamsAreNotIdentical(url1: string, url2: string) {
  const parseUrl = (url: string) => {
    const { pathname, searchParams } = new URL(url, 'https://www.transgourmet.ch'); // Use a dummy base URL for parsing
    const sortedParams = new URLSearchParams(Array.from(searchParams.entries()).sort());
    return { pathname, sortedParams: sortedParams.toString() };
  };

  const { pathname: pathname1, sortedParams: sortedParams1 } = parseUrl(url1);
  const { pathname: pathname2, sortedParams: sortedParams2 } = parseUrl(url2);

  const identical = pathname1 === pathname2 && sortedParams1 === sortedParams2;
  if (!identical) {
    return redirect(url2);
  }
}
