import { ICustomerOrderPositionData, ICustomerOrderPositionStateData } from '../../../api';
import { makeAutoObservable } from 'mobx';
import { getMLSelector } from '../../../util/i18n';
import { ppConstants } from '../../../util/ppConstants';
import ModifyPositionIntent from '../models/intent/ModifyPositionIntent';
import CartStore from './cartStore';
import debounce from 'lodash/debounce';
import BaseIntent from '../models/intent/BaseIntent';
import i18n from 'i18next';

export default class CustomerOrderPosition {
  id: number;
  articleBaseNo: string;
  state: ICustomerOrderPositionStateData;
  orderQuantity: number;
  container: boolean;
  cancelAllowed: boolean;
  deliveryDate: any; // TODO: Check type
  total: number;
  articlePriceCode: number;
  weight: number;
  sellAmount: number;
  mainCustomerOrderPositionId: number;
  price: number;
  articleDescriptionText: string;
  action: boolean;
  showAction: boolean;
  position: number;
  blocked: boolean;
  provisionSupplierGroupId: number;
  sellUnitType: number;
  articleTextUnitType: number;
  profitPlusPosition: boolean;

  inputOrderQuantity: number;
  deleteInProgress = false;

  constructor(dto: ICustomerOrderPositionData) {
    makeAutoObservable(this);

    const mls = getMLSelector();

    this.id = dto.id;
    this.articleBaseNo = dto.articleBaseNo;
    this.orderQuantity = dto.orderQuantity;
    this.state = dto.state;
    this.container = dto.container;
    this.cancelAllowed = dto.cancelAllowed;
    this.deliveryDate = dto.deliveryDate;
    this.total = dto.total;
    this.articlePriceCode = dto.articlePriceCode;
    this.weight = dto.weight;
    this.sellAmount = dto.sellAmount;
    this.mainCustomerOrderPositionId = dto.mainCustomerOrderPositionId;
    this.price = dto.price;
    this.articleDescriptionText = mls(dto.articleDescriptionText);
    this.action = dto.action;
    this.showAction = dto.showAction;
    this.position = dto.position;
    this.blocked = dto.blocked;
    this.provisionSupplierGroupId = dto.provisionSupplierGroupId;
    this.sellUnitType = dto.sellUnitType;
    this.articleTextUnitType = dto.articleTextUnitType;
    this.profitPlusPosition = dto.profitPlusPosition;
    this.inputOrderQuantity = dto.orderQuantity;
  }

  get detailLink() {
    return '/catalog/' + this.articleBaseNo;
  }

  get displayAsAction() {
    return this.action && this.showAction;
  }

  getSellUnit(sellUnit: any, articleUnit: any) {
    const sellAmountText = this.sellAmount > 1 ? sellUnit + ` ${i18n.t('sellUnitSeparator')} ` : '';
    const priceCodeText = this.articlePriceCode === ppConstants.PRICECODE_WEIGHTCONTROLLED ? this.weight.toFixed(3) : this.sellAmount;

    return sellAmountText + priceCodeText + ' ' + articleUnit;
  }

  changeInputQuantity(quantity: number) {
    this.inputOrderQuantity = Math.max(quantity || 0, 0);
  }

  toogleDeleteInProgress() {
    this.deleteInProgress = !this.deleteInProgress;
  }

  update(dto: ICustomerOrderPositionData) {
    const mls = getMLSelector();

    this.articleBaseNo = dto.articleBaseNo;
    this.orderQuantity = dto.orderQuantity;
    this.state = dto.state;
    this.container = dto.container;
    this.cancelAllowed = dto.cancelAllowed;
    this.deliveryDate = dto.deliveryDate;
    this.total = dto.total;
    this.articlePriceCode = dto.articlePriceCode;
    this.weight = dto.weight;
    this.sellAmount = dto.sellAmount;
    this.mainCustomerOrderPositionId = dto.mainCustomerOrderPositionId;
    this.price = dto.price;
    this.articleDescriptionText = mls(dto.articleDescriptionText);
    this.action = dto.action;
    this.showAction = dto.showAction;
    this.position = dto.position;
    this.blocked = dto.blocked;
    this.provisionSupplierGroupId = dto.provisionSupplierGroupId;
    this.sellUnitType = dto.sellUnitType;
    this.articleTextUnitType = dto.articleTextUnitType;
    this.profitPlusPosition = dto.profitPlusPosition;
    this.inputOrderQuantity = dto.orderQuantity;
  }

  commit(store: CartStore) {
    if (this.orderQuantity !== this.inputOrderQuantity) {
      const positionDeliveryDate = new Date(this.deliveryDate);
      this.addIntentToCartStore(new ModifyPositionIntent(store, this.id, this.inputOrderQuantity, positionDeliveryDate, this.articleBaseNo), store);
    }
  }

  private addIntentToCartStore = debounce((intent: BaseIntent, store: CartStore) => {
    store.addIntent(intent);
  }, ppConstants.DEBOUNCE_TIME);
}
