import React from 'react';
import { useTranslation } from 'react-i18next';
import { Article, NutritionalValue, nutritionalValues } from '../../models/article';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { observer } from 'mobx-react-lite';
import TableHead from '@mui/material/TableHead';
import { makeStyles } from 'tss-react/mui';

export interface IProductInfoSheetProps {
  article: Article;
}

const useStyles = makeStyles()(() => {
  return {
    tableRoot: {
      width: 'auto'
    },
    headerCell: {
      fontWeight: 600
    },
    indentCell: {
      paddingLeft: 30
    }
  };
});

const ProductInfoSheet = observer(({ article }: IProductInfoSheetProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  if (!article.showFactSheet || !article.hasNutritionalValues) {
    return null;
  }

  return (
    <Table size={'small'} classes={{ root: classes.tableRoot }} id={'product-info-table'}>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} align={'right'} style={{ minWidth: 120 }} className={classes.headerCell}>
            {t('100 g / 100 ml enthalten im Durchschnitt')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {nutritionalValues.map((value: NutritionalValue) => {
          let nutritionValue = (article as any)[value.valueKey];
          if (!nutritionValue) {
            nutritionValue = t('k.A.');
          }
          if (value.valueKey === 'saturatedFatty' || value.valueKey === 'monoUnsaturatedFatty' || value.valueKey === 'polyUnsaturatedFatty' || value.valueKey === 'sugar') {
            return (
              <TableRow key={value.valueKey}>
                <TableCell className={classes.indentCell}>{t(value.labelKey)}</TableCell>
                <TableCell align={'right'} id={'product-info-cell-' + value.valueKey}>
                  {nutritionValue}
                </TableCell>
              </TableRow>
            );
          } else {
            return (
              <TableRow key={value.valueKey}>
                <TableCell>{t(value.labelKey)}</TableCell>
                <TableCell align={'right'} id={'product-info-cell-' + value.valueKey}>
                  {nutritionValue}
                </TableCell>
              </TableRow>
            );
          }
        })}
      </TableBody>
    </Table>
  );
});

export default ProductInfoSheet;
