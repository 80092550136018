import React, { useContext } from 'react';
import { Theme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ProductInformationDescription from './ProductInformationDescription';
import { Article } from '../../models/article';
import { observer } from 'mobx-react-lite';
import ProductInfoSheet from './ProductInfoSheet';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ProductInformationDownloadsAndLinks from './ProductInformationDownloadsAndLinks';
import { StoreContext } from '../../../../../StoreContext';
import { EcoScore } from './EcoScore';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(({ spacing }: Theme) => {
  return {
    title: {
      marginTop: spacing(2),
      marginBottom: spacing(1)
    },
    tabs: {
      marginBottom: spacing(2)
    }
  };
});

interface IProductInformationProps {
  article: Article;
}

function ProductInformationComponent({ article }: Readonly<IProductInformationProps>) {
  const { userStore } = useContext(StoreContext);
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container direction={'column'}>
      {article.description && (
        <Grid item marginTop={2}>
          <Typography variant={'body1'} display={'block'}>
            {article.description}
          </Typography>
        </Grid>
      )}
      <Grid item>
        <Typography className={classes.title} variant={'subtitle1'}>
          {t('Beschreibung')}
        </Typography>
        <ProductInformationDescription article={article} />
      </Grid>
      {article.articleOrZzArticle.ecoscoreLabel && (
        <Grid item>
          <Typography className={classes.title} variant={'subtitle1'}>
            ECO-SCORE®
          </Typography>
          <EcoScore article={article.articleOrZzArticle} />
        </Grid>
      )}
      <Grid item>
        <Grid container justifyContent={'space-between'}>
          {article.articleOrZzArticle && article.articleOrZzArticle.showFactSheet && article.hasNutritionalValues && (
            <Grid item>
              <Typography className={classes.title} variant={'subtitle1'}>
                {t('Nährwertkennzeichnung')}
              </Typography>
              <ProductInfoSheet article={article.articleOrZzArticle} />
            </Grid>
          )}

          {userStore.isLoggedIn && (
            <Grid item>
              <Typography className={classes.title} variant={'subtitle1'}>
                {t('Dokumente und Links')}
              </Typography>
              <ProductInformationDownloadsAndLinks article={article} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

const ProductInformation = observer(ProductInformationComponent);
export { ProductInformation };
