import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import NavigationDropDown from '../NavigationDropDown';
import { StoreContext } from '../../../../../StoreContext';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { ListItemText, MenuItem, MenuList } from '@mui/material';
import { FEATURES } from '../../../stores/featureStore';

export default observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userStore, featureStore } = useContext(StoreContext);

  if (!userStore.currentCustomer) {
    return null;
  }

  return (
    <NavigationDropDown
      title={t('Organisation')}
      menu={(close) => (
        <MenuList>
          <MenuItem
            onClick={(event) => {
              navigate('/downloads');
              close(event);
            }}
            id={'navigation-organisation-dropdown-downloads'}
          >
            <ListItemText primary={t('Downloads')} />
          </MenuItem>
          {featureStore.isFeatureEnabled(FEATURES.inventory) && (
            <MenuItem
              onClick={(event) => {
                navigate('/inventory');
                close(event);
              }}
              id={'navigation-organisation-dropdown-inventory'}
            >
              <ListItemText primary={t('Inventar')} id="Inventory" />
            </MenuItem>
          )}
          {featureStore.isFeatureEnabled(FEATURES.externalArticle) && (
            <MenuItem
              onClick={(event) => {
                navigate('/externalarticles');
                close(event);
              }}
              id={'navigation-organisation-dropdown-externalarticles'}
            >
              <ListItemText primary={t('Fremdartikel')} />
            </MenuItem>
          )}
        </MenuList>
      )}
      id="navigation-organisation-dropdown"
    ></NavigationDropDown>
  );
});
