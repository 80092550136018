import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountNav } from './navigation/AccountNav';
import Logo from './Logo';
import ShoppingCart from './ShoppingCart';
import { NotificationInfo } from '../../../../@tgs-web-ui/notificationcenter';
import NavigationDropDown from './NavigationDropDown';
import Grid from '@mui/material/Grid';
import LanguageSelector from './LanguageSelector';
import TemplateNav from './navigation/TemplateNav';
import OrganisationNav from './navigation/OrganisationNav';
import { StoreContext } from '../../../../StoreContext';
import CatalogNav from './navigation/CatalogNav';
import AppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import Toolbar from '@mui/material/Toolbar';
import { Search } from './search/Search';
import { Stack, styled, Theme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { QuickSearch } from '../../../../features/search/quicksearch/QuickSearch';

const NavigationContainerGrid = styled(Grid)(() => ({
  '&>div': {
    height: '100%',
    '& > button': {
      height: '100%',
      paddingLeft: 15,
      paddingRight: 15
    }
  },
  '& > div:not(:last-child)': {
    marginRight: (theme: Theme) => theme.spacing(1)
  },
  '& div:first-of-type': {
    marginLeft: (theme: Theme) => theme.spacing(1)
  }
}));

export interface IHeaderProps {
  showLanguageSwitch: boolean;
  onSwitchLanguage: (language: any) => any;
}

export const Header = observer(({ showLanguageSwitch, onSwitchLanguage }: IHeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userStore, featureStore, masterDataStore, uiStore } = useContext(StoreContext);

  const switchLanguage = (locale: any) => {
    onSwitchLanguage(locale);
  };

  return (
    <AppBar
      color={'inherit'}
      position={'fixed'}
      elevation={0}
      sx={{
        backgroundColor: 'white',
        height: '210px',
        maxWidth: '1920px',
        left: '50%',
        transform: 'translateX(-50%)'
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: (theme) => theme.palette.secondary.light,
          height: '150px',
          padding: 0
        }}
      >
        <Grid
          container
          direction={'column'}
          justifyContent={'space-between'}
          wrap={'nowrap'}
          sx={{
            paddingTop: 1,
            paddingRight: 1,
            paddingLeft: 0,
            backgroundColor: (theme) => theme.palette.secondary.light,
            height: '100%'
          }}
        >
          <Grid item>
            <Grid container justifyContent={'space-between'} wrap={'nowrap'} alignItems={'center'}>
              <Grid item ml={1}>
                <Logo width={200} />
              </Grid>
              <Grid item>{featureStore.enableHubSearchNew ? <QuickSearch /> : <Search />}</Grid>
              <Grid item>
                <Stack alignItems={'center'} spacing={2} divider={<Divider orientation="vertical" flexItem />} direction={'row'}>
                  {masterDataStore.hasNotifications && <NotificationInfo onOpen={() => masterDataStore.openNotificationDialog()} tooltipText={t('Benachrichtigungen')} count={masterDataStore.unreadNotificationCount} />}
                  {showLanguageSwitch ? <LanguageSelector onSwitchLanguage={switchLanguage} /> : null}
                  <AccountNav />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ width: '100%' }}>
            <NavigationContainerGrid container wrap={'nowrap'} mt={1} height={50} pb={0} alignItems={'center'}>
              <Grid item>
                <CatalogNav />
              </Grid>
              {userStore.currentCustomer && (
                <Grid item>
                  <TemplateNav />
                </Grid>
              )}
              {userStore.currentCustomer && (
                <Grid item>
                  <NavigationDropDown
                    title={t('Aufträge')}
                    id="navigation-orders-button"
                    onClick={() => {
                      navigate('/order');
                    }}
                  />
                </Grid>
              )}
              {userStore.currentCustomer && (
                <Grid item>
                  <OrganisationNav />
                </Grid>
              )}
              <Grid item sx={{ flexGrow: 1, textAlign: 'right' }}>
                <ShoppingCart />
              </Grid>
            </NavigationContainerGrid>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
      <Toolbar
        sx={{
          height: '60px',
          backgroundColor: (theme) => theme.palette.grey['50'],
          paddingLeft: (theme) => theme.spacing(2),
          pt: 1,
          pr: 1
        }}
      >
        <Grid container justifyContent={'space-between'} alignItems={'center'}>
          <Grid
            item
            ref={(ref) => {
              uiStore.setBreadcrumbRef(ref);
            }}
          />
          <Grid
            item
            sx={{ height: '60px', pl: 2, pr: 0 }}
            ref={(ref) => {
              uiStore.setToolbarRef(ref);
            }}
          />
        </Grid>
      </Toolbar>
    </AppBar>
  );
});
