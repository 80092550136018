import { Theme } from '@mui/material';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(({ palette, typography }: Theme) => {
  return {
    buttonLink: {
      fontFamily: typography.body2.fontFamily,
      fontSize: typography.body2.fontSize,
      color: palette.primary.main,
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer',
      textDecoration: 'none',
      display: 'inline',
      margin: 0,
      padding: 0,
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  };
});

interface IButtonLinkProps {
  onClick: () => void;
  children: any;
  id?: string;
}

const ButtonLink = (props: IButtonLinkProps) => {
  const { classes } = useStyles();

  const handleClick = (event: any) => {
    event.preventDefault();
    props.onClick();
  };

  return (
    <button onClick={handleClick} className={classes.buttonLink} id={props.id}>
      {props.children}
    </button>
  );
};

export default ButtonLink;
