import { AxiosError, AxiosPromise, AxiosResponse } from 'axios';
import type { IAvailabilityOptionData, ICheckExpirationDTOData } from '../../../../api';
import { ppConstants } from '../../../../util/ppConstants';
import CartService, { CancelReason } from '../../services/cartService';
import CartStore from '../../stores/cartStore';
import { IntentServerState } from '../../types/IntentServerState';
import { IntentState } from '../../types/IntentState';
import { getUserPrompter, UserPrompter } from '../UserPrompter';
import BaseIntent from './BaseIntent';

interface IInternalState {
  state: string;
  nextState?: string;
  serverResponse?: AxiosResponse;
  option?: IAvailabilityOptionData[];
}

export default class AbstractOrderIntent extends BaseIntent {
  public internalState: IInternalState = {
    state: IntentServerState.INITIAL,
    serverResponse: undefined
  };
  public resolveCnt = 0;
  protected cartStore: CartStore;
  protected userPrompter: UserPrompter;

  constructor(cartStore: CartStore) {
    super();
    this.cartStore = cartStore;
    this.userPrompter = getUserPrompter(cartStore);
  }

  public async internalProcess(resolve: () => Promise<void>, reject: (reason?: any) => Promise<void>): Promise<any> {
    if (this.resolveCnt > 3000) {
      return reject('IllegalStateException');
    }

    switch (this.internalState.state) {
      case IntentServerState.SERVER_RESPONSE_CART_EXPIRATION: {
        await this.handleCartExpiration();
        break;
      }
      case IntentServerState.SERVER_RESPONSE_TEMP_CASH: {
        await this.handleTempCash(reject);
        break;
      }
      case IntentServerState.SERVER_RESPONSE_ERROR: {
        return reject('server error: ' + this.internalState.serverResponse);
      }
    }

    try {
      await this.internalOrderIntentProcess(resolve, reject, this.cartStore);
    } catch (error) {
      const e = error as AxiosError;
      this.internalState = {
        state: IntentServerState.SERVER_RESPONSE_ERROR,
        nextState: undefined,
        serverResponse: e.response
      };
      this.handleError();
      return reject('server error: ' + this.internalState.serverResponse);
    }

    if (this.intentState !== IntentState.DONE) {
      this.internalProcess(resolve, reject);
    }
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  public internalOrderIntentProcess(resolve: () => Promise<void>, reject: (reason?: any) => Promise<void>, storeState: any) {
    throw new Error('AbstractOrderIntent.internalOrderIntentProcess: this is an abstract method and must be overriden by a subclass!');
  }

  /* eslint-enable @typescript-eslint/no-unused-vars */

  public async handleServiceCallResponse(request: AxiosPromise<any>, nextState: any): Promise<any> {
    try {
      const response = await request;

      if (response.data.tempCash) {
        this.internalState = {
          state: IntentServerState.SERVER_RESPONSE_TEMP_CASH,
          nextState,
          serverResponse: response
        };
        return Promise.resolve();
      }

      if (Array.isArray(response.data)) {
        for (const data of response.data) {
          if (data.expired || data.expirationReason) {
            this.internalState = {
              state: IntentServerState.SERVER_RESPONSE_CART_EXPIRATION,
              nextState,
              serverResponse: response
            };
            return Promise.resolve();
          }
        }
      }

      if (response.data.expired || response.data.expirationReason) {
        this.internalState = {
          state: IntentServerState.SERVER_RESPONSE_CART_EXPIRATION,
          nextState,
          serverResponse: response
        };
        return Promise.resolve();
      }

      this.internalState = {
        state: nextState,
        serverResponse: response
      };

      return response;
    } catch (error) {
      const e = error as AxiosError;
      this.internalState = {
        state: IntentServerState.SERVER_RESPONSE_ERROR,
        nextState,
        serverResponse: e.response
      };

      return Promise.reject(e);
    }
  }

  private async handleCartExpiration(): Promise<any> {
    let responseData: ICheckExpirationDTOData;

    if (Array.isArray(this.internalState.serverResponse!.data) && this.internalState.serverResponse!.data[0]) {
      responseData = this.internalState.serverResponse!.data[0];
    } else {
      responseData = this.internalState.serverResponse!.data;
    }

    const reason = responseData.expirationReason;
    const action = await this.userPrompter.userCartExpirationRequest(reason);

    if (responseData.expirationReason === ppConstants.INVALID_DELIVERY_DATE) {
      if (responseData.deliveryCalendar?.deliveryDates) {
        this.cartStore.rootStore.userStore.deliveryCalendarExpired(responseData.deliveryCalendar.deliveryDates);
      }
    }
    if (action.type === 'DISCARD') {
      await CartService.cancelOrder(responseData.customerOrderId, CancelReason.ORDER_EXPIRED);
      this.cartStore.resetCartState();
    } else if (action.type === 'REFRESH') {
      await CartService.changeDate(responseData.customerOrderId, action.deliveryDate);
    }

    this.internalState = {
      state: IntentServerState.INITIAL,
      serverResponse: undefined
    };
  }

  private async handleTempCash(reject: (reason?: any) => Promise<void>): Promise<any> {
    if (this.cartStore.isTempCashAccepted) {
      // TempCash ist bereits akzeptiert => continue
      this.internalState.state = this.internalState.nextState!; // check if it is working !?!
    } else {
      const accepted = await this.userPrompter.userTempCashRequest();
      if (accepted) {
        if (this.cartStore.order) {
          await CartService.setTempCash(this.cartStore.order.id);
        }
        this.internalState.state = IntentServerState.INITIAL;
      } else {
        return reject('temp cash not accepted');
      }
    }
  }

  private async handleError(): Promise<any> {
    if (this.internalState?.serverResponse) {
      let errorMsg = this.internalState.serverResponse.data;
      if (errorMsg?.message) {
        errorMsg = errorMsg.message;
      }

      // format error message
      errorMsg = errorMsg.replaceAll('[', '');
      errorMsg = errorMsg.replaceAll(']', '');
      errorMsg = errorMsg.replaceAll('DeliveryAssortment', '');

      this.cartStore.currentError = {
        message: errorMsg,
        default: () => {
          if (this.internalState?.serverResponse?.status === 409) {
            //Conflict - Reload Page
            window.location.reload();
          }
          this.cartStore.cancelCartItem();
        }
      };
    }
  }
}
