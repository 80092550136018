import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import ButtonLink from '../../common/components/ButtonLink';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, useMediaQuery, useTheme } from '@mui/material';
import { StoreContext } from '../../../StoreContext';
import { useTranslation } from 'react-i18next';

const ErrorDialog = observer(() => {
  const { masterDataStore } = useContext(StoreContext);
  const [showDetails, setShowDetails] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const okPressed = () => {
    if (masterDataStore.globalError.handler) {
      masterDataStore.globalError.handler();
      masterDataStore.clearGlobalError();
    } else {
      window.location.reload();
    }
  };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  if (!masterDataStore.globalError) {
    return null;
  }

  const message = masterDataStore.globalError.message ? masterDataStore.globalError.message : t('Bitte versuchen Sie es später wieder.');

  let detailsLinkText;
  let detailsPanel;

  if (masterDataStore.globalError.details) {
    detailsLinkText = showDetails ? <span>{t('Details verbergen...')}</span> : <span>{t('Details anzeigen...')}</span>;

    if (showDetails) {
      const detailsStyle = {
        overflow: 'scroll',
        height: '300px'
      };

      detailsPanel = <div style={detailsStyle}>{JSON.stringify(masterDataStore.globalError.details)}</div>;
    }
  }

  return (
    <Dialog fullScreen={fullScreen} open={!!masterDataStore.globalError}>
      <DialogTitle>{t('Ein Fehler ist aufgetreten!')}</DialogTitle>
      <DialogContent>
        <p>{message}</p>
        <ButtonLink onClick={toggleDetails}>{detailsLinkText}</ButtonLink>
        {detailsPanel}
      </DialogContent>
      <DialogActions>
        <Button onClick={okPressed} variant={'contained'} color="primary" autoFocus>
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default ErrorDialog;
