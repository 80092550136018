import React from 'react';
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import { Article } from '../../../../shared/articles/models/article';
import List from '@mui/material/List';
import SearchArticleItem from './SearchArticleItem';
import useTheme from '@mui/material/styles/useTheme';
import SearchSuggestionItem from './SearchSuggestionItem';
import Box from '@mui/material/Box';

interface SearchResultsProps {
  searchTerm: string;
  suggestions: string[];
  articles: Article[];
  onArticleClicked: (article: Article) => void;
  onSearchSuggestionClicked: (term: string) => void;
}

const SearchResults = ({ searchTerm, suggestions, articles, onArticleClicked, onSearchSuggestionClicked }: SearchResultsProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (suggestions.length < 1 && articles.length < 1) {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant={'body1'} align={'center'}>
          {t('Keine Artikel für diese Suche!')}
        </Typography>
      </Box>
    );
  }

  return (
    <Grid container p={1} wrap={isSmallScreen ? 'wrap' : 'nowrap'}>
      <Grid item sx={{ p: 1, minWidth: 250, width: isSmallScreen ? '100%' : 'auto' }}>
        {suggestions.length > 0 && (
          <>
            <Typography variant={'body2'} gutterBottom={true}>
              {t('Suchvorschläge')}
            </Typography>
            <List id={'search-suggestions-list'}>
              {suggestions.map((suggestion: string, index: number) => (
                <SearchSuggestionItem key={index} searchTerm={searchTerm} suggestion={suggestion} onSearchSuggestionClicked={onSearchSuggestionClicked} id={'search-suggestions-' + index} />
              ))}
            </List>
          </>
        )}
      </Grid>
      <Grid item>
        <Divider orientation={isSmallScreen ? 'horizontal' : 'vertical'} />
      </Grid>
      <Grid item p={1}>
        {articles.length > 0 && (
          <>
            <Typography variant={'body2'}>{t('Produktempfehlungen')}</Typography>
            <List id={'search-suggestions-article-list'}>
              {articles.map((article: Article, index: number) => (
                <SearchArticleItem key={article.articleId} article={article} onArticleClicked={onArticleClicked} id={'search-article-suggestions-' + index} />
              ))}
            </List>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default SearchResults;
