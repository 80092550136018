import type { IWebInventoryData, IWebInventoryPositionData } from '../../../api';
import { makeAutoObservable } from 'mobx';
import i18n from 'i18next';
import InventoryPosition from './inventoryPosition';
import { formatDate } from '../../../util/dateUtils';

export default class Inventory {
  id: number;
  positions: InventoryPosition[] = [];
  name: string;
  creationDate: Date;
  endDate?: Date;

  constructor(dto: IWebInventoryData) {
    makeAutoObservable(this);

    this.id = dto.id;
    this.name = dto.name;
    this.creationDate = new Date(dto.creationDate as any);
    this.endDate = dto.endDate ? new Date(dto.endDate as any) : undefined;
    this.positions = dto.positions.map((positionDto: IWebInventoryPositionData) => InventoryPosition.createFromInventoryPositionDto(positionDto));
  }

  update(dto: IWebInventoryData) {
    this.id = dto.id;
    this.name = dto.name;
    this.creationDate = new Date(dto.creationDate as any);
    this.endDate = dto.endDate ? new Date(dto.endDate as any) : undefined;
    this.positions = dto.positions.map((positionDto: IWebInventoryPositionData) => InventoryPosition.createFromInventoryPositionDto(positionDto));
  }

  get startFormatted() {
    return formatDate(this.creationDate);
  }

  get positionCount() {
    return this.positions ? this.positions.length : 0;
  }

  get endDateFormatted() {
    return this.endDate ? formatDate(this.endDate) : i18n.t('noch offen');
  }
}
