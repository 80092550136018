import { isObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, useMediaQuery, useTheme, MenuItem } from '@mui/material';
import { StoreContext } from '../../../StoreContext';
import TextField from '@mui/material/TextField';
import notifications from '../../../notifications';

const AddToInventoryDialog = observer(() => {
  const { inventoryStore } = useContext(StoreContext);
  const [selectedInventoryId, setSelectedInventoryId] = useState<number>();

  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const inventories = inventoryStore.openInventories || [];
    const i = inventories.findIndex((inventory) => selectedInventoryId === inventory.id);
    const isSelectedInventoryStillInInvetories = i >= 0;
    if (!selectedInventoryId || !isSelectedInventoryStillInInvetories) {
      setSelectedInventoryId(inventories.length > 0 ? inventories[0].id : undefined);
    }
  }, [inventoryStore, selectedInventoryId]);

  const handleOnEnter = () => {
    if (!selectedInventoryId || !isSelectedInventoryStillInInvetories()) {
      setSelectedInventoryId(inventoryStore.hasOpenInventories ? inventoryStore.openInventories[0].id : undefined);
    }
  };

  const handleOnExit = () => {
    inventoryStore.closeAddPositionDialog();
  };

  const isSelectedInventoryStillInInvetories = () => {
    const i = inventoryStore.openInventories.findIndex((inventory: any) => selectedInventoryId === inventory.id);
    return i >= 0;
  };

  const onChangeInventory = (value: string) => {
    setSelectedInventoryId(parseInt(value, 10));
  };

  const addArticleToInventory = async () => {
    if (selectedInventoryId) {
      if (isObservableArray(inventoryStore.articleToAdd)) {
        await inventoryStore.importInventoryPositions(selectedInventoryId, inventoryStore.articleToAdd);
      } else {
        const quantity = inventoryStore.articleToAdd.amount ? inventoryStore.articleToAdd.amount : 0;
        if (inventoryStore.articleToAdd.externalArticle) {
          await inventoryStore.addPosition(selectedInventoryId, inventoryStore.articleToAdd.articleId, quantity, true);
        } else {
          await inventoryStore.addPosition(selectedInventoryId, inventoryStore.articleToAdd.mainArticleId, quantity, false);
        }
      }

      inventoryStore.closeAddPositionDialog();
      notifications.emit('success', t('Artikel wurde erfolgreich zum Inventar hinzugefügt'));
    }
  };

  return (
    <Dialog
      open={inventoryStore.showAddToInventoryDialog}
      fullScreen={fullScreen}
      fullWidth={true}
      TransitionProps={{
        onEnter: handleOnEnter,
        onExit: handleOnExit
      }}
    >
      <DialogTitle>{t('Zu Inventarsatz hinzufügen')}</DialogTitle>
      <DialogContent>
        <form noValidate autoComplete="off">
          <Grid container item direction="column" spacing={2}>
            <Grid item>{t('In welchen Inventarsatz möchten Sie den Artikel hinzufügen?')}</Grid>
            {inventoryStore.hasOpenInventories ? (
              <Grid item>
                <TextField
                  variant={'outlined'}
                  label={t('Wählen Sie einen Inventarsatz aus')}
                  value={selectedInventoryId}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeInventory(event.target.value)}
                  select
                  fullWidth
                  autoFocus
                >
                  {inventoryStore.openInventories.map((inventory) => (
                    <MenuItem key={inventory.id} value={inventory.id}>
                      {inventory.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ) : null}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button size={'small'} onClick={handleOnExit} variant={'contained'}>
          {t('Abbrechen')}
        </Button>
        {inventoryStore.hasOpenInventories && (
          <Button size={'small'} onClick={addArticleToInventory} variant={'contained'} color="primary">
            {t('Hinzufügen')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
});

export default AddToInventoryDialog;
