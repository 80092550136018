import { makeAutoObservable, runInAction } from 'mobx';
import RootStore from '../../../rootStore';
import { groupBy, sortBy, WHITESPACE_BRAND } from '../../../util/filterConstants';
import { getMLSelector } from '../../../util/i18n';
import { Article } from '../../shared/articles/models/article';
import { Category } from '../../shared/category/models/category';
import { createCategories } from '../../shared/category/util/CategoryBuilder';
import SalesOrderSetService from '../services/salesOrderSetService';
import { SalesOrderSetFilter } from './models/salesOrderSetFilter';
import { executeAsyncAction } from '../../../util/exceptionHandler';

type TemplateItem = Category | Article;

export class SalesOrderSetStore {
  rootStore: RootStore;

  articles: Article[] = [];
  filter: SalesOrderSetFilter;
  pendingRequestsCount: number = 0;
  isSalesOrderSetExpanded: boolean = true;
  showNotInWebshopDialog: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.filter = new SalesOrderSetFilter();
  }

  get isLoading() {
    return this.pendingRequestsCount > 0;
  }

  get articleCount() {
    return this.articles ? this.articles.length : 0;
  }

  get categoriesForFilter(): Category[] {
    const mls = getMLSelector();
    let categories = [new Category(mls(['Alle anzeigen', 'Afficher tous', 'Mostra tutti']), [], undefined, true)];

    categories = categories.concat(this.categoriesGroupedBy);
    return categories;
  }

  get categoriesGroupedBy(): Category[] {
    const categories: Category[] = [];
    if (this.articles.length < 1) {
      return [];
    }

    for (const article of this.articles) {
      let categoryKey = (article as any)[this.filter.groupBy.name];

      if (categoryKey === null) {
        categoryKey = WHITESPACE_BRAND;
      }

      const foundCategory = categories.find((c) => c.key === categoryKey);

      if (!foundCategory) {
        categories.push(new Category(categoryKey, [], undefined, false));
      }
    }
    categories.sort((c1: Category, c2: Category) => {
      if (c1.key && c2.key) {
        return c1.key.localeCompare(c2.key);
      } else {
        return 0;
      }
    });
    return categories;
  }

  get filteredCategories(): Category[] {
    return createCategories(this.articles, this.filter.groupBy, this.filter.sortBy);
  }

  /**
   * Flat list for rendering in react virtualized
   */
  get items(): TemplateItem[] {
    const items: TemplateItem[] = [];

    const hasFilterTerm = !!this.filter.term && this.filter.term.length > 0;
    const hasGroupKeyFilter = this.filter.groupKey ? this.filteredCategories.find((c) => c.key === this.filter.groupKey) : undefined;

    const categories = hasGroupKeyFilter ? this.filteredCategories.filter((c) => c.key === hasGroupKeyFilter.key) : this.filteredCategories;

    for (const category of categories) {
      const articles = this.filter.isSelected ? category.articles.filter((a: Article) => a.isSelected === this.filter.isSelected) : category.articles;

      if (articles.length > 0) {
        items.push(category);
      }

      if (category.isExpanded) {
        for (const article of articles) {
          if (hasFilterTerm) {
            if (article.articleText.toLowerCase().indexOf(this.filter.term.toLowerCase()) !== -1 || (article.articleNumber && article.articleNumber.toLowerCase().indexOf(this.filter.term.toLowerCase()) !== -1)) {
              items.push(article);
            }
          } else {
            items.push(article);
          }
        }
      }
    }

    return items;
  }

  get selectedArticles(): Article[] {
    const items: Article[] = [];

    this.filteredCategories.forEach((category: Category) => {
      const articles = category.articles.filter((a) => a.isSelected);
      articles.forEach((a) => {
        items.push(a);
      });
    });

    return items;
  }

  get selectedNotInWebshopArticles(): Article[] {
    return this.selectedArticles.filter((a) => a.notInWebShop);
  }

  get isAllSelected() {
    return (
      this.items.length > 0 &&
      this.items.every((x: TemplateItem) => {
        if (x instanceof Category) {
          return x.isAllSelected;
        }
        // ignore articles
        return true;
      })
    );
  }

  updateSortByCriteria(sort: string) {
    switch (sort) {
      case sortBy.ARTNR.name:
        this.filter.sortBy = sortBy.ARTNR;
        break;
      case sortBy.ARTTXT.name:
        this.filter.sortBy = sortBy.ARTTXT;
        break;
    }
  }

  updateGroupByCriteria(group: string) {
    switch (group) {
      case groupBy.HWG.name:
        this.filter.groupBy = groupBy.HWG;
        break;
      case groupBy.HAGR.name:
        this.filter.groupBy = groupBy.HAGR;
        break;
      case groupBy.BRAND.name:
        this.filter.groupBy = groupBy.BRAND;
        break;
      case groupBy.LEDGERACCOUNT.name:
        this.filter.groupBy = groupBy.LEDGERACCOUNT;
        break;
    }
  }

  updateTerm(term: string) {
    this.filter.term = term;
  }

  updateGroupKeyCriteria(groupKey: string) {
    this.filter.groupKey = groupKey;
  }

  updateIsSelectedCriteria(isSelected: boolean) {
    this.filter.isSelected = isSelected;
  }

  fetchArticles() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        const articles = await SalesOrderSetService.fetchSalesOrderSetArticles();

        runInAction(() => {
          this.articles = articles.map((a) => Article.createFromDto(a, 0));
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => this.pendingRequestsCount--);
        }
      }
    );
  }

  toogleExpanded() {
    this.isSalesOrderSetExpanded = !this.isSalesOrderSetExpanded;
    this.items.forEach((item: TemplateItem) => {
      if (item instanceof Category) {
        if (this.isSalesOrderSetExpanded) {
          item.expand();
        } else {
          item.collapsed();
        }
      }
    });
  }

  selectAllArticles() {
    this.items.forEach((item: TemplateItem) => {
      if (item instanceof Category) {
        item.selectAll();
      }
    });
  }

  unSelectAllArticles() {
    this.items.forEach((item: TemplateItem) => {
      if (item instanceof Category) {
        item.unSelectAll();
      }
    });
  }

  addArticlesToCart() {
    if (this.selectedNotInWebshopArticles && this.selectedNotInWebshopArticles.length > 0) {
      this.showNotInWebshopDialog = true;
    } else {
      this.addToCart();
    }
  }

  addToCart() {
    const articles = this.selectedArticles.filter((a) => !a.notInWebShop);
    if (articles && articles.length > 0) {
      this.rootStore.cartStore.addArticlesToCart(articles);
    }
  }

  closeNotInWebshopDialog() {
    this.showNotInWebshopDialog = false;
    this.addToCart();
  }
}
