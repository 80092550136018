import { makeAutoObservable, runInAction } from 'mobx';
import RootStore from '../../../rootStore';
import { invoiceSortBy } from '../models/InvoiceConstants';
import InvoiceService from '../services/invoiceService';
import { Invoice } from './models/invoice';
import { InvoiceFilter } from './models/invoiceFilter';
import { executeAsyncAction } from '../../../util/exceptionHandler';

export default class InvoiceStore {
  rootStore: RootStore;
  invoices: Invoice[] = [];
  pendingRequestsCount = 0;
  filter: InvoiceFilter;
  start: number = 0;
  rows: number = 20;
  sortBy: string = invoiceSortBy.INVOICENR;
  sortByDesc: boolean = true;
  nextPageLoading: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.filter = new InvoiceFilter();
  }

  fetchInvoices() {
    executeAsyncAction(
      async () => {
        this.pendingRequestsCount++;
        this.start = 0;

        const invoices = await InvoiceService.fetchInvoices(this.filter.fromValue, this.filter.tillValue, this.sortBy, this.sortByDesc, this.start, this.rows);
        runInAction(() => {
          this.invoices = invoices.map((i) => new Invoice(i));
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.pendingRequestsCount--;
          });
        }
      }
    );
  }

  fetchMore() {
    executeAsyncAction(
      async () => {
        this.nextPageLoading = true;
        this.start = this.start + 20;
        const invoices = await InvoiceService.fetchInvoices(this.filter.fromValue, this.filter.tillValue, this.sortBy, this.sortByDesc, this.start, this.rows);
        runInAction(() => {
          invoices.forEach((i) => {
            this.invoices.push(new Invoice(i));
          });
        });
      },
      true,
      {
        finallyAction: () => {
          runInAction(() => {
            this.nextPageLoading = false;
          });
        }
      }
    );
  }

  updateFilter(data: { from?: Date; till?: Date }) {
    if (data.from) {
      this.filter.from = data.from;
    }

    if (data.till) {
      this.filter.till = data.till;
    }
  }

  toggleDatePickerFrom() {
    this.filter.togglePickerFrom();
  }

  toggleDatePickerTill() {
    this.filter.togglePickerTill();
  }

  updateSortCriteria(sortBy: string, sortDesc: boolean) {
    this.sortBy = sortBy;
    this.sortByDesc = sortDesc;

    this.fetchInvoices();
  }

  isRowLoaded(index: any) {
    return this.invoices.length > index;
  }

  get isLoading() {
    return this.pendingRequestsCount > 0;
  }
}
