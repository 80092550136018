import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Article } from '../models/article';
import { Grid, Popover, IconButton } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useOrderEndTimesLongText } from '../util/useOrderEndTimes';

const ACCESS_TIME_ICON_COLOR_VIOLETT = '#b858d0';
const ACCESS_TIME_ICON_COLOR_BROWN = '#6c3a14';

export interface IArticleOrderEndTimesProps {
  article: Article;
  id?: string;
}

const ArticleInfoOrderEndTimes = observer(({ article, id }: IArticleOrderEndTimesProps) => {
  const [anchorEl, setAnchorEl] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const orderEndTimesText = useOrderEndTimesLongText(article);

  const toogleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  if (!article.articleOrZzArticle.orderEndTimes) {
    return null;
  }

  const time = article.articleOrZzArticle.orderEndTimes[0];
  let icon = <AccessTimeIcon color={'info'} />;
  if (time <= '16') {
    icon = <AccessTimeIcon htmlColor={ACCESS_TIME_ICON_COLOR_VIOLETT} />;
  }

  if (time <= '12') {
    icon = <AccessTimeIcon htmlColor={ACCESS_TIME_ICON_COLOR_BROWN} />;
  }

  return (
    <>
      <IconButton onClick={toogleMenu} size={'small'} aria-label="info" id={id}>
        {icon}
      </IconButton>
      <Popover
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Grid container direction={'column'} sx={{ padding: '10px', overflowY: 'hidden' }} spacing={0}>
          <Grid container spacing={1} wrap="nowrap">
            <Grid item>{orderEndTimesText}</Grid>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
});

export default ArticleInfoOrderEndTimes;
