import { AxiosError } from 'axios';
import RootStore from '../rootStore';
import { PP_URL } from './ppConstants';

export type ErrorType = 'unauthorized' | 'forbidden' | 'conflict' | 'badRequest' | 'unexpected' | 'connectionTimeout';

export class AppError extends Error {
  public appErrorCode: ErrorType;
  public message = '';

  constructor(errorCode: ErrorType, message?: string) {
    super();
    // In order to check error type later with the `instanceof` operator, we have to mess with teh prototype
    // See: https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    // Also see: https://github.com/adriengibrat/ts-custom-error (a library we could use if we are going to have more custom error types)
    const setPrototypeOf = (Object as any).setPrototypeOf;
    setPrototypeOf ? setPrototypeOf(this, AppError.prototype) : ((this as any).__proto__ = AppError.prototype);

    this.appErrorCode = errorCode;
    this.message = message ?? '';
  }
}

function hasProp(obj: unknown, propName: string) {
  return typeof obj === 'object' && obj !== null && propName in obj;
}

export const handleException = (error: AxiosError | AppError) => {
  if (error instanceof AppError) {
    return error;
  }

  if (error.response) {
    if (error.response.status === 440 || error.response.status === 401) {
      return new AppError('unauthorized');
    } else if (error.response.status === 403) {
      return new AppError('forbidden');
    } else if (error.response.status === 409) {
      const message = hasProp(error.response.data, 'message') ? (error.response.data as any).message : `${error.response.data}`;
      console.error(message, error);
      return new AppError('conflict', message);
    } else if (error.response.status === 400) {
      const message = hasProp(error.response.data, 'message') ? (error.response.data as any).message : `${error.response.data}`;
      return new AppError('badRequest', message);
    }
  }

  if (error.code === 'ECONNABORTED') {
    // connection timeout
    return new AppError('connectionTimeout');
  }

  const message = `Error during async call! ${error.response ? error.response.status : error.message}`;
  console.error(message, error);
  return new AppError('unexpected', message);
};

export type IAsyncAction = () => Promise<any>;

export interface IExecuteAsyncActionConfig {
  errorAction?: (appError: AppError) => void;
  finallyAction?: () => void;
  throwable?: boolean;
}

export const executeAsyncActionNew = async (action: IAsyncAction, globalExceptionHandling = true) => {
  return executeAsyncAction(action, globalExceptionHandling, undefined, window.location.href);
};

export const executeAsyncAction = async (action: IAsyncAction, globalExceptionHandling = true, config?: IExecuteAsyncActionConfig, redirectUrl?: string) => {
  try {
    return await action();
  } catch (error) {
    const appError = handleException(error as any);

    if (globalExceptionHandling) {
      handleExceptionGlobal(appError, redirectUrl);
    } else {
      handleErrorConfig(appError, config);
    }
  } finally {
    if (config?.finallyAction) {
      config.finallyAction();
    }
  }
};

const handleErrorConfig = (appError: AppError, config?: IExecuteAsyncActionConfig) => {
  if (!config) {
    return;
  }

  if (config.throwable) {
    throw appError;
  } else {
    if (config.errorAction) {
      config.errorAction(appError);
    }
  }
};

const handleExceptionGlobal = (appError: AppError, redirectUrl?: string) => {
  switch (appError.appErrorCode) {
    case 'unauthorized':
      RootStore.getInstance().masterDataStore.setShowLoginTimeout();
      RootStore.getInstance().userStore.removeLoginData();
      document.location = redirectUrl ?? PP_URL.SHOP_URL;
      break;

    case 'badRequest':
    case 'conflict':
    case 'connectionTimeout':
    case 'unexpected':
      RootStore.getInstance().masterDataStore.setGlobalError(appError);
      document.location = redirectUrl ?? PP_URL.SHOP_URL;
      break;

    default:
      RootStore.getInstance().masterDataStore.setGlobalError(appError);
      document.location = redirectUrl ?? PP_URL.SHOP_URL;
      break;
  }
};
