import type { ICheckExpirationDTOData } from '../../../../api';
import CartService, { CancelReason } from '../../services/cartService';
import { IntentServerState } from '../../types/IntentServerState';
import AbstractOrderIntent from './AbstractOrderIntent';

export default class DiscardOrderIntent extends AbstractOrderIntent {
  public async internalOrderIntentProcess(resolve: () => Promise<void>, reject: (reason?: any) => Promise<void>) {
    switch (this.internalState.state) {
      case IntentServerState.INITIAL: {
        await this.handleInit();
        break;
      }
      case IntentServerState.SERVER_RESPONSE_ORDER_DISCARD: {
        await this.handleOrderDiscard(resolve, reject);
        break;
      }
    }
  }

  private async handleInit(): Promise<any> {
    await this.handleServiceCallResponse(CartService.cancelOrder(this.cartStore.order!.id, CancelReason.ORDER_CANCELED), IntentServerState.SERVER_RESPONSE_ORDER_DISCARD);
  }

  private async handleOrderDiscard(resolve: () => Promise<void>, reject: (reason?: any) => Promise<void>): Promise<any> {
    const data = this.internalState.serverResponse!.data as ICheckExpirationDTOData;
    if (data.result.mostImportantMessage) {
      await this.userPrompter.userAlertRequest(data.result.mostImportantMessage);
      reject('Alert');
    } else {
      this.cartStore.resetCartState();
      resolve();
    }
  }
}
