import { makeAutoObservable } from 'mobx';
import { IFacetFilterData } from '../../../../api';

export class CatalogFacetFilter {
  name = '';
  values: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  toDto() {
    return {
      name: this.name,
      values: this.values.slice()
    } as IFacetFilterData;
  }

  static createFromDto(dto: IFacetFilterData): CatalogFacetFilter {
    const facetFilter = new CatalogFacetFilter();
    facetFilter.name = dto.name ? dto.name : '';
    facetFilter.values = dto.values;
    return facetFilter;
  }
}
