import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { Button, CircularProgress, Typography } from '@mui/material';
import CartIcon from 'mdi-material-ui/Cart';
import EnvelopeIcon from 'mdi-material-ui/Email';
import { StoreContext } from '../../../../StoreContext';

interface IShoppingCartButtonProps {
  displayText: React.ReactNode;
}

const ShoppingCartButton = observer(({ displayText }: IShoppingCartButtonProps) => {
  const { userStore, cartStore } = useContext(StoreContext);

  const notLoggedIn = !(userStore.loginCustomer && userStore.loginCustomer!.displayName);

  let progress = null;
  if (cartStore.hasPendingIntents) {
    progress = <CircularProgress size={20} sx={{ ml: 1 }} />;
  }
  const cartIcon = cartStore.cartOverview && cartStore.cartOverview!.isOci ? <EnvelopeIcon /> : <CartIcon />;

  const shoppingCartButtonContent = (
    <>
      {cartIcon}
      <Typography variant={'body1'} color={'inherit'} ml={1}>
        {displayText}
      </Typography>
      {progress}
    </>
  );

  let shoppingCartButtonLinkDestination: any;
  if (notLoggedIn) {
    return (
      <Button variant="text" color={'primary'} id="loginLink" sx={{ height: '100%' }} onClick={() => userStore.triggerLoginFlow()}>
        {shoppingCartButtonContent}
      </Button>
    );
  } else {
    shoppingCartButtonLinkDestination = { pathname: '/cart' };
    return (
      <Button variant="text" color={'primary'} id="loginLink" sx={{ height: '100%' }} component={Link} to={shoppingCartButtonLinkDestination}>
        {shoppingCartButtonContent}
      </Button>
    );
  }
});

export default ShoppingCartButton;
