import type { IManagedAssortmentGroupData } from '../../api';
import { makeAutoObservable } from 'mobx';
import { ISelectable } from './ISelectable';

export class Category<T extends ISelectable> {
  get title() {
    return this.assortmentGroup ? this.assortmentGroup.title : this.key;
  }

  get hasAssortmentGroup(): boolean {
    return !!this.assortmentGroup;
  }

  key: string;
  items: T[] = [];
  assortmentGroup?: IManagedAssortmentGroupData;
  showAll?: boolean;
  isExpanded: boolean = false;
  supplierId?: number;

  constructor(key: string, items: T[], supplierId?: number, assortmentGroup?: IManagedAssortmentGroupData, showAll?: boolean) {
    makeAutoObservable(this);

    this.key = key;
    this.supplierId = supplierId;

    this.items = items;
    if (assortmentGroup) {
      this.assortmentGroup = assortmentGroup;
    }

    if (showAll) {
      this.showAll = showAll;
    }
  }

  selectAll = () => {
    this.items.forEach((item) => {
      item.isSelected = true;
    });
  };

  unSelectAll = () => {
    this.items.forEach((item) => {
      item.isSelected = false;
    });
  };

  toggleExpanded = () => {
    this.isExpanded = !this.isExpanded;
  };

  expand = () => {
    this.isExpanded = true;
  };

  collapsed = () => {
    this.isExpanded = false;
  };
}
