import type { ICustomerOrderTypeData, IWebshopCustomerOrderDTOData } from '../../../../api';
import findIndex from 'lodash/findIndex';
import { runInAction, makeAutoObservable } from 'mobx';
import AddPositionIntent from '../../../cart/models/intent/AddPositionIntent';
import CustomerOrderStore, { ArticlePosition } from '../customerOrderStore';
import { CustomerOrderArticlePosition } from './customerOrderArticlePosition';
import { CustomerOrderExternalArticlePosition } from './customerOrderExternalArticlePosition';
import { CustomerOrderSupplier } from './customerOrderSupplier';
import { CustomerOrderTotalTableRow } from './customerOrderTotalTableRow';

export const TAB_ARTICLE = 'article';
export const TAB_EXTERNAL_ARTICLE = 'externalArticle';

export class CustomerDetailOrder {
  get hasArticlePositions() {
    return this.positions.length > 0;
  }

  get hasExternalArticlePositions() {
    return this.externalArticlePositions.length > 0;
  }

  get hasPositionsWithPositiveQuantity() {
    const hasPositiveQuantity = (position: CustomerOrderArticlePosition | CustomerOrderExternalArticlePosition) => position.orderQuantity > 0;
    return this.positions.some(hasPositiveQuantity) || this.externalArticlePositions.some(hasPositiveQuantity);
  }

  get externalArticleSuppliers() {
    const suppliers: CustomerOrderSupplier[] = [];
    for (const extArtPos of this.externalArticlePositions) {
      const i = findIndex(suppliers, (x: CustomerOrderSupplier) => x.id === extArtPos.supplierId);
      if (i < 0) {
        const articles = [];
        articles.push(extArtPos);
        suppliers.push(new CustomerOrderSupplier(extArtPos.supplierId, extArtPos.supplierName, articles));
      } else {
        suppliers[i].articles.push(extArtPos);
      }
    }

    for (const supplier of suppliers) {
      supplier.articles.push(new CustomerOrderTotalTableRow(supplier.total));
    }
    return suppliers;
  }

  get getArticlePositions(): ArticlePosition[] {
    const positions: ArticlePosition[] = [];
    this.positions.forEach((x) => {
      positions.push(x);
    });
    positions.push(new CustomerOrderTotalTableRow(this.total));

    return positions;
  }

  get hasPendingIntent() {
    return this.store.rootStore.cartStore!.hasPendingIntents;
  }

  get selectedNotInWebshopArticles(): Array<{
    articleNumber: string;
    articleText: string;
  }> {
    const notInWebshop = this.positions.filter((a) => a.notInWebShop);
    return notInWebshop.map((p) => {
      return {
        articleNumber: p.articleBaseNo,
        articleText: p.articleText ? p.articleText : p.articleDescriptionText
      };
    });
  }

  store: CustomerOrderStore;

  id: number;
  total: number;
  remarks: string;
  reference: string;
  desiredDeliveryDate?: Date;
  street: string;
  housenumber: string;
  domizilPlzUndOrt: string;
  positions: CustomerOrderArticlePosition[];
  type: ICustomerOrderTypeData;
  externalArticlePositions: CustomerOrderExternalArticlePosition[];
  typeText: string;

  activeTab: string = TAB_ARTICLE;
  showNotInWebshopDialog: boolean = false;

  constructor(store: CustomerOrderStore, dto: IWebshopCustomerOrderDTOData) {
    makeAutoObservable(this);

    this.store = store;

    this.id = dto.id;
    this.total = dto.total ? dto.total : 0.0;
    this.remarks = dto.remarks;
    this.reference = dto.reference;
    this.desiredDeliveryDate = dto.desiredDeliveryDate ? new Date(dto.desiredDeliveryDate as any) : undefined;
    this.street = dto.street ? dto.street : '';
    this.housenumber = dto.housenumber ? dto.housenumber : '';
    this.domizilPlzUndOrt = dto.domizilPlzUndOrt ? dto.domizilPlzUndOrt : '';
    this.positions = dto.positions ? dto.positions.map((x) => new CustomerOrderArticlePosition(x)) : [];
    this.externalArticlePositions = dto.externalPositions.map((x) => new CustomerOrderExternalArticlePosition(x));
    this.type = dto.type;
    this.typeText = dto.typeText;
    this.activeTab = this.hasArticlePositions ? TAB_ARTICLE : TAB_EXTERNAL_ARTICLE;
  }

  setActiveTab = (tabName: string) => {
    runInAction(() => {
      this.activeTab = tabName;
    });
  };

  addArticlesToCart() {
    if (this.selectedNotInWebshopArticles && this.selectedNotInWebshopArticles.length > 0) {
      this.showNotInWebshopDialog = true;
    } else {
      this.addToCart();
    }
  }

  addToCart() {
    const positions = this.positions.filter((a) => !a.notInWebShop);
    if (positions && positions.length > 0) {
      positions.forEach((p) => {
        const addPositionIntent = new AddPositionIntent(this.store.rootStore.cartStore!, p.articleBaseNo, p.orderQuantity, false);
        this.store.rootStore.cartStore!.addIntent(addPositionIntent);
      });
    }

    this.externalArticlePositions.forEach((p) => {
      const addPositionIntent = new AddPositionIntent(this.store.rootStore.cartStore!, p.articleId.toString(), p.orderQuantity, true);
      this.store.rootStore.cartStore!.addIntent(addPositionIntent);
    });
  }

  closeNotInWebshopDialog() {
    this.showNotInWebshopDialog = false;
    this.addToCart();
  }
}
